import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Info from "@mui/icons-material/Info";
import millify from "millify";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AccountLists } from "./AccountLists";

import crunchbaseLogo from "../../../assets/img/crunchbase-logo.png";
import { FundingTotalAmountRange } from "../../../components/BlendedSearch/Filters/FundingTotalAmountRange";
import { IPODateRange } from "../../../components/BlendedSearch/Filters/IPODateRange";
import { LastFundingDateRange } from "../../../components/BlendedSearch/Filters/LastFundingDateRange";
import { LastFundingTotalAmountRange } from "../../../components/BlendedSearch/Filters/LastFundingTotalAmountRange";
import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { UploadCompanyList } from "../../../components/UploadCompanyList";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    selectAccountListsSearch,
    selectCurrentCompany,
    selectNoOfCompanies,
    submitSearchLite,
} from "../../../store/reducers/search/search.slice";
import {
    selectAccountLists,
    selectTotalCompanies,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";

import { CompanyIntent } from "@/components/BlendedSearch/CompanyIntent";
import { BSAutoComplete } from "@/components/BlendedSearch/Filters/components/BSAutoComplete";
import { BSAutocompleteGeography } from "@/components/BlendedSearch/Filters/components/BSAutocompleteGeography";
import { BSAutoCompleteTypeAhead } from "@/components/BlendedSearch/Filters/components/BSAutoCompleteTypeAhead";
import { BSSelect } from "@/components/BlendedSearch/Filters/components/BSSelect";
import Loader from "@/components/Loader/Loader";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { isBlendSearchCompaniesEnabled } from "@/store/reducers/signin/Signin.reducer";
import { Button, Checkbox, Tooltip, Typography } from "@/ui";

export default function SidebarForm() {
    const ref = useRef<HTMLDivElement | null>(null);
    const handleSubmit = () => {
        dispatch(submitSearchLite({ navigate }));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectNameFromUrl = searchParams.get("name");
    const errorInSearch = searchParams.get("error");

    const navigate = useNavigate();
    const checkBlendSearchCompaniesEnabled = useSelector(isBlendSearchCompaniesEnabled);
    const dispatch = useDispatch();
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearchLite.type));
    const [projectName, setProjectName] = useState(projectNameFromUrl);
    const [expanded, setExpanded] = useState(true);
    const currentCompany = useSelector(selectCurrentCompany);
    const { values: noOfCompaniesArr } = useSelector(selectNoOfCompanies);
    const noOfCompanies = noOfCompaniesArr[0]?.value?.toString();
    const totalCompanies = useSelector(selectTotalCompanies);
    const { values: selectedAccounts } = useSelector(selectAccountListsSearch);
    const accountLists = useSelector(selectAccountLists);
    const selectedAccountIds = selectedAccounts?.map((account) => account.value);

    const totalCompaniesInAccountList = selectedAccountIds?.length
        ? accountLists.reduce((acc, accountList) => {
              if (selectedAccountIds.includes(accountList._id)) {
                  return acc + (accountList?.salesnavcompany?.universeCount ?? 0);
              }
              return acc;
          }, 0)
        : 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let showSelectedNoOfCompanies: number = currentCompany?.values?.length;

    if (noOfCompanies === totalCompanies) {
        showSelectedNoOfCompanies = parseInt(totalCompanies, 10);
    }
    if (parseInt(noOfCompanies, 10) > 1) {
        showSelectedNoOfCompanies = parseInt(noOfCompanies, 10);
    }
    if (totalCompaniesInAccountList > 0) {
        showSelectedNoOfCompanies = totalCompaniesInAccountList;
    }

    return (
        <div className="max-h-[100vh] overflow-scroll">
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <>
                    <div ref={ref} className="bg-white rounded-md p-3 gap-2 overflow-auto  flex flex-col">
                        {projectId ? (
                            <Typography>{projectName}</Typography>
                        ) : (
                            <>
                                <input
                                    placeholder="Campaign Name"
                                    value={projectName}
                                    onChange={(e) => {
                                        setProjectName(e.target.value);
                                        if (!e.target.value) {
                                            searchParams.delete("name");
                                            setSearchParams(searchParams);
                                        }
                                    }}
                                    onBlur={() => {
                                        if (projectName) {
                                            setSearchParams({ name: projectName });
                                        }
                                    }}
                                    className="min-w-[300px] text-sm px-3 py-1 border-b border-gray-300 focus:outline-none focus:border-b focus:border-[#0891B2]"
                                    style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                                />
                                {errorInSearch && (
                                    <span className="text-xs text-red-500 mt-1">Campaign name is required</span>
                                )}
                            </>
                        )}

                        <Typography component={"b"} className="my-2">
                            People filters
                        </Typography>
                        <BSAutocompleteGeography id="geography" placeholder="Geography" />
                        <BSAutoComplete id="yearsOfExperience" placeholder="Total years of tenure" />
                        <BSAutoComplete id="currentCompanyExperience" placeholder="Current company tenure" />
                        <BSAutoComplete id="currentPositionExperience" placeholder="Current position tenure" />
                        <BSAutoCompleteTypeAhead id="jobTitle" placeholder="Business title" excludable />
                        <BSAutoCompleteTypeAhead
                            id="jobFunction"
                            placeholder="Business function"
                            excludable
                            freeSolo={false}
                        />
                        <BSAutoComplete id="keywords" placeholder="Keywords" freeSolo />
                        <BSAutoCompleteTypeAhead id="school" placeholder="School" excludable freeSolo={false} />
                        <BSAutoComplete id="degree" placeholder="Degree" freeSolo />
                        <BSAutoComplete id="profileLanguage" placeholder="Profile language" />
                        <BSAutoComplete id="seniority" placeholder="Seniority" />
                        <BSAutoCompleteTypeAhead id="industry" placeholder="Industry" excludable freeSolo={false} />
                        <BSAutocompleteGeography
                            id="companyHeadQuarterLocation"
                            placeholder="Company headquarter location"
                        />
                        <BSAutoComplete id="companyHeadcount" placeholder="Company headcount" />
                        <BSAutoComplete id="companyType" placeholder="Company type" />

                        <div className="flex flex-col gap-1 p-2 pt-1 rounded-md bg-[#f9fcff] border border-[rgba(0,0,0,0.2)] w-full">
                            {checkBlendSearchCompaniesEnabled ? (
                                <div>
                                    <div className="flex justify-between items-center">
                                        <Typography className="text-[14px] text-[rgba(0, 0, 0, 0.6)] font-semibold">
                                            Select companies
                                        </Typography>
                                        <button onClick={handleExpandClick}>
                                            <ArrowDropDownIcon />
                                        </button>
                                    </div>
                                    {showSelectedNoOfCompanies > 0 ? (
                                        <Typography className="text-[12px] text-[rgba(0, 0, 0, 0.6)] mt-[-5px] ml-[5px]">
                                            {showSelectedNoOfCompanies > 1
                                                ? `${millify(showSelectedNoOfCompanies)} companies selected`
                                                : `${millify(showSelectedNoOfCompanies)} company selected`}
                                        </Typography>
                                    ) : null}

                                    {expanded && (
                                        <div className="flex flex-col gap-1">
                                            {/* <CurrentCompany id="currentCompany" /> */}
                                            <BSAutoCompleteTypeAhead
                                                id="currentCompany"
                                                excludable={true}
                                                placeholder="Current Company"
                                            />
                                            <CompanyIntent />
                                            <>
                                                <UploadCompanyList id="companyCSVUrl" />
                                                <AccountLists />
                                            </>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col gap-1 rounded-md mt-1">
                                    <div className="flex justify-between items-center">
                                        <Typography className="text-[14px] text-[rgba(0, 0, 0, 0.6)] font-semibold">
                                            Companies
                                        </Typography>
                                    </div>
                                    <BSAutoCompleteTypeAhead
                                        id="currentCompany"
                                        excludable={true}
                                        placeholder="Current Company"
                                    />
                                    <CompanyIntent />
                                </div>
                            )}

                            <div className="my-5 flex items-center">
                                <hr className="flex-grow border-t border-gray-300" />
                                <span className="mx-2 text-gray-500">Or</span>
                                <hr className="flex-grow border-t border-gray-300" />
                            </div>
                            <div className="pt-2 flex flex-col">
                                <Typography component={"b"}>Additional company filters</Typography>
                                <Typography component={"i"} variant="caption" className="flex gap-1 items-center ml-6">
                                    powered by Crunchbase{" "}
                                    <img style={{ height: 20, width: 20 }} src={crunchbaseLogo} alt="crunchbase-logo" />
                                    <Tooltip
                                        title={
                                            "Filter additional companies using Crunchbase company search. This may lead to fewer results and is recommended for very specific searches"
                                        }
                                    >
                                        <Info fontSize="small" color="disabled" />
                                    </Tooltip>
                                </Typography>
                            </div>
                            <div className="flex flex-col gap-3 mt-2 mb-2">
                                <BSAutoComplete id="investorName" placeholder="Investor name" freeSolo />
                                <BSAutoComplete
                                    id="sharesInvestorsWith"
                                    placeholder="shares investors with (coming soon)"
                                    freeSolo
                                />
                                <div className="flex flex-row items-center gap-1">
                                    <Checkbox size="sm" className="mr-2" />
                                    <Typography className="text-[12px]">Actively hiring</Typography>{" "}
                                    <Typography component={"i"} className="text-[12px] text-[grey]">
                                        (coming soon)
                                    </Typography>
                                </div>
                                <BSSelect id="ipoStatus" label="IPO status" unit="" />
                                <IPODateRange id="ipoDateRange" />
                                <BSAutoComplete id="fundingType" placeholder="Funding type" />
                                <FundingTotalAmountRange id="fundingTotalAmountRange" />
                                <LastFundingDateRange id="lastFundingDateRange" />
                                <LastFundingTotalAmountRange id="lastFundingTotalAmountRange" />
                                <BSAutoComplete id="revenue" placeholder="Revenue" />
                            </div>
                            <Button variant="default" onClick={handleSubmit} disabled={isSubmittingSearch}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" variant="light" />
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
