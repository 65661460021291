import * as React from "react";

import { cn } from "@/lib/utils";

export interface SeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
    orientation?: "horizontal" | "vertical";
    variant?: "fullWidth" | "inset" | "middle";
    textAlign?: "left" | "center" | "right";
    children?: React.ReactNode;
    flexItem?: boolean;
    light?: boolean;
}

/**
 * A React component that renders a separator line with optional text content.
 * The separator can be oriented horizontally or vertically, and can have different
 * variants and text alignment.
 *
 * @param {string} className - Additional class names to apply to the separator.
 * @param {"horizontal" | "vertical"} [orientation="horizontal"] - The orientation of the separator.
 * @param {"fullWidth" | "inset" | "middle"} [variant="fullWidth"] - The variant of the separator.
 * @param {"left" | "center" | "right"} [textAlign="center"] - The text alignment for the separator content.
 * @param {React.ReactNode} children - The content to display within the separator.
 * @param {boolean} [flexItem=false] - Whether the separator should be a flex item.
 * @param {boolean} [light=false] - Whether the separator should use a lighter color.
 * @param {React.Ref<HTMLDivElement>} ref - The ref to be forwarded to the separator's root element.
 * @param {object} props - Additional props to be spread onto the separator's root element.
 *
 * @returns {JSX.Element} The rendered separator component.
 */
const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
    (
        {
            className,
            orientation = "horizontal",
            variant = "fullWidth",
            textAlign = "center",
            children,
            flexItem = false,
            light = false,
            ...props
        },
        ref
    ) => {
        const rootClassName = cn(
            "relative flex items-center",
            orientation === "horizontal" ? "w-full" : "h-auto",
            variant === "inset" ? "ml-16" : "",
            variant === "middle" && !children ? "w-1/3 mx-auto" : "",
            flexItem && orientation === "vertical" ? "self-stretch" : "",
            children ? "justify-center" : "",
            className
        );

        const lineClassName = cn(
            "flex-grow shrink-0",
            orientation === "horizontal" ? "h-[1px] w-full" : "w-[1px] h-full",
            light ? "bg-gray-200" : "bg-gray-300"
        );

        const contentClassName = cn(
            "absolute flex px-2 bg-white",
            orientation === "horizontal" ? "top-1/2 -translate-y-1/2" : "left-1/2 -translate-x-1/2 flex-col",
            textAlign === "left" ? "left-0" : "",
            textAlign === "center" ? "left-1/2 -translate-x-1/2" : "",
            textAlign === "right" ? "right-0" : ""
        );

        return (
            <div ref={ref} className={rootClassName} {...props}>
                {children ? (
                    <>
                        <div className={lineClassName} />
                        <span className={contentClassName}>{children}</span>
                        <div className={lineClassName} />
                    </>
                ) : (
                    <div className={lineClassName} />
                )}
            </div>
        );
    }
);

Separator.displayName = "Separator";

export { Separator };
