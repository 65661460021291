import { hardRefresh } from "./refresh";

import { getlocalStorage } from "@/hooks/useLocalStorage";
import { cn } from "@/lib/utils";

/* eslint-disable */
const CACHE_NAME = "hq-cache-v1";
const ASSETS_TO_CACHE = [
    "/manifest.json",
    "/offline.html",
    // Add other assets that need to be cached
];

function createAlert(message: string) {
    if (document.getElementById("app-version-update-alert")) {
        return;
    }
    // Create search bar container
    const alert = document.createElement("div");
    alert.id = "app-version-update-alert";
    alert.className = cn(
        "flex flex-col bg-white p-4 text-black shadow-md rounded-md w-96",
        "fixed -top-[150px] right-1/2 transform translate-x-1/2 z-50 transition-all duration-300 ease-in-out",
        "shadow-[0_8px_16px_0_4px_8px_rgba(0, 0, 0, 0.08),0_-1px_3px_rgba(0, 0, 0, 0.05)]"
    );

    // Create search input
    const content = document.createElement("div");
    content.className = cn("text-sm");
    content.innerHTML = message;

    const btnContainer = document.createElement("div");
    btnContainer.className = cn("flex justify-between items-center mt-4");

    // Create close button
    const closeButton = document.createElement("button");
    closeButton.textContent = "Later";
    closeButton.className = cn(
        "cursor-pointer rounded-md bg-secondary px-3 py-2 text-[0.8125rem]/5 font-semibold border"
    );
    closeButton.title = "Close this alert, and continue using the current version";

    const confirmButton = document.createElement("button");
    confirmButton.textContent = "Update Now";
    confirmButton.className = cn(
        "cursor-pointer rounded-md bg-primary px-3 py-2 text-[0.8125rem]/5 font-semibold hover:bg-cyan-700 text-white"
    );
    confirmButton.title = "Upon clicking this button, the page will be refreshed, make sure to save your work";
    // Append input and close button to the search bar
    btnContainer.appendChild(closeButton);
    btnContainer.appendChild(confirmButton);

    alert.appendChild(content);
    alert.appendChild(btnContainer);

    // Add search bar to the document body
    document.body.appendChild(alert);

    // Trigger slide down animation by setting the top position
    setTimeout(() => {
        alert.style.top = "6px"; // Slide down to this position
    }, 300);

    closeButton.addEventListener("click", () => {
        alert.style.top = "-150px";
        setTimeout(() => {
            alert.remove();
            getlocalStorage.set("app-updated-at", new Date().getTime());
        }, 300);
    });

    confirmButton.addEventListener("click", async () => {
        alert.style.top = "-150px";
        await updateCache();
        setTimeout(() => {
            alert.remove();
            hardRefresh();
        }, 300);
    });
}

function notifyClients() {
    createAlert(`
        <p class="pb-2"><strong>Update available!</strong></p> 
        A new version is ready. Click "Update Now" to reload the screen and experience the latest features.
        `);
}

// Function to get the current version from the cache
async function getCurrentVersionFromCache() {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match("/manifest.json");
    if (!response) return null;

    const manifest = await response.json();
    return manifest.version;
}

async function updateCache() {
    const cache = await caches.open(CACHE_NAME);

    // Fetch and cache all assets again
    for (const url of ASSETS_TO_CACHE) {
        try {
            const response = await fetch(url, { cache: "no-cache" });
            if (response.ok) {
                await cache.put(url, response);
                console.log(`Updated cache for: ${url}`);
            } else {
                console.error(`Failed to fetch: ${url}`);
            }
        } catch (error) {
            console.error(`Error updating cache for ${url}:`, error);
        }
    }
}

async function checkForNewVersion() {
    try {
        const response = await fetch("/manifest.json", { cache: "no-cache" });

        if (!response.ok) {
            throw new Error("Failed to fetch manifest.json");
        }

        const manifest = await response.json();
        const latestVersion = manifest.version;

        // Compare the latest version with the current one
        const currentVersion = await getCurrentVersionFromCache();

        if (latestVersion !== currentVersion) {
            console.log("Current version:", currentVersion);
            console.log("New version available:", latestVersion);
            // Inform the client about the new version
            notifyClients();
        }
    } catch (error) {
        console.error("Version check failed:", error);
    }
}

function checkForUpdates() {
    const isOnline = navigator.onLine;

    if (!isOnline) {
        console.log("Offline. Skipping update check.");
        return;
    }

    const appUpdatedAt = getlocalStorage.read("app-updated-at");
    const now = new Date().getTime();
    const duration = 1 * 24 * 60 * 60 * 1000; // 1 day

    if (appUpdatedAt && now - appUpdatedAt < duration) {
        // Skip update check if the app was updated recently
        return;
    }
    // Check for updates
    checkForNewVersion();
}

function registerServiceWorker() {
    // Function to show a simple alert for the update notification

    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker
                .register("/service-worker.js")
                .then((registration) => {
                    console.log("Service Worker registered with scope:", registration.scope);
                    // Check for updates every 10 minutes
                    const duration = 10 * 60 * 1000;
                    // Check for updates every minute
                    // const duration = 1 * 60 * 1000;
                    setInterval(checkForUpdates, duration);
                    // Check for updates when the page becomes visible
                    document.addEventListener("visibilitychange", () => {
                        if (!document.hidden) {
                            checkForUpdates();
                        }
                    });
                })
                .catch((error) => {
                    console.error("Service Worker registration failed:", error);
                });
        });
    }
}

function unregesterServiceWorker() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (const registration of registrations) {
                    registration.unregister();
                }
            })
            .catch(function (err) {
                console.error("Service worker unregister failed: ", err);
            });
    }
}

export { registerServiceWorker, unregesterServiceWorker };
