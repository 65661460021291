import React, { useEffect } from "react";

import { Dialog, DialogContent } from "../Dialog/Dialog";

import { cn } from "@/lib/utils";

interface ModalDialogProps {
    open: boolean;
    onClose: () => void;
    BackdropComponent?: React.ElementType;
    BackdropProps?: React.HTMLAttributes<HTMLElement>;
    disableBackdropClick?: boolean;
    className?: string;
    children?: React.ReactNode;
    showClose?: boolean;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
    open,
    onClose,
    BackdropComponent = "div",
    BackdropProps = {},
    disableBackdropClick = false,
    className = "",
    children,
    showClose = true,
}) => {
    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!disableBackdropClick && e.target === e.currentTarget) {
            onClose();
        }
    };

    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose();
            }
        };
        if (open) {
            document.addEventListener("keydown", handleEsc);
        }
        return () => document.removeEventListener("keydown", handleEsc);
    }, [onClose, open]);

    return (
        <Dialog open={open}>
            <DialogContent>
                <BackdropComponent onClick={handleOutsideClick} {...BackdropProps}>
                    <div
                        className={cn(`relative p-6 bg-white rounded-lg shadow-lg`, className)}
                        role="dialog"
                        aria-modal="true"
                    >
                        {showClose && (
                            <button
                                onClick={onClose}
                                className="absolute top-2 right-3 text-lg font-semibold text-gray-600 hover:text-black focus:outline-none"
                                aria-label="Close"
                            >
                                ✕
                            </button>
                        )}
                        {children}
                    </div>
                </BackdropComponent>
            </DialogContent>
        </Dialog>
    );
};
