import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import GiftIcon from "../../../../assets/img/giftIcon.svg";

import { Button, Typography } from "@/ui";

const DetailedView = ({ detailedView, setOpenSendGiftModal, handleBack }) => {
    return (
        <>
            <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "20px" }}
                onClick={() => handleBack()}
            >
                <ArrowBackIosNewOutlinedIcon fontSize="small" />
                <Typography className="text-[0.8rem] text-light ml-1">Back to gifts</Typography>
            </div>
            <div style={{ display: "flex" }}>
                <img
                    style={{ margin: "20px", height: "400px", width: "400px" }}
                    src={detailedView?.variants[0]?.images[0]?.url ?? GiftIcon}
                    alt=""
                />

                <div style={{ display: "flex", margin: "40px", flexDirection: "column", height: "55vh" }}>
                    <div style={{ flex: 1, overflowY: "scroll", marginBottom: "20px" }}>
                        <Typography className="text-[1.5rem] font-semibold">{detailedView?.name}</Typography>
                        <Typography className="text-[1rem] font-light">{detailedView?.description}</Typography>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <Typography className="text-[1.2rem] font-semibold">
                                $ {detailedView?.variants[0].estimated_total_price?.price_per_unit}
                            </Typography>
                        </div>
                        <Typography className="text-[0.6rem] text-light">* Estimated all-in cost</Typography>
                        <Typography className="text-[0.8rem] text-normal mt-2 mb-3">
                            {" "}
                            How this works for your recipient
                            <a
                                href="https://docs.hirequotient.com/en/articles/9718280-use-sendoso-on-easysource"
                                target="_blank"
                                rel="noreferrer"
                                className="text-cyan-700 ml-1"
                            >
                                Read more here
                            </a>
                        </Typography>
                        <Typography className="text-[0.7rem] font-light italic mt-1">
                            Step 1: Once you confirm purchase, your gift recipient will receive an email from{" "}
                            <a href="mailto:mail@gift.sendoso.com" className="text-cyan-700 m-1">
                                mail@gift.sendoso.com
                            </a>
                            with your name and company.
                        </Typography>
                        <Typography className="text-[0.7rem] font-light italic mt-1">
                            Step 2: Your recipient can then choose to accept the gift and share their address
                        </Typography>
                        <Typography className="text-[0.7rem] font-light italic mt-1">
                            Step 3: You will receive a gift invoice from HireQuotient for the corresponding amount, which
                            you can pay
                        </Typography>
                        <Typography className="text-[0.7rem] font-light italic mt-1">
                            Step 4: You can track order status directly on the candidate card or enquire on{" "}
                            <a href="mailto:customer-support@hirequotient.com" className="text-cyan-700 m-1">
                                customer-support@hirequotient.com
                            </a>
                            , till your gift is delivered successfully
                        </Typography>
                        <Typography className="text-[0.7rem] font-light italic mt-1">
                            Congratulations! You have successfully selected a gift via EasySource using Sendoso
                        </Typography>
                    </div>
                    <Button className="h-[36px] w-[200px]" onClick={() => setOpenSendGiftModal(true)}>
                        Send Gift Now
                    </Button>
                </div>
            </div>
        </>
    );
};
export default DetailedView;
