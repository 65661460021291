import DraftsIcon from '@mui/icons-material/Drafts';
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import { SvgIconProps } from "@mui/material/SvgIcon";

import { OutReachEvent } from '@/store/apis/outreach/outreach.types';

const outReachElementIcons: Record<OutReachEvent, React.ElementType> = {
    email: EmailRoundedIcon,
    "follow-up": DraftsIcon,
    SMS: TextsmsRoundedIcon,
    linkedin: LinkedInIcon,
};

export function OutreachElementIcon({ eventType, props }: { eventType: OutReachEvent; props?: SvgIconProps }) {
    const Element = outReachElementIcons[eventType];

    return <Element {...props} />;
}
