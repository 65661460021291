/* eslint-disable import/no-unused-modules */
import { ReactNode, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { BookDemoForm } from "./bookDemoForm";

import { ActivitiesContext } from "../notesTab/activities.context";

import BaseModal from "@/components/BaseModal";
import { setModal } from "@/store/reducers/modals.slice";
import { ICandidate } from "@/store/reducers/project/project.types";
import { Button, Typography } from "@/ui";

const BookDemo = ({
    children,
    candidate,
    status,
    handleMenuItemClick,
}: {
    children?: ReactNode;
    candidate: ICandidate;
    status?: string;
    handleMenuItemClick?: () => void;
}) => {
    const dispatch = useDispatch();
    const { _id: candidateId, name: candidateName } = candidate;

    const handleBookDemoModal = (val) => {
        localStorage.setItem("bookDemoDetails", JSON.stringify({ candidateId, candidateName, status }));
        dispatch(
            setModal({
                key: "bookDemoModal",
                value: val,
            })
        );
        handleMenuItemClick && handleMenuItemClick();
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button
                variant="ghost"
                className="text-sm w-full justify-start font-normal h-auto px-2"
                onClick={() => {
                    handleBookDemoModal(true);
                }}
            >
                {children}
            </Button>
        </div>
    );
};

// eslint-disable-next-line import/no-unused-modules
export default BookDemo;

export const BookDemoModal = () => {
    const dispatch = useDispatch();
    const [selectedActivity, setSelectedActivity] = useState("");
    const activityFormRef = useRef(null);
    const bookDemoDetails = JSON.parse(localStorage.getItem("bookDemoDetails") || "{}");

    const {
        candidateId = bookDemoDetails.candidateId,
        candidateName = bookDemoDetails.candidateName,
        status = bookDemoDetails.status,
    } = bookDemoDetails;

    const handleSelectedActivity = (value: string) => {
        setSelectedActivity(value);
    };

    const handleScrollIntoView = () => {
        activityFormRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    const handleCloseModal = () => {
        dispatch(
            setModal({
                key: "bookDemoModal",
                value: false,
            })
        );
    };

    return (
        <ActivitiesContext.Provider
            value={{ candidateId, candidateName, selectedActivity, handleSelectedActivity, handleScrollIntoView }}
        >
            <BaseModal
                onClose={handleCloseModal}
                overlayStyles={{
                    padding: 20,
                    width:"550px",
                }}
            >
                <Typography variant="h6" className="text-[#334d6e] mb-4">
                    {"Book Demo - " + candidateName}
                </Typography>
                <BookDemoForm candidateId={candidateId} onClose={handleCloseModal} status={status} />
            </BaseModal>
        </ActivitiesContext.Provider>
    );
};
