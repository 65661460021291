import { Button } from "@/ui";

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    className?: string;
    startDecorator?: React.ReactNode;
    endDecorator?: React.ReactNode;
    variant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | "cyan";
}

const CustomButton: React.FC<CustomButtonProps> = ({
    disabled,
    className,
    variant,
    startDecorator,
    endDecorator,
    ...props
}) => {
    return (
        <Button
            className={`text-[12px] w-fit h-fit  ${className}`}
            disabled={disabled}
            variant={variant}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
            {...props}
        />
    );
};

export default CustomButton;
