import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import {
    addToGreenhouseAgency,
    getFromGreenhouse,
    getGreenhouseJobStages,
    getGreenhouseJobs,
    selectGreenhouseJobStages,
    selectGreenhouseJobs,
} from "@/store/reducers/integrations/integrations.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";

type GreenhouseModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

type GreenhouseState = "PROSPECT" | "JOBS";
export const CustomErrorTypography = styled(Typography)({
    fontSize: 14,
    marginTop: "1rem",
    textAlign: "center",
    color: "red",
});

export default function GreenhouseModal({ open, onClose, id, selectedCandidateIds, variant }: GreenhouseModalProps) {
    const dispatch = useDispatch();
    const greenhouseJobs = useSelector(selectGreenhouseJobs);
    const greenhouseJobStages = useSelector(selectGreenhouseJobStages);
    const isGreenhouseJobsLoading = useSelector(checkIfLoading(getGreenhouseJobs.type));
    const isGreenhouseJobStagesLoading = useSelector(checkIfLoading(getGreenhouseJobStages.type));
    const isGreenhouseSubmitting = useSelector(checkIfLoading(addToGreenhouseAgency.type));
    const isGreenhouseFetching = useSelector(checkIfLoading(getFromGreenhouse.type));

    const isVariantFetch = variant === "FETCH";

    const [greenhouse, setGreenhouse] = useState<GreenhouseState>("PROSPECT");
    // const [fetchType, setFetchType] = useState<GreenhouseType>("PROSPECT");
    const [jobId, setJobId] = useState("");
    const [stageId, setStageId] = useState("");
    const [createdAtStart, setCreatedAtStart] = useState<Dayjs | null>(null);
    const [createdAtEnd, setCreatedAtEnd] = useState<Dayjs | null>(null);

    useEffect(() => {
        if (open && isVariantFetch) {
            setGreenhouse("JOBS");
            dispatch(getGreenhouseJobs({ action: getGreenhouseJobs.type }));
        }
    }, [open, isVariantFetch]);

    const handleCloseModal = () => {
        setGreenhouse("PROSPECT");
        // setFetchType("PROSPECT");
        setJobId("");
        onClose();
    };

    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "JOBS") {
            dispatch(getGreenhouseJobs({ action: getGreenhouseJobs.type }));
        }
        setGreenhouse(e.target.value as GreenhouseState);
    };

    const handleAddGreenhouse = () => {
        if (isVariantFetch) {
            if (!jobId) {
                dispatch(setErrorNotification("Please select a job"));
                return;
            }
            dispatch(
                getFromGreenhouse({
                    job: jobId,
                    stage: stageId,
                    projectId: Number(id),
                    createdAtStart,
                    createdAtEnd,
                    // isProspect: fetchType === "PROSPECT",
                    onSuccess: handleCloseModal,
                })
            );
        } else {
            if (greenhouse === "JOBS" && jobId === "") {
                dispatch(setErrorNotification("Please select job"));
                return;
            }

            dispatch(
                addToGreenhouseAgency({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    action: addToGreenhouseAgency.type,
                    jobId,
                    stageId,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    const handleJobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newJobId = e.target.value;
        setJobId(newJobId);
        dispatch(getGreenhouseJobStages({ jobId: newJobId }));
    };

    const isDisabled = isGreenhouseJobsLoading || isGreenhouseSubmitting || isGreenhouseFetching;

    const isLoading = isGreenhouseSubmitting || isGreenhouseFetching;

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"sm"}>
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} Greenhouse</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {!isVariantFetch && (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Typography>Select type:</Typography>
                            <TextField
                                hiddenLabel
                                size="small"
                                value={greenhouse}
                                onChange={handleTypeChange}
                                select
                                sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                }}
                            >
                                <MenuItem value="PROSPECT">Prospect</MenuItem>
                                <MenuItem value="JOBS">Candidate</MenuItem>
                            </TextField>
                        </Stack>
                    )}
                    {/* CODE FOR PROSPECT OR CANDIDATE SELECTION FOR FETCH */}
                    {/* {isVariantFetch && fetchType ==="PROSPECT" ? (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Typography>Select Fetch Type:</Typography>
                            <TextField
                                hiddenLabel
                                size="small"
                                value={fetchType}
                                onChange={(e) => setFetchType(e.target.value as GreenhouseType)}
                                select
                                sx={{
                                    "& .MuiSelect-select.MuiInputBase-input": {
                                        fontSize: 14,
                                    },
                                }}
                            >
                                <MenuItem value="PROSPECT">Prospect</MenuItem>
                                <MenuItem value="CANDIDATE">Candidate</MenuItem>
                            </TextField>
                        </Stack>
                    ) : null} */}

                    {greenhouse === "JOBS" ? (
                        isGreenhouseJobsLoading ? (
                            <Stack alignItems="center" mt={2}>
                                <CircularProgress size={24} />
                            </Stack>
                        ) : greenhouseJobs.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select job:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={jobId}
                                    onChange={handleJobChange}
                                    select
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {greenhouseJobs.map((job) => (
                                        <MenuItem value={job.id.toString()}>{job.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <CustomErrorTypography>
                                No jobs found. Create one on your greenhouse dashboard
                            </CustomErrorTypography>
                        )
                    ) : null}
                    {/* {(greenhouse === "JOBS" && !isVariantFetch ) ? (
                        isGreenhouseJobsLoading ? (
                            <Stack alignItems="center" mt={2}>
                                <CircularProgress size={24} />
                            </Stack>
                        ) : greenhouseJobs.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select Job:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={jobId}
                                    onChange={handleJobChange}
                                    select
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {greenhouseJobs.map((job) => (
                                        <MenuItem value={job.id.toString()}>{job.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <CustomErrorTypography>
                                No jobs found. Create one on your greenhouse dashboard
                            </CustomErrorTypography>
                        )
                    ) : null} */}
                    {jobId ? (
                        isGreenhouseJobStagesLoading ? (
                            <Stack alignItems="center" mt={2}>
                                <CircularProgress size={24} />
                            </Stack>
                        ) : greenhouseJobStages.length ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <Typography>Select job stage:</Typography>
                                <TextField
                                    hiddenLabel
                                    size="small"
                                    value={stageId}
                                    onChange={(e) => setStageId(e.target.value)}
                                    select
                                    sx={{
                                        "& .MuiSelect-select.MuiInputBase-input": {
                                            fontSize: 14,
                                        },
                                    }}
                                >
                                    {greenhouseJobStages.map((jobStage) => (
                                        <MenuItem value={jobStage.id.toString()}>{jobStage.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        ) : (
                            <CustomErrorTypography>
                                No job stages found. Create one on your greenhouse dashboard
                            </CustomErrorTypography>
                        )
                    ) : null}
                    {isVariantFetch ? (
                        <>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography>Select created after:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={createdAtStart}
                                        onChange={(newValue) => {
                                            setCreatedAtStart(newValue);
                                        }}
                                        sx={{ color: "#0891b2" }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography>Select created before:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={createdAtEnd}
                                        onChange={(newValue) => {
                                            setCreatedAtEnd(newValue);
                                        }}
                                        sx={{
                                            "& .MuiPickersDay-today": {
                                                backgroundColor: "#0891b2",
                                            },
                                            "& .MuiPickersDay-root": {
                                                "&[aria-selected], &[aria-current], &:hover": {
                                                    backgroundColor: "#0891b2",
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outline" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button disabled={isDisabled} onClick={handleAddGreenhouse} variant="default">
                    <ButtonTextWithLoading isLoading={isLoading} text="Confirm" variant="light" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
