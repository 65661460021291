import { zodResolver } from "@hookform/resolvers/zod";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";

import styles from "./EditSignature.module.scss";

import { JoyProvider } from "../../../components/JoyProvider";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { useAppDispatch } from "../../../store";

import { toggleEditSignatureModal } from "@/store/reducers/outreach/outreach.slice";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

const profileFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    userEmail: z.string(),
    outboundEmail: z.string(),
    workspaceName: z.string().trim().min(1, { message: "Please enter workspace name." }),
    companyName: z.string(),
    companyUrl: z.string(),
    role: z.union([z.literal(1), z.literal(0)]).optional(),
    linkedinUrl: z.string(),
    signature: z.string().optional(),
});

type ProfileForm = z.infer<typeof profileFormSchema>;

export function EditSignature() {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const reduxDispatch = useDispatch();

    const { control, handleSubmit, reset } = useForm<ProfileForm>({
        resolver: zodResolver(profileFormSchema),
        values: {
            name: user.name ?? "",
            userEmail: user.email ?? "",
            outboundEmail: user.authorizedEmailAddress ?? "",
            workspaceName: user.orgData?.name ?? "",
            companyName: user.companyName ?? "",
            companyUrl: user.companyLink ?? "",
            role: user?.roleType === "ADMIN" ? 0 : 1,
            linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
            signature: user?.signature ?? "",
        },
    });
    const handelCancel = () => {
        reduxDispatch(toggleEditSignatureModal(false));
    };
    const onSubmit: SubmitHandler<ProfileForm> = (data) => {
        const { companyName, companyUrl, linkedinUrl, name, outboundEmail, userEmail, workspaceName, role, signature } =
            data;
        dispatch(
            updateUser({
                name,
                orgName: workspaceName,
                companyName,
                companyLink: companyUrl,
                linkedinUrl,
                authorizeEmailAddress: outboundEmail,
                email: userEmail,
                roleType: role === 0 ? "ADMIN" : "MEMBER",
                action: updateUser.type,
                signature,
            })
        );
        handelCancel();
    };

    return (
        <JoyProvider>
            <Stack style={{ padding: 10 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack sx={{ maxHeight: "28vh", overflow: "hidden", marginTop: 1.5, marginBottom: 1.5 }}>
                        <Controller
                            control={control}
                            name="signature"
                            render={({ field, fieldState: { error } }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormControl error={!!error?.message}>
                                        <FormLabel>Signature</FormLabel>
                                        <RichTextEditor
                                            className={styles.signatureEditor}
                                            enableImageModule
                                            value={value}
                                            onChange={(value) => onChange(value)}
                                        />
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                );
                            }}
                        />
                    </Stack>
                    <div>
                        <div className="flex justify-end pt-2 space-x-1">
                            <Button size="sm" variant="outline" color="neutral" onClick={() => handelCancel()}>
                                Cancel
                            </Button>
                            <Button size="sm" variant="default" type="submit">
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </Stack>
        </JoyProvider>
    );
}
