import { Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

import { setValues, submitSearch } from "@/store/reducers/search/search.slice";

export const ExpandSearchModal = ({ onClose, handleClick, isLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleExpandableSearch = () => {
        dispatch(
            setValues({
                key: "isFixedSearch",
                value: [{ value: false, excluded: false }],
            })
        );
        dispatch(submitSearch({ navigate }));
        onClose();
    };
    return (
        <BaseModal onClose={onClose} overlayStyles={{ width: "550px" }}>
            <Stack spacing={2}>
                <Typography fontSize={18} fontWeight={600} color="#334d6e">
                    Candidate pool is small
                </Typography>
                <Typography fontSize={14} component={"i"}>
                    You can expand the pool by removing the focused search. This will find candidates based on similar
                    search filters as well.
                </Typography>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant="contained" onClick={handleExpandableSearch}>
                        Expand pool
                    </Button>
                    <div>
                        <Button variant="outlined" disabled={isLoading} onClick={handleClick}>
                            <ButtonTextWithLoading text="No, proceed " isLoading={isLoading} />
                        </Button>
                    </div>
                </Stack>
            </Stack>
        </BaseModal>
    );
};
