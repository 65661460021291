import { Controller } from "react-hook-form";

import { useCreateActivityFormState } from "../NotesTab";

export function CreateActivityBody() {
    const { control } = useCreateActivityFormState();
    return (
        <Controller
            control={control}
            name="body"
            render={({ field, fieldState: { error } }) => {
                return (
                    <div className="p-1">
                        <textarea
                            {...field}
                            rows={4}
                            className={`w-full resize-none border rounded-md px-3 py-2 text-sm 
                            focus:outline-none focus:ring-2 focus:ring-cyan-500 
                            ${error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-cyan-500"}`}
                        />
                        {error?.message && <p className="text-xs text-red-500 mt-1">{error.message}</p>}
                    </div>
                );
            }}
        />
    );
}