import { parentPropsWithChildren } from "../../../../utils/react-utils";

interface ContentProps {
    children: React.ReactNode;
    loading?: string;
}

export default function Content(props: ContentProps) {
    return (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(0,1fr))] gap-4 py-2" {...props}>
            {parentPropsWithChildren(props)}
        </div>
    );
}
