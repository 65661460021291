import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import LoopIcon from "@mui/icons-material/Loop";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import { Button as JoyButton } from "@mui/joy";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    type MRT_ColumnDef,
    type MRT_RowVirtualizer,
    type MRT_SortingState,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MRData, allOptions, titleMapping } from "./mappings";

import { JoyProvider } from "../../components/JoyProvider";
import Navbar from "../../components/Navbar/Navbar";
import { useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { exportToCsvc } from "../../utils/helper";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    marketResearch,
    marketResearchFetchData,
    marketResearchStop,
    selectMarketResearchData,
    selectMarketResearchDataFetchPercentage,
    selectMarketResearchDataFetchStatus,
} from "@/store/reducers/project/project.reducer";
import { IMarketResearchPayload } from "@/store/reducers/project/project.types";

function useFetchMarketResearchDataEveryFiveSeconds() {
    const dispatch = useAppDispatch();
    const fetchStatus = useSelector(selectMarketResearchDataFetchStatus);
    const intervalRef = useRef<null | NodeJS.Timeout>(null);
    const { id } = useParams();

    useEffect(() => {
        dispatch(
            marketResearchFetchData({
                projectId: id,
            })
        );
    }, []);

    useEffect(() => {
        if (intervalRef?.current && (fetchStatus === "IN_PROGRESS" || fetchStatus === "PENDING")) {
            clearInterval(intervalRef.current);
        }

        if (fetchStatus === "IN_PROGRESS" || fetchStatus === "PENDING") {
            intervalRef.current = setInterval(() => {
                dispatch(
                    marketResearchFetchData({
                        projectId: id,
                    })
                );
            }, 10000);
        }

        return () => {
            if (intervalRef?.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [fetchStatus, id]);
}

const applyMarkdown = (data) => {
    return <Markdown>{data?.replace(/\n\n/g, "  \n")}</Markdown>;
};

export default function MarketResearch() {
    const params = useParams();
    const projectId = params?.id;
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [customColumns, setCustomColumns] = useState<MRT_ColumnDef<MRData>[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newColumnName, setNewColumnName] = useState("");
    const fetchStatus = useSelector(selectMarketResearchDataFetchStatus);
    const fetchPercentage = useSelector(selectMarketResearchDataFetchPercentage);
    const apiData = useSelector(selectMarketResearchData);
    const titles = [...new Set(apiData.flatMap((item) => Object.keys(item.marketResearch)))];
    const isLoading = useSelector(checkIfLoading(marketResearch.type));
    useFetchMarketResearchDataEveryFiveSeconds();

    useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const transformedData = apiData.map((item) => {
        const marketResearch = item?.marketResearch || {};
        return {
            name: item?.candidateName,
            Competitors: applyMarkdown(marketResearch?.Competitors),
            Industry: applyMarkdown(marketResearch?.Industry),
            CEO: applyMarkdown(marketResearch?.CEO),
            IPO: applyMarkdown(marketResearch?.IPO),
            Employees: applyMarkdown(marketResearch?.Employees),
            FoundingYear: applyMarkdown(marketResearch?.FoundingYear),
            RevenueinUSD: applyMarkdown(marketResearch?.RevenueinUSD),
            MarketCap: applyMarkdown(marketResearch?.MarketCap),
            Headquarters: applyMarkdown(marketResearch?.Headquarters),
            IPODate: applyMarkdown(marketResearch?.IPODate),
            Funding: applyMarkdown(marketResearch?.Funding),
            ProductsandServices: applyMarkdown(marketResearch?.ProductsandServices),
            FreeTrial: applyMarkdown(marketResearch?.FreeTrial),
            DemoOffering: applyMarkdown(marketResearch?.DemoOffering),
            PricingPlans: applyMarkdown(marketResearch?.PricingPlans),
            BusinessType: applyMarkdown(marketResearch?.BusinessType),
            ParentCompany: applyMarkdown(marketResearch?.ParentCompany),
            ChildCompanies: applyMarkdown(marketResearch?.ChildCompanies),
            CrunchbaseDetails: applyMarkdown(marketResearch?.CrunchbaseDetails),
            VacantPositions: applyMarkdown(marketResearch?.VacantPositions),
            DomainLink: applyMarkdown(marketResearch?.DomainLink),
            PhoneNumber: applyMarkdown(marketResearch?.PhoneNumber),
            Instagram: applyMarkdown(marketResearch?.Instagram),
            Twitter: applyMarkdown(marketResearch?.Twitter),
            YouTube: applyMarkdown(marketResearch?.YouTube),
            FundingType: applyMarkdown(marketResearch?.FundingType),
            JobTitle: applyMarkdown(marketResearch?.JobTitle),
            LinkedinURL: applyMarkdown(marketResearch?.LinkedinURL),
            Location: applyMarkdown(marketResearch?.Location),
            Company: applyMarkdown(marketResearch?.Company),
            Tenure: applyMarkdown(marketResearch?.Tenure),
            RecentlyLaunchedProducts: applyMarkdown(marketResearch?.RecentlyLaunchedProducts),
        };
    });
    const cleanMarkdown = (text: any) => {
        if (!text) {
            return "";
        }
        const cleanedText = text
            .replace(/\*\*(.*?)\*\*/g, "$1")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\n+/g, " ") // Remove extra new lines
            .trim();
        return cleanedText;
    };

    const cleanedData = transformedData.map((item) => {
        const cleanField = (field) => cleanMarkdown(item?.[field]?.props?.children);
        return Object.keys(item).reduce(
            (acc, key) => {
                // Ensure the 'name' field is correctly assigned
                acc[key] = key === "name" ? item.name : cleanField(key);
                return acc;
            },
            {} // Start with an empty object
        );
    });


    const availableCards = allOptions
        .filter((option) => !titles.includes(option))
        .map((option) => ({ title: titleMapping[option] || option }));

    const availableCardArray = availableCards.map((card) => card.title);

    const allHeadings = { name: "Name", ...titleMapping };

    const filteredHeadings = Object.keys(allHeadings).reduce((acc, key) => {
        if (!availableCardArray.includes(allHeadings[key])) {
            acc[key] = allHeadings[key];
        }
        return acc;
    }, {});
    
    const validKeys = Object.keys(filteredHeadings);
    const filteredCleanedData = cleanedData.map((item) =>
        validKeys.reduce((acc, key) => {
            acc[key] = item[key];
            return acc;
        }, {})
    );

    const columns = useMemo<MRT_ColumnDef<MRData>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                size: 300,
            },
            ...[
                {
                    accessorKey: "JobTitle",
                    header: "Job Title",
                    size: 300,
                },
                {
                    accessorKey: "LinkedinURL",
                    header: "Linkedin URL",
                    size: 500,
                },
                {
                    accessorKey: "Location",
                    header: "Location",
                    size: 300,
                },
                {
                    accessorKey: "Company",
                    header: "Company",
                    size: 300,
                },
                {
                    accessorKey: "CEO",
                    header: "CEO",
                    size: 300,
                },
                {
                    accessorKey: "FoundingYear",
                    header: "Founding Year",
                    size: 300,
                },
                {
                    accessorKey: "RevenueinUSD",
                    header: "Revenue in USD",
                    size: 300,
                },
                {
                    accessorKey: "DomainLink",
                    header: "Domain Link",
                    size: 300,
                },
                {
                    accessorKey: "MarketCap",
                    header: "Market Cap",
                    size: 500,
                },
                {
                    accessorKey: "Employees",
                    header: "Employees",
                    size: 300,
                },
                {
                    accessorKey: "Headquarters",
                    header: "Headquarters",
                    size: 300,
                },
                {
                    accessorKey: "IPO",
                    header: "IPO",
                    size: 300,
                },
                {
                    accessorKey: "IPODate",
                    header: "IPO Date",
                    size: 300,
                },
                {
                    accessorKey: "Funding",
                    header: "Funding",
                    size: 300,
                },
                {
                    accessorKey: "PhoneNumber",
                    header: "Phone Number",
                    size: 300,
                },

                {
                    accessorKey: "Instagram",
                    header: "Instagram",
                    size: 300,
                },
                {
                    accessorKey: "Twitter",
                    header: "Twitter",
                    size: 300,
                },
                {
                    accessorKey: "YouTube",
                    header: "YouTube",
                    size: 300,
                },
                {
                    accessorKey: "FreeTrial",
                    header: "Free Trial",
                    size: 300,
                },
                {
                    accessorKey: "DemoOffering",
                    header: "Demo Offering",
                    size: 300,
                },
                {
                    accessorKey: "RecentlyLaunchedProducts",
                    header: "Recently Launched Products",
                    size: 300,
                },
                {
                    accessorKey: "PricingPlans",
                    header: "Pricing Plans",
                    size: 300,
                },
                {
                    accessorKey: "BusinessType",
                    header: "Business Type",
                    size: 300,
                },
                {
                    accessorKey: "ParentCompany",
                    header: "Parent Company",
                    size: 300,
                },
                {
                    accessorKey: "ChildCompanies",
                    header: "Child Companies",
                    size: 300,
                },
                {
                    accessorKey: "VacantPositions",
                    header: "Vacant Positions",
                    size: 300,
                },
                {
                    accessorKey: "Tenure",
                    header: "Tenure",
                    size: 300,
                },
                {
                    accessorKey: "FundingType",
                    header: "Funding Type",
                    size: 300,
                },
                {
                    accessorKey: "Industry",
                    header: "Industry",
                    size: 300,
                },
                {
                    accessorKey: "ProductsandServices",
                    header: "Products and Services",
                    size: 800,
                },
                {
                    accessorKey: "Competitors",
                    header: "Competitors",
                    size: 600,
                },

                {
                    accessorKey: "CrunchbaseDetails",
                    header: "Crunchbase Details",
                    size: 300,
                },
            ].filter((column) => titles.includes(column.accessorKey)),
            ...customColumns,
        ],
        [customColumns, transformedData]
    );

    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
    const dispatch = useDispatch();

    const stopGeneration = () => {
        dispatch(
            marketResearchStop({
                projectId: projectId,
                onSuccess: () => {
                    dispatch(marketResearchFetchData({ projectId: projectId }));
                },
            })
        );
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewColumnName("");
    };

    const handleAddNewColumn = () => {
        handleDialogClose();
        const newTitle = newColumnName.replace(/\s+/g, "");
        const payload: IMarketResearchPayload = {
            titles: [newTitle],
            candidateId: [],
            projectId: +projectId,
            onSuccess: () => {
                dispatch(setSuccessNotification("Adding new column"));
                dispatch(marketResearchFetchData({ projectId: projectId }));
            },
        };
        dispatch(marketResearch(payload));
    };

    const table = useMaterialReactTable({
        columns,
        data: transformedData,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableGlobalFilterModes: true,
        enablePagination: false,
        enableColumnPinning: true,
        enableRowNumbers: true,
        enableRowVirtualization: true,
        enableColumnVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: "calc(65vh)", minHeight: "calc(68vh)" } },
        onSortingChange: setSorting,
        state: { isLoading, sorting },
        initialState: { columnPinning: { left: ["name"] } },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Button
                    sx={{ borderRadius: "5px", color: "#0d6ccc" }}
                    onClick={() => exportToCsvc(filteredCleanedData)}
                    startIcon={<FileDownloadIcon />}
                    disabled={fetchStatus === "IN_PROGRESS"}
                >
                    Export csv
                </Button>
            </Box>
        ),
    });

    const disabledColumnAdditon = fetchStatus === "IN_PROGRESS" || availableCardArray.length === 0;
    return (
        <>
            <Navbar />
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    height: "calc(85vh)",
                    display: "grid",
                    gridTemplateRows: "auto 1fr",
                    gridTemplateColumns: "1fr",
                    marginTop: "1.5rem",
                })}
            >
                <Stack direction="row" justifyContent="space-between" pt={2} px={2}>
                    <Typography
                        pb={2}
                        display="flex"
                        alignItems="center"
                        component="h2"
                        fontSize={24}
                        fontWeight="600"
                        gap={1}
                        title={"Market research"}
                    >
                        Market research
                        <Tooltip
                            title="View AI based research stats on selected candidates and queries"
                            sx={{
                                border: "1px solid lightgrey",
                                maxWidth: "25rem",
                                color: "black",
                                backgroundColor: "white",
                            }}
                        >
                            <InfoIcon color="disabled" sx={{ fontSize: "20px" }} />
                        </Tooltip>
                    </Typography>
                    <JoyProvider>
                        <JoyButton
                            onClick={() => setOpenDialog(true)}
                            sx={{ height: "10px" }}
                            disabled={disabledColumnAdditon}
                        >
                            Add column
                        </JoyButton>
                    </JoyProvider>
                </Stack>

                {fetchStatus === "IN_PROGRESS" && (
                    <Box
                        sx={{
                            backgroundColor: "#f3f5f6",
                            textAlign: "center",
                            height: "30px",
                            borderRadius: "8px",
                            boxShadow: "none",
                            marginBottom: "7px",
                            marginLeft: "2px",
                            marginRight: "2px",
                        }}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} sx={{ backgroundColor: "#d0d4d3" }}>
                            <Typography sx={{ color: "#5F6368", fontSize: "14px" }}>
                                Market research details generation in progress... ({fetchPercentage}%)
                            </Typography>
                            <IconButton onClick={() => dispatch(marketResearchFetchData({ projectId: projectId }))}>
                                <LoopIcon
                                    fontSize="small"
                                    sx={{
                                        animation: "spin 2s linear infinite",
                                        height: "14px",
                                        width: "14px",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(360deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(0deg)",
                                            },
                                        },
                                    }}
                                />
                            </IconButton>

                            <IconButton onClick={stopGeneration}>
                                <StopCircleRoundedIcon sx={{ height: "16px", width: "16px" }} />
                            </IconButton>
                        </Stack>
                    </Box>
                )}

                <MaterialReactTable table={table} />
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle sx={{ width: "33rem", marginBottom: "2px" }}>Add new column</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={availableCards.map((option) => option.title)}
                        onChange={(e: any) => setNewColumnName(e.target?.innerValue || e.target?.innerHTML)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select title field"
                                margin="dense"
                                autoFocus
                                fullWidth
                                size="medium"
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions sx={{ paddingRight: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{ borderRadius: "4px", fontSize: "12px" }}
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: "4px", fontSize: "12px", backgroundColor: "#0c6bcc" }}
                        onClick={handleAddNewColumn}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
