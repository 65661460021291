import React, { useEffect, useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import { useDispatch } from "react-redux";
import { Tooltip } from "@/ui";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getAddtoBlocklist } from "@/store/reducers/project/project.reducer";
import { CallModal } from "./CallModal";
import { useCallContext } from "./TwilloCallContext";

const TwilioVoice: React.FC<any> = ({ candidate }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { setPhoneNumber } = useCallContext();

    const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setPhoneNumber("");
        setEditPhoneNumber(false);
    };


    const handleBlockCandidate = async () => {
        const validNumber = candidate?.phone?.find((phone) => phone !== null);
        if (validNumber) {
            dispatch(getAddtoBlocklist({ candidateId: candidate._id }));
        } else {
            dispatch(setErrorNotification("Candidate cannot be blocked without a phone number"));
        }
    };
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CallModal
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                candidate={candidate}
                editPhoneNumber={editPhoneNumber}
                setEditPhoneNumber={setEditPhoneNumber}
                handleBlockCandidate={handleBlockCandidate}
            />

            <div
                style={{
                    position: "relative",
                    display: "flex",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="w-8 flex items-center justify-center rounded-full">

                    <Tooltip title="Place a call" placement="bottom" size="small">
                        <button
                            className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => setModalOpen(true)}
                            disabled={modalOpen}>
                            <CallIcon />
                        </button>

                    </Tooltip>

                </div>
            </div>
        </div>
    );
};

export default TwilioVoice;
