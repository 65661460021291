import { useSelector } from "react-redux";

import useSearchState from "../../../../hooks/useSearchState";
import { selectFilter, setValues } from "../../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../../store/reducers/search/search.types";

import { Autocomplete } from "@/ui/Autocomplete/Autocomplete";

interface BSSelectProps {
    id: SearchStateKeys;
    label: string;
    unit?: string;
}

export function BSSelect({ id, label, unit = "" }: BSSelectProps) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectFilter(id));

    const handleChange = (value: typeof values) => {
        dispatch(setValues({ key: id, value }));
    };

    const trailingUnit = unit ? ` ${unit}` : "";

    return (
        <Autocomplete
            placeholder={label}
            value={values.map((o) => ({ ...o, label: String(o.value), value: String(o.value) + trailingUnit }))}
            options={Object.keys(options).map((i) => ({ value: i, label: i + trailingUnit }))}
            onChange={(option) => handleChange(option.map((o) => ({ value: String(o.value), excluded: !!o.excluded })))}
        />
    );
}
