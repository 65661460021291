import { useSelector } from "react-redux";

import useSearchState from "@/hooks/useSearchState";
import { selectFilter, setValues } from "@/store/reducers/search/search.slice";
import { SearchStateKeys } from "@/store/reducers/search/search.types";
import { Autocomplete } from "@/ui/Autocomplete/Autocomplete";

interface BSAutocompleteGeographyProps {
    id: SearchStateKeys;
    multiple?: boolean;
    freeSolo?: boolean;
    excludable?: boolean;
    placeholder?: string;
}

export function BSAutocompleteGeography({
    id,
    multiple = true,
    freeSolo = false,
    excludable = true,
    placeholder= "Select geography"
}: BSAutocompleteGeographyProps) {
    const { setQuery, isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectFilter(id));

    const handleChange = (value: typeof values) => {
        dispatch(setValues({ key: id, value }));
    };
    return (
        <Autocomplete
            multiple={multiple}
            excludable={excludable}
            freeSolo={freeSolo}
            placeholder={placeholder}
            error={error}
            onInputChange={setQuery}
            value={values.map((o) => ({ ...o, label: String(o.value), value: String(o.value) }))}
            options={Object.keys(options).map((i) => ({ value: i, label: i }))}
            loading={isLoading}
            onChange={(option) => handleChange(option.map((o) => ({ value: String(o.value), excluded: !!o.excluded })))}
        />
    );
}
