// Textarea.tsx
import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: string;
    label?: string;
}

/**
 * A React forwardRef component for rendering a customizable textarea with optional label and error message.
 *
 * @param {TextareaProps} props - The properties passed to the Textarea component.
 * @param {string} [className] - Additional class names to apply to the textarea element.
 * @param {string} [error] - Error message to display below the textarea.
 * @param {string} [label] - Label text to display above the textarea.
 * @param {React.Ref<HTMLTextAreaElement>} ref - The ref to be forwarded to the textarea element.
 *
 * @returns {JSX.Element} The rendered Textarea component.
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, error, label, ...props }, ref) => {
    return (
        <div className="space-y-2">
            {label && (
                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    {label}
                </label>
            )}
            <textarea
                className={cn(
                    "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                    error && "border-red-500 focus-visible:ring-red-500",
                    className
                )}
                ref={ref}
                {...props}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
        </div>
    );
});

Textarea.displayName = "Textarea";

export { Textarea };
