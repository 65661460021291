import { CSSProperties, ElementType, ReactNode, RefObject, createElement, useEffect, useRef, useState } from "react";

/**
 * Props for the custom component used within the VirtualizedList.
 *
 * @interface componentProps
 * @property {CSSProperties} containerStyle - The style for the outer container of the list.
 * @property {CSSProperties} innerContainerStyle - The style for the inner container of the list.
 * @property {Iterable<ReactNode>} items - The rendered list items.
 * @property {RefObject<HTMLElement>} ref - The ref object for the container element.
 */
interface componentProps {
    containerStyle: CSSProperties;
    innerContainerStyle: CSSProperties;
    items: Iterable<ReactNode>;
}

/**
 * Props for the VirtualizedList component.
 *
 * @interface VirtualizedListProps
 * @property {number} numItems - The total number of items to be rendered in the list.
 * @property {number} itemHeight - The height of each item in the list.
 * @property {(props: { index: number; style: CSSProperties }) => React.ReactNode} renderItem - Function to render an item at a given index with specific styles.
 * @property {RefObject<HTMLElement>} [containerRef] - Optional ref object for the container element.
 * @property {number} [containerHeight] - Optional fixed height for the container.
 * @property {ElementType<componentProps>} [component] - Optional custom component to be used as the container for the list.
 */

interface VirtualizedListProps {
    numItems: number;
    itemHeight: number;
    renderItem: (props: { index: number; style: CSSProperties }) => React.ReactNode;
    containerRef?: RefObject<HTMLElement>;
    containerHeight?: number;
    component?: ElementType<componentProps>;
}

/**
 * VirtualizedList is a React component that efficiently renders a large list by only rendering the items visible
 * in the viewport. It uses a virtual scrolling technique to improve performance.
 *
 * @function
 * @param {VirtualizedListProps} props - The props for the VirtualizedList component.
 * @returns {JSX.Element} The rendered VirtualizedList component.
 *
 * @throws {Error} Will throw an error if none of containerRef, containerHeight, or component is provided.
 *
 * @example
 * <VirtualizedList
 *   numItems={1000}
 *   itemHeight={50}
 *   containerHeight={500}
 *   renderItem={({ index, style }) => (
 *     <div key={index} style={style}>
 *       Item {index}
 *     </div>
 *   )}
 * />
 */
function VirtualizedList(props: VirtualizedListProps) {
    const { numItems, itemHeight, containerHeight, renderItem, containerRef, component } = props;
    const [scrollTop, setScrollTop] = useState(0);
    const innerContainerRef = useRef<HTMLDivElement | null>(null);
    const ref = containerRef || innerContainerRef;

    if (!containerRef && !containerHeight && !component) {
        throw new Error(
            "Please provide at least one of containerRef or containerHeight or component to make the virtualization work"
        );
    }

    const windowHeight = ref?.current?.clientHeight ?? window.innerHeight;
    const innerHeight = numItems * itemHeight;
    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(
        numItems - 1, // don't render past the end of the list
        Math.floor((scrollTop + windowHeight) / itemHeight)
    );

    useEffect(() => {
        if (ref?.current) {
            const onScroll = (e: any) => {
                if (e.currentTarget.scrollTop >= 0) {
                    setScrollTop(e.currentTarget.scrollTop);
                }
            };
            ref.current.onscroll = onScroll;
        }
    }, [ref]);

    const items = [];

    for (let i = startIndex; i <= endIndex; i++) {
        items.push(
            renderItem({
                index: i,
                style: {
                    position: "absolute",
                    top: `${i * itemHeight}px`,
                    width: "100%",
                },
            })
        );
    }

    const containerStyle: CSSProperties = {
        overflowY: "scroll",
        height: `${containerHeight}px`,
        width: "100%",
    };

    const innerContainerStyle: CSSProperties = {
        position: "relative",
        height: `${innerHeight}px`,
        width: "100%",
    };

    if (component) {
        return createElement(component, { containerStyle, innerContainerStyle, items });
    }

    if (containerRef?.current) {
        return <div style={innerContainerStyle}>{items}</div>;
    }

    return (
        <div style={{ overflowY: "scroll", height: `${containerHeight}px` }} ref={innerContainerRef}>
            <div style={innerContainerStyle}>{items}</div>
        </div>
    );
}

export default VirtualizedList;
