import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { CssVarsProvider } from "@mui/joy/styles";
import { Experimental_CssVarsProvider } from "@mui/material";
import { useState } from "react";

import WritingStyleMenu from "./WritingStylesMenu";

import useDisclosure from "../../../hooks/useDisclosure";
import { useFetchWritingStylesQuery } from "../../../store/apis/outreach/outreach.api";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { Badge, Button, Tooltip, Typography } from "@/ui";

export function MimicWritingStyles() {
    const { projectId } = useOutreachParams();
    const [hovered, setHovered] = useState(false);

    const {
        data: writingStylesData = {},
        refetch: refetchStyles,
        isLoading: writingStylesDataLoading,
    } = useFetchWritingStylesQuery({
        projectId,
    });
    const {
        isOpen: isMimicWritingStyle,
        onOpen: onOpenMimicWritingStyle,
        onClose: onCloseMimicWritingStyle,
    } = useDisclosure();

    if (writingStylesDataLoading) return <></>;
    return (
        <>
            <CssVarsProvider>
                <Experimental_CssVarsProvider>
                    <Tooltip
                        title={writingStylesData?.applyStyle && "Your writing samples and writing style has been saved"}
                        aria-disabled={!hovered}
                        placement="top"
                    >
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "180px" }}>
                            <Badge
                                invisible={!writingStylesData?.applyStyle}
                                badgeContent={
                                    writingStylesData?.applyStyle  ? <DoneAllIcon sx={{ color: "green" }} /> : null
                                }
                                variant={"secondary"}
                            >
                                <button
                                    onClick={() => {
                                        onOpenMimicWritingStyle();
                                        setHovered(false);
                                    }}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: hovered ? "auto" : "30px",
                                        color: "#0891B2",
                                        border: hovered ? "1px solid #0891b2" : "none",
                                        borderRadius: hovered ? "4px" : "none",
                                        textTransform: "none",
                                        padding: "4px",
                                        transition: "width 0.4s ease, border 0.4s ease",
                                        width: hovered ? "180px" : "30px",
                                        overflow: "hidden",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <DesignServicesIcon
                                        sx={{ transition: "margin-right 0.4s ease", color: "#0891B2" }}
                                    />
                                    <Typography
                                        variant="body2"
                                        className={`ml-1 transition-transform duration-400 whitespace-nowrap italic ${
                                            hovered ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
                                        }`}
                                    >
                                        Mimic writing style
                                    </Typography>
                                </button>
                            </Badge>
                        </div>
                    </Tooltip>
                </Experimental_CssVarsProvider>
                {isMimicWritingStyle && (
                    <WritingStyleMenu
                        isOpen={isMimicWritingStyle}
                        onClose={onCloseMimicWritingStyle}
                        writingStylesData={writingStylesData}
                        refetch={refetchStyles}
                    />
                )}
            </CssVarsProvider>
        </>
    );
}
