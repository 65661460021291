import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { capitalize } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

import CustomButton from "../../../../components/CustomButton";
import { useAppDispatch } from "../../../../store";
import { useFetchOrganizationMembersQuery } from "../../../../store/apis/all-candidates/all-candidates.api";
import { objectEntries, objectKeys } from "../../../../utils/helper";

import {
    resetActivityFilters,
    selectContactOverviewActivitiesFilters,
    setActivityFilter,
} from "@/store/reducers/contact-overview-activities/contact-overview-activities.slice";
import {
    ContactOverviewActivitiesStateFilters,
    ContactOverviewActivitiesStateFiltersKeys,
    SubFilterKey,
} from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { Checkbox, Popover, Tooltip } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

function CheckBoxSection({
    filterName,
    subFilters,
}: {
    filterName: ContactOverviewActivitiesStateFiltersKeys;
    subFilters: Record<string, boolean>;
}) {
    const dispatch = useAppDispatch();
    const { data = [] } = useFetchOrganizationMembersQuery();

    const handleCheck = (checked: boolean, name: string) => {
        dispatch(
            setActivityFilter({
                value: checked,
                filterKey: filterName as ContactOverviewActivitiesStateFiltersKeys,
                subFilterKey: name as SubFilterKey,
            })
        );
    };

    return (
        <fieldset className="border-0 p-0 m-0 mr-5">

            <legend className="text-[14px] pb-[0.3rem] text-[#707070]">
                {capitalize(filterName === "activity Types" ? "activity Tags" : filterName)}
            </legend>
            <div
                className={`max-h-[300px] ${filterName === "activity Types" ? "overflow-y-scroll" : "overflow-y-auto"
                    } flex-nowrap overflow-x-hidden`}
            >
                {objectKeys(subFilters).map((i) => {
                    const checked = subFilters[i];
                    const label =
                        filterName === "assignee"
                            ? data.find((member) => String(member._id) === i)?.name
                            : capitalize(i);
                    return (
                        <label
                            key={i}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                fontSize: "14px",
                                marginTop: "12px",
                            }}
                        >
                            <Checkbox checked={checked} className={"h-[15px] w-[15px] p-2"} onChange={(checked) => handleCheck(checked, i)} name={i} />
                            <span>{label}</span>
                        </label>
                    );
                })}
            </div>
        </fieldset>
    );
}

export function ActivityFilters() {
    const dispatch = useAppDispatch();
    const selectFilters = useSelector(selectContactOverviewActivitiesFilters);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    useFetchOrganizationMembersQuery();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFiltersReset = () => {
        dispatch(resetActivityFilters());
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title="Filter activities">
                <button onClick={handleClick} style={{ marginRight: "2px", marginLeft: "5px" }}>
                    <FilterAltRoundedIcon fontSize="small" style={{color:"#707070"}} />
                </button>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                align="end"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                className="mt-3"
            >
                <Stack
                    p={2}
                    gap={1}
                    className="grid max-w-[70vw] grid-cols-[repeat(auto-fit,_minmax(80px,_max-content))] grid-rows-1"
                >
                    {objectEntries<ContactOverviewActivitiesStateFilters>(selectFilters).map(
                        ([filterName, subFilters]) => {
                            return <CheckBoxSection filterName={filterName} subFilters={subFilters} key={filterName} />;
                        }
                    )}
                    <Stack
                        direction="row"
                        sx={{
                            gridColumnStart: "5",
                            gridColumnEnd: "6",
                            justifySelf: "end",
                        }}
                    >
                        <button onClick={handleClose} className="mr-2">
                            <CloseRoundedIcon fontSize="small" />
                        </button>
                        <CustomButton onClick={handleFiltersReset}>Reset</CustomButton>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
}
