import CustomButton from "../../../components/CustomButton";
import useMenu from "../../../hooks/useMenu";
import PersonalizedTagsMenu from "../molecules/PersonalizedTagsMenu";
import { useTemplateEditorValues } from "../TemplateEditorProvider";

import { Tooltip } from "@/ui";

export function PersonalizedMenu() {
    const { actions, customField, defaultCustomActions, signatureAction, rb2bAction } = useTemplateEditorValues();
    const { handleClick, handleClose, getMenuProps: getDefaultMenuProps } = useMenu();

    const { customFieldActions, isLoadingCustomFieldData, isErrorCustomFieldData } = customField;

    return (
        <>
            <div>
                <Tooltip title="Add personalization tags">
                    <CustomButton
                        onClick={handleClick}
                        variant="outline"
                        className="text-[13px] py-[0.4rem] px-[1rem] rounded-[0.3rem] bg-transparent shadow-none text-[#0891B2] hover:opacity-100 hover:bg-[#0891B2] hover:text-white self-end"

                    >
                        Personalize
                    </CustomButton>
                </Tooltip>
            </div>
            <PersonalizedTagsMenu
                actions={actions}
                defaultCustomActions={defaultCustomActions}
                customActions={customFieldActions}
                isLoadingCustomFieldData={isLoadingCustomFieldData}
                rb2bAction={rb2bAction}
                isErrorCustomFieldData={isErrorCustomFieldData}
                signatureAction={signatureAction}
                menuProps={{
                    ...getDefaultMenuProps(),
                    onClose: handleClose,
                }}
            />
        </>
    );
}
