import React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Chip, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

type EmailPopoverProps = {
    open: boolean;
    email: string[];
    professional_email: string[];
    primaryEmail: string | undefined;
    anchorEl: HTMLElement | null;
    handleEmailPopoverClose: (event: React.MouseEvent<HTMLElement>) => void;
    handleEmailPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleEmailPopoverToggle: (event: React.MouseEvent<HTMLElement>) => void;
};

const truncateText = (text: string, length: number) => {
    if (text) {
        if (text.length > length) {
            return text?.slice(0, length) + "...";
        }
        return text;
    }

    return text;
};
function EmailPopover({
    open,
    email,
    anchorEl,
    primaryEmail,
    professional_email,
    handleEmailPopoverClose,
    handleEmailPopoverToggle,
}: EmailPopoverProps) {
    const dispatch = useDispatch();
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(setSuccessNotification("Email address copied to clipboard!"));
    };
    return (
        <Popover
            sx={{
                pointerEvents: "none",
                "& .MuiPopover-paper": {
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    minWidth: "300px",
                    width: "auto",
                },
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleEmailPopoverToggle}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            disableRestoreFocus
            disableEnforceFocus
        >
            <Stack
                onMouseLeave={handleEmailPopoverClose}
                onMouseEnter={handleEmailPopoverToggle}
                sx={{
                    p: 3,
                    bgcolor: "background.paper",
                    pointerEvents: "all",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {email?.length === 0 && primaryEmail?.length === 0 && professional_email?.length === 0 && (
                    <Typography variant="body2" sx={{ color: "#6C6C6C" }}>
                        No email address available
                    </Typography>
                )}

                {primaryEmail?.length > 0 && (
                    <Stack>
                        <Typography variant="subtitle2" gutterBottom>
                            Primary email
                        </Typography>
                        <Chip
                            label={truncateText(primaryEmail, 40)}
                            onDelete={() => handleCopyToClipboard(primaryEmail)}
                            deleteIcon={<ContentCopyIcon sx={{ fontSize: "15px", color: "#1976d2" }} />}
                            sx={{
                                marginBottom: "2px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "5px",
                                borderRadius: "4px",
                            }}
                        />
                    </Stack>
                )}

                {email?.length > 0 && (
                    <>
                        <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "15px" }}>
                            Email address
                        </Typography>
                        <Stack sx={{ maxHeight: "110px", overflowY: "auto" }}>
                            {email?.map((item) => (
                                <Chip
                                    label={truncateText(item, 40)}
                                    onDelete={() => handleCopyToClipboard(item)}
                                    deleteIcon={<ContentCopyIcon sx={{ fontSize: "15px", color: "#1976d2" }} />}
                                    sx={{
                                        marginBottom: "2px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "5px",
                                        borderRadius: "4px",
                                    }}
                                />
                            ))}
                        </Stack>
                    </>
                )}

                {professional_email?.length > 0 && (
                    <>
                        <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "15px" }}>
                            Professional email address
                        </Typography>
                        <Stack sx={{ maxHeight: "110px", overflowY: "auto" }}>
                            {professional_email?.map((item) => (
                                <Chip
                                    label={truncateText(item, 40)}
                                    onDelete={() => handleCopyToClipboard(item)}
                                    deleteIcon={<ContentCopyIcon sx={{ fontSize: "15px", color: "#1976d2" }} />}
                                    sx={{
                                        marginBottom: "2px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "5px",
                                        borderRadius: "4px",
                                    }}
                                />
                            ))}
                        </Stack>
                    </>
                )}
            </Stack>
        </Popover>
    );
}

// type EmailProps = {
//     emailDetails: {
//         email: string;
//         state?: string;
//     };
// };

// function Email({ emailDetails }: EmailProps) {
//     const renderBadge = () => {
//         const state = emailDetails?.state;

//         switch (state) {
//             case "accept_all":
//                 return (
//                     <Tooltip title="Accept all" placement="left" arrow>
//                         <CheckCircleOutlineIcon sx={{ color: "warning.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             case "deliverable":
//                 return (
//                     <Tooltip title="Deliverable" placement="left" arrow>
//                         <VerifiedOutlinedIcon sx={{ color: "success.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             case "unknown":
//                 return (
//                     <Tooltip title="Unknown" placement="left" arrow>
//                         <HelpOutlineIcon sx={{ color: "neutral", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             case "undeliverable":
//                 return (
//                     <Tooltip title="Undeliverable" placement="left" arrow>
//                         <ErrorOutlineOutlinedIcon sx={{ color: "error.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             default:
//                 return null;
//         }
//     };

//     return (
//         <Stack direction="row" alignItems="center" gap={0.5}>
//             {renderBadge()}
//             <CopyableText
//                 textToCopy={emailDetails?.email}
//                 message="Email copied to clipboard!"
//                 onClick={(e: any) => {
//                     e.stopPropagation();
//                 }}
//             >
//                 <HoverTruncateText sx={{ color: "#6C6C6C" }} maxWidth={300}>
//                     {emailDetails?.email}
//                 </HoverTruncateText>
//             </CopyableText>
//         </Stack>
//     );
// }

export default EmailPopover;
