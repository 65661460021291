import { styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectEditContactListNameModal, setModal } from "../../../store/reducers/modals.slice";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

import { selectSingleContactList, updateContactListName } from "@/store/reducers/contact-list/contact-list.slice";

const CustomButton = styled(Button)<ButtonProps>(() => ({
    width: "fit-content",
    height: "fit-content",
    fontSize: "12px",
}));
export default function EditContactListNameModal() {
    const dispatch = useDispatch();
    const isEditContactListModalOpen = useSelector(selectEditContactListNameModal);
    const contactList = useSelector(selectSingleContactList);
    const contactListName = contactList?.name;
    const contactListId = contactList?.id;
    const isUpdating = useSelector(checkIfLoading(updateContactListName.type));
    const [name, setName] = useState("");
    const onClose = () => dispatch(setModal({ key: "showEditContactListNameModal", value: false }));

    useEffect(() => {
        if (contactListName) {
            setName(contactListName);
        }
    }, [contactListName]);

    if (!isEditContactListModalOpen) {
        return null;
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (contactListId) {
            dispatch(
                updateContactListName({
                    id: contactListId,
                    name,
                })
            );
        }
    };

    return (
        <BaseModal
            hideCloseButton={isUpdating}
            onClose={onClose}
            overlayStyles={{
                padding: "3.5rem 1.7rem",
                paddingBottom: "1.7rem",
                width: 550,
            }}
        >
            <form
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
                onSubmit={handleSubmit}
            >
                <TextField
                    disabled={isUpdating}
                    value={name}
                    name="name"
                    label="Name"
                    variant="outlined"
                    required
                    inputProps={{ minLength: 1, maxLength: 100 }}
                    onChange={(e) => setName(e.target.value)}
                />
                <CustomButton type="submit" variant="contained" sx={{ alignSelf: "flex-end" }} disabled={isUpdating}>
                    <ButtonTextWithLoading isLoading={false} text="Update" variant="light" />
                </CustomButton>
            </form>
        </BaseModal>
    );
}
