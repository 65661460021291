import CustomButton from "./CustomButton";

interface TextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const TextButton = (props: TextButtonProps) => {
    return (
        <CustomButton
            {...props}
            className={`border-0 shadow-none focus:outline-none focus:shadow-none ${props.className || ''}`}
        />
    );
};
