import { Option } from "../../../common";

import { cn } from "@/lib/utils";
import { getCandidateStageColor } from "@/pages/all-candidates/AllCandidates";
import { Button, ReusableDropdown } from "@/ui";

export type ProjectStatusDropDownAction = {
    label: string;
    value: string;
    handleClick: () => void;
};

type ProjectStatusDropDownProps = {
    selectedOption: Option;
    actions: ProjectStatusDropDownAction[];
    isLoading: boolean;
};

export function ProjectStatusDropDown({ selectedOption, actions, isLoading = false }: ProjectStatusDropDownProps) {
    const { label, value } = selectedOption;
    const color = getCandidateStageColor(value);

    const classes = cn(
        "flex items-center gap-1 ",
        color ? `bg-${color}  hover:bg-${color} hover:opacity-80` : "bg-default hover:bg-default hover:opacity-80"
    );

    return (
        <>
            <ReusableDropdown
                items={actions.map(({ label, handleClick }) => ({ label, onClick: handleClick }))}
                trigger={
                    <Button
                        disabled={isLoading}
                        label={label}
                        style={{
                            border: 0,
                            borderRadius: "0.3rem",
                            fontSize: 10,
                            height: "22px",
                            minWidth: 48,
                            boxShadow: "none",
                        }}
                        className={classes}
                    />
                }
            />
        </>
    );
}
