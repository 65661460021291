import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useDispatch } from "react-redux";

import AllContactsCSVUpload from "./AllContactsCSVUpload";

import CustomButton from "../../../components/CustomButton";
import { setModal } from "../../../store/reducers/modals.slice";

export default function AddContacts() {
    const dispatch = useDispatch();
    const onOpen = () => dispatch(setModal({ key: "showAllContactsCSVModal", value: true }));
    return (
        <>
            <CustomButton  startDecorator={<AddRoundedIcon />} onClick={onOpen}>
                Add
            </CustomButton>
            <AllContactsCSVUpload />
        </>
    );
}
