import Badge from "@mui/joy/Badge";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getHighlightedText } from "../../../../atoms/HighlightedText";
import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { TCandidate } from "../../../../store/apis/super-inbox/super-inbox.types";
import { useSuperInboxContext } from "../../Context";
import { formatMessageDate, toggleMessagesPane } from "../../utils";
import ChannelIcon from "../channels/ChannelIcon";

import { Typography } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";

type ChatListItemProps = ListItemButtonProps & TCandidate;

export default function ChatListItem(props: ChatListItemProps) {
    const {
        filters: { searchQuery },
        candidateParamId,
    } = useSuperInboxContext();
    const {
        candidateName,
        candidateId,
        photo,
        lastMessageTime,
        lastMessageSnippet,
        lastMessageChannel,
        // projectIds,
        threadRead,
    } = props;
    const [isThreadRead, setIsThreadRead] = useState<boolean>(threadRead);

    const navigate = useNavigate();

    const navigateToConversations = () => {
        navigate(`/inbox/${candidateId}/${lastMessageChannel}`);
        if (!isThreadRead) setIsThreadRead(true);
    };

    const onClickCard = () => {
        toggleMessagesPane();
        navigateToConversations();
    };

    const selected = candidateId === candidateParamId;

    return (
        <>
            <ListItem>
                <ListItemButton
                    onClick={onClickCard}
                    selected={selected}
                    color="neutral"
                    sx={{
                        flexDirection: "column",
                        alignItems: "initial",
                        gap: 1,
                    }}
                >
                    <div className="flex flex-row gap-1.5">
                        <Avatar
                            src={photo?.startsWith("data:") ? candidateName : photo}
                            className="w-[33px] h-[33px] bg-white"
                            fallback={
                                <svg
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                </svg>
                            }
                        />
                        <div className="flex-1">
                            <HoverTruncateText stopPropagation={false}>
                                {getHighlightedText(candidateName, searchQuery)}
                            </HoverTruncateText>
                        </div>
                        <div className="flex gap-1.5 leading-[1.5]">
                            <Typography className="text-xs truncate">{formatMessageDate(lastMessageTime)}</Typography>
                            <Badge size="sm" invisible={isThreadRead} color="success">
                                <ChannelIcon channel={lastMessageChannel} />
                            </Badge>
                        </div>
                    </div>
                    <Typography className="text-sm break-words overflow-hidden text-ellipsis line-clamp-2">
                        <span
                            style={{ fontSize: "inherit" }}
                            dangerouslySetInnerHTML={{
                                __html: lastMessageSnippet,
                            }}
                        />
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListDivider sx={{ margin: 0 }} />
        </>
    );
}
