import Tooltip from "@mui/material/Tooltip";

import { TextButton } from "../../../components/TextButton";
import { useAppDispatch } from "../../../store";

import { setDefaultView } from "@/store/reducers/all-candidates/all-candidates.slice";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

export function SetDefaultView() {
    const dispatch = useAppDispatch();
    const handleSetDefaultView = () => {
        dispatch(setDefaultView());
        dispatch(setSuccessNotification("Your current set of columns has now been set as your default view."));
    };
    return (
        <Tooltip title="Save your visible columns and corresponding filters in this view">
            <TextButton className="text-xs" onClick={handleSetDefaultView}>
                Set as default view
            </TextButton>
        </Tooltip>
    );
}
