import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";

import BaseModal from "../BaseModal";
import Loader from "../Loader/Loader";

import { useGetFocusedSearchInfoQuery } from "@/store/apis/projects/projects.api";
import { Chip, Table, TableBody, TableCell, TableHeader, TableRow, Typography } from "@/ui";

function FocusedSearchInfoModal({ onClose, currentData, isLoading }) {
    return (
        <BaseModal onClose={onClose} overlayStyles={{ width: "550px" }}>
            <Typography variant="h6" className="mb-4">
                Focused search
            </Typography>
            <div className="h-[50vh] overflow-auto">
                {isLoading ? (
                    <Loader size="sm" />
                ) : (
                    <div>
                        <Table>
                            <TableHeader className="bg-gray-100">
                                <TableCell>Search Id</TableCell>
                                <TableCell>Created at</TableCell>
                            </TableHeader>
                            <TableBody>
                                {!currentData?.length && (
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="body2" align="center">
                                                No data found!
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}

                                {currentData?.length
                                    ? currentData?.map((search) => (
                                          <TableRow key={search._id}>
                                              <TableCell>{search._id}</TableCell>
                                              <TableCell>
                                                  {dayjs(search.createdAt).format("DD MMM YYYY hh:mm A")}
                                              </TableCell>
                                          </TableRow>
                                      ))
                                    : null}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        </BaseModal>
    );
}

function FocusedSearchButton() {
    const { id: projectId } = useParams();
    const { currentData, isLoading } = useGetFocusedSearchInfoQuery({ projectId });
    const [showModal, setShowModal] = useState(false);

    const handleOnOpen = () => setShowModal(true);
    const handleOnClose = () => setShowModal(false);

    if (isLoading) return null;

    return (
        <>
            {currentData?.data?.length ? (
                <Chip className="mx-2 font-normal bg-cyan-600 text-white text-[12px] cursor-pointer" onClick={handleOnOpen}>
                    Focused search
                </Chip>
            ) : null}
            {showModal ? (
                <FocusedSearchInfoModal currentData={currentData?.data} isLoading={isLoading} onClose={handleOnClose} />
            ) : null}
        </>
    );
}

export default FocusedSearchButton;
