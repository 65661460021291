import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // for active
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DraftsIcon from "@mui/icons-material/Drafts";
import EmailIcon from "@mui/icons-material/Email";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"; // for invalid
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // for unknown
import LinkedIn from "@mui/icons-material/LinkedIn";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import StarIcon from "@mui/icons-material/Star";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
// for verified
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Tooltip as JoyTooltip } from "@mui/joy";
import { Avatar, Box, Chip, Link, Stack, Tooltip, useMediaQuery } from "@mui/material";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { MailErr } from "../../../../assets/img/canndidateCardIcons/MailErr";
import { MailRespondedOutlined } from "../../../../assets/img/canndidateCardIcons/MailRespondedOutlined";
import MessageSentIcon from "../../../../assets/img/canndidateCardIcons/MessageSentIcon";
import SmsAccepted from "../../../../assets/img/canndidateCardIcons/SmsAccepted";
import SmsBlockedIcon from "../../../../assets/img/canndidateCardIcons/SmsBlockedIcon";
import SmsDeliveredIcon from "../../../../assets/img/canndidateCardIcons/SmsDeliveredIcon";
import SmsFailedIcon from "../../../../assets/img/canndidateCardIcons/SmsFailedIcon";
import SmsIcon from "../../../../assets/img/canndidateCardIcons/SmsIcon";
import SmsQueuedIcon from "../../../../assets/img/canndidateCardIcons/SmsQueuedIcon";
import SmsReceived from "../../../../assets/img/canndidateCardIcons/SmsReceived";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import {
    TContactType,
    useGetCandidateSourceTypesQuery,
    useGetOutreachTimestampsQuery,
} from "../../../../store/apis/projects/projects.api";
import handleCleverTap from "../../../../utils/clevertap";
import { reviewStageObj } from "../../../../utils/Constants";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import style from "../../components/candidateProfileCard/candidateProfileCard.module.scss";
import CandidateModal from "../candidateModal/CandidateModal";
import { StatusMenuAnimButton } from "../candidateProfileCard/CandidateProfileCard";
import EmailPopover from "../emailPopover/emailPopover";
import OutreachStatusPopover from "../outreachStatusPopover/outreachStatusPopover";
import PhonePopover from "../phonePopover/phonePopover";
import { OrganizationName } from "../profileTab/ProfileTab";

import { JoyProvider } from "@/components/JoyProvider";
import {
    selectActiveCandidateInfoModalIndex,
    setActiveCandidateInfoModalIndex,
} from "@/store/reducers/candidates/Candidates.reducer";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    moveTo,
    removeSelectedCandidate,
    selectCandidateCurrentPage,
    selectProjectCandidates,
    selectSelectedCandidates,
    setCandidates,
    setScrollToSkills,
    setSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import {
    EmailStatus,
    FollowUpEmailStatus,
    ICandidate,
    ReviewStage,
    SMSStatus,
} from "@/store/reducers/project/project.types";
import { checkEasyGrowth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Typography } from "@/ui";

const ContactTypeMapping: any = {
    ROCKETREACH: "Rocketreach",
    PROXYCURL: "Proxycurl",
    CONTACTS_OUT: "ContactOut",
    PEOPLE_DATA_LABS: "People Data Labs",
    RB2BAPI: "RB2B",
    APOLLO: "Apollo",
};

type ProjectCardProps = {
    data: ICandidate;
    index: number;
    isSample?: boolean;
};

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value?.toString(16)}`?.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            backgroundColor: stringToColor(name),
            width: "15px",
            height: "15px",
            padding: "10px",
        },
        children: `${name?.split(" ")[0][0]}`,
    };
}

export default function ProjectCardWithoutImage(props: ProjectCardProps) {
    const { _id } = props.data;
    const { index } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const [seeMore, setSeeMore] = useState(false);

    const { id: projectId } = useParams();
    const candidateSearchId = searchParams.get("candidateId");
    const dispatch = useDispatch();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const candidatePageNo = useSelector(selectCandidateCurrentPage);
    const isModalOpen = useSelector(selectActiveCandidateInfoModalIndex) === props?.index;
    const isEasyGrowth = useSelector(checkEasyGrowth);

    const showModal = () => {
        dispatch(setActiveCandidateInfoModalIndex(props?.index));
    };
    const closeModal = () => {
        dispatch(setActiveCandidateInfoModalIndex(null));
        setSeeMore(false);
    };

    useEffect(() => {
        if (candidateSearchId === _id) {
            showModal();
            searchParams.delete("candidateId");
            setSearchParams(searchParams);
        }
    }, []);

    const handleSelectCandidate = (e: any) => {
        handleCleverTap("Select candidate", {
            checked: e.target.checked,
            "Project id": projectId,
        });

        if (e.target.checked) {
            dispatch(
                setSelectedCandidates({
                    candidateId: [_id],
                    pageNo: candidatePageNo,
                })
            );
        } else {
            dispatch(removeSelectedCandidate(_id));
        }
    };

    const isSelected = Boolean(selectedCandidates.find((item: any) => item.candidateId === _id));

    const handleSeeMore = () => {
        dispatch(setActiveCandidateInfoModalIndex(props.index));
        setSeeMore((prev) => !prev);
        dispatch(setScrollToSkills(true));
    };

    return (
        <>
            <Stack
                direction="row"
                height={110}
                alignItems="center"
                spacing={3}
                sx={{
                    // bgcolor: index % 2 === 0 ? "white" : "grey.100",
                    padding: 2,
                    cursor: "pointer",
                    borderBottom: "1px solid #E0E0E0",
                    width: "100%",
                }}
            >
                <Checkbox checked={isSelected} onChange={handleSelectCandidate} onClick={(e) => e.stopPropagation()} />
                <div onClick={() => showModal()} style={{ width: "100%" }}>
                    <ProfileCardWithoutImage projectId={projectId} candidate={props.data} index={props.index} />
                </div>
            </Stack>

            {isModalOpen && (
                <BaseModal onClose={closeModal} overlayStyles={{ width: "70vw", height: "80vh" }}>
                    <CandidateModal />
                </BaseModal>
            )}
        </>
    );
}

type ProfileCardWithoutImageProps = {
    projectId?: string | undefined;
    candidate: ICandidate;
    index?: number;
};

type EmailStatusIconProps = {
    finalStatus: {
        title: string;
        color: string;
        Icon: any;
    };
};

const EmailStatusIcon = (props: EmailStatusIconProps) => {
    const { title, color, Icon } = props?.finalStatus;

    return (
        <JoyTooltip title={title} placement="left">
            <Icon sx={{ color, fontSize: "16px" }} />
        </JoyTooltip>
    );
};

function ProfileCardWithoutImage({ projectId, candidate, index }: ProfileCardWithoutImageProps) {
    const {
        _id,
        name,
        experience,
        location,
        phone,
        email,
        professional_email,
        primaryEmail,
        totalExperience,
        profileUrl,
        outreachStatus,
        profileImage,
        stage,
        reviewStage,
        isSample,
        starRating,
        exclusionRating,
        emailResponseStats,
    } = candidate;

    const dispatch = useDispatch();
    // Email Popover Utils
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const [emailPopoverOpen, setEmailPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOutreachStatusNotSent = outreachStatus
        ? Object.values(outreachStatus).every((status) => status === "NOT_SENT")
        : true;
    const { data: outreachTimestamps } = useGetOutreachTimestampsQuery(
        {
            candidateId: _id,
            projectId: Number(projectId),
            bodyOutreachStatus: outreachStatus,
        },
        { skip: isOutreachStatusNotSent }
    );

    const desktop1000 = useMediaQuery("(min-width:1000px)");
    const desktop1100 = useMediaQuery("(min-width:1100px)");
    const desktop1200 = useMediaQuery("(min-width:1200px)");
    const desktop1400 = useMediaQuery("(min-width:1400px)");
    const desktop1500 = useMediaQuery("(min-width:1500px)");
    const desktop1600 = useMediaQuery("(min-width:1600px)");
    const desktop1800 = useMediaQuery("(min-width:1800px)");
    const desktop2000 = useMediaQuery("(min-width:2000px)");
    const desktop2100 = useMediaQuery("(min-width:2100px)");
    const desktop2200 = useMediaQuery("(min-width:2200px)");
    const desktop2300 = useMediaQuery("(min-width:2300px)");
    const desktop2400 = useMediaQuery("(min-width:2400px)");
    const desktop2500 = useMediaQuery("(min-width:2500px)");
    const desktop2800 = useMediaQuery("(min-width:280px)");
    const desktop3000 = useMediaQuery("(min-width:3000px)");

    const getMaxWidth = (currentWidth: any) => {
        switch (currentWidth) {
            case !desktop1000:
                return "140px";
            case !desktop1100:
                return "160px";
            case !desktop1200:
                return "180px";
            case !desktop1400:
                return "200px";
            case !desktop1500:
                return "225px";
            case !desktop1600:
                return "250px";
            case !desktop1800:
                return "270px";
            case !desktop2000:
                return "300px";
            case !desktop2100:
                return "325px";
            case !desktop2200:
                return "345px";
            case !desktop2300:
                return "365px";
            case !desktop2400:
                return "385px";
            case !desktop2500:
                return "400px";
            case !desktop2800:
                return "420px";
            case !desktop3000:
                return "450px";
            default:
                return "100%";
        }
    };
    const maxWidth = getMaxWidth(desktop1400);

    const handleEmailPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setEmailPopoverOpen(true);
    };

    const handleEmailPopoverClose = (event: React.MouseEvent<HTMLElement>) => {
        setEmailPopoverOpen(false);
    };

    const handleEmailPopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setEmailPopoverOpen((prev) => !prev);
    };

    const emailPopoverProps = {
        onMouseEnter: handleEmailPopoverOpen,
        onMouseLeave: handleEmailPopoverClose,
        onClick: handleEmailPopoverToggle,
    };

    // Phone Popover utils
    const [phonePopoverOpen, setPhonePopoverOpen] = useState(false);
    const [phoneAnchorEl, setPhoneAnchorEl] = useState<null | HTMLElement>(null);

    const handlePhonePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setPhoneAnchorEl(event.currentTarget);
        setPhonePopoverOpen(true);
    };

    const handlePhonePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
        setPhonePopoverOpen(false);
    };

    const handlePhonePopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setPhonePopoverOpen((prev) => !prev);
    };

    const phonePopoverProps = {
        onMouseEnter: handlePhonePopoverOpen,
        onMouseLeave: handlePhonePopoverClose,
        onClick: handlePhonePopoverToggle,
    };

    // Outreach Status Popover Utils
    const user = useSelector(selectUser);
    const candidates = useSelector(selectProjectCandidates);
    const [outreachStatusPopoverOpen, setOutreachStatusPopoverOpen] = useState(false);
    const [outreachStatusAnchorEl, setOutreachStatusAnchorEl] = useState<null | HTMLElement>(null);
    const [outreachType, setOutreachType] = useState<"email" | "inmail" | "sms" | null>(null);

    const handleOutreachStatusPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOutreachStatusAnchorEl(event.currentTarget);
        setOutreachType(event.currentTarget.id as "email" | "inmail" | "sms");
        setOutreachStatusPopoverOpen(true);
    };

    const handleOutreachStatusPopoverClose = (event: React.MouseEvent<HTMLElement>) => {
        setOutreachStatusPopoverOpen(false);
    };

    const handleOutreachStatusPopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOutreachStatusPopoverOpen((prev) => !prev);
    };

    const outreachStatusPopoverProps = {
        onMouseEnter: handleOutreachStatusPopoverOpen,
        onMouseLeave: handleOutreachStatusPopoverClose,
        onClick: handleOutreachStatusPopoverToggle,
    };

    // Outreach Icons
    const renderOutreachIcon = (type: string) => {
        const { connection, email, followUpEmail, inMail, sms } = outreachStatus || {};

        // const email = 'NOT_SENT'
        // const followUpEmail = 'NOT_SENT'
        // const sms = 'ACCEPTED'

        // const connection = 'ACCEPTED_WITH_NOTE'
        // const inMail = 'ACCEPTED_WITH_NOTE'

        switch (type) {
            case "email":
                return emailStatusIcons(email, followUpEmail);
            case "inmail":
                return inMailStatusIcons(connection, inMail);
            case "sms":
                return smsStatusIcons(sms);
            default:
                return null;
        }
    };

    // Email Status Icons
    const emailStatusIcons = (
        emailStatus: EmailStatus | undefined,
        followUpStatus: FollowUpEmailStatus | undefined
    ) => {
        if (emailStatus === "NOT_FOUND" || followUpStatus === "NOT_FOUND") return <MailErr />;
        if (emailStatus === "RESPONDED" || followUpStatus === "RESPONDED") return <MailRespondedOutlined />;

        switch (emailStatus) {
            case "NOT_SENT":
                return <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "24px" }} />;
            case "TRIGGERED":
                return <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "24px" }} />;
            case "BOUNCED":
                return <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "24px", color: "error.main" }} />;
            case "FAILED":
                return <MarkEmailUnreadOutlinedIcon sx={{ fontSize: "24px", color: "error.main" }} />;
            case "SENT":
                return followUpStatus === "SENT" ? (
                    <MarkEmailReadIcon sx={{ fontSize: "24px", color: "success.main" }} />
                ) : (
                    <MarkEmailReadOutlinedIcon sx={{ fontSize: "24px", color: "success.main" }} />
                );
            case "OPENED":
                return <DraftsIcon sx={{ fontSize: "24px", color: "#EA9A3E" }} />;
            default:
                return null;
        }
    };

    // inmail status icons
    const inMailStatusIcons = (connection: string, inMail: string) => {
        if (connection === "NOT_FOUND")
            return <ReportGmailerrorredIcon sx={{ fontSize: "24px", color: "error.main" }} />;
        if (connection === "ACCEPTED_WITH_NOTE" || inMail === "ACCEPTED_WITH_NOTE")
            return <StarIcon sx={{ fontSize: "24px", color: "success.main" }} />;

        switch (inMail) {
            case "NOT_SENT":
                return <LinkedInIcon sx={{ fontSize: "24px", color: "#828283" }} />;
            case "TRIGGERED":
                return <LinkedInIcon sx={{ fontSize: "24px", color: "#479cd2" }} />;
            case "FAILED":
                return <LinkedInIcon sx={{ fontSize: "24px", color: "error.main" }} />;
            case "SENT":
                return connection === "SENT_WITH_NOTE" ? (
                    <LinkedInIcon sx={{ fontSize: "24px", color: "success.main" }} />
                ) : (
                    <LinkedInIcon sx={{ fontSize: "24px", color: "success.main" }} />
                );
            case "OPENED":
                return <DraftsIcon sx={{ fontSize: "24px", color: "#EA9A3E" }} />;
            default:
                return null;
        }
    };

    // SMS Status Icons
    const smsStatusIcons = (sms: SMSStatus | undefined) => {
        switch (sms) {
            case "NOT_SENT":
                return <SmsIcon />;
            case "FAILED":
                return <SmsFailedIcon />;
            case "NOT_FOUND":
                return <SmsFailedIcon />;
            case "SENT":
                return <MessageSentIcon />;
            case "DELIVERED":
                return <SmsDeliveredIcon />;
            case "RECEIVED":
                return <SmsReceived />;
            case "OPTED_OUT":
                return <SmsBlockedIcon />;
            case "QUEUED":
                return <SmsQueuedIcon />;
            case "UNDELIVERED":
                return <SmsFailedIcon />;
            case "ACCEPTED":
                return <SmsAccepted />;
            default:
                return null;
        }
    };

    const starIconShouldBeIncluded = (value: number, isEasyGrowth: boolean) => {
        if (isEasyGrowth) return value > -1 && value < 6;
        return value > 0 && value < 6;
    };

    const handleChangeStatus = (id: string, type: ReviewStage, shortlist1?: boolean) => {
        if (isSample) {
            const tmpCandidates = candidates.map((item: ICandidate) => {
                if (item._id === _id) {
                    return {
                        ...item,
                        reviewStage: type,
                    };
                }

                return item;
            });
            dispatch(setCandidates(tmpCandidates));
            return;
        }
        dispatch(
            moveTo({
                candidateIds: [id],
                stage: type,
                shortlist1: shortlist1,
                projectId: Number(projectId),
            })
        );
        return;
    };
    const emailsWithoutEmptyValues = email?.length ? email.filter((i: string) => Boolean(i)) : [];
    const professionalEmailsWithoutEmptyValues = professional_email?.length
        ? professional_email?.filter((i: string) => Boolean(i))
        : [];

    const emailsCombined = [...emailsWithoutEmptyValues, ...professionalEmailsWithoutEmptyValues]?.map((email) => ({
        contactType: "email" as TContactType,
        value: email,
    }));

    const candidateNumber =
        phone?.filter((number) => {
            return number && number !== "-" && number !== "";
        }) ?? [];

    const phoneNumbers = candidateNumber.map((number) => ({
        contactType: "phone" as TContactType,
        value: number,
    }));

    const contactsTypeData = [...emailsCombined, ...phoneNumbers];

    const { data: contactsType } = useGetCandidateSourceTypesQuery(
        {
            profileUrl,
            data: contactsTypeData,
        },
        { skip: !profileUrl || !contactsTypeData?.length }
    );

    const filterContactType = (type: TContactType) => {
        return contactsType?.sourceTypeData?.filter((item: any) => item.contactType === type);
    };

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(setSuccessNotification("Copied to clipboard!"));
    };

    const ContactTypeTooltip = ({
        children,
        contactType,
        emailResponseStats,
    }: {
        children: any;
        contactType: any[];
        emailResponseStats?: {};
    }) => {
        const renderEmailStatusIcon = (email: string) => {
            const emailStatus = emailResponseStats?.[email];
            if (!emailStatus) return null;

            console.log("emailStatus", emailResponseStats);

            const { bouncifyResponse, zeroBounceStatus, zeroBounceAIScore } = emailStatus;

            const statusMap = {
                valid: { title: "Email verified", color: "green", Icon: CheckCircleOutlineIcon },
                invalid: { title: "Invalid email", color: "red", Icon: ErrorOutlineOutlinedIcon },
                failed: { title: "Email verification failed", color: "red", Icon: NewReleasesOutlinedIcon },
                unknown: { title: "Unknown", color: "orange", Icon: HelpOutlineIcon },
            };

            const responseTypeMap = {
                valid: ["deliverable", "valid"],
                invalid: ["undeliverable", "invalid", "disposable", "role-based"],
                failed: ["bouncify-api-failed", "zeroBounce-valid-api-failed"],
            };

            const getStatusFromResponse = (response: string): keyof typeof statusMap => {
                for (const [status, types] of Object.entries(responseTypeMap)) {
                    if (types.includes(response)) {
                        return status as keyof typeof statusMap;
                    }
                }
                return "unknown";
            };

            const checkZeroBounce = () => {
                if (zeroBounceStatus === "catch-all" && zeroBounceAIScore && zeroBounceAIScore > 8) {
                    return statusMap["valid"];
                }
                return statusMap[getStatusFromResponse(zeroBounceStatus)];
            };
            const checkBouncify = () => {
                if (bouncifyResponse === "accept all" && zeroBounceAIScore && zeroBounceAIScore > 8) {
                    return statusMap["valid"];
                }

                const status = getStatusFromResponse(bouncifyResponse);
                if (status === "unknown" || status === "failed") {
                    return checkZeroBounce();
                }
                return statusMap[status];
            };

            const finalStatus = bouncifyResponse ? checkBouncify() : null;

            return finalStatus ? <EmailStatusIcon finalStatus={finalStatus} /> : null;
        };

        const filterContacts = (contacts) => {
            if (!Array.isArray(contacts) || contacts.length === 0) return [];

            // Separate contacts into different categories
            const personalEmails = contacts.filter(
                (item) => item.value.includes("@") && !professionalEmailsWithoutEmptyValues.includes(item.value)
            );
            const professionalEmails = contacts.filter(
                (item) => item.value.includes("@") && professionalEmailsWithoutEmptyValues.includes(item.value)
            );
            const phoneNumbers = contacts.filter((item) => !item.value.includes("@")); // Include phone numbers

            // Determine which emails to show based on user preference
            let filteredEmails = [];
            switch (user.emailSendType) {
                case "ONLY_PERSONAL":
                    filteredEmails = personalEmails;
                    break;
                case "ONLY_PROFESSIONAL":
                    filteredEmails = professionalEmails;
                    break;
                case "PERSONAL_PREFERRED":
                    filteredEmails = personalEmails.length ? personalEmails : professionalEmails;
                    break;
                case "PROFESSIONAL_PREFERRED":
                    filteredEmails = professionalEmails.length ? professionalEmails : personalEmails;
                    break;
                default:
                    filteredEmails = contacts.filter((item) => item.value.includes("@")); // Default to all emails
                    break;
            }

            // Combine filtered emails with phone numbers
            return [...filteredEmails, ...phoneNumbers];
        };

        const filteredContactType = filterContacts(contactType);

        return (
            <JoyProvider>
                <JoyTooltip
                    arrow
                    variant="outlined"
                    title={
                        Array.isArray(filteredContactType) && filteredContactType.length
                            ? filteredContactType.map((item, index) => {
                                const emailType = professionalEmailsWithoutEmptyValues.includes(item.value)
                                    ? "Professional email"
                                    : "Personal email";
                                const isEmail = item?.value?.includes("@");
                                return (
                                    <div key={index} style={{ fontSize: "12px", marginBottom: "7px" }}>
                                        {isEmail ? (
                                            <strong style={{ color: "#02728d", fontSize: "13px" }}>
                                                {emailType}
                                            </strong>
                                        ) : (
                                            <strong style={{ color: "#02728d", fontSize: "13px" }}>
                                                Phone number
                                            </strong>
                                        )}

                                        <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                                            <JoyProvider>{isEmail && renderEmailStatusIcon(item?.value)}</JoyProvider>
                                            <strong>{item.value}</strong>:{" "}
                                            {item.sourceType && item.sourceType.length > 0
                                                ? item.sourceType
                                                    .map((type) => ContactTypeMapping[type] ?? type)
                                                    .join(", ")
                                                : "HireQuotient"}
                                            <ContentCopyIcon
                                                sx={{
                                                    fontSize: "12px",
                                                    marginRight: "5px",
                                                    "&:hover": {
                                                        color: "black",
                                                    },
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCopyToClipboard(item.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                            : undefined
                    }
                >
                    {children}
                </JoyTooltip>
            </JoyProvider>
        );
    };

    const orgName = experience?.[0]?.org?.length > 35 ? `${experience[0].org.slice(0, 34)}...` : experience?.[0]?.org;
    return (
        <Stack
            direction="row"
            alignItems="center"
            display="grid"
            gridTemplateColumns={"2fr 2fr 1.5fr 0.5fr"}
            sx={{ width: "100%" }}
            gap={2}
        >
            <Stack direction="row" alignItems="center" display="grid" gap={1} gridTemplateColumns={"1fr 3fr"}>
                {/* Name Section */}
                <Stack direction="column" alignItems="center">
                    <Avatar
                        src={profileImage?.startsWith("data:") ? name : profileImage}
                        alt={name}
                        className={style["spcard__card-avatar"]}
                        sx={{ backgroundColor: "#8cc0e3" }}
                    />
                    {(!isNil(starRating) && !isNil(starRating?.value)) ||
                        (!isNil(exclusionRating) && !isNil(exclusionRating?.value) && exclusionRating?.value !== 0) ? (
                        <Tooltip
                            title={
                                starRating?.value == 0 && isEasyGrowth ? (
                                    "Person did not qualify your lead scoring criteria"
                                ) : starRating?.description?.length ? (
                                    <ul
                                        style={{
                                            paddingInlineStart: 10,
                                        }}
                                    >
                                        {starRating.description.map(({ text }) => (
                                            <li
                                                key={text}
                                                style={{
                                                    fontSize: 10,
                                                }}
                                            >
                                                {text}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null
                            }
                        >
                            <Chip
                                sx={{
                                    bgcolor: "#fff",
                                    boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
                                    fontSize: 10,
                                    fontWeight: 600,
                                    position: "relative",
                                    // left: "0%",
                                    // top:"-4%",
                                    transform: "translateY(-30%)",
                                    cursor: "pointer",
                                    maxWidth: "fit-content",
                                    "& .MuiChip-label": {
                                        padding: "0 6px",
                                        overflow: "unset",
                                    },
                                    "& .MuiChip-icon": {
                                        color: starRating?.value == 0 ? "grey" : "#FDCC0D",
                                    },
                                }}
                                icon={
                                    exclusionRating?.value === 1 ? undefined : starRating &&
                                        starIconShouldBeIncluded(starRating?.value || 0, isEasyGrowth) ? (
                                        <StarRoundedIcon />
                                    ) : undefined
                                }
                                label={
                                    exclusionRating?.value === 1
                                        ? "Excluded"
                                        : starRating && starRating?.value == 0
                                            ? isEasyGrowth
                                                ? ""
                                                : "Unqualified"
                                            : starRating && starRating?.value !== -1
                                                ? starRating.label
                                                : starRating && starRating?.value === -1
                                                    ? "Unqualified"
                                                    : ""
                                }
                                size="small"
                            />
                        </Tooltip>
                    ) : null}
                </Stack>

                <Stack direction="column" alignItems="left">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <p
                            className="whitespace-nowrap overflow-hidden text-ellipsis font-semibold text-[17px] text-black"
                            style={{ maxWidth: maxWidth }}
                        >
                            {name}
                        </p>
                    </Box>

                    <Stack direction="row" alignItems="left" justifyContent="flex-start">
                        <Link
                            href={profileUrl}
                            target="_blank"
                            sx={{ display: "flex", marginRight: "5px" }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Tooltip title="View linkedin profile" arrow>
                                <LinkedIn sx={{ fontSize: "24px", color: "primary.500", marginTop: "5px" }} />
                            </Tooltip>
                        </Link>

                        {phoneNumbers?.length > 0 && (
                            <ContactTypeTooltip
                                contactType={filterContactType("phone")}
                                emailResponseStats={emailResponseStats}
                            >
                                <Box>
                                    <PhoneIcon sx={{ fontSize: "24px", color: "#828283", marginRight: "5px" }} />
                                </Box>
                            </ContactTypeTooltip>
                        )}

                        {emailsCombined?.length > 0 && (
                            <ContactTypeTooltip
                                contactType={filterContactType("email")}
                                emailResponseStats={emailResponseStats}
                            >
                                <Box>
                                    {((user.emailSendType === "ONLY_PERSONAL" &&
                                        emailsWithoutEmptyValues?.length > 0) ||
                                        (user.emailSendType === "ONLY_PROFESSIONAL" &&
                                            professionalEmailsWithoutEmptyValues?.length > 0) ||
                                        user.emailSendType === "PERSONAL_PREFERRED" ||
                                        user.emailSendType === "PROFESSIONAL_PREFERRED") && (
                                            <EmailIcon sx={{ fontSize: "24px", color: "#828283", marginRight: "5px" }} />
                                        )}
                                </Box>
                            </ContactTypeTooltip>
                        )}

                        <div style={{ marginTop: "-3px" }}>
                            <StatusMenuAnimButton
                                candidateId={_id}
                                stage={reviewStageObj[reviewStage]}
                                isDefaultStageShortlisPlus={candidate?.shortlist1 || false}
                                handleChangeStatus={(stage, shortlist1) => handleChangeStatus(_id, stage, shortlist1)}
                            />
                        </div>

                        {/* Button to select demo status */}
                        {/* <DemoStatus candidate={candidate} /> */}

                        {/* Email Popover */}
                        <EmailPopover
                            anchorEl={anchorEl}
                            open={emailPopoverOpen}
                            professional_email={professional_email}
                            email={email}
                            primaryEmail={primaryEmail}
                            handleEmailPopoverClose={handleEmailPopoverClose}
                            handleEmailPopoverOpen={handleEmailPopoverOpen}
                            handleEmailPopoverToggle={handleEmailPopoverToggle}
                        />

                        {/* Phone Popover */}
                        <PhonePopover
                            candidateNumber={phone}
                            anchorEl={phoneAnchorEl}
                            open={phonePopoverOpen}
                            handlePhonePopoverClose={handlePhonePopoverClose}
                            handlePhonePopoverOpen={handlePhonePopoverOpen}
                            handlePhonePopoverToggle={handlePhonePopoverToggle}
                        />
                    </Stack>
                </Stack>

                {/*Rating Section */}
                {/* <Stack direction="row" alignItems="left">
                    <StatusMenuAnimButton
                        candidateId={_id}
                        stage={reviewStageObj[reviewStage]}
                        isDefaultStageShortlisPlus={candidate?.shortlist1 || false}
                        handleChangeStatus={(stage, shortlist1) => handleChangeStatus(_id, stage, shortlist1)}
                    />
                </Stack> */}
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                sx={{ overflow: "hidden" }}
                display="grid"
                gap={4}
                gridTemplateColumns={"3fr 3fr"}
            >
                {/* Job title Section */}
                <Stack direction="column" alignItems="left">
                    <Typography className="break-words font-normal text-sm text-black">
                        {experience[0]?.position && experience[0].position.length > 40
                            ? `${experience[0].position.substring(0, 40)}...`
                            : experience[0]?.position || "Title not available"}
                    </Typography>
                </Stack>

                {/* Company Section */}
                <Stack direction="column" alignItems="left">
                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        {orgName ? (
                            <>
                                <Avatar
                                    src={experience?.[0]?.orgLogo || "N"}
                                    alt={"N"}
                                    variant="rounded"
                                    sx={{
                                        backgroundColor: "gray",
                                        border: "none",
                                        width: "25px",
                                        height: "25px",
                                        marginRight: "5px",
                                    }}
                                />
                                <OrganizationName link={experience?.[0]?.orgUrl} org={orgName} noHighlightName={true} />
                            </>
                        ) : (
                            <Typography className="text-sm text-black">Organization not available</Typography>
                        )}
                    </Box>
                </Stack>
            </Stack>

            {/* Experience Section */}
            <Stack direction="column" alignItems="left" sx={{ overflow: "hidden" }}>
                {totalExperience && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px", marginBottom: "10px" }}>
                        <WorkHistoryIcon sx={{ fontSize: "large", color: "#828283" }} />
                        <Typography className="whitespace-nowrap overflow-hidden text-ellipsis text-sm">
                            {totalExperience?.label || "Not available"}
                        </Typography>
                    </Box>
                )}
                {location && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <LocationOnIcon sx={{ fontSize: "large", color: "#828283" }} />
                        <Typography className="text-sm break-words">
                            {location !== "-" ? location : "Not available"}
                        </Typography>
                    </Box>
                )}
            </Stack>

            {/* Outreach Status */}
            <Stack
                direction="row"
                sx={{ placeItems: "center" }}
                display="grid"
                gap={1}
                gridTemplateColumns={"repeat(3,1fr)"}
            >
                <Box id="email" {...outreachStatusPopoverProps}>
                    {renderOutreachIcon("email")}
                </Box>
                <Box id="inmail" {...outreachStatusPopoverProps}>
                    {renderOutreachIcon("inmail")}
                </Box>
                <Box id="sms" {...outreachStatusPopoverProps}>
                    {renderOutreachIcon("sms")}
                </Box>

                {/* Outreach Status Popover */}
                <OutreachStatusPopover
                    open={outreachStatusPopoverOpen}
                    type={outreachType}
                    outreachStatus={outreachStatus}
                    anchorEl={outreachStatusAnchorEl}
                    handleOutreachPopoverClose={handleOutreachStatusPopoverClose}
                    handleOutreachPopoverToggle={handleOutreachStatusPopoverToggle}
                    outreachTimestamps={outreachTimestamps}
                />
            </Stack>
        </Stack>
    );
}
