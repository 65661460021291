import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const badgeContentVariants = cva(
    "inline-flex items-center justify-center rounded-full text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default: "bg-primary text-primary-foreground",
                secondary: "bg-[#f5f5f5] text-secondary-foreground",
                destructive: "bg-destructive text-destructive-foreground",
                success: "bg-green-500 text-white",
                error: "bg-red-500 text-white",
                info: "bg-blue-500 text-white",
                warning: "bg-yellow-500 text-white",
                plain: "bg-transparent text-primary-foreground",
            },
            size: {
                small: "min-w-[16px] h-4 px-1",
                default: "min-w-[20px] h-5 px-1.5",
                large: "min-w-[24px] h-6 px-2",
            },
            overlap: {
                true: "absolute",
                false: "relative",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
            overlap: false,
        },
    }
);

const anchorOriginPositions = {
    "top-right": "-translate-y-1/2 translate-x-1/2 top-0 right-0",
    "top-left": "-translate-y-1/2 -translate-x-1/2 top-0 left-0",
    "bottom-right": "translate-y-1/2 translate-x-1/2 bottom-0 right-0",
    "bottom-left": "translate-y-1/2 -translate-x-1/2 bottom-0 left-0",
};

interface AnchorOrigin {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
}

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeContentVariants> {
    badgeContent?: React.ReactNode;
    children?: React.ReactNode;
    invisible?: boolean;
    max?: number;
    showZero?: boolean;
    anchorOrigin?: AnchorOrigin;
    overlap?: boolean;
    dot?: boolean;
}

/**
 * A Badge component that displays a badge with customizable content, position, and appearance.
 * 
 * @param {React.ReactNode} children - The content to be wrapped by the badge.
 * @param {string} className - Additional class names to apply to the badge container.
 * @param {React.ReactNode} badgeContent - The content to display inside the badge.
 * @param {"default" | string} [variant="default"] - The variant of the badge, which determines its appearance.
 * @param {"default" | string} [size="default"] - The size of the badge.
 * @param {boolean} [invisible=false] - If true, the badge will be hidden.
 * @param {boolean} [showZero=false] - If true, the badge will be shown even if the content is zero.
 * @param {{ vertical: "top" | "bottom", horizontal: "left" | "right" }} [anchorOrigin={ vertical: "top", horizontal: "right" }] - The position of the badge relative to its container.
 * @param {boolean} [overlap=true] - If true, the badge will overlap its container.
 * @param {boolean} [dot=false] - If true, the badge will be displayed as a dot.
 * @param {React.Ref<HTMLDivElement>} ref - The ref to be forwarded to the badge container.
 * @param {object} props - Additional props to be spread onto the badge container.
 * 
 * @returns {JSX.Element} The rendered Badge component.
 */
const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
    (
        {
            children,
            className,
            badgeContent,
            variant = "default",
            size = "default",
            invisible = false,

            showZero = false,
            anchorOrigin = { vertical: "top", horizontal: "right" },
            overlap = true,
            dot = false,
            ...props
        },
        ref
    ) => {
        const shouldShow = !invisible && (showZero || badgeContent !== 0);
        const displayContent = dot ? "" : badgeContent;

        const anchorPosition =
            `${anchorOrigin.vertical}-${anchorOrigin.horizontal}` as keyof typeof anchorOriginPositions;

        return (
            <div ref={ref} className={cn("inline-flex relative", className)} {...props}>
                {children}
                {shouldShow && (
                    <div
                        className={cn(
                            badgeContentVariants({ variant, size, overlap }),
                            anchorOriginPositions[anchorPosition],
                            dot && "w-2 h-2 min-w-0 p-0",
                            "z-10"
                        )}
                    >
                        {displayContent}
                    </div>
                )}
            </div>
        );
    }
);

Badge.displayName = "Badge";

export { Badge, badgeContentVariants };
