/* eslint-disable no-console */
import { zodResolver } from "@hookform/resolvers/zod";
import { PaperProps } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { CustomFormHelperText } from "../../../../components/CustomFormHelperText";
import { useAppDispatch } from "../../../../store";
import {
    referToSchema,
    useBookDemoMutation,
    useFetchOrganizationMembersQuery,
} from "../../../../store/apis/all-candidates/all-candidates.api";
import { POPOVER_BOX_SHADOW } from "../../../../utils/Constants";

import { useFetchActivityTags } from "@/pages/all-candidates/AllCandidatesContent";
import { CustomTextField } from "@/pages/all-candidates/components/CustomTextField";
import { ActivityColumn } from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { setUser } from "@/store/reducers/signin/Signin.reducer";
import { Button as CustomButton } from "@/ui";

const channels = ["Call", "Email", "SMS", "LinkedIn"];

const projectSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

type ProjectOption = z.infer<typeof projectSchema>;

const candidateSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

type CandidateOption = z.infer<typeof candidateSchema>;

const bookDemoFormSchema = z.object({
    tag: z.string().trim().nonempty({ message: "Please select tag" }),
    notes: z.string().trim().min(1, { message: "Please enter body" }),
    channel: z
        .string()
        .nonempty({ message: "Please select channel" })
        .transform((val) => {
            if (!val) return null;
            return val.toLowerCase() || null;
        }),
    referTo: referToSchema,
    projectId: z.number().int(),
    candidateId: z.string().trim(),
    scheduledTime: z
        .custom<Dayjs>((val) => {
            if (!(val instanceof dayjs)) return false; // Basic instance check
            return true;
        }, "Enter valid time")
        .nullable()
        .transform((val) => {
            return val ? val.toISOString() : null; // Convert Dayjs to ISO string
        }),

    duration: z.number().int().min(1, { message: "Duration must be greater than 0" }).optional(),
    timeBooked: z.string().optional(),
    status: z.string().optional(),
    meetingLink: z.string().optional(),
});

type BookDemoFormType = z.infer<typeof bookDemoFormSchema>;

function useBookDemoFormState() {
    const { ...rest } = useFormContext<BookDemoFormType>();

    return { ...rest };
}

const commonPaperProps: PaperProps["sx"] = {
    boxShadow: POPOVER_BOX_SHADOW,
    // borderRadius: "0.5rem",
    // maxHeight: "100px",
    "& .MuiAutocomplete-option": {
        fontSize: "14px",
    },
    "& .MuiAutocomplete-noOptions": {
        fontSize: "14px",
    },
    marginTop: "10px",
    marginBottom: "10px",
};

function ReferToMenu() {
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="referTo"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={data}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    label="Assigned to"
                                    error={error !== undefined}
                                    legendWidth={70}
                                    isLoading={isLoading}
                                    inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                                />
                            )}
                        />
                        {error !== undefined && (
                            <CustomFormHelperText>{error?.message || "Please select assignee"}</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function ActivityTypeMenu() {
    const { control } = useBookDemoFormState();
    const { isLoading, data = [] } = useFetchActivityTags();
    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                                option.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                                filtered.push(`Add "${params.inputValue}"`);
                            }
                            return filtered;
                        }}
                        freeSolo
                        disabled={isSubmitting}
                        value={value}
                        options={data}
                        getOptionLabel={(option) => {
                            return option;
                        }}
                        onChange={(e, newValue) => {
                            e.stopPropagation();
                            if (
                                typeof newValue === "string" &&
                                newValue.startsWith('Add "') &&
                                newValue.endsWith('"')
                            ) {
                                onChange(newValue.slice(5, -1));
                            } else {
                                onChange(newValue);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                legendWidth={70}
                                error={error !== undefined}
                                label="Activity Tag"
                                isLoading={isLoading}
                                inputProps={{ onClick: (e) => e.stopPropagation(), ...params.inputProps }}
                            />
                        )}
                    />
                    {error !== undefined && <CustomFormHelperText>Please select tag</CustomFormHelperText>}
                </FormControl>
            )}
        />
    );
}

function Channel() {
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="channel"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl
                        error={Boolean(error)}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormLabel-root ": {
                                    ...fontSize,
                                    top: "-5px",
                                    '&[data-shrink="true"]': {
                                        top: "1px",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "43px",
                                },
                                "& .MuiSelect-select": {
                                    ...fontSize,
                                    padding: "0.7rem",
                                },
                            };
                        }}
                    >
                        <InputLabel>Channel</InputLabel>
                        <Select
                            error={Boolean(error)}
                            disabled={isSubmitting}
                            size="small"
                            value={value}
                            label="Channel"
                            onChange={(e) => onChange(e.target.value)}
                        >
                            {channels.map((i) => {
                                if (!i) {
                                    return null;
                                }

                                return (
                                    <MenuItem
                                        key={i}
                                        value={i}
                                        sx={{
                                            "&.MuiMenuItem-root": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >
                                        {i}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {error !== undefined && <CustomFormHelperText>Channel is required.</CustomFormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function ScheduleTime() {
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="scheduledTime"
            render={({ field, fieldState: { error } }) => {
                const value = field.value;
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            {...field}
                            disablePast
                            label="Scheduled At"
                            ampm={false}
                            slotProps={{
                                field: { clearable: true },
                                actionBar: {
                                    actions: ["clear", "accept"],
                                },
                                textField: {
                                    error: Boolean(error),
                                    helperText: error !== undefined ? "Please select valid time value" : null,
                                    FormHelperTextProps: { sx: { marginX: 0 } },
                                },
                                clearButton: {
                                    size: "small",
                                    sx: {
                                        top: "1px",
                                        right: "1px",
                                    },
                                },
                            }}
                            sx={(theme) => {
                                const fontSize = theme.typography.body2;
                                return {
                                    marginTop: "0.2rem",
                                    "& .MuiFormLabel-root ": {
                                        ...fontSize,
                                        top: "-4px",
                                        '&[data-shrink="true"]': {
                                            top: "1px",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        ...fontSize,
                                        padding: "0.7rem",
                                        color: value ? theme.palette.text.primary : theme.palette.text.disabled,
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        fontSize: "13px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline legend": {
                                        width: "57px",
                                    },
                                };
                            }}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
}

function ActivityBody() {
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="notes"
            render={({ field, fieldState: { error } }) => {
                return (
                    <TextField
                        {...field}
                        label="Body"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        minRows={3}
                        multiline
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormHelperText-root": {
                                    margin: "4px 0 0",
                                },
                                "& .MuiFormLabel-root": {
                                    top: "-8px",
                                    ...fontSize,
                                    '&[data-shrink="true"]': {
                                        top: "0px",
                                    },
                                },
                                "& .MuiInputBase-root": {
                                    padding: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "0.7rem",
                                    maxHeight: "200px",
                                    overflow: "auto !important",
                                    ...fontSize,
                                },
                            };
                        }}
                    />
                );
            }}
        />
    );
}

function Duration() {
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="duration"
            render={({ field, fieldState: { error } }) => {
                return (
                    <TextField
                        {...field}
                        label="Duration (in minutes)"
                        helperText={error?.message}
                        error={Boolean(error?.message)}
                        type="number"
                        onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value ? parseInt(value) : null);
                        }}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormHelperText-root": {
                                    margin: "4px 0 0",
                                },
                                "& .MuiFormLabel-root": {
                                    top: "-8px",
                                    ...fontSize,
                                    '&[data-shrink="true"]': {
                                        top: "0px",
                                    },
                                },
                                "& .MuiInputBase-root": {
                                    padding: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "0.7rem",
                                    ...fontSize,
                                },
                            };
                        }}
                    />
                );
            }}
        />
    );
}

function MeetingLink() {
    const { control } = useBookDemoFormState();
    return (
        <Controller
            control={control}
            name="meetingLink"
            render={({ field }) => {
                return (
                    <TextField
                        {...field}
                        label="Meeting Link"
                        type="text"
                        onChange={(e) => {
                            field.onChange(e.target.value);
                        }}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormLabel-root": {
                                    top: "-8px",
                                    ...fontSize,
                                    '&[data-shrink="true"]': {
                                        top: "0px",
                                    },
                                },
                                "& .MuiInputBase-root": {
                                    padding: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "0.7rem",
                                    ...fontSize,
                                },
                            };
                        }}
                    />
                );
            }}
        />
    );
}

type BookDemoFormProps = {
    onClose?: () => void;
    variant?: "EDIT" | "CREATE" | "SUPER_INBOX_ACTIVITY_CREATE" | "PROJECT_ACTIVITY_CREATE";
    data?: ActivityColumn;
    projects?: ProjectOption[];
    selectedCandidate?: CandidateOption;
    candidateId: string;
    status?: string;
};

const getOptionInitialValue = (variant: BookDemoFormProps["variant"] = "EDIT"): { label: string; value: string } => {
    switch (variant) {
        case "SUPER_INBOX_ACTIVITY_CREATE":
        case "CREATE": {
            return { label: "", value: "" };
        }
        case "EDIT":
        case "PROJECT_ACTIVITY_CREATE": {
            return { label: "-1", value: "-1" };
        }
        default:
            return { label: "", value: "" };
    }
};

function getEditInitialValues(data: ActivityColumn) {
    const {
        activityType,
        activityDetails: { body: originalBody, dueDate },
        assignTo,
    } = data;
    return {
        candidate: getOptionInitialValue("EDIT"),
        project: getOptionInitialValue("EDIT"),
        body: originalBody,
        tag: activityType,
        dueDate: dueDate ? dayjs(dueDate) : null,
        referTo: { _id: assignTo?._id ?? -1, name: assignTo?.name ?? "" },
    };
}

export function BookDemoForm(props: BookDemoFormProps) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const numericalProjectId = Number(id);

    const { data: editData, candidateId, onClose, status } = props;
    const [bookDemo, { isLoading }] = useBookDemoMutation();

    const methods = useForm<BookDemoFormType>({
        resolver: zodResolver(bookDemoFormSchema),
        defaultValues:
            editData !== undefined
                ? getEditInitialValues(editData)
                : {
                      notes: "",
                      tag: "",
                      scheduledTime: null,
                      duration: 1,
                      candidateId: candidateId,
                      projectId: numericalProjectId,
                      timeBooked: dayjs().toISOString(),
                      referTo: { _id: -1, name: "" },
                      status: status,
                  },
    });

    const onSubmit: SubmitHandler<BookDemoFormType> = (data) => {
        bookDemo({
            ...data,
        })
            .unwrap()
            .then((res: any) => {
                if (res?.data) {
                    dispatch(setUser(res?.data));
                }
                onClose?.();
                dispatch(setSuccessNotification("Demo status changed successfully"));
            })
            .catch((err) => {
                console.error(err);
                dispatch(setErrorNotification("Failed to change demo status"));
            });
    };

    return (
        <FormProvider {...methods}>
            <Stack component="form" gap={2} onSubmit={methods.handleSubmit(onSubmit)}>
                <Channel />
                <ScheduleTime />
                <ActivityTypeMenu />
                <Duration />
                <ReferToMenu />
                <MeetingLink />
                <ActivityBody />
                <CustomButton className="self-end" disabled={isLoading}>
                    <ButtonTextWithLoading
                        isLoading={isLoading}
                        text="Save"
                        progressSize={16}
                        variant="light"
                    />
                </CustomButton>
            </Stack>
        </FormProvider>
    );
}