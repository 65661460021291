import { zodResolver } from "@hookform/resolvers/zod";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import { Experimental_CssVarsProvider, FormHelperTextProps, SxProps, Theme, Typography, styled } from "@mui/material";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useAppDispatch } from "../../../store";
import { useSendReminderMailMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import handleException from "../../../utils/sentry";

import { SendReminderEmailRequest } from "@/store/reducers/all-candidates/all-candidates.types";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";
import { Popover } from "@/ui/Popover/Popover";

const HELPER_TEXT_MARGIN: FormHelperTextProps["sx"] = {
    marginX: "3px",
};

export const dateTimePickerStyles: SxProps<Theme> = (theme) => {
    const fontSize = theme.typography.body2;
    return {
        marginTop: "0.2rem",
        "& .MuiFormLabel-root ": {
            ...fontSize,
        },
        "& .MuiInputBase-input": {
            ...fontSize,
            padding: "0.7rem",
        },
        "& .MuiOutlinedInput-notchedOutline legend": {
            width: "88px",
        },
    };
};

const scheduleReminderEmailForActivityFormSchema = z.object({
    email: z.string({ required_error: '"Email" is required' }).email(),
    scheduledFor: z.custom<Dayjs>((val) => {
        if (!(val instanceof dayjs)) return false; // Basic instance check

        return true;
    }, "Enter valid time"),
});

type ScheduleReminderEmailForActivityFormType = z.infer<typeof scheduleReminderEmailForActivityFormSchema>;

const CustomTextField = styled((props: TextFieldProps) => {
    return <TextField variant="outlined" {...props} />;
})(({ theme }) => {
    const fontSize = theme.typography.body2;
    return {
        "& .MuiInputBase-input": {
            padding: "0.7rem",
            ...fontSize,
        },
        "& .MuiFormLabel-root": {
            ...fontSize,
            top: "-4px",
            '&[data-shrink="true"]': {
                top: "1px",
            },
        },
        "& .MuiInputBase-input::placeholder": {
            ...fontSize,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            ...fontSize,
        },
    };
});

function ScheduleReminderEmailForActivityForm({
    activityId,
    handleClose,
}: {
    activityId: string;
    handleClose?: () => void;
}) {
    const dispatch = useAppDispatch();
    const [sendReminderEmail, { isLoading }] = useSendReminderMailMutation();
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm<ScheduleReminderEmailForActivityFormType>({
        resolver: zodResolver(scheduleReminderEmailForActivityFormSchema),
        defaultValues: {
            email: "",
            scheduledFor: dayjs(),
        },
    });

    const onSubmit = (data: ScheduleReminderEmailForActivityFormType) => {
        const sendReminderEmailPayload: SendReminderEmailRequest = {
            activityId,
            emailId: data.email,
            scheduledFor: data.scheduledFor.toISOString(),
        };

        sendReminderEmail(sendReminderEmailPayload)
            .then(() => {
                dispatch(setSuccessNotification("Reminder email sent successfully"));
                reset();
                handleClose();
            })
            .catch((err) => {
                console.log({ err });
                handleException(err);
                dispatch(setErrorNotification("Error while sending reminder email please try again."));
            });
    };

    return (
        <div className="flex flex-col p-3 gap-3" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body1" fontWeight={500}>
                Send reminder email
            </Typography>
            <form className="flex flex-col gap-2.5 ">
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <CustomTextField
                            FormHelperTextProps={{
                                sx: HELPER_TEXT_MARGIN,
                            }}
                            placeholder="Enter Email"
                            label="Email"
                            helperText={errors?.email?.message}
                            error={Boolean(errors?.email?.message)}
                            {...field}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="scheduledFor"
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                ampm={false}
                                label="Schedule Send"
                                slotProps={{
                                    textField: {
                                        helperText: errors.scheduledFor?.message,
                                        FormHelperTextProps: {
                                            sx: HELPER_TEXT_MARGIN,
                                        },
                                        error: Boolean(errors?.scheduledFor?.message),
                                    },
                                }}
                                sx={dateTimePickerStyles}
                                {...field}
                            />
                        </LocalizationProvider>
                    )}
                />
                <Button disabled={isLoading} type="submit" variant="default" className="self-end">
                    Submit
                </Button>
            </form>
        </div>
    );
}

export function ScheduleReminderEmailForActivity({ activityId }: { activityId: string }) {
    return (
        <Experimental_CssVarsProvider>
            <Popover
                align="end"
                sideOffset={10}
                trigger={
                    <Button variant="ghost" className="hover:cursor-pointer hover:bg-gray-300 rounded-full p-1 h-auto">
                        <ScheduleRoundedIcon fontSize="small" />
                    </Button>
                }
            >
                <ScheduleReminderEmailForActivityForm activityId={activityId} />
            </Popover>
        </Experimental_CssVarsProvider>
    );
}
