import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LocationPicker from "./LocationPicker";

import scholarIcon from "@/assets/img/email-providers-logo/googlescholar.png";
import { useFetchBetaAICandidatesMutation } from "@/store/apis/all-candidates/all-candidates.api";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectGeography } from "@/store/reducers/search/search.slice";
import { addAIcandidatesToProject } from "@/store/reducers/searchCandidates/searchCandidates.slice";
import { ISampleMessage } from "@/store/reducers/searchCandidates/searchCandidates.types";
import { Button, Modal, Tooltip, Typography } from "@/ui";

type Candidate = {
    name: string;
    scholarUrl: string;
    profileUrl: string;
    degreeType: string;
    citations: number | string;
    hIndex: number | string;
    relevanceScore: number;
    rating: any;
    highlight: string;
};

type AISearchModalProps = {
    open: boolean;
    onClose: () => void;
    projectId: string;
};

const handleGoogleScholarClick = (url: string) => {
    const dispatch = useDispatch();
    if (url) {
        window.open(url);
    } else {
        dispatch(setErrorNotification("No Google scholar url exists"));
    }
};

function SampleMessage(props: ISampleMessage) {
    const { message, onClick } = props;
    return (
        <div
            className="text-[#068093] text-[12px] p-0.5 border border-[#068093] rounded cursor-pointer mb-[5px] w-[94%] ml-[18px]"
            onClick={() => onClick(message)}
        >
            <span style={{ fontSize: "14px" }}>💡</span> <i>{message}</i>
        </div>
    );
}

const roles = [
    "Artificial Intelligence",
    "Machine Learning",
    "Deep Learning",
    "Neural Networks",
    "Generative AI",
    "Computer Vision",
    "Natural Language Processing",
    "Reinforcement Learning",
    "Representation Learning",
    "AI Explainability",
    "Data Efficiency",
    "Conversational AI",
    "Multimodal Data Intelligence",
    "Multitask Learning",
    "Supervised Learning",
    "Unsupervised Learning",
    "Semi Supervised Learning",
    "Transfer Learning",
    "Meta Learning",
    "Graph Neural Networks",
    "Convolutional Neural Networks",
    "Recurrent Neural Networks",
    "Generative Adversarial Networks",
    "Self Supervised Learning",
    "Explainable Ai",
    "Ai Ethics",
    "Ai Safety",
    "Data Science",
    "Medical Image Analysis",
    "Image Recognition",
    "Machine Translation",
    "Speech Recognition",
    "Speech Synthesis",
    "Text To Speech",
    "Information Retrieval",
    "Sentiment Analysis",
    "Language Modeling",
    "Embedding Models",
    "Transformer Models",
];

const AISearchModal: React.FC<AISearchModalProps> = ({ open, onClose, projectId }) => {
    const [inputValue, setInputValue] = useState("");
    const [isFinding, setIsFinding] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [noCandidatesFound, setNoCandidatesFound] = useState<boolean | null>(null);
    const [searchId, setSearchId] = useState<string>("");
    const [expanded, setExpanded] = useState(true);
    const dispatch = useDispatch();
    const [fetchBetaAICandidates] = useFetchBetaAICandidatesMutation();

    const handleFindClick = async () => {
        setExpanded(false);
        setIsFinding(true);
        setFetchError(false);
        try {
            const { data } = await fetchBetaAICandidates({
                statement: inputValue,
            }).unwrap();

            if (data && data.total === 0) {
                setFetchError(false);
                setNoCandidatesFound(true);
                setShowCandidateDetails(false);
            } else if (data && data.profiles) {
                const mappedCandidates = data.profiles.map((candidate: any) => ({
                    scholarUrl: candidate.scholarUrl,
                    name: candidate.name,
                    citations: candidate.citations !== null ? candidate.citations : "N/A",
                    hIndex: candidate.hIndex !== null ? candidate.hIndex : "N/A",
                    degreeType: candidate.degreeType,
                    profileUrl: candidate.profileUrl,
                    relevanceScore: candidate.relevanceScore,
                    rating: candidate.rating,
                    highlight: candidate.highlight,
                }));
                setCandidates(mappedCandidates);
                setShowCandidateDetails(true);
                setNoCandidatesFound(false);
            }
            setSearchId(data.searchId);
        } catch (error) {
            console.error("Failed to fetch candidates:", error);
            setFetchError(true);
            setShowCandidateDetails(false);
        } finally {
            setIsFinding(false);
        }
    };

    const handleAddAICandidates = () => {
        if (projectId) {
            try {
                dispatch(
                    addAIcandidatesToProject({
                        projectId,
                        searchId,
                        projectName: null,
                        navigate: () => {},
                        canNavigateNextStep: false,
                        onSuccess() {
                            dispatch(
                                getProject({
                                    projectId,
                                    action: getProject.type,
                                })
                            );
                        },
                    })
                );
                onClose();
                setShowCandidateDetails(false);
                dispatch(setSuccessNotification("Adding candidates to project"));
            } catch (error) {
                console.error("Error adding AI candidates to project:", error);
                dispatch(setErrorNotification("Error adding AI candidates to project"));
            }
        }
    };

    function CustomMessage(props: ISampleMessage) {
        const [role, setRole] = useState("Artificial Intelligence");
        const { values } = useSelector(selectGeography);

        const handleClick = () => {
            const customizedMessage = `Find top candidates in the fields of ${role} in the location of ${values[0]?.value}.`;
            setInputValue(customizedMessage);
        };

        return (
            <div style={{ display: "flex" }}>
                <div className="text-[15px] p-0.5 cursor-pointer mb-[5px] w-[80%] ml-[18px] flex items-center flex-wrap text-center h-auto">
                    <div className="text-[15px] ml-1">
                        <i>Find top candidates in the field of </i>
                    </div>

                    <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="standard"
                        sx={{
                            mx: 1,
                            fontSize: "14px",
                            width: "120px",
                            maxWidth: "120px",
                            border: "1px solid lightgrey",
                            padding: "3px",
                            borderRadius: "5px",
                        }}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>

                    <div className="text-[15px] ml-1">
                        <i>in the areas of</i>
                    </div>

                    <LocationPicker id={"geography"} multiple={false} />
                </div>

                <div className="text-[14px] p-0.5 cursor-pointer mb-[5px] w-[20%] ml-[18px] flex items-center flex-wrap text-center h-auto justify-center">
                    <Button
                        variant="outline"
                        onClick={handleClick}
                        className="text-[14px] ml-[10px] h-[30px]"
                        label="Use"
                    />
                </div>
            </div>
        );
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="absolute top-[5%] left-[54%] bg-white p-[10px] rounded-[8px] max-h-[88vh] w-[575px] overflow-hidden transform -translate-x-1/2 outline-none"
        >
            <div>
                <div className="flex items-center relative">
                    <Typography variant="h6" className="w-full text-center mb-5 text-lg font-semibold text-wrap">
                        AI Recruiter: Find the top AI talent
                        <Tooltip
                            placement="left"
                            title={
                                "EasySource helps you uncover hard-to-find, highly qualified, and sought after talent in artificial intelligence, machine learning, and other similar fields"
                            }
                        >
                            <InfoIcon
                                fontSize="small"
                                color="disabled"
                                sx={{ marginLeft: "6px", marginBottom: "-3px" }}
                            />
                        </Tooltip>
                    </Typography>

                    <button onClick={() => setExpanded(!expanded)} className="absolute right-0 mb-[20px] mr-3">
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                </div>

                {expanded && (
                    <>
                        <Typography className="text-left ml-[18px] text-[14px]">Sample queries</Typography>
                        <SampleMessage
                            message="AI researchers with a background in AI Architecture and understand LLMs in US."
                            onClick={(val) => setInputValue(val)}
                        />
                        <SampleMessage
                            message="Researchers experienced in Generative AI and Natural Language Processing."
                            onClick={(val) => setInputValue(val)}
                        />
                        <SampleMessage
                            message="PhD students who have researched in General Adverserial Networks in the US."
                            onClick={(val) => setInputValue(val)}
                        />

                        <Typography className="text-left ml-[18px] text-[14px] mt-[10px]">
                            Customize your query
                        </Typography>

                        <CustomMessage
                            message="I am looking for XYZ engineer who have skills in XYZ from XYZ."
                            onClick={(val) => setInputValue(val)}
                        />
                    </>
                )}
                <div className="flex gap-1 mt-2 mb-[20px] pl-4">
                    <input
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter search query..."
                        className="h-[40px] flex-grow pl-[18px] pr-[18px] bg-white border border-[#5a5656] rounded"
                    />
                    <Button
                        variant="default"
                        onClick={handleFindClick}
                        className="h-[40px] mr-[15px]"
                        disabled={!inputValue || isFinding}
                        label={isFinding ? "Finding..." : "Find"}
                    />
                </div>

                {isFinding ? (
                    <Typography className="text-center mt-[5px] text-[12px] italic text-[#5a5656]">
                        This should take a few moments...
                    </Typography>
                ) : null}

                {fetchError ? (
                    <>
                        <Typography className="text-center mt-[5px] text-[12px] italic text-[#5a5656]">
                            Results were not fetched successfully at this point in time.
                        </Typography>
                        <Typography className="text-center text-[12px] italic text-[#5a5656]">
                            Please try again in a while or contact your Account Manager
                        </Typography>
                    </>
                ) : null}

                {!fetchError && noCandidatesFound === true && !isFinding ? (
                    <Typography className="text-center mt-[5px] text-[12px] italic text-[#5a5656]">
                        Unfortunately, no relevant results were found for your query. Please try changing and refreshing
                        your results.
                    </Typography>
                ) : null}

                {showCandidateDetails && (
                    <>
                        <Typography
                            variant="h6"
                            className="text-center p-[10px] border-t-2 pb-[10px] border-t-[lightgrey]"
                        >
                            Candidate details ({candidates?.length} candidates)
                        </Typography>
                        <div className="max-h-[400px] overflow-y-auto scroll-smooth">
                            {candidates.map((candidate, index) => (
                                <div
                                    key={index}
                                    className="flex items-center border border-[rgba(0,0,0,0.2)] rounded-[8px] p-2 mt-2 bg-[#f8fcfd]"
                                >
                                    <div className="flex-grow">
                                        <div className="flex justify-between items-center mb-[4px]">
                                            {candidate?.name && (
                                                <div className="flex text-[16px] font-semibold text-black ">
                                                    {candidate.name?.length > 20
                                                        ? `${candidate.name.substring(0, 20)}...`
                                                        : candidate.name}

                                                    {candidate?.scholarUrl && (
                                                        <Tooltip title="Google scholar" placement="bottom" >
                                                            <img
                                                                src={scholarIcon}
                                                                alt="icon"
                                                                width={20}
                                                                height={20}
                                                                style={{ paddingTop: "5px", marginLeft: "5px" }}
                                                                onClick={() =>
                                                                    handleGoogleScholarClick(candidate.scholarUrl)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}

                                            {candidate?.highlight && (
                                                <span
                                                    style={{
                                                        fontStyle: "italic",
                                                        fontSize: "13px",
                                                        fontWeight: "500",
                                                        padding: "1.5px",
                                                        borderRadius: "10px",
                                                        backgroundColor: "#dfff0b",
                                                        paddingLeft: "7px",
                                                        paddingRight: "7px",
                                                    }}
                                                >
                                                    {candidate.highlight}
                                                </span>
                                            )}
                                        </div>

                                        {candidate?.degreeType && (
                                            <Typography className="text-[14px] font-medium">
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {" "}
                                                    {candidate.degreeType}{" "}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.rating && (
                                            <Typography className="text-[14px] font-medium">
                                                Rating:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.rating}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.relevanceScore && (
                                            <Typography className="text-[14px] font-medium">
                                                Relevance :{" "}
                                                {candidate.relevanceScore === 1 && (
                                                    <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>High</span>
                                                )}
                                                {candidate.relevanceScore !== 1 && (
                                                    <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                        Moderate
                                                    </span>
                                                )}
                                            </Typography>
                                        )}

                                        {candidate?.citations && (
                                            <Typography className="text-[14px] font-medium">
                                                Citations:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.citations}
                                                </span>{" "}
                                            </Typography>
                                        )}

                                        {candidate?.hIndex && (
                                            <Typography className="text-[14px] font-medium">
                                                H-Index:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.hIndex}
                                                </span>
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-end mt-4 fixed bottom-1 right-[20px] bg-white w-full pt-[5px] pb-[4px]">
                            <Button
                                variant="default"
                                onClick={handleAddAICandidates}
                                className="h-[35px] self-end"
                                label="Add list to project"
                            />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AISearchModal;
