import React from "react";

import style from "./button.module.scss";

interface btnPropsType {
    label: string;
    variant: "primary" | "secondary" | "success" | "primary-outlined";
    onClick?: () => void | undefined;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    [x: string]: any;
}

export default function Button(props: btnPropsType) {
    const { label, variant, onClick, disabled, startIcon, endIcon, ...rest } = props;

    return (
        <button
            className={`${style["btn"]} ${variant === "primary"
                    ? style["btn-primary"]
                    : variant === "success"
                        ? style["btn-success"]
                        : variant === "primary-outlined"
                            ? style["btn-primary-outlined"]
                            : style["btn-secondary"]
                }`}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            <span className={style["startIcon"]}>{startIcon}</span>
            {label}
            <span className={style["endIcon"]}>{endIcon}</span>
        </button>
    );
}
