/* eslint-disable import/no-unused-modules */
import { Star } from "lucide-react";
import React, { useState } from "react";

interface RatingProps {
    /**
     * The initial rating value (1-5).
     *
     * @type {number}
     * @required
     */
    value: number;

    /**
     * Whether the rating is read-only and cannot be modified by the user.
     *
     * @type {boolean}
     * @default false
     */
    readOnly?: boolean;

    /**
     * Whether to display the numerical rating value alongside the stars.
     *
     * @type {boolean}
     * @default false
     */
    showNumberRating?: boolean;

    /**
     * Callback function invoked when the user changes the rating.
     *
     * @type {(rating: number) => void}
     * @optional
     */
    onChange?: (rating: number) => void;
}

/**
 * A Rating component that allows users to select a rating from 1 to 5 stars.
 * 
 * @param {number} value - The initial rating value.
 * @param {boolean} [readOnly=false] - If true, the rating is read-only and cannot be changed.
 * @param {boolean} [showNumberRating=false] - If true, the numeric rating value is displayed next to the stars.
 * @param {function} [onChange] - Callback function that is called when the rating value changes.
 * 
 * @returns {JSX.Element} The rendered Rating component.
 */
export default function Rating({
    value: initialValue,
    readOnly = false,
    showNumberRating = false,
    onChange,
}: RatingProps) {
    const [rating, setRating] = useState(initialValue);
    const [hover, setHover] = useState(0);

    const handleRatingChange = (newRating: number) => {
        if (!readOnly) {
            setRating(newRating);
            onChange?.(newRating);
        }
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLButtonElement>, star: number) => {
        if (!readOnly) {
            const { left, width } = event.currentTarget.getBoundingClientRect();
            const percent = (event.clientX - left) / width;
            setHover(star - (percent > 0.5 ? 0 : 0.5));
        }
    };

    return (
        <div className="flex flex-row items-center p-0 m-0 gap-1.5">
            {[1, 2, 3, 4, 5].map((star) => {
                const isHalfStar = star - 0.5 <= (hover || rating);
                const isFullStar = star <= (hover || rating);

                return (
                    <button
                        key={star}
                        className={`flex items-center relative w-5 h-5 ${readOnly ? "cursor-default" : "cursor-pointer"}`}
                        onClick={(e) => {
                            const { left, width } = e.currentTarget.getBoundingClientRect();
                            const percent = (e.clientX - left) / width;
                            handleRatingChange(star - (percent > 0.5 ? 0 : 0.5));
                        }}
                        onMouseMove={(e) => handleMouseMove(e, star)}
                        onMouseEnter={() => !readOnly && setHover(star)}
                        onMouseLeave={() => !readOnly && setHover(0)}
                        disabled={readOnly}
                    >
                        <Star
                            className={`absolute w-5 h-5 ${
                                isFullStar ? "text-yellow-400 fill-yellow-400" : "text-gray-300"
                            }`}
                        />
                        {isHalfStar && !isFullStar && (
                            <div className="absolute w-[50%] h-full overflow-hidden">
                                <Star className="absolute w-5 h-5 text-yellow-400 fill-yellow-400" />
                            </div>
                        )}
                    </button>
                );
            })}
            {showNumberRating ? <span className="ml-2 text-sm text-gray-600">{hover || rating || 0} / 5</span> : null}
        </div>
    );
}
