import { PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";

import { Option, OptionWithValueAsNumber } from "@/common";
import { prioritySchema } from "@/store/apis/all-candidates/all-candidates.api";
import { candidateReachoutsTabs } from "@/utils/Constants";

export type SubmitCandidateActivityPayload = PayloadAction<{
    emailId: string;
    activityId: string;
    callback?: () => void;
}>;

export type SelectedProjectPayload = PayloadAction<OptionWithValueAsNumber>;

export type CandidateReachoutTabs = keyof typeof candidateReachoutsTabs;

export type CandidateReachoutTabValues = (typeof candidateReachoutsTabs)[CandidateReachoutTabs];

export type SetCandidatesReachoutTabPayload = PayloadAction<CandidateReachoutTabs>;

// activity form state
export const optionSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

export const optionWithValueAsNumberSchema = z.object({
    label: optionSchema.shape.label,
    value: z.number().refine((value) => value !== -1, { message: "Please select project" }),
});

export const activityFormStateSchema = z.object({
    project: optionWithValueAsNumberSchema.nullable(),
    tag: optionSchema.nullable(),
    body: z.string().trim().min(1, { message: "Please enter body." }),
    assignee: optionWithValueAsNumberSchema.nullable().optional(),
    priority: prioritySchema.optional(),
    dueDate: z
        .custom<Dayjs>((val) => {
            if (!(val instanceof dayjs)) return false; // Basic instance check

            return true;
        }, "Enter valid time")
        .nullable()
        .optional(),
});

export type ActivityFormState = z.infer<typeof activityFormStateSchema> & {
    id?: string;
};

//------------------------------

// references form state
export const referencesFormStateSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    relation: z.string().trim().min(1, { message: "Please enter relation." }),
    contact: z.string().trim().min(1, { message: "Please enter contact." }),
    linkedin: z.string().nullable(),
    networkType: z.string().nullable(),
    email: z.string().nullable(),
    notes: z.string().nullable(),
    id: z.string().optional(),
});

export type ReferencesFormState = z.infer<typeof referencesFormStateSchema>;

export type ReferencesKeys = keyof ReferencesFormState;

export type CreateReferenceRequestPayload = {
    candidateId: string;
    referrerName: string;
    relation: string;
    referrerPhoneNo: string;
    referrerLinkedinUrl?: string;
    title?: string;
    referrerEmail?: string;
    body?: string;
};

export type UpdateReferencesRequestPayload = Omit<CreateReferenceRequestPayload, "candidateId"> & {
    referralId: string;
};
//----------------------

export type ActivityFormStateKeys = keyof ActivityFormState;

export type ActivityFormStateValues = string | Option | OptionWithValueAsNumber;

type FORM_MODE = "CREATE" | "EDIT";

export type AllContactsState = {
    editReferencesState: ReferencesFormState;
    editFormStateSubmitMode: FORM_MODE;
    activityFormSubmitMode: FORM_MODE;
    activityFormState: ActivityFormState;
    insightsSelectedProject: OptionWithValueAsNumber;
    activitiesSelectedProject: OptionWithValueAsNumber;
    openEditDrawer: boolean;
    tab: CandidateReachoutTabs;
    activityLogs: any;
    references: any;
    projects: any;
    orgUsers: any;
    contactReachouts: {
        contactInfo: any;
        reachout: any;
    };
};

export const activityTagsOption = [
    {
        label: "Call",
        value: "Call",
    },
    {
        label: "Interview",
        value: "Interview",
    },
    {
        label: "Conversation",
        value: "Conversation",
    },
    {
        label: "Notes",
        value: "Notes",
    },
    { label: "Candidate comments", value: "Candidate comments" },
    { label: "Activities", value: "Activities" },
    { label: "Search Status", value: "Search Status" },
    { label: "Referral", value: "Referral" },
    { label: "Next Steps", value: "Next Steps" },
    { label: "Client Comments", value: "Client Comments" },
    { label: "Referral/comments", value: "Referral/comments" },
    { label: "Stats", value: "Stats" },
    { label: "Referral Source", value: "Referral Source" },
    { label: "Status", value: "Status" },
    { label: "Vetting Sources", value: "Vetting Sources" },
    { label: "References Summary", value: "References Summary" },
];

export const referenceNetworkType = [
    {
        label: "Referral",
        value: "Referral",
    },
    {
        label: "Reference",
        value: "Reference",
    },
    {
        label: "Covert (stealth)",
        value: "Covert (stealth)",
    },
];

export const yearsOfExperienceOptions = [
    {
        label: "Less than 1 year",
        value: "0-1",
    },
    {
        label: "1 to 2 years",
        value: "1-2",
    },
    {
        label: "3 to 5 years",
        value: "3-5",
    },
    {
        label: "6 to 10 years",
        value: "6-10",
    },
    {
        label: "More than 10 years",
        value: encodeURIComponent("10+"),
    },
];

export const candidateStages = {
    "SHORTLISTED++": "Shortlisted++",
    SHORTLISTED: "Shortlisted",
    CONTACTED: "Contacted",
    NOT_INTERESTED: "Not interested",
    REJECTED: "Rejected",
    TO_BE_REVIEWED: "To be reviewed",
};

export const projectStageOptions = {
    "SHORTLISTED++": "Shortlisted++",
    SHORTLISTED: "Shortlisted",
    // CONTACTED: "Contacted",
    NOT_INTERESTED: "Not interested",
    REJECTED: "Rejected",
    TO_BE_REVIEWED: "To be reviewed",
} as const;

export type ProjectStageOptionKey = keyof typeof projectStageOptions;

type ProjectStageOptionLabel = (typeof projectStageOptions)[keyof typeof projectStageOptions];

export type ProjectStageOption = {
    label: ProjectStageOptionLabel;
    value: ProjectStageOptionKey;
};

export const projectStageOptionsList: ProjectStageOption[] = Object.entries(projectStageOptions).map(
    ([key, value]) => ({
        label: value,
        value: key as ProjectStageOptionKey,
    })
);

export const detailedStageOptions = {
    in_play: "In play",
    recommend_meet: "Recommend meet",
    Engaged: "Engaged",
    reached_out: "Reached out",
    vetting_underway: "Vetting underway",
    candidate_bowed_out: "Candidate bowed out",
    client_not_moving_forward: "Client not moving forward",
    candidate_recently_switched: "Candidate recently switched",
    not_interested: "Not interested",
    not_fit: "Not a fit",
} as const;

export type DetailedStageOptionKey = keyof typeof detailedStageOptions;

export type DetailedProjectOptionLabel = (typeof detailedStageOptions)[keyof typeof detailedStageOptions];

export type DetailedProjectStageOption = {
    label: DetailedProjectOptionLabel;
    value: DetailedStageOptionKey;
};

export const detailedProjectStageOptionsList: DetailedProjectStageOption[] = Object.entries(detailedStageOptions).map(
    ([key, value]) => ({
        label: value,
        value: key as DetailedStageOptionKey,
    })
);

export const activityLogSortOption = [
    {
        label: "Tag",
        value: "tag",
    },
    {
        label: "Project name",
        value: "projectName",
    },
    {
        label: "Date",
        value: "createdAt",
    },
];

export type ActivityProject = {
    id: string;
    name: string;
};

export type ActivityCardType = {
    id: string;
    profilePicture: string;
    body: string;
    project: ActivityProject;
    timestamp: string;
    tag: string;
    name: string;
    read: boolean;
    referTo: {
        name: string;
        _id: string;
    };
};
