import CloseIcon from "@mui/icons-material/Close";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Experimental_CssVarsProvider, List, ListItem, Popover } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

import style from "./Workflow.module.scss";

import { MaterialProvider } from "../../../../components/MaterialProvider";
import { useGetWorkflowDetailsQuery } from "../../../../store/apis/linkedin-workflow/linkedin-workflow.api";

import Loader from "@/components/Loader/Loader";
import { Chip, Typography } from "@/ui";

function WorkflowDetails({ workflow }: { workflow: any }) {
    const { data, isLoading, isFetching } = useGetWorkflowDetailsQuery({ workflowId: workflow.workflowId });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popoverData, setPopoverData] = useState<{ title: string; items: string[] }>({
        title: "",
        items: [],
    });

    const handleContactClick = (event: React.MouseEvent<HTMLElement>, phones: string[]) => {
        const validPhones = phones.filter((phone) => phone && phone.trim().length > 0);
        setAnchorEl(event.currentTarget);
        if (validPhones?.length === 0) {
            setPopoverData({
                title: "Phone numbers",
                items: [],
            });
        } else {
            setPopoverData({
                title: "Phone numbers",
                items: validPhones,
            });
        }
    };

    const handleEmailClick = (
        event: React.MouseEvent<HTMLElement>,
        email: { primary: string; personal: string[]; professional: string[] }
    ) => {
        setAnchorEl(event.currentTarget);
        if (email?.primary?.length === 0 && email?.personal?.length === 0 && email?.professional?.length === 0) {
            setPopoverData({
                title: "Emails",
                items: [],
            });
        } else {
            setPopoverData({
                title: "Emails",
                items: [email.primary, ...email.personal, ...email.professional].filter((item) => item !== ""),
            });
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverData({ title: "", items: [] });
    };
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };
    if (isFetching || isLoading) {
        return (
            <div className="h-[100px]">
                <Loader size="sm" />
            </div>
        );
    }

    const truncateText = (text: string, length: number) => {
        if (text) {
            if (text.length > length) {
                return text?.slice(0, length) + "...";
            }
            return text;
        }

        return text;
    };
    const formatScheduledDate = (dateString: any) => {
        const date = new Date(dateString);
        return dayjs(date).format("MMM DD,  hh:mm a");
    };

    return (
        <>
            <MaterialProvider>
                <div>
                    <div className="flex justify-end w-full">
                        <div className={style["animatedTypography"]}>
                            Workflow was triggered at {formatScheduledDate(workflow.triggeredAt)}
                        </div>
                    </div>
                    <List
                        sx={{
                            margin: 0,
                            maxHeight: "180px",
                            overflowY: "auto",
                            paddingTop: 1,
                            marginLeft: "4.2rem",
                            backgroundColor: "white",
                            paddingRight: "2rem",
                            paddingLeft: "2rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {data?.[0]?.candidates?.map((candidate) => (
                            <ListItem
                                key={candidate.name}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    padding: 0,
                                    marginBottom: 2,
                                }}
                            >
                                <div className="p-2 bg-[#FAF9F6] rounded-[5px] flex justify-between items-center w-full shadow-md h-[40px] -mb-[13px]">
                                    <Typography className="text-[15px] font-semibold">{candidate.name}</Typography>
                                    <div className="flex space-x-2">
                                        <div onClick={(event) => handleContactClick(event, candidate.phone)}>
                                            <ContactPageIcon
                                                sx={{
                                                    color: "black",
                                                }}
                                            />
                                        </div>

                                        <div onClick={(event) => handleEmailClick(event, candidate.email)}>
                                            <EmailIcon
                                                sx={{
                                                    color: "black",
                                                }}
                                            />
                                        </div>

                                        <div onClick={() => window.open(candidate.profileUrl)}>
                                            <LinkedInIcon
                                                sx={{
                                                    color: "#0c66c2",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </MaterialProvider>

            <Experimental_CssVarsProvider>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        boxShadow: "none",
                        border: "0px",
                        minWidth: "350px",
                    }}
                >
                    <div className="p-2 min-w-[280px] relative shadow-lg rounded-lg bg-white">
                        <div onClick={handleClosePopover} className="absolute top-[6px] right-[6px] text-gray-300">
                            <CloseIcon />
                        </div>
                        <Typography className="font-semibold text-lg text-left text-[#333] ml-[5px] mb-2">
                            {popoverData.title}
                        </Typography>
                        <div className="max-h-[120px] overflow-y-auto -mb-[3px]">
                            {popoverData.items.length > 0 ? (
                                popoverData.items.map((item, index) => (
                                    <Chip
                                        key={index}
                                        variant="secondary"
                                        className="w-full text-[14px] font-normal text-[#333] mt-1 rounded-sm flex justify-between items-center p-1"
                                    >
                                        {truncateText(item, 40)}
                                        <ContentCopyIcon
                                            onClick={() => handleCopyToClipboard(item)}
                                            sx={{
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                color: "black",
                                                marginLeft: "5px",
                                                alignSelf: "right",
                                            }}
                                        />
                                    </Chip>
                                ))
                            ) : (
                                <Typography className="text-center text-sm text-[#888] w-full mt-1">
                                    No items available
                                </Typography>
                            )}
                        </div>
                    </div>
                </Popover>
            </Experimental_CssVarsProvider>
        </>
    );
}

export default WorkflowDetails;
