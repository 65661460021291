import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { EQueries } from "./analytics.enums";
import {
    TFilter,
    TFilterGraph,
    TFunnelOverview,
    TGraph,
    TLeaderBoardFilter,
    TMultipleUserFunnelFilter,
    TOutreactStats,
    TProjectsStatus,
} from "./analytics.types";

import { fetchBaseQueryWithZod } from "../utils";

import { useRTKQueryCachedState } from "@/hooks/useRTKQueryCachedState";

export const analyticsApi = createApi({
    reducerPath: "analyticsApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        EQueries.MULTIPLE_USER_FUNNEL,
        EQueries.FUNNEL_OVERVIEW,
        EQueries.SOURCE_GRAPH,
        EQueries.CHANNEL_GRAPH,
        EQueries.OUTREACH_STATS,
        EQueries.PROJECTS_STATUS,
        EQueries.CREDIT_USAGE,
        EQueries.GRAPH,
        EQueries.TOP_PROJECT_DETAILS,
        EQueries.RECENT_PROJECT_DETAILS,
        EQueries.EMAIL_OUTREACH_STATS,
        EQueries.LINKEDIN_OUTREACH_STATS,
        EQueries.SMS_OUTREACH_STATS,
        EQueries.CALLING_OUTREACH_STATS,
        EQueries.RB2B_OUTREACH_STATS,
        EQueries.EMAIL_OUTREACH_STATS_DETAILED,
        EQueries.LINKEDIN_OUTREACH_STATS_DETAILED,
        EQueries.SMS_OUTREACH_STATS_DETAILED,
        EQueries.CALLING_OUTREACH_STATS_DETAILED,
        EQueries.RB2B_OUTREACH_STATS_DETAILED,
        EQueries.LEADER_BOARD,
    ],
    endpoints: (builder) => ({
        getMultipleUserFunnel: builder.query<any, TMultipleUserFunnelFilter>({
            query: (filters) => ({
                url: `/analytics/multiple-users-funnel`,
                body: filters,
                method: "POST",
            }),
            providesTags: [EQueries.MULTIPLE_USER_FUNNEL],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data;
            },
        }),
        getFunnelOverview: builder.query<TFunnelOverview, TFilter>({
            query: (filters) => ({
                url: `/analytics/funnel-overview?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.FUNNEL_OVERVIEW],
            transformResponse(baseQueryReturnValue: { data: TFunnelOverview }) {
                return baseQueryReturnValue?.data || [];
            },
        }),

        getGraph: builder.query<TGraph, TFilter>({
            query: (filters) => ({
                url: `/analytics/graph`,
                method: "POST",
                body: {
                    fromDate: filters.startDate,
                    toDate: filters.endDate,
                    projectId: filters.projectId,
                    userId: filters.userId,
                },
            }),
            providesTags: [EQueries.GRAPH],
            transformResponse(baseQueryReturnValue: { data: TGraph }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getSourceGraph: builder.query<TFunnelOverview, TFilterGraph>({
            query: (filters) => ({
                url: `/analytics/source-graph?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.SOURCE_GRAPH],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getChannelGraph: builder.query<TFunnelOverview, TFilterGraph>({
            query: (filters) => ({
                url: `/analytics/channel-graph?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CHANNEL_GRAPH],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getOutreachStats: builder.query<TOutreactStats, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: TOutreactStats }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getProjectsStatus: builder.query<TProjectsStatus, TFilter>({
            query: (filters) => ({
                url: `/analytics/projects-status?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.PROJECTS_STATUS],
            transformResponse(baseQueryReturnValue: { data: TProjectsStatus }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCreditUsage: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/credit-usage?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CREDIT_USAGE],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        // v3
        getTopProjectDetails: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/project-details/top?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.TOP_PROJECT_DETAILS],
        }),
        getRecentProjectDetails: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/project-details/recent?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.RECENT_PROJECT_DETAILS],
        }),
        getEmailOutreachStats: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats/email?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.EMAIL_OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getLinkedinOutreachStats: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats/linkedin?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.LINKEDIN_OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getSMSOutreachStats: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats/sms?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.SMS_OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCallingOutreachStats: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats/calling?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CALLING_OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getRB2BOutreachStats: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats/rb2b?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.RB2B_OUTREACH_STATS],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getEmailOutreachStatsDetailed: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats-detailed/email?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.EMAIL_OUTREACH_STATS_DETAILED],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getLinkedinOutreachStatsDetailed: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats-detailed/linkedin?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.LINKEDIN_OUTREACH_STATS_DETAILED],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getSMSOutreachStatsDetailed: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats-detailed/sms?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.SMS_OUTREACH_STATS_DETAILED],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getCallingOutreachStatsDetailed: builder.query<any, TFilter>({
            query: (filters) => ({
                url: `/analytics/outreach-stats-detailed/calling?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.CALLING_OUTREACH_STATS_DETAILED],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        getLeaderBoardData: builder.query<any, TLeaderBoardFilter>({
            query: (filters) => ({
                url: `/analytics/leader-board?${queryString.stringify(filters)}`,
                method: "GET",
            }),
            providesTags: [EQueries.LEADER_BOARD],
            transformResponse(baseQueryReturnValue: { data: any }) {
                return baseQueryReturnValue?.data || [];
            },
        }),
        setUserTarget: builder.mutation<any, { userId: number; target: number }>({
            query: (payload) => ({
                url: `/analytics/target-update`,
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

/* eslint-disable import/no-unused-modules */

export const {
    useGetMultipleUserFunnelQuery,
    useGetFunnelOverviewQuery,
    useGetGraphQuery,
    useGetSourceGraphQuery,
    useGetChannelGraphQuery,
    useGetOutreachStatsQuery,
    useGetProjectsStatusQuery,
    useGetCreditUsageQuery,
    useGetTopProjectDetailsQuery,
    useGetRecentProjectDetailsQuery,
    useGetEmailOutreachStatsQuery,
    useGetLinkedinOutreachStatsQuery,
    useGetSMSOutreachStatsQuery,
    useGetCallingOutreachStatsQuery,
    useGetRB2BOutreachStatsQuery,
    useGetEmailOutreachStatsDetailedQuery,
    useGetLinkedinOutreachStatsDetailedQuery,
    useGetSMSOutreachStatsDetailedQuery,
    useGetCallingOutreachStatsDetailedQuery,
    useGetLeaderBoardDataQuery,
    useSetUserTargetMutation,
} = analyticsApi;

const config = {
    reducerPath: analyticsApi.reducerPath,
    skip: false, // this is used to skip the cache
};

export const useCachedGetFunnelOverviewQuery = (...args: Parameters<typeof useGetFunnelOverviewQuery>) =>
    useRTKQueryCachedState(config, useGetFunnelOverviewQuery, ...args);
export const useCachedGetGraphQuery = (...args: Parameters<typeof useGetGraphQuery>) =>
    useRTKQueryCachedState(config, useGetGraphQuery, ...args);
export const useCachedGetSourceGraphQuery = (...args: Parameters<typeof useGetSourceGraphQuery>) =>
    useRTKQueryCachedState(config, useGetSourceGraphQuery, ...args);
export const useCachedGetChannelGraphQuery = (...args: Parameters<typeof useGetChannelGraphQuery>) =>
    useRTKQueryCachedState(config, useGetChannelGraphQuery, ...args);
export const useCachedGetOutreachStatsQuery = (...args: Parameters<typeof useGetOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetOutreachStatsQuery, ...args);
export const useCachedGetProjectsStatusQuery = (...args: Parameters<typeof useGetProjectsStatusQuery>) =>
    useRTKQueryCachedState(config, useGetProjectsStatusQuery, ...args);
export const useCachedGetCreditUsageQuery = (...args: Parameters<typeof useGetCreditUsageQuery>) =>
    useRTKQueryCachedState(config, useGetCreditUsageQuery, ...args);
export const useCachedGetMultipleUserFunnelQuery = (...args: Parameters<typeof useGetMultipleUserFunnelQuery>) =>
    useRTKQueryCachedState(config, useGetMultipleUserFunnelQuery, ...args);
export const useCachedGetTopProjectDetailsQuery = (...args: Parameters<typeof useGetTopProjectDetailsQuery>) =>
    useRTKQueryCachedState(config, useGetTopProjectDetailsQuery, ...args);
export const useCachedGetRecentProjectDetailsQuery = (...args: Parameters<typeof useGetRecentProjectDetailsQuery>) =>
    useRTKQueryCachedState(config, useGetRecentProjectDetailsQuery, ...args);
export const useCachedGetEmailOutreachStatsQuery = (...args: Parameters<typeof useGetEmailOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetEmailOutreachStatsQuery, ...args);
export const useCachedGetLinkedinOutreachStatsQuery = (...args: Parameters<typeof useGetLinkedinOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetLinkedinOutreachStatsQuery, ...args);
export const useCachedGetSMSOutreachStatsQuery = (...args: Parameters<typeof useGetSMSOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetSMSOutreachStatsQuery, ...args);
export const useCachedGetCallingOutreachStatsQuery = (...args: Parameters<typeof useGetCallingOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetCallingOutreachStatsQuery, ...args);
export const useCachedGetRB2BOutreachStatsQuery = (...args: Parameters<typeof useGetRB2BOutreachStatsQuery>) =>
    useRTKQueryCachedState(config, useGetRB2BOutreachStatsQuery, ...args);
export const useCachedGetEmailOutreachStatsDetailedQuery = (
    ...args: Parameters<typeof useGetEmailOutreachStatsDetailedQuery>
) => useRTKQueryCachedState(config, useGetEmailOutreachStatsDetailedQuery, ...args);
export const useCachedGetLinkedinOutreachStatsDetailedQuery = (
    ...args: Parameters<typeof useGetLinkedinOutreachStatsDetailedQuery>
) => useRTKQueryCachedState(config, useGetLinkedinOutreachStatsDetailedQuery, ...args);
export const useCachedGetSMSOutreachStatsDetailedQuery = (
    ...args: Parameters<typeof useGetSMSOutreachStatsDetailedQuery>
) => useRTKQueryCachedState(config, useGetSMSOutreachStatsDetailedQuery, ...args);
export const useCachedGetCallingOutreachStatsDetailedQuery = (
    ...args: Parameters<typeof useGetCallingOutreachStatsDetailedQuery>
) => useRTKQueryCachedState(config, useGetCallingOutreachStatsDetailedQuery, ...args);
export const useCachedGetLeaderBoardDataQuery = (...args: Parameters<typeof useGetLeaderBoardDataQuery>) =>
    useRTKQueryCachedState(config, useGetLeaderBoardDataQuery, ...args);
