import useDebounce from "./useDebounce";

import { highlightText } from "../utils/highlight-text";

export function useHighlightText(
    keywords: string[] = [],
    targetId: string,
    delay: number = 100,
    removePreviousHighlights: boolean = true
) {
    useDebounce(() => {
        const root = document.getElementById(targetId);

        if (!root) {
            console.warn("root element not found on useHighlightText");
            return;
        }
        keywords.forEach((keyword) => {
            highlightText(keyword, root, removePreviousHighlights);
        });
    }, delay);
}
