import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditIcon from "@mui/icons-material/Edit";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    Rating,
    Stack,
    TextField,
    type TextFieldProps,
    styled,
} from "@mui/material";
import { Tooltip, Typography, Card, CardHeader, Button, Switch } from "@/ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { withConfigModeHOC } from "../hocs/withConfigMode";
import { useCandidateModalContext } from "../pages/project/components/candidateModal/Context";
import { HtmlTooltip } from "../pages/project/components/profileTab/ProfileTab";
import style from "../pages/project/components/profileTab/profileTab.module.scss";
import { useAppDispatch } from "../store";

import {
    selectActiveHiddenEditModal,
    setActiveHiddenEditModal,
} from "@/store/reducers/candidates/Candidates.reducer";
import {
    changeStarRating,
    refreshCandidateRating,
    selectSingleCandidateInsightGenerating,
} from "@/store/reducers/project/project.reducer";
import { StarRating } from "@/store/reducers/project/project.types";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";


export type VettingCriteriaRatingProps = {
    starRating?: StarRating;
    projectId: string;
    candidateId: string;
    vettingFixed: boolean;
};

function VCRating({ projectId, candidateId, vettingFixed }: VettingCriteriaRatingProps) {
    const dispatch = useAppDispatch();
    const singleCandidateRefreshRating = useSelector(selectSingleCandidateInsightGenerating);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const showEditPopup = useSelector(selectActiveHiddenEditModal);
    const { starRating, handleStarRatingChange } = useCandidateModalContext();

    const showModal = () => {
        dispatch(setActiveHiddenEditModal(true));
    };
    const closeModal = () => {
        dispatch(setActiveHiddenEditModal(false));
    };
    const handleRefreshRating = () => {
        dispatch(
            refreshCandidateRating({
                projectId,
                candidateIds: [candidateId],
                action: refreshCandidateRating.type,
            })
        );
    };

    const isRefreshingRating = singleCandidateRefreshRating === candidateId;

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center">
                        <Typography className="font-semibold text-[#6C6C6C] mr-1"
                        >
                            Candidate Star Rating:
                        </Typography>
                        <Rating
                            value={starRating?.value || 0}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                dispatch(
                                    changeStarRating({
                                        ...starRating,
                                        label: newValue?.toString() || "0",
                                        value: newValue || 0,
                                        candidateId,
                                        enableFixedVetting: vettingFixed,
                                    })
                                );

                                handleStarRatingChange({
                                    ...starRating,
                                    label: newValue?.toString() || "0",
                                    value: newValue || 0,
                                });
                            }}
                            onKeyDown={(event) => {
                                // Prevent keyboard interactions affecting the rating
                                event.preventDefault();
                            }}
                        />
                    </Stack>
                }
                action={
                    <Stack flexDirection="row">
                        {isSuperAdmin && (
                            <IconButton
                                disabled={isRefreshingRating}
                                size="small"
                                onClick={showModal}
                                sx={{ marginRight: "120px" }}
                            >
                                <EditIcon sx={{ color: "#fff" }} />
                            </IconButton>
                        )}
                        <Tooltip title="Refresh rating">
                            <IconButton disabled={isRefreshingRating} size="small" onClick={handleRefreshRating}>
                                <RefreshRoundedIcon
                                    sx={{
                                        animation: isRefreshingRating ? "spin 2s linear infinite" : "none",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
                sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            />
            {starRating.description?.length ? (
                <List>
                    {starRating.description.map(({ text, intent, criterion, tag }, index) => {
                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    intent ? (
                                        <HtmlTooltip title="Good to have">
                                            <CriteriaTag variant={tag}>
                                                <DoneRoundedIcon htmlColor="#44B700" />
                                            </CriteriaTag>
                                        </HtmlTooltip>
                                    ) : (
                                        <CriteriaTag variant={tag}>
                                            <WarningRoundedIcon htmlColor="#ED6C02" />
                                        </CriteriaTag>
                                    )
                                }
                            >
                                {!criterion ? (
                                    <ListItemText
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 14,
                                            },
                                        }}
                                        primary={`• ${text}`}
                                    />
                                ) : (
                                    <HtmlTooltip title={`Criterion: ${criterion}`} arrow>
                                        <ListItemText
                                            sx={{
                                                "& .MuiTypography-root": {
                                                    fontSize: 14,
                                                },
                                            }}
                                            primary={`• ${text}`}
                                        />
                                    </HtmlTooltip>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            {showEditPopup && (
                <EditDescription
                    onClose={closeModal}
                    starRating={starRating}
                    candidateId={candidateId}
                    vettingFixed={vettingFixed}
                />
            )}
        </Card>
    );
}

export const VettingCriteriaRating = withConfigModeHOC<VettingCriteriaRatingProps, {}>({
    Es: VCRating,
});

const CustomTextField = styled((props: TextFieldProps) => <TextField {...props} multiline size="small" fullWidth />)(
    () => ({
        "& .MuiOutlinedInput-input": {
            fontSize: "14px",
        },
        "& .MuiInputBase-input::placeholder": {
            fontSize: "14px !important",
        },
    })
);

const EditDescription = ({
    onClose,
    candidateId,
    starRating,
    vettingFixed,
}: {
    onClose: () => void;
    candidateId: string;
    starRating: StarRating;
    vettingFixed?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [newStarRating, setNewStarRating] = useState<StarRating>();
    const [enableFixedVetting, setEnabledFixedVetting] = useState(() =>
        vettingFixed !== undefined ? vettingFixed : false
    );

    useEffect(() => {
        setNewStarRating(starRating);
    }, [starRating]);
    const onChangeValue = ({
        text,
        criterion,
        intent,
        tag,
        index,
        _id,
        id,
        type,
        appendText,
    }: {
        text: string;
        criterion: string;
        intent: number;
        tag: "GOOD" | "MUST";
        index: number;
        _id?: string;
        id?: string;
        type?: string;
        appendText?: string;
    }) => {
        const data: StarRating = JSON.parse(JSON.stringify(newStarRating));
        if (data?.description && data.description[index]) {
            data.description[index] = {
                text,
                criterion,
                intent,
                tag,
                _id,
                type,
                id,
                appendText,
            };
        }
        setNewStarRating(data);
    };

    const onSubmit = () => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        const description: any = newStarRating?.description?.filter((item) => !item?.delete);

        if (description) {
            data.description = description;
        }
        dispatch(
            changeStarRating({
                ...data,
                candidateId,
                enableFixedVetting,
            })
        );
        onClose();
    };

    const onDelete = ({ index }: { index: number }) => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        data.description[index].delete = true;
        setNewStarRating(data);
    };

    const onAddCriteria = () => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));
        const newDescription = {
            text: "",
            criterion: "",
            intent: 0,
            delete: true,
        };
        data.description.push(newDescription);
        setNewStarRating(data);
    };

    const onChangeStar = ({ value, key }: { value: string | number; key: string }) => {
        const data: any = JSON.parse(JSON.stringify(newStarRating));

        if (key === "label") {
            data["label"] = value;
        } else {
            data["value"] = value;
        }

        setNewStarRating(data);
    };

    return (
        <Dialog
            id="hidden-modal"
            open={true}
            classes={{
                paper: style.popupContainer,
            }}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Edit star rating description</Typography>
                    <FormControlLabel
                        sx={(theme) => ({
                            cursor: "pointer",
                            "& .MuiTypography-root": {
                                fontSize: theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightMedium,
                            },
                        })}
                        control={
                            <Switch
                                className="cursor-pointer text-xs"
                                checked={enableFixedVetting}
                                onChange={(val) => setEnabledFixedVetting(val)}
                            />
                        }
                        label={enableFixedVetting ? "Vetting Fixed" : "Enable Fix Vetting"}
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <Stack>
                        <FormHelperText>Label</FormHelperText>
                        <CustomTextField
                            value={newStarRating?.label}
                            onChange={(e) =>
                                onChangeStar({
                                    value: e.target.value,
                                    key: "label",
                                })
                            }
                        />
                    </Stack>
                    <Stack>
                        <FormHelperText>Value</FormHelperText>
                        <CustomTextField
                            value={newStarRating?.value}
                            sx={{ marginBottom: "10px" }}
                            type="text" // Keep as text for full control
                            onChange={(e) => {
                                const value = e.target.value;

                                // If the input is empty, allow it
                                if (value === "") {
                                    onChangeStar({
                                        value: "",
                                        key: "value",
                                    });
                                    return;
                                }

                                // Allow the "-" character to be typed
                                if (value === "-" || value === "." || value === "-.") {
                                    onChangeStar({
                                        value: value,
                                        key: "value",
                                    });
                                    return;
                                }

                                // Parse the value to a float only if it's a valid number
                                const numericValue = parseFloat(value);

                                // Ensure the numeric value is within the range of -1 to 5
                                if (!isNaN(numericValue) && numericValue >= -1) {
                                    onChangeStar({
                                        value: value,
                                        key: "value",
                                    });
                                }
                            }}
                            inputProps={{
                                step: "any", // Allows decimals
                                inputMode: "decimal", // Optimizes for mobile numeric keypad with decimal
                            }}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            maxHeight: "280px",
                            overflowY: "auto",
                        }}
                        gap={2}
                    >
                        {!isEmpty(newStarRating?.description) &&
                            newStarRating?.description.map((item: any, i) => {
                                return (
                                    <Box
                                        key={i}
                                        flexDirection="row"
                                        border={1}
                                        py={3}
                                        px={2}
                                        pb={1}
                                        alignItems="flex-start"
                                        borderRadius={1}
                                        sx={(theme) => ({
                                            borderColor: theme.palette.grey[400],
                                        })}
                                    >
                                        <Stack gap={1.5}>
                                            <CustomTextField
                                                placeholder="text"
                                                label="Text"
                                                variant="outlined"
                                                value={item.text}
                                                onChange={(e) =>
                                                    onChangeValue({
                                                        ...item,
                                                        text: e.target.value,
                                                        index: i,
                                                    })
                                                }
                                            />
                                            <CustomTextField
                                                value={item.criterion}
                                                label="Criterion"
                                                onChange={(e) =>
                                                    onChangeValue({
                                                        ...item,
                                                        criterion: e.target.value,
                                                        index: i,
                                                    })
                                                }
                                            />
                                            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                                                <Stack>
                                                    <FormHelperText>Intent</FormHelperText>
                                                    <RadioGroup name="radio-buttons-group" value={item.intent} row>
                                                        <FormControlLabel
                                                            value={1}
                                                            control={<Radio />}
                                                            label="1"
                                                            onClick={() =>
                                                                onChangeValue({
                                                                    ...item,
                                                                    intent: 1,
                                                                    index: i,
                                                                })
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            value={0}
                                                            control={<Radio />}
                                                            label="0"
                                                            onClick={() =>
                                                                onChangeValue({
                                                                    ...item,
                                                                    intent: 0,
                                                                    index: i,
                                                                })
                                                            }
                                                        />
                                                    </RadioGroup>
                                                </Stack>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            onDelete({
                                                                index: i,
                                                            })
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                );
                            })}
                    </Stack>

                    <Button

                        startDecorator={<AddIcon />}
                        onClick={onAddCriteria}
                        className="w-fit"
                    >
                        Add criteria
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outline" onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={onSubmit}>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

function CriteriaTag({ variant, children }: { variant?: "MUST" | "GOOD"; children: React.ReactNode }) {
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            {children}
        </Stack>
    );
}
