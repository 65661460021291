import { useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { RootState } from "../../../store";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useSaveOutreachTemplate } from "../hooks/useSaveOutreach.hook";

import {
    checkTempalateHaveUnsavedChanges,
    selectCurrentOutreachElement,
} from "@/store/reducers/outreach/outreach.slice";
import { Button, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

export function ConfirmDelete({
    deleteOutreachElementId,
    onClose,
    onDelete,
}: {
    deleteOutreachElementId: string;
    onDelete: () => void;
    onClose: () => void;
}) {
    const { saveTemplate, saveMutationState } = useSaveOutreachTemplate({
        successCallback: () => {
            onDelete();
            onClose();
        },
    });
    const templateHaveUnsavedChanges = useSelector((state: RootState) =>
        checkTempalateHaveUnsavedChanges(state, deleteOutreachElementId)
    );
    const deleteOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: deleteOutreachElementId })
    );

    const onDeleteOutreachElement = (shouldSave: boolean) => {
        if (shouldSave) {
            saveTemplate(deleteOutreachElement);
        } else {
            onDelete();
            onClose();
        }
    };

    const { isLoading } = saveMutationState;

    return (
        <BaseModal onClose={onClose}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography className="text-[20px]" variant="h6">
                        Delete outreach element
                    </Typography>
                    <Typography variant="body2">
                        Are you sure you want to delete this element? You can add it back to your outreach flow with the{" "}
                        <span style={{ fontWeight: 500 }}>Add</span> button
                    </Typography>
                    {templateHaveUnsavedChanges && (
                        <>
                            <Typography className="text-[#E4003A] mt-0.75 italic text-[12.5px]" variant="body2">
                                Note: Your most recent changes to this template have not been saved{" "}
                            </Typography>
                        </>
                    )}
                </Stack>

                <Stack
                    gap={1.3}
                    style={{
                        width: "100%",
                        marginTop: 1,
                    }}
                    justifyContent={"flex-end"}
                    direction="row"
                >
                    {templateHaveUnsavedChanges ? (
                        <Button
                            onClick={() => onDeleteOutreachElement(false)}
                            variant="outline"
                            className="shadow-none rounded-sm border border-black text-black hover:border-black"

                        >
                            Delete without saving
                        </Button>
                    ) : (
                        <Button
                            onClick={onClose}
                            variant="outline"
                            className="shadow-none rounded-sm border border-black text-black hover:border-black"

                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        onClick={() => onDeleteOutreachElement(templateHaveUnsavedChanges)}
                        className="shadow-none rounded-sm bg-red-500 border-none hover:bg-red-500"

                    >
                        <ButtonTextWithLoading
                            variant="light"
                            isLoading={isLoading}
                            text={templateHaveUnsavedChanges ? "Save and delete" : "Delete"}
                        />
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
