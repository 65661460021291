import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import JDUpload from "../../../components/Navbar/CreateProject/JDUpload";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import { selectCurrProject, updateProjectJdDescription, updateProjectJdTitle, updateProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { setProjectJd, updateJd } from "@/store/reducers/create-project/CreateProject.reducer";
import { resetState } from "@/store/reducers/marketing-page/MarketingPage.reducer";
import { checkBlendedSearchVersion, checkEasyGrowth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";

export type EditProjectProps = {
    close: () => void;
};

export default function EditProject({ close }: EditProjectProps) {
    const dispatch = useDispatch();
    const project = useSelector(selectCurrProject);
    const user = useSelector(selectUser);
    const isUpdatingProject = useSelector(checkIfLoading(updateJd.type));
    const isBlendedSearchEnabled = useSelector(checkBlendedSearchVersion);
    const isEasyGrowth = useSelector(checkEasyGrowth);

    const marketingData = project?.marketing;

    if (!project?.name) {
        return null;
    }

    const handleUpdateProject = () => {
        dispatch(updateJd({}));
    };

    const handleEditSearch = () => {
        window.open(`/search?project=${project._id}&name=${project.name}&isEdit=true`);
    };

    const handleMarketingButton = () => {
        if (marketingData)
            dispatch(
                resetState({
                    marketingCompanyLogo: marketingData.logo,
                    marketingCompanyVideo: marketingData.video,
                    marketingCompanyVideoLink: marketingData.videoLink,
                    marketingOverview: marketingData.overview,
                    projectId: project._id,
                    showUploadMarketingCompanyPage: true,
                })
            );
        close();
    };

    return (
        <>
            <BaseModal onClose={close} overlayStyles={{ minWidth: "35vw", overflowY: "auto" }}>
                <div className="space-y-3">
                    <div className="space-y-2">
                        <EditProjectName project={project?.name} />
                        <EditJdTitle jdTitle={project?.jdTitle} />
                        <EditJdDescription jdDescription={project?.jdDescription} />
                        <EditJD />
                        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography>Send emails to personal email ids only</Typography>
                            <Switch
                                checked={Boolean(project.onlyPersonalEmail)}
                                onChange={(e) => dispatch(updateProjectOnlyPersonalEmail(e.target.checked))}
                            />
                        </Stack> */}
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-2">
                        {marketingData && (
                            <Button variant="outline" onClick={handleMarketingButton} label="Edit/view Talent Marketing page" />
                        )}
                        {(user?.isSuperAdmin || isBlendedSearchEnabled || !isEasyGrowth) && (
                            <Button variant="outline" onClick={handleEditSearch} label="Edit candidate search" />
                        )}

                        <Button variant="default" onClick={handleUpdateProject} className="flex items-center">
                            <ButtonTextWithLoading isLoading={isUpdatingProject} text="Update" variant="light" />
                        </Button>
                    </div>
                </div>
            </BaseModal>
        </>
    );
}

function EditJdTitle({ jdTitle }: { jdTitle: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(jdTitle);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit job title
            </Typography>
            <input
                type="text"
                placeholder="Job title"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectJdTitle(text))}
                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 border-gray-300 focus:ring-gray-500"}`}
                style={{ fontSize: "16px", backgroundColor: "white", padding: 8 }}
            />
        </Stack>
    );
}

function EditJdDescription({ jdDescription }: { jdDescription: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(jdDescription);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit job description
            </Typography>
            <input
                type="text"
                placeholder="Job description"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectJdDescription(text))}
                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 border-gray-300 focus:ring-gray-500"}`}
                style={{ fontSize: "16px", backgroundColor: "white", padding: 8 }}
            />
        </Stack>
    );
}

function EditProjectName({ project }: { project: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(project);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit project name
            </Typography>
            <input
                type="text"
                placeholder="Project name"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                onBlur={() => dispatch(updateProjectName(text))}
                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 border-gray-300 focus:ring-gray-500"}`}
                style={{ fontSize: "16px", backgroundColor: "white", padding: 8 }}
            />
        </Stack>
    );
}

function EditJD() {
    const dispatch = useDispatch();
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Update JD
            </Typography>
            <JDUpload handleJdUpload={(val) => dispatch(setProjectJd(val[0]))} />
        </Stack>
    );
}
