import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@/ui"
import { selectUser } from "@/store/reducers/signin/Signin.reducer";

const UserProfile: React.FC = () => {
    const user = useSelector(selectUser);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 14,
                marginTop: 2,
                marginBottom: 2,
            }}
        >
            <div style={{ display: "flex", fontWeight: 400, flexDirection: "column" }}>
                <div>{user?.displayName} </div>
                <div>
                    Twillio phone number : {user?.twilioPhoneNumber}
                    <Tooltip className="w-[20vw]"
                        title={
                            <>
                                Free caller registry: Add to the registry to improve call delivery rates and increase
                                trust by ensuring their calls are not marked as spam or fraudulent by call-blocking apps
                                or carriers. Refer to Twilio article:
                                <a
                                    href="https://help.twilio.com/articles/9375068873499-Outbound-calls-blocked-or-labeled-as-spam-or-scam-likely"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "#0891b2",
                                        backgroundColor: "white",
                                        marginLeft: 5,
                                        padding: 2,
                                    }}
                                >
                                    Link
                                </a>
                            </>
                        }
                    >
                        <button className="p-1 rounded-full" >
                            <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "15px" }} />
                        </button>
                    </Tooltip>
                </div>
                <div style={{ marginTop: "4px" }}>
                    Audio & Devices
                    <Tooltip className="w-[20vw]"
                        title={
                            "Kindly keep in mind that the audio devices being used for the browser will also be used for the phone call."
                        }
                    >
                        <button className="p-1 rounded-full" >

                            <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "15px" }} />
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
