import React, { useEffect } from "react";

import { cn } from "@/lib/utils";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    BackdropComponent?: React.ElementType;
    BackdropProps?: React.HTMLAttributes<HTMLElement>;
    disableBackdropClick?: boolean;
    className?: string;
    children?: React.ReactNode;
    showClose?: boolean;
    style?:React.CSSProperties;
}

/**
 * Modal component that renders a modal dialog with backdrop.
 *
 * @param {ModalProps} props - The properties for the Modal component.
 * @param {boolean} open - Determines if the modal is open or closed.
 * @param {() => void} onClose - Callback function to close the modal.
 * @param {React.ElementType} [BackdropComponent="div"] - Component to use for the backdrop.
 * @param {object} [BackdropProps={}] - Additional props to pass to the BackdropComponent.
 * @param {boolean} [disableBackdropClick=false] - If true, clicking on the backdrop will not close the modal.
 * @param {string} [className=""] - Additional class names to apply to the modal content.
 * @param {React.ReactNode} children - The content to display inside the modal.
 *
 * @returns {JSX.Element | null} The rendered modal component or null if not open.
 */
export const Modal: React.FC<ModalProps> = ({
    open,
    onClose,
    BackdropComponent = "div",
    BackdropProps = {},
    disableBackdropClick = false,
    className = "",
    children,
    showClose = false,
    style
}) => {
    // Handle outside click
    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!disableBackdropClick && e.target === e.currentTarget) {
            onClose();
        }
    };

    // Close on Esc key
    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose();
            }
        };
        if (open) {
            document.addEventListener("keydown", handleEsc);
        }
        return () => document.removeEventListener("keydown", handleEsc);
    }, [onClose, open]);

    // Lock background scroll when modal is open
    useEffect(() => {
        if (open) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [open]);

    if (!open) return null;

    return (
        <BackdropComponent
            onClick={handleOutsideClick}  
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 !ml-0"
            {...BackdropProps}
        >
            <div
                className={cn(`relative p-6 bg-white rounded-lg shadow-lg`, className)} style={style}
                role="dialog"
                aria-modal="true"
            >
                {showClose && (
                    <button
                        onClick={onClose}
                        className="absolute top-2 right-3 text-lg font-semibold text-gray-600 hover:text-black focus:outline-none z-50"
                        aria-label="Close"
                    >
                        ✕
                    </button>
                )}
                {children}
            </div>
        </BackdropComponent>
    );
};
