import { Progress, Tooltip } from "@/ui";

interface AnalyticsLoaderProps {
    loading?: string;
}

export default function AnalyticsLoader(props: AnalyticsLoaderProps) {
    const { loading } = props;
    return (
        <div className="">
            {loading === "true" && (
                <Tooltip title="Syncing most updated information...">
                    <Progress variant="indeterminate" thickness={3} />
                </Tooltip>
            )}
        </div>
    );
}
