import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { nameInitials } from "../../utils/helper";

const ProfileAvatar = ({ onClick }: { onClick?: any }) => {
    const user = useSelector(selectUser);
    const auth = getAuth();
    const firebaseProviderImage = auth?.currentUser?.providerData?.find(
        (provider) => provider?.providerId === "google.com"
    )?.photoURL;
    const profileImage = user.photoURL || firebaseProviderImage;

    return (
        <button
            onClick={onClick}
            className="flex items-center justify-center rounded-full w-7 h-7 focus:outline-none"
        >
            {profileImage ? (
                <img
                    referrerPolicy="no-referrer"
                    src={profileImage}
                    alt="user profile icon"
                    className="rounded-full w-full h-full"
                />
            ) : (
                <div className="flex items-center justify-center rounded-full w-7 h-7 bg-gray-300">
                    <span className="font-medium text-sm">
                        {nameInitials(user?.displayName ? user.displayName : "JD")}
                    </span>
                </div>
            )}
        </button>
    );
};

export default ProfileAvatar;
