import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 relative",
    {
        variants: {
            variant: {
                default: "bg-cyan-600 text-white hover:bg-cyan-600",
                destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                outline: "border border-cyan-600 text-cyan-600  bg-background hover:bg-accent",
                secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                link: "text-cyan-600 font-normal",
                cyan: "bg-cyan-600 text-white hover:bg-cyan-600",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    /** Button contents */
    label?: string;
    /** Loading state */
    loading?: boolean;
    children?: React.ReactNode;
    /** Optional custom classes to apply additional Tailwind styles */
    className?: string;
    /** Optional click handler */
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    /** Optional variant for button styling */
    variant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | "cyan";
    /** Optional size for the button */
    size?: "default" | "sm" | "lg" | "icon";
    /** Optional disabled state for the button */
    disabled?: boolean;
    /** Optional start decorator */
    startDecorator?: React.ReactNode;
    /** Optional end decorator */
    endDecorator?: React.ReactNode;
}

/**
 * A customizable button component that supports various variants, sizes, and additional decorators.
 * It can also handle loading and disabled states.
 *
 * @param {string} className - Additional class names to apply to the button.
 * @param {string} variant - The variant of the button (e.g., primary, secondary).
 * @param {string} size - The size of the button (e.g., small, medium, large).
 * @param {boolean} [asChild=false] - If true, renders the button as a child component.
 * @param {string} label - The label text of the button.
 * @param {boolean} [loading=false] - If true, shows a loading spinner inside the button.
 * @param {React.ReactNode} children - The content to be rendered inside the button.
 * @param {boolean} [disabled=false] - If true, disables the button and prevents click events.
 * @param {React.ReactNode} startDecorator - An optional element to render at the start of the button.
 * @param {React.ReactNode} endDecorator - An optional element to render at the end of the button.
 * @param {React.Ref<HTMLButtonElement>} ref - A ref to the button element.
 * @param {object} props - Additional props to be passed to the button element.
 *
 * @returns {JSX.Element} The rendered button component.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            label,
            loading = false,
            children,
            disabled = false,
            startDecorator,
            endDecorator,
            ...props
        },
        ref
    ) => {
        const Comp = asChild ? Slot : "button";
        if (disabled) {
            props.onClick = () => {};
        }

        return (
            <Comp
                disabled={disabled}
                className={cn(
                    buttonVariants({
                        variant,
                        size,
                        className: cn(
                            {
                                "flex items-center justify-center": loading,
                                "opacity-60 cursor-not-allowed": disabled,
                            },
                            className
                        ),
                    })
                )}
                ref={ref}
                {...props}
            >
                {startDecorator && <span className="mr-2">{startDecorator}</span>}
                <span>{     children || label}</span>
                {loading &&
                    <button className="flex items-center justify-center h-5 w-5 ml-2">
                    <Loader2 className="animate-spin relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  " />
                    </button>}
                
                {endDecorator && <span className="ml-2">{endDecorator}</span>}
                {/* Add an invisible copy of content to maintain button width during loading */}
                {/* {loading && <span className="invisible">{children || label}</span>} */}
            </Comp>
        );
    }
);

Button.displayName = "Button";

export { Button, buttonVariants };
