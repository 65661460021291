import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import DateRangePicker, { TDate } from "../../../molecules/DateRangePicker/DateRangePicker";
import { selectIPODateRange, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function IPODateRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectIPODateRange);

    const startDate = values[0]?.value ? dayjs(values[0]?.value) : null;
    const endDate = values[1]?.value ? dayjs(values[1]?.value) : null;

    const handleDateChange = (value) => {
        const [start, end] = value;

        dispatch(
            setValues({
                key: id,
                value: [
                    { value: start, excluded: false },
                    { value: end, excluded: false },
                ],
            })
        );
    };

    return (
        <Box>
            <Typography color="GrayText" fontSize={14} pb={1}>
                IPO Date Range
            </Typography>
            <DateRangePicker
                buttonProps={{
                    className: "w-full h-[36px]",
                }}
                value={[startDate as TDate, endDate as TDate]}
                onChange={handleDateChange}
                disableHighlightToday
                clearable
            />
        </Box>
    );
}
