// Avatar.tsx
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn } from "@/lib/utils";

export interface AvatarProps extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> {
    src?: string;
    alt?: string;
    fallback?: React.ReactNode;
}

/**
 * Avatar component using React.forwardRef to pass down refs.
 *
 * This component renders an avatar with an image and a fallback.
 * It uses AvatarPrimitive from Radix UI to handle the avatar structure.
 *
 * @param {Object} props - The properties passed to the Avatar component.
 * @param {string} className - Additional class names for styling the avatar.
 * @param {string} src - The source URL of the avatar image.
 * @param {string} alt - The alt text for the avatar image.
 * @param {React.ReactNode} fallback - The fallback content to display when the image is not available.
 * @param {React.Ref} ref - The ref to be forwarded to the AvatarPrimitive.Root element.
 *
 * @returns {JSX.Element} The rendered Avatar component.
 */
const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
    ({ className, src, alt, fallback, ...props }, ref) => (
        <AvatarPrimitive.Root
            ref={ref}
            className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)}
            {...props}
        >
            <AvatarPrimitive.Image src={src} alt={alt} className="aspect-square h-full w-full" />
            <AvatarPrimitive.Fallback className="flex h-full w-full items-center justify-center rounded-full bg-muted">
                {fallback}
            </AvatarPrimitive.Fallback>
        </AvatarPrimitive.Root>
    )
);

Avatar.displayName = "Avatar";

export { Avatar };
