import { capitalize } from "lodash";

export type StageType = "PIPELINE" | "CONTACTED" | "RESPONDED" | "TRIGGERED";

export type StageComponents = Record<StageType, (label: string) => React.ReactNode>;

const components: StageComponents = {
    CONTACTED: (label: string) => (
        <div
            className="inline-flex items-center justify-center px-3 py-1.5 rounded-full text-white text-xs font-medium bg-[#FF9800]"
        >
            {label}
        </div>
    ),
    RESPONDED: (label: string) => (
        <div
            className="inline-flex items-center justify-center px-3 py-1.5 rounded-full text-white text-xs font-medium bg-green-600"
        >
            {label}
        </div>
    ),
    PIPELINE: (label: string) => (
        <div
            className="inline-flex items-center justify-center px-3 py-1.5 rounded-full text-gray-600 text-xs font-medium border bg-gray-200"
        >
            {label}
        </div>
    ),
    TRIGGERED: (label: string) => (
        <div
            className="inline-flex items-center justify-center px-3 py-1.5 rounded-full text-white text-xs font-medium bg-[#FF9800]"
        >
            {label}
        </div>
    ),
};

export default function Stage({ stage }: { stage: StageType }) {
    const value = components?.[stage]?.(capitalize(stage));
    return <>{value}</>;
}
