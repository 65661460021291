import {Typography} from "@/ui"

const NA = ({ children = "N/A" }: { children?: React.ReactNode }) => (
    <Typography
        variant="body2"
        className="text-xs text-gray-600 bg-gray-200 text-center font-medium py-1.5 px-2.5 rounded-sm"

    >
        {children}
    </Typography>
);

export default NA;
