/* eslint-disable import/no-unused-modules */
import Stack from "@mui/joy/Stack";
import { Experimental_CssVarsProvider } from "@mui/material";
import { capitalize, lowerCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { Activity } from "./components/Activity";
import ContactInfo from "./components/ContactInfo";
import { Insights } from "./components/Insights";
import { Interactions } from "./components/Interactions";
import { References } from "./components/ReferencesNew";

import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { candidateReachoutsTabs, invertedCandidateReachoutsTabs } from "../../utils/Constants";

import {
    getContactReachout,
    selectCandidatesReachoutsTab,
    setCandidatesReachoutTab,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import { TabsComponent } from "@/ui/Tabs/Tabs";

export function AllCandidatesReachOutBodyContainer({ children }: { children: React.ReactNode }) {
    return <Stack sx={{ width: "100%" }}>{children}</Stack>;
}

export function CandidateReachOutTabs() {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const tab = useSelector(selectCandidatesReachoutsTab);

    useEffect(() => {
        const activityId = searchParams.get("activityId");
        if (activityId) {
            const value = invertedCandidateReachoutsTabs["activity"];

            dispatch(setCandidatesReachoutTab(value));
        }
    }, [searchParams]);

    return (
        <TabsComponent
            className="m-0"
            value={capitalize(candidateReachoutsTabs[tab])}
            onValueChange={(newValue) => {
                const value = invertedCandidateReachoutsTabs[lowerCase(newValue)];
                if (typeof value === "number") {
                    dispatch(setCandidatesReachoutTab(value));
                }
            }}
            tabs={[
                ...Object.values(candidateReachoutsTabs).map((k) => {
                    return {
                        content: (
                            <>
                                {k === "activity" && <Activity />}
                                {k === "insights" && <Insights />}
                                {k === "interactions" && <Interactions />}
                                {k === "references" && <References />}
                            </>
                        ),
                        label: capitalize(k),
                        formatLabel: (label) => <p className="py-1">{label}</p>,
                    };
                }),
            ]}
        />
    );
}

const AllCandidatesReachout = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(checkIfLoading(getContactReachout.type));

    useEffect(() => {
        if (!params?.id) return;
        dispatch(getContactReachout(params.id));
    }, [params?.id]);

    return (
        <div className="h-full">
            <Experimental_CssVarsProvider>
                <Navbar />
            </Experimental_CssVarsProvider>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="grid gap-[10px] grid-cols-[minmax(380px,1fr)2.5fr] grid-rows-[1fr] rounded-[0.7rem] md:h-[90%] sm:h-[90%]">
                    <ContactInfo />
                    <div className="bg-background border-[1px] border-divider rounded-[0.3rem]">
                        <CandidateReachOutTabs />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllCandidatesReachout;
