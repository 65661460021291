import { ReactNode, RefObject, useCallback, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type Position = {
    top: number;
    left: number;
    width: number;
};
interface PortalPopupProps {
    anchorRef: RefObject<HTMLElement>;
    children: ReactNode | ((position: Position) => JSX.Element);
    open: boolean;
}

/**
 * PortalPopup component that renders its children in a portal, positioned relative to an anchor element.
 *
 * @param {PortalPopupProps} props - The props for the component.
 * @param {RefObject<HTMLElement>} props.anchorRef - The reference to the anchor element.
 * @param {ReactNode} props.children - The content to render inside the portal.
 * @param {boolean} props.open - Whether the portal is open.
 * @returns {JSX.Element} - The rendered PortalPopup component.
 */
function PortalPopup({ anchorRef, open, children }: PortalPopupProps): JSX.Element {
    const [position, setPosition] = useState<Position>({ top: 0, left: 0, width: 0 });
    const popupRef = useRef<HTMLDivElement>(null);
    const rafId = useRef<number | null>(null);

    /**
     * Updates the position of the popup based on the anchor element's position.
     */
    const updatePosition = useCallback(() => {
        if (anchorRef.current && popupRef.current) {
            const rect = anchorRef.current.getBoundingClientRect();
            const popupHeight = popupRef.current.offsetHeight;
            const popupWidth = popupRef.current.offsetWidth;
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            const spaceBelow = viewportHeight - rect.bottom;
            const spaceAbove = rect.top;
            const spaceRight = viewportWidth - rect.right;
            const spaceLeft = rect.left;

            let top = rect.bottom + 4; // 4px offset to prevent flickering
            let left = rect.left;

            if (spaceBelow < popupHeight && spaceAbove > spaceBelow) {
                top = rect.top - popupHeight - 4; // 4px offset to prevent flickering
            } else if (spaceBelow < popupHeight && spaceAbove < popupHeight) {
                if (spaceRight > popupWidth) {
                    top = rect.top + rect.height / 2 - popupHeight / 2;
                    left = rect.right;
                } else if (spaceLeft > popupWidth) {
                    top = rect.top + rect.height / 2 - popupHeight / 2;
                    left = rect.left + window.scrollX - popupWidth;
                }
            }

            const width = rect.width;
            setPosition((prevPosition) => {
                if (prevPosition.top !== top || prevPosition.left !== left || prevPosition.width !== width) {
                    return { top, left, width };
                }
                return prevPosition;
            });
        }
    }, [anchorRef, open]);

    /**
     * Handles the scroll event by updating the position of the popup using requestAnimationFrame.
     */
    const handleScroll = () => {
        if (rafId.current) {
            cancelAnimationFrame(rafId.current);
        }
        rafId.current = requestAnimationFrame(updatePosition);
    };

    useLayoutEffect(() => {
        updatePosition();
        document.body.classList.add("overflow-hidden");
        window.addEventListener("scroll", handleScroll, true);

        const resizeObserver = new ResizeObserver(() => {
            updatePosition();
        });

        if (anchorRef.current) {
            resizeObserver.observe(anchorRef.current);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll, true);
            document.body.classList.remove("overflow-hidden");
            if (anchorRef.current) {
                resizeObserver.unobserve(anchorRef.current);
            }
            if (rafId.current) {
                cancelAnimationFrame(rafId.current);
            }
        };
    }, [anchorRef, open]);

    if (!open) {
        return null;
    }

    return createPortal(
        <div className="fixed inset-0 z-50">
            <div
                ref={popupRef}
                className="absolute z-50 rounded-md border bg-popover text-popover-foreground shadow-md outline-none"
                style={{
                    top: position.top,
                    left: position.left,
                    minWidth: position.width,
                }}
            >
                {children instanceof Function ? children(position) : children}
            </div>
        </div>,
        document.getElementById("root")
    );
}

export default PortalPopup;
