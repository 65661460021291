import type { MenuProps } from "@mui/material/Menu";
import { useState } from "react";

const menuSX = {
    "& .MuiPaper-root": {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "0.5rem",
    },
    "& .MuiMenuItem-root": {
        fontSize: "14px",
        borderRadius: "0.5rem",
        padding: "6px 10px",
    },
    "& .MuiList-root": {
        display: "flex",
        flexDirection: "column",
        gap: "0.3rem",
        padding: "10px",
    },
};

const menuItemSX = {
    "& .MuiListItemIcon-root": {
        minWidth: "30px",
    },
};

type UseMenuProps = { onClose?: () => void };

export default function useMenu(callbacks?: UseMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { onClose } = callbacks || {};
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        onClose?.();
        setAnchorEl(null);
    };

    const getMenuProps = (): MenuProps => ({
        sx: menuSX,
        anchorEl: anchorEl,
        open,
        onClose: handleClose,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });

    return {
        anchorEl,
        setAnchorEl,
        open,
        handleClick,
        handleClose,
        menuSX,
        menuItemSX,
        getMenuProps,
    };
}
