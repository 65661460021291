import AddRounded from "@mui/icons-material/AddRounded";
import TravelExplore from "@mui/icons-material/TravelExplore";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUrl from "../../molecules/ImageUrl/ImageUrl";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectCurrentCompany, setValues } from "../../store/reducers/search/search.slice";
import { setCompanyPrompt } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ICompanySuggestion, ISampleMessage } from "../../store/reducers/searchCandidates/searchCandidates.types";
import Loader from "../Loader/Loader";

import { getAiCompany, selectScrappedCompany } from "@/store/reducers/filters/Filters.reducer";
import { scrapeCompanyType } from "@/store/reducers/filters/Filters.type";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Typography } from "@/ui";

function SampleMessage(props: ISampleMessage) {
    const { message, onClick } = props;
    return (
        <div onClick={() => onClick(message)}>
            <Typography className="text-[#068093] text-[12px] p-1 border border-[#068093] rounded cursor-pointer">
                <span style={{ fontSize: "14px" }}>💡</span> <i> E.g. {message}</i>
            </Typography>
        </div>
    );
}

function CompanySuggestion(props: ICompanySuggestion) {
    const { label, imageUrl, handleAdd } = props;

    return (
        <Button
            variant="outline"
            onClick={handleAdd}
            style={{
                padding: "0.3rem 0.75rem",
                fontSize: 12,
                fontWeight: 400,
                color: "black",
            }}
            startDecorator={
                <div className="rounded-md overflow-hidden flex justify-center items-center m-0.5">
                    <ImageUrl src={imageUrl} height={24} width={24} />
                </div>
            }
            endDecorator={<AddRounded sx={{ width: 16, height: 16 }} />}
        >
            {label}
        </Button>
    );
}

export function CompanyIntent() {
    const dispatch = useDispatch();
    const isSearchLoading = useSelector(checkIfLoading(getAiCompany.type));
    const scrappedCompany = useSelector(selectScrappedCompany);
    const { values } = useSelector(selectCurrentCompany);
    const [intent, setIntent] = useState("");
    const [companySuggestion, setCompanySuggestion] = useState<{ name: string; imageUrl: string }[]>([]);

    const updateCompanySuggestions = () => {
        const tempCompanySuggestion: { name: string; imageUrl: string }[] = [];
        scrappedCompany.forEach(({ name, imageUrl }: scrapeCompanyType) => {
            tempCompanySuggestion.push({ name: name, imageUrl: imageUrl ?? "" });
        });

        values.forEach((item) => {
            const index = tempCompanySuggestion.findIndex((company) => company.name === item.value);
            if (index !== -1) {
                tempCompanySuggestion.splice(index, 1);
            }
        });

        setCompanySuggestion(tempCompanySuggestion);
    };
    useEffect(updateCompanySuggestions, [scrappedCompany, values]);

    const handleAiCompanySearch = () => {
        if (!intent) {
            dispatch(setErrorNotification("Oops! You need to enter a prompt to find relevant companies"));
            return;
        }

        dispatch(getAiCompany(intent));
    };

    const handleIntentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        dispatch(setCompanyPrompt(value));
        setIntent(value);
    };

    const handleAddCurrCompany = (company: string) => {
        dispatch(
            setValues({
                key: "currentCompany",
                value: [{ value: company, excluded: false }, ...values],
            })
        );
    };

    return (
        <div className="flex flex-col gap-1">
            <div className="flex flex-row items-start gap-1">
                <input
                    style={{
                        fontSize: 12,
                        fontStyle: "italic",
                        padding: "10px",
                        backgroundColor: "transparent",
                        color: "#3A3B3C",
                        width: "100%",
                        height: "30px",
                        borderBottom: "1px solid lightgrey",
                    }}
                    className="hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-opacity-50"
                    placeholder="Use AI to find relevant companies"
                    value={intent}
                    onChange={handleIntentChange}
                />
                <button
                    className={`p-1 mt-[3px] mr-2 ${isSearchLoading ? "cursor-not-allowed opacity-50" : ""}`}
                    onClick={handleAiCompanySearch}
                    disabled={isSearchLoading}
                >
                    {isSearchLoading ? <Loader size={"sm"} className="mt-2"/> : <TravelExplore sx={{ marginRight: "-4px" }} />}
                </button>
            </div>
            <SampleMessage message="I want to find similar companies to Google" onClick={(val) => setIntent(val)} />
            {!!companySuggestion?.length && (
                <div className="flex flex-row max-w-full max-h-[300px] gap-2 overflow-y-auto p-2 flex-wrap">
                    {companySuggestion.map((company, i) => (
                        <CompanySuggestion
                            key={`${company}-${i}`}
                            label={company.name}
                            imageUrl={company.imageUrl}
                            handleAdd={() => handleAddCurrCompany(company.name)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
