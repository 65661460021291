import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { CsvUpload } from "./AddExclusionList";

import { headers } from "../../pages/project/components/editExclusionList/ExclusionList";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { RootState, useAppDispatch } from "../../store";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

import {
    EXCLUSION_FIELD,
    EXCLUSION_LIST_TYPE,
    editExclusionList,
    fetchExclusionListForCSV,
    getExclusionLists,
    selectExclusionById,
    selectExclusionListCSVData,
} from "@/store/reducers/exclusion-list/ExclusionList.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

function DownloadExclusionList() {
    const isFetching = useSelector(checkIfLoading(getExclusionLists.type));
    const { listId = "" } = useParams<{ listId?: string }>();
    const csvData = useSelector(selectExclusionListCSVData);
    const dispatch = useAppDispatch();
    const csvLink = useRef();

    const handleDownloadCSV = () => {
        dispatch(fetchExclusionListForCSV({ id: listId, handleLinkClick }));
    };

    const handleLinkClick = () => {
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
            dispatch(setSuccessNotification("exclusion list downloaded successfully."));
        });
    };
    return (
        <>
            <IconButton size="small" sx={{ padding: 0.1 }} onClick={handleDownloadCSV} disabled={isFetching}>
                {isFetching ? <CircularProgress size={12} /> : <DownloadRoundedIcon fontSize="small" />}
            </IconButton>
            <CSVLink
                headers={headers}
                data={csvData?.data || []}
                filename={`${csvData?.name}-exclusion_list.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
}

function SelectedFile({
    fileName,
    isFileRemoved,
    setFileRemoved,
}: {
    fileName: string;
    setFileRemoved: () => void;
    isFileRemoved: boolean;
}) {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ opacity: isFileRemoved ? 0.5 : 1 }}
            justifyContent="space-between"
        >
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <InsertDriveFileRoundedIcon fontSize="small" />
                <Typography variant="body2">{fileName}.csv</Typography>
            </Stack>

            <Stack direction="row" gap={0.5}>
                <DownloadExclusionList />
                <IconButton size="small" sx={{ padding: 0.1 }} onClick={() => setFileRemoved()}>
                    {isFileRemoved ? (
                        <RestorePageRoundedIcon fontSize="small" sx={{ height: 16, width: 16 }} />
                    ) : (
                        <RemoveCircleRoundedIcon fontSize="small" sx={{ height: 16, width: 16 }} />
                    )}
                </IconButton>
            </Stack>
        </Stack>
    );
}

export function EditExclusionList() {
    const dispatch = useAppDispatch();
    const { listId = "", id: projectId } = useParams<{ listId?: string; id?: string }>();
    const exclusionList = useSelector((state: RootState) => selectExclusionById(state, listId));
    const isLoading = useSelector(checkIfLoading(getExclusionLists.type));
    const [name, setName] = useState("");
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [type, setType] = useState<EXCLUSION_LIST_TYPE>("LOCAL");
    const [listData, setListData] = useState<EXCLUSION_FIELD>("EMAIL");
    const [commaSeparatedEmails, setCommaSeparatedEmails] = useState("");
    const [isFileRemoved, setFileRemoved] = useState(false);

    useEffect(() => {
        if (exclusionList) {
            setName(exclusionList.name);
            setType(exclusionList.type);
        } else {
            dispatch(getExclusionLists());
        }
    }, [exclusionList?._id]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListData(event.target.value as EXCLUSION_FIELD);
    };

    const navigate = useNavigate();

    const handleClose = () => navigate(`/projects/${projectId}`);

    const handleEdit = () => {
        if (isFileRemoved && !csvFile?.name && !commaSeparatedEmails?.length) {
            dispatch(setErrorNotification("You can not leave all inputs empty!"));
            return;
        }

        if (!name) {
            dispatch(setErrorNotification(!name ? "Name is required" : "CSV file is required"));
            return;
        }

        dispatch(
            editExclusionList({
                id: exclusionList?._id || "",
                action: csvFile?.name ? "OVERWRITE" : "ADD",
                csvFile,
                emailsStringArray: commaSeparatedEmails
                    ? commaSeparatedEmails.split(",").filter((i) => Boolean(i))
                    : [],
                exclusionField: listData,
                exclusionListType: type,
                name,
                onSuccess: handleClose,
            })
        );
    };

    return (
        <BaseModal onClose={handleClose} overlayStyles={{ width: "550px" }}>
            {isLoading ? (
                <Box
                    py={2.5}
                    sx={{ display: "flex", justifyContent: "center", minHeight: "40vh", alignItems: "center" }}
                >
                    <CircularProgress size={24} />
                </Box>
            ) : (
                <Stack spacing={2}>
                    <Stack>
                        <Typography fontSize={20} fontWeight={500} color="#334d6e">
                            Edit exclusion list
                        </Typography>
                        <Typography variant="caption" fontStyle="italic" mt={0}>
                            Replace the (.csv) list above or append emails/domains to this from below
                        </Typography>
                    </Stack>
                    <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <Stack>
                        <Typography variant="body1">Exclusion List Data</Typography>
                        <RadioGroup
                            value={listData}
                            onChange={handleChange}
                            sx={{
                                flexDirection: "row",
                            }}
                        >
                            <FormControlLabel value="EMAIL" control={<Radio />} label="Email" />
                            <FormControlLabel value="COMPANY" control={<Radio />} label="Company" />
                        </RadioGroup>
                    </Stack>
                    <SelectedFile
                        fileName={exclusionList?.name || ""}
                        isFileRemoved={isFileRemoved}
                        setFileRemoved={() => setFileRemoved((prev) => !prev)}
                    />
                    <CsvUpload handleCsvUpload={(file) => setCsvFile(file[0])} />
                    <TextField
                        multiline
                        label={
                            listData === "EMAIL"
                                ? "Paste your emails here(comma-separated)"
                                : "Paste your companys here(comma-separated)"
                        }
                        value={commaSeparatedEmails}
                        onChange={(e) => setCommaSeparatedEmails(e.target.value)}
                        // sx={{ maxHeight: "100px", overflow: "auto" }}
                        sx={{
                            "& .MuiInputBase-input": {
                                maxHeight: "100px",
                                overflow: "auto !important",
                            },
                        }}
                    />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">Exclusion List Type</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={type === "GLOBAL"}
                                    onChange={(_, v) => setType(v ? "GLOBAL" : "LOCAL")}
                                />
                            }
                            label={type?.slice(0, 1)?.toUpperCase() + type?.slice(1)?.toLowerCase()}
                        />
                    </Stack>
                    <Stack alignItems="center">
                        <Button variant="contained" onClick={handleEdit}>
                            <ButtonTextWithLoading isLoading={isLoading} text="Submit" />
                        </Button>
                    </Stack>
                </Stack>
            )}
        </BaseModal>
    );
}
