import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
    Button,
    IconButton,
    Table as MUITable,
    TableBody as MuiTableBody,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import millify from "millify";
import { Fragment, forwardRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TableVirtuoso } from "react-virtuoso";

import style from "./table.module.scss";

import HoverTruncateText from "../../../../atoms/HoverTruncateText";
import { AutopilotDropdown } from "../../../../components/AutopilotDropdown";
import Loader from "../../../../components/Loader/Loader";
import { ProjectStatus } from "../../../../components/Project";
import useResponsiveWidth from "../../../../hooks/useResponsiveWidth";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import handleCleverTap from "../../../../utils/clevertap";

import {
    editProject,
    refreshProjectNetNew,
    setAllProjectsList,
} from "@/store/reducers/allProjects/allProjects.reducer";
import { setEmailEnrich, setLinkedinEnrich, setPhoneEnrich } from "@/store/reducers/project/project.reducer";
import { IProject, IProjectStatus } from "@/store/reducers/project/project.types";
import { checkEasyGrowth, checkShowAdditionalStats, selectUser } from "@/store/reducers/signin/Signin.reducer";

const getScreenInfo = () => {
    const screenWidth = useResponsiveWidth();
    const isSmallScreen = screenWidth < 1000;
    const isMediumScreen = screenWidth < 1300;

    switch (true) {
        case screenWidth < 1000:
            return { isSmallScreen, isMediumScreen, maxWidth: 200 };
        case screenWidth < 1200:
            return { isSmallScreen, isMediumScreen, maxWidth: 300 };
        case screenWidth < 1400:
            return { isSmallScreen, isMediumScreen, maxWidth: 500 };
        case screenWidth < 1600:
            return { isSmallScreen, isMediumScreen, maxWidth: 600 };
        case screenWidth < 1800:
            return { isSmallScreen, isMediumScreen, maxWidth: 700 };
        case screenWidth < 2000:
            return { isSmallScreen, isMediumScreen, maxWidth: 900 };
        case screenWidth < 2200:
            return { isSmallScreen, isMediumScreen, maxWidth: 1000 };
        case screenWidth < 2400:
            return { isSmallScreen, isMediumScreen, maxWidth: 1100 };
        case screenWidth < 2600:
            return { isSmallScreen, isMediumScreen, maxWidth: 1200 };
        case screenWidth < 2800:
            return { isSmallScreen, isMediumScreen, maxWidth: 1300 };
        case screenWidth < 3000:
            return { isSmallScreen, isMediumScreen, maxWidth: 1400 };
        default:
            return { isSmallScreen, isMediumScreen, maxWidth: 1500 };
    }
};

const Columns = [
    {
        name: "Name",
        key: "name",
    },
    {
        name: "Created on",
        key: "createdAt",
        style: {
            maxWidth: "220px",
            minWidth: "220px",
        },
    },
    {
        name: "Total",
        key: "pipelined",
        style: {
            maxWidth: "10%",
        },
    },
    {
        name: "Contacted",
        key: "contacted",
        style: {
            maxWidth: "10%",
        },
    },
    {
        name: "Net new prospects",
        key: "netNew",
        style: {
            maxWidth: "10%",
        },
        dynamic: true,
    },
    {
        name: "Emails",
        key: "emailAddCount",
        style: {
            maxWidth: "10%",
        },
        dynamic: true,
    },
    {
        name: "Phone numbers",
        key: "phoneNoCount",
        style: {
            maxWidth: "10%",
        },
        dynamic: true,
    },
];

// Types
type TableProps = {
    data: IProject[];
    loading: boolean;
};

type OrderByColumn = keyof IProject | "pipelined" | "netNew" | "contacted" | "emailAddCount" | "phoneNoCount" | null;

// NetNew Component
const NetNew = ({ text, record }: { text: string; record: any }) => {
    const isNetNewLoading = useSelector(checkIfLoading(`net-new-${record._id}`));
    const dispatch = useDispatch();

    return (
        <Stack alignItems={"center"} justifyContent={"center"} direction="row" gap={1}>
            <p className={style["table__text"]}>{millify(Number(text))}</p>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(refreshProjectNetNew({ customType: `net-new-${record._id}`, projectId: record._id }));
                }}
            >
                {isNetNewLoading ? <CircularProgress size={20} /> : <RefreshIcon sx={{ fontSize: "20px" }} />}
            </IconButton>
        </Stack>
    );
};

const ProjectPurpose = ({ purpose }: { purpose: string }) => {
    const MAP = {
        AI_SOURCING: "AI Sourcing",
        IMPORT_CANDIDATES: "Import",
    };
    return (
        <Button
            size="small"
            sx={{
                fontSize: 10,
                height: "22px",
                minWidth: 48,
                "& .MuiButton-endIcon": {
                    marginLeft: 0.5,
                },
            }}
            variant="outlined"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {MAP[purpose] || "Both"}
        </Button>
    );
};

const TableHeader = ({ orderBy, order, onSort, showAdditionalStats, isEasyGrowth, t }) => {
    const { isSmallScreen, isMediumScreen, maxWidth } = getScreenInfo();

    const getSortTooltip = (columnKey: string) => {
        if (orderBy !== columnKey) return "Click to sort ascending";
        if (order === "asc") return "Click to sort descending";
        return "Click to cancel sorting";
    };

    const renderColumn = (column: any, index: number) => {
        const isColumnSorted = orderBy === column.key;
        const cellStyle = {
            backgroundColor: isColumnSorted ? "#f6f6f6" : "#fafafa",
            transition: "background-color 0.2s",
            color: "#334d6e",
            maxWidth: maxWidth,
        };

        const sortLabelStyle = {
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "12px" : "16px",
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // padding: "17px 20px",
            padding: isSmallScreen ? "10px 8px" : isMediumScreen ? "12px 10px" : "17px 20px",
            fontWeight: "500",
            "&.MuiTableSortLabel-root": {
                color: "inherit",
            },
            "&.MuiTableSortLabel-root:hover": {
                color: "inherit",
            },
            "&.Mui-active": {
                color: "inherit",
            },
        };

        const renderSortLabel = () => (
            <TableSortLabel
                active={true}
                direction={orderBy === column.key ? order : "asc"}
                sx={{
                    ...sortLabelStyle,
                }}
                IconComponent={() => (
                    <span style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                        {orderBy === column.key ? (
                            order === "asc" ? (
                                <ArrowUpwardIcon fontSize="small" />
                            ) : (
                                <ArrowDownwardIcon fontSize="small" />
                            )
                        ) : (
                            <ImportExportIcon fontSize="small" style={{ opacity: 0.3 }} />
                        )}
                    </span>
                )}
            >
                {column.name}
            </TableSortLabel>
        );

        if (column.dynamic) {
            return showAdditionalStats && isEasyGrowth ? (
                <Tooltip key={index} title={getSortTooltip(column.key)} arrow placement="top">
                    <TableCell
                        key={column.key}
                        onClick={() => onSort(column.key)}
                        sx={{
                            ...cellStyle,
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "#f4f4f4",
                            },
                            ...column?.style,
                        }}
                    >
                        {renderSortLabel()}
                    </TableCell>
                </Tooltip>
            ) : null;
        }

        return (
            <Tooltip key={index} title={getSortTooltip(column.key)} arrow placement="top">
                <TableCell
                    key={column.key}
                    sx={{
                        ...cellStyle,
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#f4f4f4",
                        },
                        ...column?.style,
                    }}
                    onClick={() => onSort(column.key)}
                >
                    {renderSortLabel()}
                </TableCell>
            </Tooltip>
        );
    };

    return (
        <TableRow
            sx={{
                "& .MuiTableCell-root": {
                    width: "100%",
                },
            }}
        >
            {Columns.map(renderColumn)}
        </TableRow>
    );
};

// Main Table Component
export default function Table({ data, loading }: TableProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showAdditionalStats = useSelector(checkShowAdditionalStats);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const user = useSelector(selectUser);
    const [orderBy, setOrderBy] = useState<OrderByColumn>(null);
    const [order, setOrder] = useState<"asc" | "desc" | null>(null);
    const { isSmallScreen, isMediumScreen, maxWidth } = getScreenInfo();

    const visibleColumns = useMemo(() => {
        return Columns.filter((column) => {
            if (column.dynamic) {
                return showAdditionalStats && isEasyGrowth;
            }
            return true;
        });
    }, [showAdditionalStats, isEasyGrowth]);

    const handleSort = (property: OrderByColumn) => {
        if (orderBy === property) {
            if (order === "asc") {
                setOrder("desc");
            } else if (order === "desc") {
                setOrder(null);
                setOrderBy(null);
            }
        } else {
            setOrderBy(property);
            setOrder("asc");
        }
    };

    const sortedData = useMemo(() => {
        if (!data || !orderBy || !order) return data;
        return [...data].sort((a, b) => {
            if (orderBy === "createdAt") {
                const dateA = new Date(a[orderBy]);
                const dateB = new Date(b[orderBy]);
                return order === "asc" ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
            }

            const valueA = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
            const valueB = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];

            if (valueA < valueB) return order === "asc" ? -1 : 1;
            if (valueA > valueB) return order === "asc" ? 1 : -1;
            return 0;
        });
    }, [data, order, orderBy]);

    const menuClickHandler = (record: IProject, status: IProjectStatus) => {
        if (record.dashboardStatus === status) return;
        if (record._id === "9999") {
            const tmpProjects = data.map((item: IProject) => {
                if (item?._id === "9999") {
                    return {
                        ...item,
                        dashboardStatus: status,
                    };
                }

                return item;
            });
            dispatch(setAllProjectsList(tmpProjects));
            return;
        }

        if (status === "CLOSED") {
            return navigate(`/close/${record._id}`);
        }

        if (status === "ON_HOLD") {
            return navigate(`/on-hold/${record._id}`);
        }

        dispatch(
            editProject({
                ...record,
                excludeVettingCriteria: true,
                dashboardStatus: status,
            })
        );
    };

    const onClickProject = (id: number | string) => {
        // resetting states here
        dispatch(setEmailEnrich({ emailEnrich: false }));
        dispatch(setPhoneEnrich({ phoneEnrich: false }));
        dispatch(setLinkedinEnrich({ linkedInEnrich: false }));
        const isIdNumber = Number.isInteger(id);
        if (isIdNumber) {
            handleCleverTap("Clicks on Project", {
                "Project id": id,
            });
        }

        navigate("/projects/" + id);
    };

    const TableWrapper = ({ children }: { children: React.ReactNode }) => {
        return <tbody style={{ width: "100%", height: "80vh" }}>{children}</tbody>;
    };

    const VirtuosoTableComponents = {
        Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props: React.HTMLAttributes<HTMLTableElement>) => (
            <MUITable
                {...props}
                style={{
                    position: "relative",
                    ...(data?.length ? {} : (!data?.length || loading) && { height: "100%" }),
                }}
            />
        ),
        TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => {
            return (
                <TableHead
                    {...props}
                    ref={ref}
                    id="table-head"
                    sx={{
                        th: {
                            padding: 0,
                        },
                        tr: {
                            border: "none",
                            borderBottom: "2px solid #f4f4f4",
                            // outline: "2px solid #f4f4f4",
                            // boxShadow: "2px 2px 10px 2px rgba(100, 100, 100, 0.1)",
                        },
                    }}
                />
            );
        }),
        TableRow: ({ item: _item, ...props }: any) => (
            <TableRow
                hover
                {...props}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#f9f9f9 !important",
                    },
                }}
            />
        ),
        TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => {
            if (loading && !data?.length) {
                return (
                    <TableWrapper>
                        <tr
                            style={{
                                position: "static",
                            }}
                        >
                            <td style={{ position: "absolute", top: "50%", right: "45%" }}>
                                <Loader />
                            </td>
                        </tr>
                    </TableWrapper>
                );
            }

            if (!data?.length) {
                return (
                    <TableWrapper>
                        <tr
                            style={{
                                position: "static",
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#404040",
                            }}
                        >
                            <td style={{ position: "absolute", top: "50%", right: "45%" }}>No projects found</td>
                        </tr>
                    </TableWrapper>
                );
            }

            return <MuiTableBody {...props} ref={ref} />;
        }),
    };

    const fixedHeaderContent = () => (
        <TableHeader
            orderBy={orderBy}
            order={order}
            onSort={handleSort}
            showAdditionalStats={showAdditionalStats}
            isEasyGrowth={isEasyGrowth}
            t={t}
        />
    );

    const TableCellWrapper = ({
        children,
        column,
        record_id,
    }: {
        children: React.ReactNode;
        column: any;
        record_id: string;
    }) => {
        const isColumnSorted = orderBy === column.key;
        const { isSmallScreen, isMediumScreen, maxWidth } = getScreenInfo();

        let nameColumnWidth = maxWidth;
        if (isSmallScreen) {
            nameColumnWidth = 300;
        } else if (isMediumScreen) {
            nameColumnWidth = 350;
        }

        return (
            <TableCell
                sx={{
                    padding: isSmallScreen ? "10px 8px" : isMediumScreen ? "12px 10px" : "15px 20px",
                    cursor: "pointer",
                    ...(isColumnSorted && {
                        backgroundColor: "#f9f9f9",
                    }),
                    fontSize: isSmallScreen ? "12px" : isMediumScreen ? "12px" : "16px",
                    maxWidth: column.key === "name" ? nameColumnWidth : maxWidth,
                    minWidth: column.key === "name" ? nameColumnWidth : "auto",
                    ...column?.style,
                }}
                onClick={() => {
                    onClickProject(record_id);
                }}
            >
                {children}
            </TableCell>
        );
    };

    const renderRowContent = (record: any, column: any, index: number) => {
        if (column.dynamic) {
            return showAdditionalStats && isEasyGrowth ? (
                <Fragment key={index}>
                    {column?.key === "netNew" ? (
                        <TableCellWrapper column={column} record_id={record._id}>
                            <NetNew text={record?.netNew} record={record} />
                        </TableCellWrapper>
                    ) : (
                        <TableCellWrapper column={column} record_id={record._id}>
                            <p className={style["table__text"]}>{record[column.key]}</p>
                        </TableCellWrapper>
                    )}
                </Fragment>
            ) : null;
        }

        if (column.key === "createdAt") {
            return (
                <TableCellWrapper key={index} column={column} record_id={record._id}>
                    <p className={style["table__text"]}>{dayjs(record.createdAt).format("MMM D YYYY, h:mm A")}</p>
                </TableCellWrapper>
            );
        }

        return (
            <TableCellWrapper key={index} column={column} record_id={record._id}>
                {column.key === "name" ? (
                    <>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <HoverTruncateText
                                maxWidth={maxWidth - 240 >= 100 ? maxWidth - 300 : 100}
                                sx={{
                                    color: "#404040",
                                    fontWeight: "500 !important",
                                    maxHeight: "22px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    fontSize:"16px",
                                    "@media (max-width: 1280px)": {
                                        fontSize: "13px",
                                    },
                                    "@media (max-width: 850px)": {
                                        fontSize: "12px",
                                    },
                                    "@media (max-width: 800px)": {
                                        fontSize: "11px",
                                    },
                                    "@media (max-width: 700px)": {
                                        fontSize: "10px",
                                    },
                                }}
                                stopPropagation={false}
                            >
                                {record.name}
                            </HoverTruncateText>
                            <ProjectStatus
                                status={record.dashboardStatus as IProjectStatus}
                                menuClickHandler={(status) => menuClickHandler(record, status)}
                            />
                            <AutopilotDropdown projectId={record?._id} autoSource={record?.autoSource} />
                            <ProjectPurpose purpose={record?.purpose} />
                        </Stack>
                        <p className={style["table__name-text"]}>
                            <i>
                                {" "}
                                Created by {record?.createdBy?.name} ({record?.createdBy?.email}){" "}
                            </i>
                        </p>
                    </>
                ) : (
                    <p className={style["table__text"]}>{record[column.key]}</p>
                )}
            </TableCellWrapper>
        );
    };

    const rowContent = (_index: number, record: any) => {
        return visibleColumns.map((column, index) => renderRowContent(record, column, index));
    };

    return (
        <Paper sx={{ borderRadius: "15px", overflow: "hidden", borderBottom: "none", height: "calc(100% - 120px)" }}>
            <TableVirtuoso
                style={{ height: "100%" }}
                data={sortedData}
                components={VirtuosoTableComponents as any}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
            />
        </Paper>
    );
}
