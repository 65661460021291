import { PayloadAction } from "@reduxjs/toolkit";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { FileWithPath } from "react-dropzone";
import { z } from "zod";

import { EVENT_ENUMS, EVENT_NAMES } from "./outreach.enums";
import { IProject } from "@/store/reducers/project/project.types";

const eventNameSchema = z.union([
    z.literal(EVENT_NAMES.email),
    z.literal(EVENT_NAMES["linkedin"]),
    z.literal(EVENT_NAMES["follow-up"]),
    z.literal(EVENT_NAMES["SMS"]),
]);

export type EventNames = z.infer<typeof eventNameSchema>;
const TemplateTypesEnum = z.enum(["EMAIL", "FOLLOW_UP", "SMS", "LINKEDIN"]);
const eventEnumSchema = z.union([
    z.literal(EVENT_ENUMS.email),
    z.literal(EVENT_ENUMS["linkedin"]),
    z.literal(EVENT_ENUMS["follow-up"]),
    z.literal(EVENT_ENUMS["SMS"]),
]);

export type EventEnums = z.infer<typeof eventEnumSchema>;

const outReachEventsSchema = z.object({
    email: z.literal("1"),
    linkedin: z.literal("25"),
    "follow-up": z.literal("12"),
    SMS: z.literal("11"),
});

type OutReachEvents = z.infer<typeof outReachEventsSchema>;

export type OutReachEvent = keyof OutReachEvents;

const bodyWithSubjectSchema = z.object({
    subject: z.string(),
    body: z.string(),
});

const linkedinSchema = z.object({
    // connection-request
    body: z.string(),
    connectionReminderMessageBody: z.string(),
    connectionReminderMessageStatus: z.boolean(),
    inmailFollowupStatus: z.boolean(),
    inmailFollowupBody: z.string(),
    inmailFollowupSubject: z.string(),
    inmailFollowupReminder: z.string(),
    // in-mail
    inMailSubject: z.string(),
    inMailBody: z.string(),

    //status
    connectionStatus: z.boolean(),
    inMailStatus: z.boolean(),
    isScheduleRecruiterOnSameDay: z.boolean(),
});

export type LinkedinSchema = z.infer<typeof linkedinSchema>;

export type BodyWithSubject = z.infer<typeof bodyWithSubjectSchema>;

const onlyBodySchema = z.object({
    body: z.string(),
});

export type OnlyBody = z.infer<typeof onlyBodySchema>;

const connectionRequestContentSchema = onlyBodySchema.extend({
    reminderMessageBody: z.string(),
    reminderMessageStatus: z.boolean(),
});

export type ConnectionRequestBody = z.infer<typeof connectionRequestContentSchema>;

const eventBodySchema = z.union([
    z.object(bodyWithSubjectSchema.shape),
    z.object(bodyWithSubjectSchema.shape),
    z.object(bodyWithSubjectSchema.shape),
    z.object(onlyBodySchema.shape),
    z.object(linkedinSchema.shape),
]);

export type EventBody = z.infer<typeof eventBodySchema>;

const scheduleDaySchema = z.number();
const scheduledTime = z.string();

const errorTypesSchema = z.union([
    z.literal("EMPTY_ERROR"),
    z.literal("CURLY_BRACES_ERROR"),
    z.literal("ROUND_BRACES_ERROR"),
    z.literal("CHARACTER_LIMIT_ERROR"),
    z.literal("EMAIL_FOLLOW_UP_ERROR"),
]);

export type ErrorTypes = z.infer<typeof errorTypesSchema>;

export type OutreachErrorValue = Partial<Record<ErrorTypes, string>>;

export type OutreachErrors = {
    subject?: OutreachErrorValue;
    body: OutreachErrorValue;
    orderingErrors?: OutreachErrorValue;
    connectionReminderMessageBody?: OutreachErrorValue;
    inmailFollowupBody?: OutreachErrorValue;
    inMailSubject?: OutreachErrorValue;
    inMailBody?: OutreachErrorValue;
    inmailFollowupSubject?: OutreachErrorValue;
    inmailFollowupReminder?: OutreachErrorValue;
};

const attachmentsSchema = z.object({
    name: z.string(),
    link: z.string(),
    id: z.string(),
});

export type Attachment = z.infer<typeof attachmentsSchema>;

const outReachEventValueSchema = z.object({
    _id: z.string(),
    eventName: eventNameSchema,
    eventEnum: eventEnumSchema, // This could be refined to specific enum values if needed
    scheduledDay: scheduleDaySchema,
    scheduledTime: scheduledTime, // For ISO string, you could use a custom validation to ensure format
    isSaved: z.boolean(),
    eventBody: eventBodySchema,
    stepId: z.string(),
    attachments: z.array(attachmentsSchema).optional(),
    // following three fields are only exist on client side
    isNew: z.boolean().optional(),
});

export type OutreachEventValue = z.infer<typeof outReachEventValueSchema>;

export type WorkflowStepValue = z.infer<typeof outReachEventValueSchema> & {
    errors: OutreachErrors;
};

export type WorkflowSteps = Record<WorkflowStepValue["_id"], WorkflowStepValue>;

// create outreach types
const createOutreachResponsePayloadSchema = z.object({
    candidateIds: z.string().array(),
    selectAll: z.boolean().optional(),
    projectId: z.string(),
    filterBy: z.string().array().optional(),
    search: z.string().optional(),
});

export type CreateOutreachRequestPayload = z.infer<typeof createOutreachResponsePayloadSchema>;

const createOutreachResponseDataSchema = z.object({
    workflowId: z.string(),
    workflowSteps: z.array(outReachEventValueSchema),
});

export type CreateOutreachResponseData = z.infer<typeof createOutreachResponseDataSchema>;

export const createOutreachResponseSchema = z.object({
    message: z.string(),
    success: z.boolean(),
    data: createOutreachResponseDataSchema,
});

export type CreateOutreachResponse = z.infer<typeof createOutreachResponseSchema>;
//---------------------------------------------------------- add workflow step

const addWorkflowStepRequestPayload = z.object({
    projectId: z.number(),
    eventName: z.enum(["email", "follow-up", "SMS", "linkedin"]),
});

const addWorkflowStepResponse = z.object({
    eventBody: z.object({
        body: z.string().optional(),
        subject: z.string().optional(),
        inMailBody: z.string().optional(),
        inMailSubject: z.string().optional(),
        connectionReminderMessageBody: z.string().optional(),
        inmailFollowupBody: z.string().optional(),
    }),
    type: z.enum(["email", "follow-up", "SMS", "linkedin"]),
});

export type AddWorkflowRequestPayload = z.infer<typeof addWorkflowStepRequestPayload>;
export type AddWorkflowResponse = z.infer<typeof addWorkflowStepResponse>;

//------------------------------test

const testEmailPayload = z.object({
    email: z.string(),
    subject: z.string(),
    body: z.string(),
    candidateId: z.string(),
    projectId: z.string(),
    attachments: z.array(attachmentsSchema).optional(),
});

export type TestEmailPayload = z.infer<typeof testEmailPayload>;

// fetch outreach candidates types
const fetchOutreachCandidatesRequestPayload = z.object({
    outreachId: z.string(),
});

export type FetchOutreachCandidatesRequestPayload = z.infer<typeof fetchOutreachCandidatesRequestPayload>;

const fetchOutreachCandidatesResponseDataSchema = z.object({
    _id: z.string(),
    name: z.string(),
});

const fetchOutreachCandidatesResponseDataWithValue = fetchOutreachCandidatesResponseDataSchema
    .extend({
        value: z.string(),
        id: z.string(),
        label: z.string(),
    })
    .omit({ _id: true, name: true });

export type FetchOutreachResponseDataWithValue = z.infer<typeof fetchOutreachCandidatesResponseDataWithValue>[];

export type FetchOutreachResponseData = z.infer<typeof fetchOutreachByIdResponseDataSchema>[];

export const fetchOutreachResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
    data: z.array(fetchOutreachCandidatesResponseDataSchema),
});

export type FetchOutreachResponse = z.infer<typeof fetchOutreachResponseSchema>;
//----------------------------------------------------------

// create preview types
const createPreviewRequestPayload = z.object({
    candidateId: z.string(),
    projectId: z.string(),
    eventBody: eventBodySchema,
    eventName: eventNameSchema,
    unsubscribeEmail: z.boolean().optional(),
});

export type CreatePreviewRequestPayload = z.infer<typeof createPreviewRequestPayload>;

const createPreviewResponseDataSchema = z.object({
    eventBody: eventBodySchema,
    eventName: eventNameSchema,
});

export type CreatePreviewResponseData = z.infer<typeof createPreviewResponseDataSchema>;

export const createPreviewResponseSchema = z.object({
    message: z.string(),
    success: z.boolean(),
    data: createPreviewResponseDataSchema,
});

export type CreatePreviewResponse = z.infer<typeof createPreviewResponseSchema>;
//----------------------------------------------------------

// save template types
const saveTemplateRequestPayload = outReachEventValueSchema
    .extend({
        workflowId: z.string(),
        stepId: z.string().optional(),
        projectId: z.number(),
        unsubscribeEmail: z.boolean().optional(),
    })
    .omit({
        isSaved: true,
    });

export type SaveTemplatesRequestPayload = z.infer<typeof saveTemplateRequestPayload>;

export const saveTemplatesResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
    data: outReachEventValueSchema,
});

export type SaveTemplatesResponse = z.infer<typeof saveTemplatesResponseSchema>;
//----------------------------------------------------------

// fetch outreach templates by outreach-id
export type FetchOutreachByIdRequestPayload = string;

const fetchOutreachByIdResponseDataSchema = z.object({
    workflowId: z.string(),
    workflowSteps: z.array(outReachEventValueSchema),
});

export type FetchOutreachByIdResponseData = z.infer<typeof fetchOutreachByIdResponseDataSchema>;

export const fetchOutreachByIdResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
    data: fetchOutreachByIdResponseDataSchema,
});

export type FetchOutreachByIdResponse = z.infer<typeof fetchOutreachByIdResponseSchema>;
//----------------------------------------------------------

// schedule outreach types
const scheduleOutreachRequestPayload = z.object({
    isScheduledNow: z.boolean().optional(),
    bccEmail: z.string().optional(),
    senderEmail: z.string().optional(),
    senderName: z.string().optional(),
    cookie: z.string().optional(),
    workflowId: z.string(),
    workflowSteps: z.array(outReachEventValueSchema),
    outreachIntent: z.string(),
    newExclusionListIds: z.array(z.string()),
    unsubscribeEmail: z.boolean().optional(),
    timezone: z.string(),
    rotatingInbox: z.boolean().optional(),
});

export type ScheduleOutreachRequestPayload = z.infer<typeof scheduleOutreachRequestPayload>;
const sendWritingStylesPayload = z.object({
    data: z.array(z.string()),
    projectId: z.string(),
});

export type SendWritingStylesPayload = z.infer<typeof sendWritingStylesPayload>;

const saveWritingStylesPayload = z.object({
    "Vocabulary and Language Pattern": z.string(),
    Tone: z.string(),
    Style: z.string(),
    Structure: z.string(),
    projectId: z.number(),
    Status: z.string(),
});

export type SaveWritingStylesPayload = z.infer<typeof saveWritingStylesPayload>;

export const scheduleOutreachResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
});

export type ScheduleOutreachResponse = z.infer<typeof scheduleOutreachResponseSchema>;
//----------------------------------------------------------

// upload attachment types
export type UploadAttachmentsRequestPayload = {
    files: FileWithPath[];
    projectId: string;
    currentOutreachElementId: string;
};

export type UploadAttachmentsResponseData = {
    files: Attachment[];
    currentOutreachElementId: string;
};

const uploadAttachmentsSchema = z.string().array();

export type UploadAttachmentsResponse = z.infer<typeof uploadAttachmentsSchema>;
//------------------------

export type OutReachElementWithEventEnumAndName = Pick<WorkflowStepValue, "eventEnum" | "eventName">;

export type DraggableProps = {
    draggableProps: DraggableProvidedDragHandleProps | null | undefined;
};

export type OutreachElementProps = WorkflowStepValue & { index: number; nthFollowup: string };

export type EditorValues = "MAIN" | "SECONDARY" | "TERTIARY" | "Quaternary";

export type Candidate = {
    id: string;
    name: string;
    templates: WorkflowSteps;
    templatesOrder: WorkflowStepValue["_id"][];
};

export type Candidates = Record<string, Candidate>;

export type HyperPersonalizationState = {
    baseTemplates: WorkflowSteps;
    baseTemplatesOrder: WorkflowStepValue["_id"][];
    isEditSignatureModalOpen?: boolean;
    currentOutreachStep?: string;
    currentEditor: "editor1" | "editor2" | "editor3" | "editor4" | "editor5" | null;
    isMobileViewOn: boolean;
    exclusionListIds: string[];
    customPersonalizedFields: { label: string; value: string }[];
    writingStyles: any;
    isWarningModalOpen?: any;
    projectData?: IProject;
};
export type ChangeCurrentEditor = PayloadAction<"editor2" | "editor1" | "editor3" | "editor4" | "editor5" | null>;
export type ToggleEditSignatureModal = PayloadAction<boolean>;
export type ToggleCurrentOutreachStep = PayloadAction<string>;

export type SetOutReachElementDaysPayload = PayloadAction<{
    templateId: string;
    days: number;
}>;

export type AddOutReachElementPayload = PayloadAction<{
    eventName: WorkflowStepValue["eventName"];
}>;

export type MoveToNextElementPayload = PayloadAction<{
    currentSelectedTemplateId: string;
    moveToOutreachElement: (templateId: string) => void;
}>;

// fetch templates
// ------------------------------------------------------

const fetchTemplateResposeDataSchema = z.object({
    _id: z.string(),
    createdBy: z.number(),
    belongsTo: z.number(),
    type: TemplateTypesEnum,
    name: z.string(),
    subject: z.string(),
    inMailBody: z.string(),
    inMailSubject: z.string(),
    connectionReminderMessageBody: z.string(),
    inmailFollowupBody: z.string(),
    inmailFollowupSubject: z.string(),
    body: z.string(),
    active: z.boolean(),
    updatedAt: z.string(),
    user: z.object({
        name: z.string(),
    }),
});
export const fetchTemplatesResponseSchema = z.object({
    message: z.string(),
    data: z.array(fetchTemplateResposeDataSchema),
});

const fetchMimicWritingStyleDataSchema = z.object({
    "Vocabulary And Language Pattern": z.string(),
    Tone: z.string(),
    Style: z.string(),
    Structure: z.string(),
    NotablePhrases: z.array(z.string()),
    samples: z.array(z.string()),
    applyStyle: z.boolean(),
});

const fetchMimicWritingStyleResponseSchema = z.object({
    message: z.string(),
    data: fetchMimicWritingStyleDataSchema,
});
const fetchMimicWritingStylePayload = z.object({
    projectId: z.string(),
});
const applyMimicWritingStylePayload = z.object({
    projectId: z.string(),
    Status: z.string(),
});

const applyMimicWritingStyleresponseSchema = z.object({
    message: z.string(),
    data: z.boolean(),
});

export type FetchMimicWritingStylePayload = z.infer<typeof fetchMimicWritingStylePayload>;

export type FetchTemplatesResponse = z.infer<typeof fetchTemplatesResponseSchema>;
export type FetchTemplatesResponseData = z.infer<typeof fetchTemplateResposeDataSchema>;
export type FetchMimicWritingStyleResponse = z.infer<typeof fetchMimicWritingStyleResponseSchema>;
export type FetchMimicWritingStyleResponseData = z.infer<typeof fetchMimicWritingStyleDataSchema>;
export type ApplyMimicWritingStylePayload = z.infer<typeof applyMimicWritingStylePayload>;
export type ApplyMimicWritingStyleResponse = z.infer<typeof applyMimicWritingStyleresponseSchema>;

export type CreateTemplatePayload = {
    name: string;
    subject: string;
    body: string;
};

export type EditTemplatePaylod = {
    name: string;
    type: z.infer<typeof TemplateTypesEnum>;
    subject: string;
    body: string;
    active: boolean;
};

// --------------------------------------------

const excludeItem = z.object({
    listId: z.string(),
    name: z.string(),
    updatedAt: z.string(),
    totalCount: z.number(),
    userName: z.string(),
});

type PartialExcludeItem<K extends keyof ExcludeItemType> = Omit<ExcludeItemType, K> & Partial<Pick<ExcludeItemType, K>>;
export type ExcludeItemWithOptionals = PartialExcludeItem<"updatedAt" | "totalCount" | "userName">;

const fetchExclusionListResponseType = z.array(excludeItem);

const uploadExclusionListPayload = z.any();

const downloadExclusionListPayload = z.object({
    exclusionListId: z.string(),
});

const downloadExclusionListResponse = z.array(
    z.object({
        key: z.enum(["email", "phone", "linkedin"]),
        value: z.string(),
    })
);

const downloadExclusionListAutomaticResponse = z.array(
    z.object({
        email: z.string(),
        phone: z.string(),
        profileUrl: z.string(),
    })
);

const downloadExclusionListAutomaticPayload = z.object({
    projectId: z.number(),
    exclusionList: z.array(z.string()),
});

export type FetchExclusionListResponeType = z.infer<typeof fetchExclusionListResponseType>;
export type ExcludeItemType = z.infer<typeof excludeItem>;
export type UploadExclusionListPayload = z.infer<typeof uploadExclusionListPayload>;
export type DownloadExclusionListPayload = z.infer<typeof downloadExclusionListPayload>;
export type DownloadExclusionListResponse = z.infer<typeof downloadExclusionListResponse>;

export type DownloadExclusionListAutomaticPayload = z.infer<typeof downloadExclusionListAutomaticPayload>;
export type DownloadExclusionListAutomaticResponse = z.infer<typeof downloadExclusionListAutomaticResponse>;
