import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

import { CountryCodes } from "../../../../constant/countryCodes";

import { Button, Chip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

interface IFilterMenuProps {
    minPrice: string;
    setMinPrice: any;
    maxPrice: string;
    setMaxPrice: any;
    clearFilter: any;
    selectedRange: string;
    handleRangeChange: any;
    selectedCountryCodes: any;
    setSelectedCountryCodes: any;
    setApplyCountry: any;
    filterGifts: any;
    candidate: any;
}
//if max and minPrice changes and doesn't match the selected range, set selected range to custom
const predefinedRanges = {
    "0-25": [0, 25],
    "25-50": [25, 50],
    "50-75": [50, 75],
    "75-100": [75, 100],
    "100-200": [100, 200],
};

export default function FilterMenu({
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    clearFilter,
    selectedRange,
    handleRangeChange,
    selectedCountryCodes,
    setSelectedCountryCodes,
    setApplyCountry,
    filterGifts,
    candidate,
}: IFilterMenuProps) {
    const [focused, setFocused] = useState<string>("");
    useEffect(() => {
        const matchedRange = Object.entries(predefinedRanges).find(([key, range]) => {
            const [min, max] = range;
            return parseInt(minPrice) === min && parseInt(maxPrice) === max;
        });

        if (matchedRange) {
            handleRangeChange({ target: { value: matchedRange[0] } });
        } else if (selectedRange !== "custom" && selectedRange !== "") {
            handleRangeChange({ target: { value: "custom" } });
        }
    }, [minPrice, maxPrice]);

    return (
        <div>
            <Stack direction="column" style={{ flexWrap: "wrap", paddingLeft: "30px", paddingRight: "30px" }}>
                <Stack
                    style={{ width: 200, height: 320, margin: "20px", borderRadius: "sm", backgroundColor: "white" }}
                >
                    <div>
                        <div>
                            <Typography variant="h6" gutterBottom>
                                Price range
                            </Typography>
                            <div className="mb-2">
                                <div className="relative mr-2">
                                    <label
                                        htmlFor="minPrice"
                                        className={`bg-white pr-2 absolute transition-all ${
                                            focused === "min" || minPrice !== ""
                                                ? "text-[#0891B2] top-[-0.5rem] text-sm"
                                                : "top-2 left-2 text-gray-400"
                                        }`}
                                    >
                                        Minimum price ($)
                                    </label>
                                    <div className="flex items-center border rounded px-3 py-2 focus-within:border-[#0891B2] mt-3">
                                        <input
                                            id="minPrice"
                                            type="text"
                                            value={minPrice}
                                            onChange={(e) => setMinPrice(e.target.value)}
                                            className="flex-1 focus:outline-none ml-2"
                                            onFocus={() => setFocused("min")}
                                            onBlur={() => setFocused("")}
                                            style={{ fontSize: "14px", backgroundColor: "white" }}
                                        />
                                    </div>
                                </div>

                                <Typography variant="caption" className="ml-2 text-[darkgrey]">
                                    to
                                </Typography>

                                {/* Maximum Price TextField */}
                                {/* <TextField
                                        label="Maximum price"
                                        variant="outlined"
                                        value={maxPrice}
                                        onChange={(e) => setMaxPrice(e.target.value)}
                                        size="small"
                                        sx={{
                                            mr: 2,
                                            mt: 2,
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#0891B2", // Border color when focused
                                                },
                                            },

                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: "#0891B2", // Label color when focused
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: focused === "max" || maxPrice !== "",
                                            sx: {
                                                ml: focused === "max" || maxPrice !== "" ? 0 : 2,
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        onFocus={() => setFocused("max")}
                                        onBlur={() => setFocused("")}
                                    /> */}
                                <div className="relative mt-2 mr-2">
                                    <label
                                        htmlFor="maxPrice"
                                        className={`bg-white pr-2 absolute transition-all ${
                                            focused === "max" || maxPrice !== ""
                                                ? "text-[#0891B2] top-[-0.5rem] text-sm"
                                                : "top-2 left-2 text-gray-400"
                                        }`}
                                    >
                                        Maximum price ($)
                                    </label>
                                    <div className="flex items-center border rounded px-3 py-2 focus-within:border-[#0891B2]">
                                        <input
                                            id="maxPrice"
                                            type="text"
                                            value={maxPrice}
                                            onChange={(e) => setMaxPrice(e.target.value)}
                                            className="flex-1 focus:outline-none ml-2"
                                            onFocus={() => setFocused("max")}
                                            onBlur={() => setFocused("")}
                                            style={{ fontSize: "14px", backgroundColor: "white" }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <RadioGroup
                                value={selectedRange}
                                onChange={handleRangeChange}
                                sx={{
                                    "& .MuiRadio-root.Mui-checked": { color: "#0891B2" },
                                }}
                            >
                                {Object.entries(predefinedRanges).map(([key, range]) => {
                                    const [min, max] = range;
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            value={key}
                                            control={<Radio />}
                                            label={`$${min} to $${max}`}
                                        />
                                    );
                                })}
                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                        </div>
                        <Autocomplete
                            multiple
                            sx={{ mt: 2 }}
                            id="tags-filled"
                            options={CountryCodes}
                            getOptionLabel={(option) => option.name}
                            defaultValue={[]}
                            size="small"
                            value={selectedCountryCodes}
                            onChange={(event, newValue) => {
                                setSelectedCountryCodes(newValue);
                                setApplyCountry((prevApplyCountry) => ({
                                    ...prevApplyCountry,
                                    [candidate?.candidateId]: [...newValue.map((country) => country.code)],
                                }));
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return <Chip children={option.name} key={key} {...tagProps} />;
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Country"
                                    sx={{
                                        "& .MuiInputBase-input": { color: "#0891B2" },
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#0891B2",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#0891B2",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#0891B2",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#0891B2",
                                        },
                                    }}
                                />
                            )}
                        />

                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Button
                                onClick={() => {
                                    filterGifts();
                                }}
                                style={{
                                    height: "36px",
                                    backgroundColor: "#0891B2",
                                    color: "white",
                                }}
                            >
                                Filter
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilter();
                                }}
                                variant="outline"
                                style={{ height: "36px", marginLeft: "5px" }}
                            >
                                Clear
                            </Button>
                        </div>
                    </div>
                </Stack>
            </Stack>
        </div>
    );
}
