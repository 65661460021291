// import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectKeywords, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

import { Autocomplete } from "@/ui/Autocomplete/Autocomplete";

interface KeywaordsProps {
    multiple?: boolean;
    id: SearchStateKeys;
}

export function Keywords({ id, multiple = true }: KeywaordsProps) {
    const { isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectKeywords);

    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value: value,
            })
        );
    };

    return (
        <Autocomplete
            placeholder="Keywords"
            multiple={multiple}
            freeSolo
            loading={isLoading}
            value={values.map((o) => ({ ...o, label: o.value }))}
            // options={Object.keys(options).map((i) => ({ value: i, label: i }))}
            onChange={(option) => handleChange(option.map((o) => ({ value: o.value, excluded: !!o.excluded })))}
            error={error}
        />
    );

    // return (
    //     <Autocomplete
    //         multiple={multiple}
    //         freeSolo
    //         size="small"
    //         ChipProps={{ color: "success" }}
    //         value={multiple ? values.map(({ value }) => value) : values[0]?.value || ""}
    //         options={Object.keys(options).map((i) => i)}
    //         loading={isLoading}
    //         onChange={(_, value) => {
    //             handleChange(value);
    //         }}
    //         onFocus={() => setIsFocused(true)}
    //         onBlur={() => {
    //             setIsFocused(false);
    //             dispatchEnterKeyboardEvent(ref.current);
    //         }}
    //         renderInput={(params) => (
    //             <Tooltip
    //                 title={multiple ? "Press 'enter' after each keyword" : "Use 'AND' or 'OR' between keywords"}
    //                 open={isFocused}
    //             >
    //                 <TextField
    //                     {...params}
    //                     InputLabelProps={{ sx: { fontSize: 14 } }}
    //                     ref={ref}
    //                     label="Keywords"
    //                     error={Boolean(error)}
    //                     helperText={error}
    //                     InputProps={{
    //                         ...params.InputProps,
    //                         endAdornment: (
    //                             <Fragment>
    //                                 {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
    //                                 {params.InputProps.endAdornment}
    //                             </Fragment>
    //                         ),
    //                         sx: { fontSize: 14 },
    //                     }}
    //                 />
    //             </Tooltip>
    //         )}
    //     />
    // );
}
