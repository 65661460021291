import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";

import { cn } from "@/lib/utils";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List ref={ref} className={cn("flex w-full border-b border-gray-200", className)} {...props} />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            "inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-transparent border-b-2 border-transparent hover:text-primary",
            "transition-all duration-200 ease-in-out",
            "data-[state=active]:text-primary data-[state=active]:border-primary",
            className
        )}
        {...props}
    />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content ref={ref} className={cn("mt-4 focus:outline-none", className)} {...props} />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export interface TabsProps extends React.ComponentProps<typeof Tabs> {
    tabs: (Omit<React.HTMLAttributes<HTMLButtonElement>, "content"> & {
        label: string;
        content: React.ReactNode;
        formatLabel?: (label: string) => React.ReactNode;
    })[];
    orientation?: "horizontal" | "vertical";
    centered?: boolean;
    contentStyle?: string | React.CSSProperties;
}

/**
 * TabsComponent is a React functional component that renders a tabbed interface.
 * 
 * @param {TabsProps} props - The properties for the TabsComponent.
 * @param {Array} props.tabs - An array of tab objects, each containing a label, optional formatLabel function, content, and optional className.
 * @param {string} props.orientation - The orientation of the tabs, either "vertical" or "horizontal".
 * @param {string} [props.className] - Additional class names to apply to the Tabs component.
 * @param {boolean} [props.centered] - If true, centers the tab labels.
 * @param {string | object} [props.contentStyle] - Additional styles to apply to the content area, either as a string or an object.
 * @param {object} [props.props] - Additional props to pass to the Tabs component.
 * 
 * @returns {JSX.Element} The rendered Tabs component.
 */
const TabsComponent: React.FC<TabsProps> = ({ tabs, orientation, className, centered, contentStyle, ...props }) => {
    const isVertical = orientation === "vertical";

    return (
        <Tabs
            defaultValue={tabs[0].label}
            className={cn(
                "flex",
                {
                    "flex-col": !isVertical,
                    "flex-row": isVertical,
                },
                className
            )}
            {...props}
        >
            <TabsList
                className={cn("flex", {
                    "flex-col": isVertical,
                    "flex-row": !isVertical,
                    "w-1/4": isVertical, // Adjust width as needed
                    "border-r border-b-0": isVertical,
                    "justify-center": centered,
                })}
            >
                {tabs.map(({ label, formatLabel = (label) => label, content: _, className, ...props }) => (
                    <TabsTrigger
                        key={label}
                        value={label}
                        className={cn(
                            {
                                "border-r-2 border-b-0": isVertical,
                                "w-full text-center": isVertical,
                            },
                            className
                        )}
                        {...props}
                    >
                        {formatLabel(label)}
                    </TabsTrigger>
                ))}
            </TabsList>
            <div
                className={cn(
                    {
                        "w-3/4": isVertical, // Adjust width as needed
                        "w-full": !isVertical,
                    },
                    typeof contentStyle === "string" ? contentStyle : ""
                )}
                style={typeof contentStyle === "object" ? contentStyle : {}}
            >
                {tabs.map((tab) => (
                    <TabsContent key={tab.label} value={tab.label} className="m-0">
                        {tab.content}
                    </TabsContent>
                ))}
            </div>
        </Tabs>
    );
};




export { Tabs, TabsList, TabsTrigger, TabsContent, TabsComponent };
