import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetLeaderBoardDataQuery, useSetUserTargetMutation } from "@/store/apis/analytics/analytics.api";
import { TLeaderBoardFilter } from "@/store/apis/analytics/analytics.types";

function Targets({ value = 0, userId }: { value: number; userId: number }) {
    const [target, setTarget] = useState(value);
    const [isEditing, setIsEditing] = useState(false);
    const [setUserTarget, { isLoading: isSubmitting }] = useSetUserTargetMutation();

    if (isEditing) {
        return (
            <input
                disabled={isSubmitting}
                autoFocus
                className="w-full px-2 bg-transparent"
                type="number"
                value={target}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setTarget(Number(e.target.value))}
                onBlur={() => {
                    setIsEditing(false);
                    if (target !== value) {
                        setUserTarget({ userId, target });
                    }
                }}
            />
        );
    }

    return (
        <div title="Double click to set target" onDoubleClick={() => setIsEditing(true)} className="w-full">
            {target}
        </div>
    );
}

function LeaderBoardTable({ filters }: { filters: TLeaderBoardFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetLeaderBoardDataQuery(
        { channel: "all" },
        {
            skip: !isMounted,
        }
    );

    const filteredData =
        filters.channel !== "all"
            ? [...data]?.sort((a, b) => {
                  if (a.channel === filters.channel) return -1;
                  if (b.channel === filters.channel) return 1;
              })
            : data;

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "userName", //access nested data with dot notation
                header: `User name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "channel",
                header: "Channel",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "demosBooked",
                header: "Demos booked",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: "Positive Responses",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "Target.daily",
                header: "Targets",
                // size: 100,
                // Header: HeaderText,
                Cell({ row: { original } }) {
                    return <Targets value={original.Target.daily} userId={original.userId} />;
                },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"Calling matrix"}
            columns={columns}
            data={filteredData}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default LeaderBoardTable;
