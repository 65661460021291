import { Typography } from "@/ui"
function LinkedInWorkflowsTab({ ratingOfLinkedin, linkedInApplicantsQuestionnaire }) {
    return (
        <div className="p-[1rem]">
            <Typography variant="h6" className="mb-[1rem]">
                LinkedIn Applicants Details
            </Typography>
            <div className="mb-[1rem]">
                <Typography variant="subtitle1" color="textSecondary">
                    Selected Rating:
                </Typography>
                <Typography variant="body1">{ratingOfLinkedin || "UNRATED"}</Typography>
            </div>
            <div className="border-b mb-6"></div>
            <div>
                <Typography variant="subtitle1" className="mb-[1rem]">
                    Questionnaire Responses
                </Typography>
                {linkedInApplicantsQuestionnaire.map((qa, index) => (
                    <div key={index} className="mb-[1rem]">
                        <Typography variant="body2" color="textSecondary">
                            {qa.question}
                        </Typography>
                        <Typography variant="body1" className="font-bold">
                            {qa.answer}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default LinkedInWorkflowsTab;
