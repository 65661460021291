import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { useSelector } from "react-redux";

import WorkflowPane from "./components/workflows/WorkflowPane";
import LinkedinWorkflowContextProvider from "./Context";

import { ErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import Navbar from "../../components/Navbar/Navbar";

import { checkLinkedinOutreachEnabled, isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";

export default function LinkedinWorkflow() {
    const linkedinOutreachEnabled = useSelector(checkLinkedinOutreachEnabled);
    const isSuperAdmin = useSelector(isSuperAdminUser);

    if (!linkedinOutreachEnabled && !isSuperAdmin) {
        return null;
    }

    return (
        <>
            <ErrorBoundary>
                <LinkedinWorkflowContextProvider>
                    <div className="hidden lg:block">
                        <Navbar />
                    </div>
                    <CssVarsProvider disableTransitionOnChange>
                        <CssBaseline />
                        <div className="flex h-[90%] overflow-y-hidden">
                            <main className="w-full h-full flex">
                                <WorkflowPane />
                            </main>
                        </div>
                    </CssVarsProvider>
                </LinkedinWorkflowContextProvider>
            </ErrorBoundary>
        </>
    );
}
