import * as Sentry from "@sentry/react";

function initializeSentry() {
    Sentry.init({
        dsn: import.meta.env.VITE_REACT_APP_SENTRY_KEY,
        // @ts-ignore
        integrations: [new Sentry.BrowserTracing({ enableHTTPTimings: true }), new Sentry.Replay()],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event) {
            if (event.exception) {
                const exceptionType = event.exception.values?.[0]?.type || "";
                const firebaseExceptionPattern = /FirebaseError/gi;

                if (firebaseExceptionPattern.test(exceptionType)) {
                    return null;
                }
            }
            return event;
        },
        ignoreErrors: [
            "Object captured as exception",
            "Firebase: The email address is already in use by another account. (auth/email-already-in-use)",
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications.",
            "Network Error",
            "Request failed with status code 500",
            "Cannot read properties of undefined (reading 'src')",
            "Cannot read properties of undefined (reading 'cancelToken')",
            "Firebase: A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
            "Firebase: The password is invalid or the user does not have a password",
            "Firebase: The popup has been closed by the user before finalizing the operation",
            "Firebase: This operation has been cancelled due to another conflicting popup being opened",
            "The popup has been closed by the user before finalizing the operation",
            "Unable to establish a connection with the popup",
            "TypeError: window.opener is null",
            "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)",
            "Firebase: Unable to establish a connection with the popup. It may have been blocked by the browser. (auth/popup-blocked)",
            "Firebase: This operation has been cancelled due to another conflicting popup being opened. (auth/cancelled-popup-request)",
            "Firebase: The popup has been closed by the user before finalizing the operation. (auth/popup-closed-by-user)",
            "Firebase: Error (auth/internal-error)",
            "Please keep 4 companies or fewer before finding similar companies",
            "Firebase: The project's quota for this operation has been exceeded. (auth/quota-exceeded)",
            "Oops! Similar companies could not be fetched at this moment",
            "Error in input stream",
            "No error message",
            "error while fetching all contacts",
            "error while fetching names",
            "Firebase: The popup has been closed by the user before finalizing the operation. (auth/popup-closed-by-user).",
            "Firebase: Password should be at least 6 characters (auth/weak-password).",
            "null",
            "No user logged in",
            "Non-Error promise rejection captured with value: undefined",
        ],
    });
}

/**
 * Starts a custom Sentry transaction for tracking.
 * @returns {Sentry.Transaction | undefined} The created transaction or undefined if not started.
 */
function startTracking(name: string, op?: string) {
    const transaction = Sentry.startTransaction({
        name,
        op, // Optional: operation name, helps categorize the transaction
    });

    // Set the transaction on the current scope
    Sentry.configureScope((scope) => {
        scope.setSpan(transaction);
    });

    return transaction;
}

/**
 * Ends a custom Sentry transaction.
 * @param {Sentry.Transaction | undefined} transaction - The transaction to finish.
 */
function endTracking(transaction: Sentry.Transaction | undefined) {
    if (transaction) {
        transaction.finish(); // Finish the transaction and send it to Sentry
    }
}

function handleException(err: any) {
    Sentry.captureException(err);
}

export default handleException;
export { startTracking, endTracking, initializeSentry };
