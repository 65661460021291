import React, { useEffect, useState } from "react";
import CallMadeOutlined from "@mui/icons-material/CallMadeOutlined";
import TtyOutlined from "@mui/icons-material/Tty";
import { Tooltip  , Modal , Typography} from "@/ui"
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { getTwilloCallLogs, selectTwilloCalls } from "@/store/reducers/project/project.reducer";
import Loader from "@/components/Loader/Loader";

const TwilioLogs: React.FC<any> = ({ candidate }) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const twilloCalls = useSelector(selectTwilloCalls);

    const loading = useSelector(checkIfLoading(getTwilloCallLogs.type));

    useEffect(() => {
        if (modalOpen) {
            dispatch(getTwilloCallLogs({ page: pageNumber, limit: pageSize, filter: { candidateId: candidate._id } }));
        }
    }, [pageNumber, pageSize, candidate._id, modalOpen]);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <CallLogsModal logs={twilloCalls} toggleModal={setModalOpen} modalOpen={modalOpen} loading={loading} />
            <div style={{ width: "32px", alignContent: "center", borderRadius: "50%" }}>
               
                    <Tooltip title="Call logs" placement="bottom" size="sm">
                        <button
                            className="p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => setModalOpen(true)}
                            disabled={modalOpen}>
                            <TtyOutlined className="w-5 h-5" />
                        </button>

                    </Tooltip>
            
            </div>
        </div>
    );
};

export default TwilioLogs;

const CallLogsModal = ({ logs, toggleModal, modalOpen, loading }) => {
    return (
        <Modal open={modalOpen} onClose={() => toggleModal(false)} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-[400px] overflow-auto h-[40vh] bg-white shadow-2xl p-4 rounded-md">
            {loading ? (
                <div
                   
                >
                    <div className="mt-9">
                        <Loader size="sm"/>
                    </div>
                </div>
            ) : (
                <div className="p-4">
                    <Typography id="call-list-modal" variant="h6" component="h2" gutterBottom>
                        Call Logs
                    </Typography>
                    {logs?.callLogs?.length === 0 && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            No call logs.
                        </Typography>
                    )}

                    {logs?.callLogs?.map((item, index) => (
                        <div key={index}>
                            {item?.direction === "OUTGOING" ? (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <CallMadeOutlined
                                                fontSize="small"
                                                sx={{ position: "relative", top: "0.26em" }}
                                            />{" "}
                                            <span style={{ marginLeft: "0.5em", color: "black" }}>{item?.to}</span>
                                        </Typography>

                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {item?.statuses?.[item?.statuses?.length - 2]?.status === "ringing"
                                                ? "completed"
                                                : item?.statuses?.[item?.statuses?.length - 2]?.status || "completed"}
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                              className="text-[0.7rem]"
                                        >
                                            {dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                                        </Typography>
                                    </div>
                                    <hr className="border-t border-gray-300 my-4" />
                                </>
                            ) : (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            <CallMadeOutlined
                                                fontSize="small"
                                                sx={{
                                                    position: "relative",
                                                    top: "0.26em",
                                                    transform: "rotate(180deg)",
                                                }}
                                            />{" "}
                                            <span style={{ marginLeft: "0.5em", color: "black" }}>
                                                {item?.from?.split(":")?.[1] ? item?.from?.split(":")?.[1] : item?.from}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {item?.statuses?.[item?.statuses?.length - 2]?.status === "ringing"
                                                ? "completed"
                                                : item?.statuses?.[item?.statuses?.length - 2]?.status || "cancelled"}
                                        </Typography>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                            className="text-[0.7rem]"
                                        >
                                            {dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                                        </Typography>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </Modal>
    );
};
