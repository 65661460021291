import { SxProps, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

type ButtonTextWithLoadingProps = {
    isLoading: boolean;
    text: string | React.ReactNode;
    progressSize?: number;
    progressStyle?: SxProps<Theme>;
    variant?: 'light' | 'dark';
};

export const ButtonTextWithLoading = ({
    isLoading,
    text,
    progressSize = 20,
    progressStyle = {},
    variant = 'dark'
}: ButtonTextWithLoadingProps) => {
    if (isLoading) {
        return (
            <CircularProgress
                size={progressSize}
                sx={{
                    margin: "0.1rem",
                    marginTop: "0.35rem",
                    color: variant === 'light' ? '#fff' : '#0891B2',
                    ...progressStyle
                }}
            />
        );
    }

    return <>{text}</>;
};
