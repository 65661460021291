import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Filters.module.css";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import { getAiCompany, setShowAICompanyPopup } from "@/store/reducers/filters/Filters.reducer";

const AiCompanyDialog = () => {
    const dispatch = useDispatch();
    const isSearchLoading = useSelector(checkIfLoading(getAiCompany.type));
    const [error, setError] = useState({
        intent: "",
    });
    const [intent, setIntent] = useState("");

    const onProceed = () => {
        const newError: any = {};
        if (!intent) newError.intent = "Should be valid intent";

        setError(newError);

        if (isEmpty(newError)) {
            dispatch(getAiCompany(intent));
        }
    };

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(setShowAICompanyPopup(false))}
            classes={{
                paper: styles.popupContainer,
            }}
        >
            <DialogTitle>Get Company with GPT</DialogTitle>
            <DialogContent>
                <Stack className={styles.fieldContainer}>
                    <TextField
                        placeholder="Intent"
                        value={intent}
                        fullWidth
                        onChange={(e) => setIntent(e.target.value)}
                        rows={4}
                        multiline
                    />
                    <Typography className={styles.error} variant="body2">
                        {error.intent}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => dispatch(setShowAICompanyPopup(false))}>
                    Close
                </Button>
                <Button variant="contained" onClick={onProceed} disabled={isSearchLoading}>
                    <ButtonTextWithLoading isLoading={isSearchLoading} text="Proceed" variant="light" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AiCompanyDialog;
