import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import dayjs from "dayjs";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AlertToggle from "./components/AlertToggle";
import SplitChart from "./components/SplitChart";

import CustomDropdown, { CustomDropdownOptionsType } from "../../../../components/CustomDropdown/CustomDropdown";
import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { formatDate } from "../../../../utils/helper";

import Loader from "@/components/Loader/Loader";
import DateRangePicker, { TDate } from "@/molecules/DateRangePicker/DateRangePicker";
import {
    fetchEmailCredit,
    fetchEmailEnrichmentCredit,
    fetchLinkedinOutreachCredit,
    fetchOrganizationDetails,
    fetchPhoneEnrichmentCredit,
    fetchProfileCreditInfo,
    fetchSmsOutreachCredit,
    selectEmailCredit,
    selectEmailEnrichmentCredit,
    selectLinkedinOutreachCredit,
    selectOrganizationDetails,
    selectPhoneEnrichmentCredit,
    selectProfileCreditInfo,
    selectSmsOutreachCredit,
} from "@/store/reducers/my-account/myAccount.reducer";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Skeleton, Switch, Tooltip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

const getUserAdminCount = (members: any[]) => {
    const { noOfAdmins, noOfUsers } = members.reduce(
        (acc, member) => {
            if (member.role === "ADMIN") {
                acc.noOfAdmins += 1;
            } else {
                acc.noOfUsers += 1;
            }
            return acc;
        },
        { noOfAdmins: 0, noOfUsers: 0 }
    );

    return { noOfAdmins, noOfUsers };
};

const checkUnlimited = (value: number) => {
    if (!value) return "NA";
    return value === -1 ? "Unlimited" : value;
};

const Header = ({ organizationDetails }: any) => {
    const { licenseCount, projectCreditCount, profileCreditCount, contactDataCreditCount, isContractActive } =
        organizationDetails?.contractDetails || {};

    const { noOfAdmins, noOfUsers } = getUserAdminCount(organizationDetails?.members || []);

    return (
        <Stack
            px={3}
            py={4}
            style={{
                borderRadius: "0.5rem",
                marginBottom: "1rem",
                marginTop: "2rem",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
        >
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant="h6" className="font-semibold">
                    Subscription plan
                </Typography>
                <Stack gap={0.75} direction="row" alignItems={"center"}>
                    {isContractActive ? (
                        <CheckCircleIcon sx={{ fontSize: "1.25rem", color: "#1BB267" }} />
                    ) : (
                        <CancelIcon sx={{ fontSize: "1.25rem", color: "#FF4D4F" }} />
                    )}

                    <Typography className="text-sm">{isContractActive ? "Active" : "Inactive"}</Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} style={{ paddingTop: 1 }} justifyContent={"space-between"}>
                <Stack style={{ paddingBottom: "2rem", paddingTop: "1rem", marginLeft: "20px" }}>
                    <Stack direction={"row"} alignItems={"start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <LocalPoliceIcon sx={{ fontSize: "1.25rem", color: "#1BB267" }} />
                        </span>
                        <div>
                            <Typography className="text-normal text-lg">
                                {checkUnlimited(licenseCount)} Licenses
                            </Typography>
                            <Typography className="text-xs">{noOfUsers} Users</Typography>
                            <Typography className="text-xs">{noOfAdmins} Admins</Typography>
                        </div>
                    </Stack>
                </Stack>
                <div style={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />

                <Stack style={{ paddingBottom: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <AllInclusiveIcon sx={{ fontSize: "1.25rem", color: "#CA2B79" }} />
                        </span>
                        <div>
                            <Typography className="text-normal text-lg">
                                {checkUnlimited(projectCreditCount)}
                            </Typography>
                            <Typography className="text-xs">projects</Typography>
                        </div>
                    </Stack>
                </Stack>
                <div style={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />
                <Stack style={{ paddingBottom: "2rem", paddingTop: "1rem" }}>
                    <Stack direction={"row"} alignItems={"start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <GroupIcon sx={{ fontSize: "1.25rem", color: "#FF9752" }} />
                        </span>
                        <div>
                            <Typography className="text-normal text-lg">
                                {checkUnlimited(profileCreditCount)}
                            </Typography>
                            <Typography className="text-xs">profiles credits</Typography>
                        </div>
                    </Stack>
                </Stack>
                <div style={{ border: "0.5px solid #E0E1E3", alignSelf: "center", height: "80px" }} />
                <Stack style={{ paddingBottom: "2rem", paddingTop: "1rem", marginRight: "75px" }}>
                    <Stack direction={"row"} alignItems={"start"} gap={0.75}>
                        <span style={{ paddingTop: "0.25rem" }}>
                            <ContactsIcon sx={{ fontSize: "1.25rem", color: "#136EF6" }} />
                        </span>
                        <div>
                            <Typography className="text-normal text-lg">
                                {checkUnlimited(contactDataCreditCount)}
                            </Typography>
                            <Typography className="text-xs">contact data credits</Typography>
                        </div>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
        <Stack
            p={3}
            style={{
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                borderRadius: "0.5rem",
                marginTop: "1rem",
                marginBottom: "1rem",
            }}
        >
            {children}
        </Stack>
    );
};

const selectorDispatcherMapping = {
    PROFILE_CREDIT: {
        label: "Profile credits",
        selector: selectProfileCreditInfo,
        dispatcher: fetchProfileCreditInfo,
    },
    LINKEDIN_OUTREACH_CREDIT: {
        label: "Linkedin outreach",
        selector: selectLinkedinOutreachCredit,
        dispatcher: fetchLinkedinOutreachCredit,
    },
    SMS_OUTREACH_CREDIT: {
        label: "SMS outreach",
        selector: selectSmsOutreachCredit,
        dispatcher: fetchSmsOutreachCredit,
    },
    EMAIL_CREDIT: {
        label: "Email outreach delivery",
        selector: selectEmailCredit,
        dispatcher: fetchEmailCredit,
    },
    EMAIL_ENRICHMENT_CREDIT: {
        label: "Email enrichment credits",
        selector: selectEmailEnrichmentCredit,
        dispatcher: fetchEmailEnrichmentCredit,
    },
    PHONE_ENRICHMENT_CREDIT: {
        label: "Phone enrichment credits",
        selector: selectPhoneEnrichmentCredit,
        dispatcher: fetchPhoneEnrichmentCredit,
    },
};

const CreditsCardWithSelector = ({ item }: any) => {
    const { type, maxC, statsType, selectedView, handleViewChange, alertStatus } = item;
    const data: any[] = useSelector(selectorDispatcherMapping[type].selector) || [];
    const isLoading = useSelector(checkIfLoading(selectorDispatcherMapping[type].dispatcher.type));

    const [expandedView, setExpandedView] = useState(true);

    const options: CustomDropdownOptionsType[] = [
        {
            label: "Org Level",
            key: "ORG",
            handleClick: () => {
                handleViewChange(type, "ORG");
            },
        },
        {
            label: "Project Level",
            key: "PROJECT",
            handleClick: () => {
                handleViewChange(type, "PROJECT");
            },
        },
        {
            label: "User Level",
            key: "USER",
            handleClick: () => {
                handleViewChange(type, "USER");
            },
        },
        ...(type === "EMAIL_ENRICHMENT_CREDIT"
            ? [
                  {
                      label: "Email Type",
                      key: "EMAIL_TYPE",
                      handleClick: () => {
                          handleViewChange(type, "EMAIL_TYPE");
                      },
                  },
              ]
            : []),
    ];

    return (
        <Stack
            sx={{
                transition: "all 0.2s",
            }}
        >
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Stack alignItems={"center"} gap={0.5} direction={"row"}>
                    <Typography className="text-sm">{selectorDispatcherMapping[type].label}</Typography>
                    <Tooltip title={item?.tooltipTitle}>
                        <HelpOutlineIcon sx={{ fontSize: "1rem" }} />
                    </Tooltip>
                </Stack>

                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
                    {statsType === "current" && (
                        <AlertToggle alertStatus={alertStatus} alertFor={type} disabled={maxC === -1} />
                    )}
                    <MaterialProvider>
                        <CustomDropdown options={options} tooltipTitle="Change view">
                            <Typography className="text-sm text-[14px] border border-gray-500 px-2.5 py-1 rounded capitalize">
                                {selectedView.split("_").join(" ").toLowerCase()}
                            </Typography>
                        </CustomDropdown>
                    </MaterialProvider>
                    <button onClick={() => setExpandedView(!expandedView)}>
                        {!expandedView ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </button>
                </Stack>
            </Stack>
            {expandedView && (
                <>
                    {isLoading && (
                        <Stack style={{ marginTop: 2 }}>
                            <Skeleton className="h-[20px] w-[full] mb-1" />
                            <Stack justifyContent={"space-between"} direction={"row"} style={{ marginTop: 2 }}>
                                <Skeleton className="h-[20px] w-[80px]" />
                                <Skeleton className="h-[20px] w-[80px]" />
                            </Stack>
                        </Stack>
                    )}

                    {!isLoading && data && data?.length === 0 && (
                        <Stack style={{ marginTop: 2 }}>
                            <Typography className="text-sm">No data available</Typography>
                        </Stack>
                    )}

                    {!isLoading && data && data?.length > 0 && (
                        <>
                            {selectedView === "ORG" && (
                                <Stack style={{ marginTop: 2 }}>
                                    <SplitChart data={data} totalCredits={maxC} isLoading={isLoading} />
                                </Stack>
                            )}

                            {selectedView === "USER" && (
                                <Stack style={{ marginTop: 2 }}>
                                    <SplitChart
                                        data={data}
                                        totalCredits={maxC}
                                        isLoading={isLoading}
                                        onlyLabelForMax
                                        showEmptySegments
                                    />
                                </Stack>
                            )}

                            {selectedView === "PROJECT" ? (
                                <Stack style={{ marginTop: 2 }}>
                                    <SplitChart data={data} totalCredits={maxC} isLoading={isLoading} onlyLabelForMax />
                                </Stack>
                            ) : null}

                            {selectedView === "EMAIL_TYPE" && (
                                <Stack style={{ marginTop: 2 }}>
                                    <SplitChart data={data} isLoading={isLoading} />
                                </Stack>
                            )}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};

const CreditPlans = () => {
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const [statsType, setStatsType] = useState<"current" | "lifetime">("current");
    const [date, setDate] = useState<TDate[]>([null, null]);
    const startingDate = date[0] ? dayjs(date[0]).format("YYYY-MM-DD") : null;
    const endingDate = date[1] ? dayjs(date[1]).format("YYYY-MM-DD") : null;

    const isOrgLoading = useSelector(checkIfLoading(fetchOrganizationDetails.type));

    const [selectedViews, setSelectedViews] = useState<{ [key: string]: string }>({
        PROFILE_CREDIT: "ORG",
        LINKEDIN_OUTREACH_CREDIT: "ORG",
        SMS_OUTREACH_CREDIT: "ORG",
        EMAIL_CREDIT: "ORG",
        EMAIL_ENRICHMENT_CREDIT: "ORG",
        PHONE_ENRICHMENT_CREDIT: "ORG",
    });

    // Organaization details
    const organizationDetails = useSelector(selectOrganizationDetails);
    const {
        cycleStartDate,
        cycleEndDate,
        cycleType,
        profileCreditCount,
        linkedinOutreachCreditCount,
        smsOutreachCreditCount,
        emailOutreachCreditCount,
        emailEnrichmentCreditCount,
        phoneEnrichmentCreditCount,
        notificationPreferences,
    } = organizationDetails?.contractDetails || {};

    useEffect(() => {
        dispatch(
            fetchOrganizationDetails({
                action: fetchOrganizationDetails.type,
                ...(statsType === "lifetime" && {
                    queryParams: {
                        isLifetimeStats: true,
                    },
                }),
            })
        );
    }, [statsType]);

    useEffect(() => {
        if (cycleStartDate && cycleEndDate) {
            Object.keys(selectedViews).forEach((type) => {
                dispatchCreditData(type, selectedViews[type], statsType);
            });
        }
    }, [statsType, cycleStartDate, cycleEndDate]);

    useEffect(() => {
        if (startingDate && endingDate) {
            Object.keys(selectedViews).forEach((type) => {
                dispatchCreditDataCustomDate(type, selectedViews[type]);
            });
        }
    }, [endingDate]);

    const handleStatsTypeChange = () => {
        setStatsType(statsType !== "lifetime" ? "lifetime" : "current");
    };

    const handleViewChange = (type: string, view: string) => {
        setSelectedViews((prev) => ({ ...prev, [type]: view }));
        dispatchCreditData(type, view, statsType);
    };

    const dispatchCreditData = (type, view, statsType) => {
        dispatch(
            selectorDispatcherMapping[type].dispatcher({
                action: selectorDispatcherMapping[type].dispatcher.type,
                creditPayload: {
                    type: view,
                    endDate: cycleEndDate,
                    ...(statsType !== "lifetime" && { startDate: cycleStartDate }),
                },
            })
        );
    };

    const dispatchCreditDataCustomDate = (type, view) => {
        if (startingDate && endingDate) {
            dispatch(
                selectorDispatcherMapping[type].dispatcher({
                    action: selectorDispatcherMapping[type].dispatcher.type,
                    creditPayload: {
                        type: view,
                        endDate: endingDate,
                        startDate: startingDate,
                    },
                })
            );
        }
    };

    const items = [
        {
            type: "PROFILE_CREDIT",
            tooltipTitle: "Profile credits are used for fetching profiles from various sources",
            maxC: profileCreditCount || 5000,
            alertStatus: notificationPreferences?.PROFILE_CREDIT || false,
        },
        {
            type: "LINKEDIN_OUTREACH_CREDIT",
            tooltipTitle: "Linkedin outreach credits are used for sending messages to linkedin profiles",
            maxC: linkedinOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.LINKEDIN_OUTREACH_CREDIT || false,
        },
        {
            type: "SMS_OUTREACH_CREDIT",
            tooltipTitle: "SMS outreach credits are used for sending messages to phone numbers",
            maxC: smsOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.SMS_OUTREACH_CREDIT || false,
        },
        {
            type: "EMAIL_CREDIT",
            tooltipTitle: "Email outreach credits are used for sending emails to email addresses",
            maxC: emailOutreachCreditCount || 5000,
            alertStatus: notificationPreferences?.EMAIL_CREDIT || false,
        },
        {
            type: "EMAIL_ENRICHMENT_CREDIT",
            tooltipTitle: "Email enrichment credits are used for enriching email addresses",
            maxC: emailEnrichmentCreditCount || 5000,
            alertStatus: notificationPreferences?.EMAIL_ENRICHMENT_CREDIT || false,
        },
        {
            type: "PHONE_ENRICHMENT_CREDIT",
            tooltipTitle: "Phone enrichment credits are used for enriching phone numbers",
            maxC: phoneEnrichmentCreditCount || 5000,
            alertStatus: notificationPreferences?.PHONE_ENRICHMENT_CREDIT || false,
        },
    ];

    return (
        <JoyProvider>
            {isOrgLoading && !organizationDetails?.contractDetails && (
                <div
                    style={{
                        height: "80vh",
                    }}
                >
                    <Loader />
                </div>
            )}
            {organizationDetails?.contractDetails && (
                <Stack direction={"column"} gap={2}>
                    <Stack
                        sx={{
                            height: "calc(100vh - 150px)",
                            width: "75%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            overflowY: "scroll",
                            paddingRight: "20px",
                            paddingLeft: "15px",
                        }}
                    >
                        <Header organizationDetails={organizationDetails} />
                        <Stack px={3} py={4} direction="row" alignItems={"start"} justifyContent={"space-between"}>
                            <div>
                                <Typography className="pb-[0.25rem] text-semibold" variant="h6">
                                    {statsType === "current" ? "Current usage cycle" : "Lifetime usage stats"}
                                </Typography>
                                <Typography className="text-sm">{cycleType ?? "NA"} credit cycle</Typography>
                                <Typography className="text-sm">
                                    Resets on <b>{cycleEndDate ? formatDate(cycleEndDate, "D MMMM YYYY") : "NA"} </b>
                                </Typography>
                            </div>

                            <Stack gap={2} style={{ justifyContent: "flex-end" }}>
                                <Stack direction="row" gap={2} justifyContent="flex-end">
                                    <Typography component="label" className="justify-end mr-2 mt-[5px] text-sm">
                                        View lifetime stats
                                    </Typography>
                                    <Switch className="ml-1" onChange={handleStatsTypeChange} />
                                </Stack>

                                {isSuperAdmin && statsType === "current" && (
                                    <Stack direction="row" gap={2}>
                                        <Typography component="label" className="justify-end mr-2 mt-[5px] text-sm">
                                            Custom period analysis
                                        </Typography>
                                        <DateRangePicker
                                            value={date}
                                            onChange={setDate}
                                            disableFuture
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>

                        <Stack gap={3}>
                            {items.map((item) => (
                                <Wrapper key={item?.type}>
                                    <CreditsCardWithSelector
                                        item={{
                                            ...item,
                                            selectedView: selectedViews[item.type],
                                            handleViewChange,
                                            statsType,
                                        }}
                                    />
                                </Wrapper>
                            ))}
                        </Stack>

                        <Typography className="p-1.5 pt-4 pb-5 text-sm">
                            Have any questions? Reach out to{" "}
                            <a
                                style={{ color: "#2296f3", textDecoration: "none" }}
                                href="mailto:customer-support@hirequotient.com"
                            >
                                customer-support@hirequotient.com
                            </a>{" "}
                            for any discussion on credits cycle
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </JoyProvider>
    );
};
export default CreditPlans;
