import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { nanoid } from "@reduxjs/toolkit";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";

import useMenu from "../hooks/useMenu";
import { useAppDispatch } from "../store";

import { cn } from "@/lib/utils";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { IProjectStatus } from "@/store/reducers/project/project.types";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Chip, Popover } from "@/ui";

export function ProjectStatus({
    status,
    menuClickHandler,
}: {
    status: IProjectStatus;
    menuClickHandler: (status: IProjectStatus) => void;
}) {
    const dispatch = useAppDispatch();
    const { menuSX, anchorEl, open, handleClose, handleClick } = useMenu();
    const isSuperAdmin = useSelector(isSuperAdminUser);

    const validateAction = (callback: () => void) => {
        return () => {
            if (status === "CLOSED") {
                if (!isSuperAdmin) {
                    dispatch(
                        setErrorNotification(
                            "Kindly contact your POC or customer-support@hirequotient.com to reactivate this project"
                        )
                    );
                    return;
                }
            }
            callback();
        };
    };

    const actions = [
        {
            id: nanoid(),
            label: "Active",
            handleClick: validateAction(() => menuClickHandler("ACTIVE")),
        },
        {
            id: nanoid(),
            label: "Closed",
            handleClick: () => menuClickHandler("CLOSED"),
        },
        {
            id: nanoid(),
            label: "On hold",
            handleClick: validateAction(() => menuClickHandler("ON_HOLD")),
        },
    ];

    const handleMenuItemClick = (callback: () => void) => {
        handleClose();
        callback();
    };

    return (
        <>
            {/* <Box flexShrink={0}>
                <Button
                    size="small"
                    sx={{
                        fontSize: 10,
                        height: "22px",
                        minWidth: 48,
                        "& .MuiButton-endIcon": {
                            marginLeft: 0.5,
                        },
                    }}
                    variant="outlined"
                    color={getColor()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                    }}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                >
                    {status === "ON_HOLD" ? "On hold" : capitalize(status)}
                </Button>
            </Box>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                {actions.map(({ id, label, handleClick }) => {
                    return (
                        <MenuItem
                            key={id}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMenuItemClick(handleClick);
                            }}
                        >
                            {label}
                        </MenuItem>
                    );
                })}
            </Menu> */}

            <Popover
                sideOffset={15}
                className="min-w-[80px] p-2"
                align="end"
                trigger={
                    <Chip
                        variant="default"
                        size="small"
                        className={cn("cursor-pointer border text-[10px] px-2 py-0.5 border-gray-400", {
                            "border-success-500 text-success-500": status === "ACTIVE",
                            "border-default-600 text-default-600": status === "ON_HOLD",
                            "border-red-600 text-red-600": status === "CLOSED",
                        })}
                        label={
                            <div className="flex items-center gap-1">
                                {status === "ON_HOLD" ? "On hold" : capitalize(status)}
                                <KeyboardArrowDownRoundedIcon sx={{ fontSize: "16px" }} />
                            </div>
                        }
                        onClick={(e) => {
                            handleClick(e);
                            e.stopPropagation();
                        }}
                    />
                }
                open={open}
                onClose={handleClose}
            >
                {actions.map(({ id, label, handleClick }) => {
                    return (
                        <div
                            key={id}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMenuItemClick(handleClick);
                            }}
                            className="cursor-pointer text-[13px] py-1 px-2 hover:bg-gray-100 rounded-sm"
                            onBlur={(e) => e.stopPropagation()}
                        >
                            {label}
                        </div>
                    );
                })}
            </Popover>
        </>
    );
}
