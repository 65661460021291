import "./index.css";

import "firebase/compat/auth";

import { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "./config";
import App from "./App";
import AppLoader from "./components/Loader/AppLoader";
import { initializeFirebase } from "./utils/firebase";
import { initializeSentry } from "./utils/sentry";
import { registerServiceWorker } from "./utils/service-worker";

const isProduction = import.meta.env.VITE_REACT_APP_ENV === "production";

initializeFirebase();

if (isProduction) {
    initializeSentry();
    registerServiceWorker();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <>
        <AppLoader delay={3000} zIndex={999} />
        <Suspense fallback={<AppLoader />}>
            <App />
        </Suspense>
    </>
);
