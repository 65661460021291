import { useTranslation } from "react-i18next";

// import { useCachedGetOutreachStatsQuery } from "../../../../store/apis/analytics/analytics.api";
// import AnalyticsLoader from "../../molecules/AnalyticsLoader";
import Calling from "./Calling";
import Email from "./Email";
import LinkedIn from "./Linkedin";
import RB2B from "./RB2B";
import SMS from "./SMS";

import DataCard from "../../molecules/DataCard/DataCard";
import Section from "../../molecules/Section/Section";
import { FunnelViewProps } from "../FunnelView/FunnelView";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";
import { TabsComponent } from "@/ui/Tabs/Tabs";

const { Item, ListItem } = DataCard;

interface OutreachStatsProps extends FunnelViewProps {}

function OutreachStatsContent(props: OutreachStatsProps) {
    const { filters } = props;
    const { t } = useTranslation();
    // const { data, isLoading, isFetching } = useCachedGetOutreachStatsQuery(
    //     // Dates are not used for getting outreach stats
    //     { ...filters, startDate: "2024-03-19T23:28:18+05:30", endDate: "2024-03-19T23:28:18+05:30" },
    //     {
    //         // skip: !filters.startDate || !filters.endDate,
    //     }
    // );

    return (
        <>
            <ListItem sx={{ pt: 4 }} disableHoverEffect>
                <Item
                    subtitle
                    info={
                        <span>
                            {t("analytics.outreachStatusInfoTitle")}
                            <br />
                            <br />
                            <small>
                                <i>{t("analytics.outreachStatusInfoSubtitle")}</i>
                            </small>
                        </span>
                    }
                >
                    {t("analytics.outreachStatusTitle")}
                </Item>
                <Item>All time</Item>
            </ListItem>
            {/* <AnalyticsLoader loading={`${!isLoading && isFetching}`} /> */}
            <Section.Content pb={4} border={"1px solid rgba(0,0,0,0.12)"} borderRadius={"4px"} my={2}>
                <TabsComponent
                    className="h-72 items-center"
                    orientation="vertical"
                    centered
                    tabs={[
                        {
                            label: "Email",
                            content: <Email filters={filters} />,
                        },
                        {
                            label: "Linkedin",
                            content: <LinkedIn filters={filters} />,
                        },
                        {
                            label: "SMS",
                            content: <SMS filters={filters} />,
                        },
                        {
                            label: "Calling",
                            content: <Calling filters={filters} />,
                        },
                        {
                            label: "RB2B",
                            content: <RB2B filters={filters} />,
                        },
                    ]}
                />
            </Section.Content>
        </>
    );
}

export default function OutreachStats(props: OutreachStatsProps) {
    return (
        <IntersectionObserverWrapper style={{ minHeight: "360px" }}>
            <OutreachStatsContent {...props} />
        </IntersectionObserverWrapper>
    );
}
