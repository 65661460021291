import AssignmentIcon from "@mui/icons-material/Assignment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ErrorIcon from "@mui/icons-material/Error";
import HomeIcon from "@mui/icons-material/Home";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MouseIcon from "@mui/icons-material/Mouse";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import UndoIcon from "@mui/icons-material/Undo";

export const statusWithIcon = {
    Cancelled: { icon: <CancelIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "error" },
    Bounced: { icon: <UndoIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "error" },
    Credited: { icon: <CreditCardIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Clicked: { icon: <MouseIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    "Collecting recipient info": {
        icon: <AssignmentIcon fontSize="small" sx={{ fontSize: "10px" }} />,
        color: "primary",
    },
    "Confirming Address": { icon: <HomeIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Delivered: { icon: <LocalShippingIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "success" },
    Expired: { icon: <HourglassEmptyIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "error" },
    Failed: { icon: <ErrorIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "error" },
    Opened: { icon: <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Packed: { icon: <CardGiftcardIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Paused: { icon: <PauseCircleFilledIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Processing: { icon: <AutorenewIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Refunded: { icon: <MonetizationOnIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Sent: { icon: <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Shipped: { icon: <LocalShippingIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    Undeliverable: { icon: <BlockIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "error" },
    Used: { icon: <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "success" },
    Pending: { icon: <HourglassEmptyIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
    "Order Placed": { icon: <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />, color: "primary" },
};
