import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { CustomTitle } from "./CustomTitle";

import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { RootState } from "../../../store";
import { useCreatePreview } from "../hooks/useCreatePreview.hook";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import templatePreviewStyles from "../outreach.module.css";

import { BodyWithSubject, LinkedinSchema } from "@/store/apis/outreach/outreach.types";
import {
    checkInMailReminderMessageEnabledInBaseTemplates,
    checkReminderMessageEnabledInBaseTemplates,
    selectCurrentOutreachElementType,
} from "@/store/reducers/outreach/outreach.slice";
import { Skeleton, Tooltip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

function SubjectLoader() {
    return (
        <Stack>
            <Skeleton className="h-[20px] m-2 " />
            <Skeleton className="h-[20px] w-[40%]  m-2" />
        </Stack>
    );
}

function BodyLoader() {
    return (
        <Stack>
            <Skeleton className="h-[20px]  m-2" />
            <Skeleton className="h-[20px]  m-2" />
            <Skeleton className="h-[20px] w-[40%]  m-2" />
        </Stack>
    );
}

function PreviewError({ handleRetry }: { handleRetry: () => void }) {
    return (
        <Stack alignItems="center" justifyContent="center">
            <BrokenImageIcon
                sx={(theme) => ({
                    height: 200,
                    width: 200,
                    color: theme.palette.divider,
                })}
            />
            <Stack direction="row" gap={1} alignItems="center">
                <Tooltip title="retry">
                    <button onClick={handleRetry}>
                        <RestartAltIcon fontSize="small" />
                    </button>
                </Tooltip>
                <Typography variant="body2" className="font-normal text-center">
                    Unexpected error occurred while generating preview...
                </Typography>
            </Stack>
        </Stack>
    );
}

export function HyperPersonalizationPreview() {
    const isMobileViewOn = useSelector((state: RootState) => state.outreach.isMobileViewOn);
    const { currentOutreachElementId } = useOutreachParams();
    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const { isLoading, isError, data, error, isFetching, refetch } = useCreatePreview();
    const { eventBody: template } = data ?? {
        eventBody: {
            body: "",
        },
        eventName: "email",
    };

    const isLinkedin = currentElementType === "linkedin";
    const isSubjectPresent = "subject" in template;
    const connnectionStatus = "connectionStatus" in template && template.connectionStatus === true;
    const inMailStatus = "inMailStatus" in template && template.inMailStatus === true;

    const isReminderMessageEnabled = useSelector((state: RootState) =>
        checkReminderMessageEnabledInBaseTemplates(state, currentOutreachElementId)
    );

    const isInMailReminderMessageEnabled = useSelector((state: RootState) =>
        checkInMailReminderMessageEnabledInBaseTemplates(state, currentOutreachElementId)
    );

    const showSubjectLoader = currentElementType === "email" || currentElementType === "follow-up";

    if (isLoading || isFetching) {
        return (
            <Stack p={1.5}>
                {showSubjectLoader && <SubjectLoader />}
                <BodyLoader />
            </Stack>
        );
    }

    if (isError) {
        console.log(error); //eslint-disable-line no-console
        return (
            <PreviewError
                handleRetry={() => {
                    refetch();
                }}
            />
        );
    }

    if (
        isMobileViewOn &&
        (currentElementType === "email" || currentElementType === "follow-up" || currentElementType === "SMS")
    ) {
        return (
            <>
                <Stack
                    py={3}
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        width: "100%",
                        height: "fit-content",
                        overflowY: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            width: "27.5%",
                            minWidth: "325px",
                            minHeight: "600px",
                            borderRadius: "3rem",
                            border: "6px solid #111111",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                width: "38%",
                                height: "14px",
                                borderRadius: 9999,
                                top: 8,
                                left: "50%",
                                background: "#111111",
                                transform: "translateX(-50%)",
                                zIndex: 5,
                            }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                width: "50%",
                                height: "10px",
                                borderRadius: 9999,
                                bottom: 8,
                                left: "50%",
                                background: "#ebebeb",
                                transform: "translateX(-50%)",
                                zIndex: 5,
                            }}
                        />

                        <Stack
                            px={2}
                            py={6}
                            gap={1}
                            style={{
                                overflowY: "scroll",
                                borderRadius: "2.5rem",
                                width: "100%",
                                height: "100%",
                                scrollbarWidth: "none",
                            }}
                            className="webkit-scrollbar: display-none"
                        >
                            {currentElementType !== "SMS" && (
                                <Typography variant="body2">
                                    <Typography component="span" className="font-semibold" variant="body2">
                                        Subject
                                    </Typography>
                                    : {(template as BodyWithSubject).subject as string}
                                </Typography>
                            )}
                            {<hr className="border-t border-gray-300" />}
                            <RichTextEditor
                                value={template.body?.replaceAll("\n", "<br>")}
                                className={templatePreviewStyles.disabled}
                                style={{ background: "transparent" }}
                                readOnly
                            />
                        </Stack>
                    </div>
                </Stack>
                <Outlet />
            </>
        );
    }

    return (
        <>
            <Stack
                direction="row"
                style={{ width: "100%", minHeight: "100%", height: "fit-content", backgroundColor: "#F3F6F9" }}
            >
                {((isLinkedin && connnectionStatus) || !isLinkedin) && (
                    <Stack style={{ width: "100%" }} p={1.5} gap={1.5}>
                        <>
                            <Stack gap={1}>
                                {isLinkedin && connnectionStatus && (
                                    <>
                                        <CustomTitle>Preview for connection message</CustomTitle>
                                        <hr className="border-t border-gray-300" />
                                    </>
                                )}

                                {isSubjectPresent && (
                                    <Typography variant="body2">
                                        <Typography component="span" className="font-semibold" variant="body2">
                                            Subject
                                        </Typography>
                                        : {template.subject as string}
                                    </Typography>
                                )}
                                {isSubjectPresent && <hr className="border-t border-gray-300" />}
                                <RichTextEditor
                                    value={template.body?.replaceAll("\n", "<br>")}
                                    className={templatePreviewStyles.disabled}
                                    readOnly
                                />
                            </Stack>

                            {isReminderMessageEnabled && (
                                <>
                                    <div>
                                        <CustomTitle sx={{ marginBottom: "5px" }}>
                                            Preview for reminder message
                                        </CustomTitle>

                                        {<hr className="border-t border-gray-300 mb-[10px]" />}

                                        <RichTextEditor
                                            value={(
                                                template as LinkedinSchema
                                            ).connectionReminderMessageBody?.replaceAll("\n", "<br>")}
                                            className={templatePreviewStyles.disabled}
                                            readOnly
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    </Stack>
                )}
                {isLinkedin && inMailStatus && (
                    <Stack style={{ width: "100%" }} p={1.5} gap={1.5}>
                        <Stack gap={1}>
                            <CustomTitle>Preview for InMail </CustomTitle>
                            {<hr className="border-t border-gray-300" />}
                            <Typography variant="body2">
                                <Typography component="span" variant="body2">
                                    Subject
                                </Typography>
                                : {(template as LinkedinSchema).inMailSubject as string}
                            </Typography>

                            <RichTextEditor
                                value={(template as LinkedinSchema).inMailBody?.replaceAll("\n", "<br>")}
                                className={templatePreviewStyles.disabled}
                                readOnly
                            />
                        </Stack>

                        {isInMailReminderMessageEnabled && (
                            <>
                                <div style={{ minHeight: "20vh", overflowY: "scroll" }}>
                                    <CustomTitle sx={{ marginBottom: "5px" }}>
                                        Preview for InMail reminder message
                                    </CustomTitle>

                                    {<hr className="border-t border-gray-300 mb-[10px]" />}

                                    <Typography variant="body2">
                                        <Typography component="span" variant="body2">
                                            Subject
                                        </Typography>
                                        : {(template as LinkedinSchema).inmailFollowupSubject as string}
                                    </Typography>

                                    <RichTextEditor
                                        value={(template as LinkedinSchema).inmailFollowupBody?.replaceAll(
                                            "\n",
                                            "<br>"
                                        )}
                                        className={templatePreviewStyles.disabled}
                                        readOnly
                                    />
                                </div>
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
            <Outlet />
        </>
    );
}
