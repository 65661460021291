import InfoIcon from "@mui/icons-material/Info";
import { ReactNode } from "react";

import Content from "./Content";
import Header from "./Header";

import { parentPropsWithChildren } from "../../../../utils/react-utils";
import { tooltipSetting } from "../../utils";

import { Tooltip } from "@/ui";

export interface SectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
    title?: string;
    minHeight?: number;
    rightElement?: ReactNode;
    loading?: string;
    fetching?: string;
    info?: string;
    height?: number;
}

export default function Section(props: SectionProps) {
    const { title, rightElement, info, minHeight, height, ...rest } = props;
    return (
        <div className={`p-2 h-[${height}px] min-h-[${minHeight}px]`} {...rest}>
            <div className="flex flex-row justify-between">
                <h2 className="pb-2 flex items-center text-[24px] font-semibold gap-2">
                    {title}
                    {info && (
                        <Tooltip {...tooltipSetting} title={info}>
                            <InfoIcon fontSize="small" color="disabled" />
                        </Tooltip>
                    )}
                </h2>
                {rightElement}
            </div>
            <div>{parentPropsWithChildren(rest)}</div>
        </div>
    );
}

Section.Header = Header;
Section.Content = Content;
