import { useState } from "react";
import { NavigateFunction } from "react-router-dom";

import { useProjectData } from "./hooks";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { addSearchCandidatesToProject } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

import { Button, Typography } from "@/ui";

interface AddCustomCandidatesProps {
    onConfirm?: (data: {
        projectId: string | null;
        projectName: string | null;
        searchId: string;
        navigate: NavigateFunction;
        profilesCount: number;
        canNavigateNextStep: boolean;
    }) => void;
    maxCount?: number;
    minCount?: number;
}

export default function AddCustomCandidates(props: AddCustomCandidatesProps) {
    const { maxCount = 1000, minCount = 0, onConfirm } = props;

    const [openModal, setOpenModal] = useState(false);
    const [candidateCount, setCandidateCount] = useState<number | "">(50);
    const {
        projectId,
        projectName,
        dispatch,
        searchId,
        navigate,
        candidates,
        isSuperAdmin,
        isAddingToProject,
        searchType,
    } = useProjectData();

    const canAccess = isSuperAdmin || searchType === "resume-library";

    if (!searchId || !candidates?.length || !canAccess) return null;

    const handleCloseModal = () => {
        setOpenModal(false);
        setCandidateCount(50);
    };

    const handleAddToProject = () => {
        if (Number(candidateCount) <= minCount || Number(candidateCount) > maxCount) return;
        const payload = {
            projectId,
            projectName,
            searchId,
            navigate,
            profilesCount: Number(candidateCount),
            canNavigateNextStep: false,
        };
        if (onConfirm) {
            onConfirm(payload);
        } else {
            dispatch(addSearchCandidatesToProject(payload));
        }
    };

    return (
        <>
            <Button variant="outline" onClick={() => setOpenModal(true)}>
                Add candidates to project
            </Button>
            {openModal && (
                <BaseModal hideCloseButton onClose={handleCloseModal} overlayStyles={{ width: "550px" }}>
                    <div className="flex flex-col gap-2">
                        <Typography className="flex flex-col gap-2">Add candidates to project</Typography>
                        <div className="flex flex-col w-full">
                            <label className="text-sm font-medium mb-1">Candidates count</label>
                            <input
                                type="text"
                                value={candidateCount}
                                onChange={(e) =>
                                    setCandidateCount(
                                        !isNaN(Number(e.target.value)) ? Number(e.target.value) || "" : ""
                                    )
                                }
                                className={`w-full text-sm px-3 py-2 border rounded focus:outline-none focus:ring-2 
                                ${
                                    Number(candidateCount) <= minCount || Number(candidateCount) > maxCount
                                        ? "border-red-500 focus:ring-red-500"
                                        : "border-gray-300 focus:ring-blue-500"
                                }`}
                                placeholder="Enter candidates count"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {Number(candidateCount) <= minCount && (
                                <span className="text-xs text-red-500 mt-1">*It should be greater than zero</span>
                            )}
                            {Number(candidateCount) > maxCount && (
                                <span className="text-xs text-red-500 mt-1">*It should be less than {maxCount}</span>
                            )}
                        </div>
                        <div className="flex flex-row gap-2 items-center justify-end">
                            <Button variant="outline" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="default" disabled={isAddingToProject} onClick={handleAddToProject}>
                                <ButtonTextWithLoading text="Submit" isLoading={isAddingToProject} variant="light" />
                            </Button>
                        </div>
                    </div>
                </BaseModal>
            )}
        </>
    );
}
