import { Controller } from "react-hook-form";
import { useCreateActivityFormState } from "../NotesTab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

export function DueDateSelect() {
    const { control } = useCreateActivityFormState();
    return (
        <Controller
            control={control}
            name="dueDate"
            render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                        orientation="landscape"
                        disablePast
                        label="Due Date"
                        slotProps={{
                            field: { clearable: true },
                            actionBar: {
                                actions: ["clear", "today", "accept"]
                            },
                            textField: {
                                error: Boolean(error),
                                helperText: error !== undefined ? "Please select valid time value" : null,
                                FormHelperTextProps: { sx: { marginX: 0 } },
                            },
                        }}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                marginTop: "0.2rem",
                                "& .MuiFormLabel-root ": {
                                    ...fontSize,
                                    top: "-5px",
                                    '&[data-shrink="true"]': {
                                        top: "1px",
                                    },
                                },
                                "& .MuiInputBase-input": {
                                    ...fontSize,
                                    padding: "0.7rem",
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "57px",
                                },
                            };
                        }}
                        {...field}
                    />
                </LocalizationProvider>
            )}
        />
    );
}
