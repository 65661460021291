import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { formatDate } from "../../../../utils/helper";

import { getCandidateWorkflows, selectCandidateWorkflows } from "@/store/reducers/project/project.reducer";
import { candidateWrokflowType } from "@/store/reducers/project/project.types";

const CandidateWorkflows = ({ candidateId }: { candidateId: string }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const candidateWorkflows: candidateWrokflowType[] = useSelector(selectCandidateWorkflows);

    const isLoading = useSelector(checkIfLoading(getCandidateWorkflows.type));
    useEffect(() => {
        if (!candidateId) return;
        dispatch(
            getCandidateWorkflows({
                candidateId,
                projectId: (id && parseInt(id)) || 0,
            })
        );
    }, [candidateId]);

    return isLoading ? (
        <div className="flex mt-20">
            <Loader size="sm" />
        </div>
    ) : (
        <div>
            {candidateWorkflows.length ? (
                <div className="overflow-auto mt-2 max-h-[calc(100vh-180px)]">
                    <table className="min-w-full table-auto text-sm border-collapse">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-4 py-2 text-left border-b">S. No.</th>
                                <th className="px-4 py-2 text-left border-b">Created at</th>
                                <th className="px-4 py-2 text-left border-b">Triggered by</th>
                                <th className="px-4 py-2 text-left border-b">Email</th>
                                <th className="px-4 py-2 text-left border-b">Connection</th>
                                <th className="px-4 py-2 text-left border-b">InMail</th>
                                <th className="px-4 py-2 text-left border-b">Follow up email</th>
                                <th className="px-4 py-2 text-left border-b">SMS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {candidateWorkflows?.map((workflow, i) => {
                                const { email, linkedin, followupEmail, sms, triggerBy, triggerAt } = workflow;
                                const isEmailPresent = !!email?.success;
                                const isFollowupEmail = !!followupEmail?.success;

                                const isConnectionSent = !!linkedin?.connReq;
                                const isInmailSent = !!linkedin?.inmail;
                                const isSMSPresent = !!sms?.body;

                                return (
                                    <tr key={i} className="hover:bg-gray-50">
                                        <td className="px-4 py-2 border-b">{i + 1}</td>
                                        <td className="px-4 py-2 border-b">
                                            {formatDate(triggerAt, "HH:MM, D MMM, YYYY")}
                                        </td>
                                        <td className="px-4 py-2 border-b">{triggerBy}</td>
                                        <td className="px-4 py-2 border-b">
                                            {isEmailPresent ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            {isConnectionSent ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            {isInmailSent ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            {isFollowupEmail ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            {isSMSPresent ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-sm">No reachouts triggered.</p>
            )}
        </div>
    );
};

export default CandidateWorkflows;
