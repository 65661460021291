import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useRef } from "react";

import { MaterialProvider } from "../../components/MaterialProvider";

import { cn } from "@/lib/utils";
import { Button, Tooltip, Typography } from "@/ui";
import { ButtonProps } from "@/ui/Button/Button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/Dropdown/Dropdown";

export type TDate = Dayjs | null;

function DatePickerComponent(props: StaticDatePickerProps<TDate>) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
                sx={{
                    "& .MuiPickersToolbar-root": {
                        display: "none",
                    },
                    "& .MuiDialogActions-root": {
                        display: "none",
                    },
                }}
                {...props}
            />
        </LocalizationProvider>
    );
}

export interface DateRangePickerProps extends Omit<StaticDatePickerProps<TDate>, "value" | "onChange"> {
    value?: TDate[];
    onChange: (dateRange: TDate[]) => void | Dispatch<SetStateAction<TDate[]>>;
    buttonProps?: ButtonProps;
    startDateLabel?: string;
    endDateLabel?: string;
    format?: string;
    startDateProps?: StaticDatePickerProps<TDate>;
    endDateProps?: StaticDatePickerProps<TDate>;
    clearable?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
}

export default function DateRangePicker(props: DateRangePickerProps) {
    const {
        value = [],
        onChange,
        buttonProps = {},
        format = "MMM D, YYYY",
        startDateLabel = "--",
        endDateLabel = "--",
        startDateProps,
        endDateProps,
        clearable,
        disabled,
        hideLabel,
        ...rest
    } = props;
    const [startDate, endDate] = value;

    const datePickerRef = useRef<HTMLDivElement>(null);

    const onChangeStartDate = (date: TDate) => {
        onChange([date, null]);
    };

    const onChangeEndDate = (date: TDate) => {
        onChange([startDate, date]);
    };

    const buttonText = `${startDate?.format(format) ?? startDateLabel} - ${endDate?.format(format) ?? endDateLabel}`;

    const cancelIcon = () => {
        if (clearable && (startDate || endDate)) {
            return (
                <CancelIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange([]);
                    }}
                    color="action"
                    fontSize="inherit"
                    sx={{
                        marginLeft: 2,
                    }}
                />
            );
        }
    };

    const { className, ...restBtnProps } = buttonProps;

    const renderButton = () => {
        if (hideLabel) {
            return (
                <Button
                    disabled={disabled}
                    variant="ghost"
                    className={cn("p-0 h-[35px] bg-[#f0f4f8] hover:bg-[#e3eef4]", className)}
                    {...restBtnProps}
                >
                    <DropdownMenuTrigger className="flex items-center w-full" asChild>
                        <div className={cn("flex items-center h-full px-3 pr-0 py-2")}>
                            <CalendarMonthIcon
                                sx={{
                                    fontSize: "inherit",
                                }}
                            />
                        </div>
                    </DropdownMenuTrigger>
                    <span className="h-full py-2 pr-3">{cancelIcon()}</span>
                </Button>
            );
        }
        return (
            <Button
                disabled={disabled}
                variant="ghost"
                className={cn("flex items-center h-[35px] p-0 bg-[#f0f4f8] hover:bg-[#e3eef4]", className)}
                {...restBtnProps}
            >
                <div className="flex items-center h-full ">
                <DropdownMenuTrigger className="w-full" asChild>
                    <div className="flex items-center h-full px-3 py-3">
                        <CalendarMonthIcon
                            sx={{
                                fontSize: "inherit",
                                marginRight: 1,
                            }}
                        />
                        <Typography className="text-xs text-nowrap">{buttonText}</Typography>
                    </div>
                    
                </DropdownMenuTrigger>
                <span className="h-full py-2 pr-3">{cancelIcon()}</span>
                </div>

            </Button>
        );
    };

    return (
        <DropdownMenu>
            <div>
                <Tooltip title={buttonText} placement="top">
                    {renderButton()}
                </Tooltip>
            </div>

            <DropdownMenuContent>
                <MaterialProvider>
                    <div ref={datePickerRef} className="flex flex-row">
                        <DatePickerComponent
                            value={startDate}
                            onChange={onChangeStartDate}
                            {...rest}
                            {...startDateProps}
                        />
                        <DatePickerComponent
                            disabled={!startDate}
                            minDate={startDate}
                            value={endDate}
                            onChange={onChangeEndDate}
                            {...rest}
                            {...endDateProps}
                        />
                    </div>
                </MaterialProvider>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
