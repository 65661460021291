import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Experimental_CssVarsProvider } from "@mui/material";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { CandidateInfoContentContainer } from "./ContactInfo";
import {
    SelectProject,
    SelectProjectFilterProps,
    useFetchCandidateProjects,
    useSelectProjectValues,
} from "./SelectProject";

import { useAppDispatch } from "../../../store";
import {
    InsightDescription,
    useFetchCandidateInsightsQuery,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";

import {
    selectInsightsSelectedProject,
    setInsightsSelectedProject,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import { Card, CardContent, Separator, Skeleton, Tooltip, Typography } from "@/ui";
import Rating from "@/ui/Rating/Rating";

const iconProps = {
    marginX: "0.3rem",
};

function InsightPoint(props: InsightDescription) {
    const { text, criterion, intent } = props;
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
                <FiberManualRecordRoundedIcon
                    sx={{
                        fontSize: "0.8rem",
                    }}
                />
                <Tooltip title={criterion} arrow className=" text-xs">
                    <div>
                        <Typography variant="body2" className="text-light">
                            {text}
                        </Typography>
                    </div>
                </Tooltip>
            </div>
            {intent ? (
                <CheckRoundedIcon color="success" sx={iconProps} />
            ) : (
                <WarningRoundedIcon color="warning" sx={iconProps} />
            )}
        </div>
    );
}

function InsightEmptyPlaceholder() {
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: "#bdbdbd" }} />}
            title="No Insights found"
            message="Insights has not been generated for this candidate on this project."
        />
    );
}

export function Insights() {
    const params = useParams();
    return (
        <CandidateInfoContentContainer>
            <AllCandidatesReachOutBodyContainer>
                <InsightsHeader />
                <Separator />
                <InsightsMain candidateId={params.id} />
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

function InsightsLoading() {
    const skeletons = (
        <>
            {Array(5)
                .fill(1)
                .map((_, idx) => (
                    <Skeleton key={`loading-${idx}`} className="w-full h-5 bg-slate-200 rounded-sm" />
                ))}
        </>
    );
    return (
        <Card variant="outlined" className="m-4 p-4">
            <CardContent className="flex flex-col gap-2 p-0">
                <div className="flex items-center gap-2">
                    <Skeleton className="w-1/3 h-6 bg-slate-200 rounded-sm" />
                </div>
                <Separator className="my-2" />
                <div className="flex flex-col gap-2 overflow-auto max-h-[calc(100vh-310px)]">{skeletons}</div>
            </CardContent>
        </Card>
    );
}

function InsightsMain({ candidateId }: { candidateId?: string }) {
    const project = useSelector(selectInsightsSelectedProject);
    const { isLoading: isFetchingProjects } = useFetchCandidateProjects();
    const { isLoading, data, isError } = useFetchCandidateInsightsQuery(
        {
            candidateId: candidateId || "",
            projectId: project.value,
        },
        {
            skip: !project.value || !candidateId,
        }
    );

    if (isLoading || isFetchingProjects) {
        return <InsightsLoading />;
    }

    if (isError || isEmpty(data)) {
        return <InsightEmptyPlaceholder />;
    }

    const { value, description } = data;

    return (
        <Card variant="outlined" className="m-4 p-4">
            <>
                <CardContent className="flex flex-col gap-2 p-0">
                    <div className="flex items-center gap-2">
                        <Typography variant="h6" className="text-lg font-semibold">
                            Star Rating
                        </Typography>
                        <Experimental_CssVarsProvider>
                            <Rating value={value} readOnly />
                        </Experimental_CssVarsProvider>
                    </div>
                    <Separator />
                    <div className="flex flex-col gap-1 overflow-auto max-h-[calc(100vh-310px)]">
                        <>
                            {description.map((i) => {
                                return <InsightPoint {...i} key={i.criterion} />;
                            })}
                        </>
                    </div>
                </CardContent>
            </>
        </Card>
    );
}

function InsightsProjectFilter() {
    const dispatch = useAppDispatch();
    const selectedProject = useSelector(selectInsightsSelectedProject);
    const { ...rest } = useSelectProjectValues({ project: selectedProject });

    const handleSelectChange: SelectProjectFilterProps["handleSelectChange"] = (value) => {
        dispatch(setInsightsSelectedProject(value));
    };
    return <SelectProject {...rest} selectedProject={selectedProject} handleSelectChange={handleSelectChange} />;
}

function InsightsHeader() {
    return (
        <div className="flex justify-between items-center p-4 pb-2 h-[70px]">
            <InsightsProjectFilter />
        </div>
    );
}
