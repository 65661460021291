import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import {
    addToNucleus,
    getNucleusJobs,
    getNucleusProjects,
    getNucleusStages,
    selectNucleusJobs,
    selectNucleusProjects,
    selectNucleusStages,
} from "@/store/reducers/integrations/integrations.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Typography } from "@/ui";

type NucleusModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export default function NucleusModal({ open, onClose, id, selectedCandidateIds, variant }: NucleusModalProps) {
    const dispatch = useDispatch();
    const [jobId, setJobId] = useState("");
    const [stageId, setStageId] = useState("");
    const [nucleusProjectId, setNucleusProjectId] = useState();

    const nucleusJobs = useSelector(selectNucleusJobs);
    const nucleusStages = useSelector(selectNucleusStages);
    const nucleusProjects = useSelector(selectNucleusProjects);
    const isNucleusJobsLoading = useSelector(checkIfLoading(getNucleusJobs.type));
    const isNucleusStagesLoading = useSelector(checkIfLoading(getNucleusStages.type));
    const isNucleusSubmitting = useSelector(checkIfLoading(addToNucleus.type));
    const isNucleusProjectsLoading = useSelector(checkIfLoading(getNucleusProjects.type));

    const isDisabled = isNucleusJobsLoading || isNucleusStagesLoading || isNucleusSubmitting;

    useEffect(() => {
        if (open) {
            dispatch(getNucleusJobs({ action: getNucleusJobs.type }));
            dispatch(getNucleusStages({ action: getNucleusStages.type }));
            dispatch(getNucleusProjects({ action: getNucleusProjects.type }));
        }
    }, [open, dispatch]);

    const handleCloseModal = () => {
        setJobId("");
        setStageId("");
        onClose();
    };

    const handleAddNucleus = () => {
        if (!stageId) {
            dispatch(setErrorNotification("Please select a stage"));
            return;
        }

        dispatch(
            addToNucleus({
                projectId: id,
                candidateIds: selectedCandidateIds,
                jobId,
                stageId,
                nucleusProjectId,
                action: addToNucleus.type,
                onSuccess: handleCloseModal,
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            maxWidth="sm"
            fullWidth
            aria-labelledby="nucleus-modal-title"
            aria-describedby="nucleus-modal-description"
        >
            <DialogTitle id="nucleus-modal-title">
                <Typography variant="h6">Send to Nucleus ATS</Typography>
            </DialogTitle>

            <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    <JobSelector
                        isLoading={isNucleusJobsLoading}
                        jobs={nucleusJobs}
                        value={jobId}
                        onChange={setJobId}
                    />
                    <StageSelector
                        isLoading={isNucleusStagesLoading}
                        stages={nucleusStages}
                        value={stageId}
                        onChange={setStageId}
                    />
                    <ProjectSelector
                        isLoading={isNucleusProjectsLoading}
                        projects={nucleusProjects}
                        value={nucleusProjectId}
                        onChange={setNucleusProjectId}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button variant="outline" color="inherit" onClick={handleCloseModal} label="Cancel" />

                <Button variant="default" disabled={isDisabled} onClick={handleAddNucleus}>
                    <ButtonTextWithLoading isLoading={isNucleusSubmitting} text="Confirm" variant="light" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type SelectorProps<T> = {
    isLoading: boolean;
    value: string;
    onChange: (value: string) => void;
    items: T[];
    label: string;
};

function Selector<T extends { id: string; name: string }>({
    isLoading,
    value,
    onChange,
    items,
    label,
}: SelectorProps<T>) {
    return (
        <Autocomplete
            disableClearable={false}
            options={items}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
            onChange={(_, newValue) => {
                onChange(newValue?.id);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading && <CircularProgress size={20} sx={{ mr: 1 }} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    <Typography variant="body2">{option.name}</Typography>
                </li>
            )}
            sx={{
                "& .MuiAutocomplete-clearIndicator": {
                    display: "none",
                },
            }}
        />
    );
}

function JobSelector({ isLoading, jobs, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={jobs} label="Select Job" />;
}

function StageSelector({ isLoading, stages, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={stages} label="Select Stage" />;
}

function ProjectSelector({ isLoading, projects, value, onChange }) {
    return <Selector isLoading={isLoading} value={value} onChange={onChange} items={projects} label="Select Project" />;
}
