import { Dayjs } from "dayjs";
import { ChevronDown } from "lucide-react";
import { useCallback, useMemo, useRef, useState } from "react";

import { Checkbox } from "../Checkbox/Checkbox";

import useOnClickOutside from "@/hooks/useClickOutside";
import { cn } from "@/lib/utils";

type TOption = {
    label: string;
    value: string | number | Dayjs;
};

export interface SelectProps {
    placeholder?: string;
    value?: string | number | Dayjs | Array<string | number | Dayjs>;
    onChange?: (value: Array<string | number | Dayjs> | string | number | Dayjs) => void;
    options: TOption[];
    disabled?: boolean;
    fullWidth?: boolean;
    error?: string;
    className?: string;
    label?: string;
    helperText?: string;
    size?: "sm" | "md" | "lg";
    multiple?: boolean;
    selectTriggerStyles?: string;
    optionsStyles?: string;
    selectClassName?: string;
}

/**
 * A customizable Select component that allows users to choose one or multiple options from a dropdown list.
 *
 * @param {string} [placeholder="Select"] - The placeholder text displayed when no option is selected.
 * @param {Array<string | number | Dayjs>} [value=[]] - The currently selected value(s).
 * @param {function} onChange - Callback function triggered when the selected value changes.
 * @param {Array<{ value: string | number | Dayjs, label: string }>} options - The list of options to display in the dropdown.
 * @param {boolean} [disabled=false] - Whether the select component is disabled.
 * @param {boolean} [fullWidth=false] - Whether the select component should take the full width of its container.
 * @param {string} [error] - Error message to display when there is an error.
 * @param {string} [className] - Additional class names to apply to the select component.
 * @param {string} [helperText] - Helper text to display below the select component.
 * @param {string} [size="sm"] - The size of the select component. Can be "sm", "md", or "lg".
 * @param {boolean} [multiple=false] - Whether multiple options can be selected.
 * @param {string} [selectTriggerStyles] - Additional styles for the select trigger element.
 * @param {string} [optionsStyles] - Additional styles for the options dropdown.
 *
 * @returns {JSX.Element} The rendered Select component.
 */
export const Select = ({
    placeholder = "Select",
    value = [],
    onChange,
    options,
    disabled = false,
    fullWidth = false,
    error,
    className,
    helperText,
    size = "sm",
    multiple = false,
    selectTriggerStyles,
    optionsStyles,
    selectClassName,
}: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    const selectedValues = useMemo(() => (Array.isArray(value) ? value : [value]), [value]);

    const toggleDropdown = () => {
        if (!disabled) setIsOpen((prev) => !prev);
    };

    const handleSelect = useCallback(
        (selectedValue: string | number | Dayjs) => {
            if (multiple) {
                const newValue = selectedValues.includes(selectedValue)
                    ? selectedValues.filter((v) => v !== selectedValue)
                    : [...selectedValues, selectedValue];

                onChange?.(newValue);
            } else {
                onChange?.(selectedValue);
                setIsOpen(false);
            }
        },
        [multiple, selectedValues, onChange]
    );

    const renderSelectedLabel = useMemo(() => {
        if (selectedValues.length === 0) return placeholder;
        if (!multiple) return options.find((opt) => opt.value === value)?.label || placeholder;
        return selectedValues
            .map((selected) => options.find((opt) => opt.value === selected)?.label)
            .filter(Boolean)
            .join(", ");
    }, [selectedValues, multiple, options, placeholder, value]);

    useOnClickOutside(selectRef, () => setIsOpen(false));

    const sizeStyles = useMemo(() => {
        switch (size) {
            case "sm":
                return "h-10 text-sm px-3";
            case "lg":
                return "h-14 text-lg px-5";
            default:
                return "h-12 text-base px-4";
        }
    }, [size]);

    return (
        <div className={cn("relative select-container", { "w-full": fullWidth }, className)} ref={selectRef}>
            <div
                className={cn(
                    "relative flex items-center rounded-lg bg-white shadow-sm border transition duration-200",
                    sizeStyles,
                    {
                        "cursor-not-allowed bg-gray-100 border-gray-200": disabled,
                        "hover:border-gray-400 focus-within:ring-2 focus-within:ring-cyan-500": !disabled,
                        "border-red-500 ring-2 ring-red-500": error,
                    },
                    selectTriggerStyles
                )}
                onClick={toggleDropdown}
                tabIndex={0}
            >
                <div
                    className={cn("truncate flex-1 text-gray-700", selectClassName, {
                        "text-gray-500": !value,
                    })}
                >
                    {renderSelectedLabel || placeholder}
                </div>
                <ChevronDown
                    className={cn("w-5 h-5 text-gray-500 transition-transform duration-200", {
                        "rotate-180": isOpen,
                    })}
                />
            </div>

            {isOpen && !disabled && (
                <div
                    className={cn(
                        "absolute z-10 mt-1 bg-white rounded-md shadow-lg border border-gray-200 max-h-60 overflow-auto",
                        optionsStyles
                    )}
                    style={{ width: "100%" }} // Matches the trigger width
                >
                    {options.map((option) => (
                        <div
                            key={option.value as string}
                            className={cn("px-4 py-2 flex items-center gap-2 cursor-pointer hover:bg-gray-50", {
                                "bg-gray-100": selectedValues.includes(option.value),
                            })}
                            onClick={() => handleSelect(option.value)}
                        >
                            {multiple && (
                                <Checkbox
                                    checked={selectedValues.includes(option.value)}
                                    className="w-4 h-4"
                                    size="sm"
                                />
                            )}
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}

            {helperText && !error && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
        </div>
    );
};
