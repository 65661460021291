import { LineChart } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import { TGraph } from "../../../../store/apis/analytics/analytics.types";
import { defaultChartColors } from "../../utils";

import Loader from "@/components/Loader/Loader";

const getChartData = (sourcing?: TGraph) => {
    const labels: Date[] = [];
    const outsourcedData: number[] = [];
    const qualifiedData: number[] = [];

    Object.keys(sourcing?.chartLabel || []).forEach((key: string) => {
        labels.push(new Date(key));
        const value = (sourcing?.chartLabel as any)?.[key];
        if (value) {
            outsourcedData.push(value.sourcedCandidates || 0);
            qualifiedData.push(value.qualifiedCandidates || 0);
        }
    });

    return { labels, outsourcedData, qualifiedData };
};

interface GraphProps {
    graph?: TGraph;
    loading?: boolean;
}

export default function Graph(props: GraphProps) {
    const { graph, loading } = props;
    const { labels, outsourcedData, qualifiedData } = getChartData(graph);
    const { t } = useTranslation();

    if (loading) {
        return (
            <div className="flex h-[350px]">
                <Loader />
            </div>
        );
    }

    return (
        <LineChart
            sx={{
                "& .MuiAreaElement-series-sourced": {
                    fill: "rgb(2,178,175, 0.3)",
                },
                "& .MuiAreaElement-series-qualified": {
                    fill: "rgb(46,150,255, 0.3)",
                },
            }}
            xAxis={[
                {
                    id: "date",
                    scaleType: "band",
                    data: labels,
                    valueFormatter: (date) => {
                        return date?.toLocaleDateString("en-GB", {
                            month: "short",
                            day: "numeric",
                        });
                    },
                },
            ]}
            series={[
                {
                    id: "sourced",
                    curve: "linear",
                    data: outsourcedData,
                    area: true,
                    showMark: true,
                    label: t("analytics.candidateSourcedLabel"),
                },
                {
                    id: "qualified",
                    curve: "linear",
                    data: qualifiedData,
                    area: true,
                    showMark: true,
                    label: t("analytics.qualifiedCandidatesLabel"),
                },
            ]}
            colors={defaultChartColors}
            height={350}
            slots={{ legend: () => null }}
            leftAxis={null}
        />
    );
}
