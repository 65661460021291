import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import style from "./skillsSection.module.scss";

import { TextButton } from "../../../../components/TextButton";
import { ICandidateSkills } from "@/store/reducers/project/project.types";

type HandleSeeMore = () => void;

type Props = {
    all_skills: ICandidateSkills;
    relevancy_skills: ICandidateSkills;
    isLoading: boolean;
    isSelected?: boolean;
    handleSeeMore: HandleSeeMore;
};

export default function Skills({ all_skills, relevancy_skills, isLoading, isSelected, handleSeeMore }: Props) {
    const theme = useTheme();
    const isXL = useMediaQuery(theme.breakpoints.up("xl"));
    const skillsDataLength = all_skills.length;
    const skillsCount = isXL ? 2 : 1;

    if (isLoading) {
        return <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />;
    }

    const isMoreSkills = skillsDataLength > skillsCount;

    const skills = isMoreSkills ? all_skills?.slice(0, skillsCount) : all_skills;

    return (
        <>
            {skillsDataLength ? (
                <div className={`${style["spcard__itemRight"]} ${style["spcard__skills"]}`}>
                    {skills.map((name: string) => (
                        <Tooltip key={name} title={name}>
                            <p
                                className={`${relevancy_skills && relevancy_skills.length && relevancy_skills.includes(name)
                                        ? style["spcard__relevantSkill"]
                                        : ""
                                    } ${style["spcard__skillsItem"]} 
                            ${isSelected && style["spcard__skillsItem-selected"]}`}
                            >
                                {name.length > 15 ? name.substring(0, 15) + "..." : name}
                            </p>
                        </Tooltip>
                    ))}
                    {isMoreSkills && (
                        <TextButton
                            variant="text"
                            className="text-xs text-cyan-600"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSeeMore();
                            }}
                        >
                            See More
                        </TextButton>
                    )}
                </div>
            ) : (
                <p className={style["spcard__skillsItem"]}>N/A</p>
            )}
        </>
    );
}
