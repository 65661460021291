import React from "react";

interface CustomCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    children?: React.ReactNode;
}

const BpIcon = () => (
    <span className="inline-block w-4 h-4 rounded bg-transparent shadow-inner border border-gray-300 dark:border-gray-600">
        <span className="bg-gradient-to-b from-white to-transparent block w-full h-full" />
    </span>
);

const BpCheckedIcon = () => (
    <span className="inline-block w-4 h-4 rounded bg-cyan-600 shadow-inner">
        <span
            className="block w-full h-full bg-gradient-to-b from-white to-transparent"
            style={{
                maskImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E")`,
                WebkitMaskImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E")`,
                backgroundColor: "white",
            }}
        />
    </span>
);

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props) => {
    return (
        <label className="relative flex items-center group">
            <input
                type="checkbox"
                className="sr-only peer"
                {...props}
            />
            <div className="peer-checked:hidden">
                <BpIcon />
            </div>
            <div className="hidden peer-checked:block">
                <BpCheckedIcon />
            </div>
        </label>
    );
};

const Checkbox: React.FC<CustomCheckboxProps> = ({ children, ...props }) => {
    if (children) {
        return (
            <label className="flex items-center space-x-2">
                <CustomCheckbox {...props} />
                <span className="text-gray-700 dark:text-gray-300">{children}</span>
            </label>
        );
    }

    return <CustomCheckbox {...props} />;
};

export default Checkbox;
