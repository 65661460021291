import { Typography } from "@/ui";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { VettingCriteriaRating } from "../../../../components/VettingCriteriaRating";
import { useHighlightText } from "../../../../hooks/useHighlightText";
import { ICandidate } from "@/store/reducers/project/project.types";

import DocumentViewer from "@/components/DocumentViewer/DocumentViewer";
import { getFiltersToHighlight } from "@/pages/all-candidates/AllCandidates";
import {
    selectAllCandidatesFilters,
    selectSearchQueryInAllCandidates,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";

type ResumeTabProps = {
    candidate: ICandidate;
    projectId?: string;
};

export default function ResumeTab(props: ResumeTabProps) {
    const { projectId, candidate } = props;
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const filters = useSelector(selectAllCandidatesFilters);

    const isResumeNotAvailable = !candidate?.resume || candidate?.resume === "-" || isEasyGrowth;

    if (isResumeNotAvailable) {
        return <Typography>Resume not available!</Typography>;
    }

    const keywords = getFiltersToHighlight(filters, searchQuery);

    useHighlightText(keywords, "candidate-resume-tab", 500, false);

    return (
        <>
<div className="flex flex-col justify-center space-y-3 py-2">
            
                {projectId && !isEmpty(candidate.starRating) ? (
                    <VettingCriteriaRating
                        esProps={{
                            projectId: projectId,
                            candidateId: candidate._id,
                            vettingFixed: candidate?.vettingFixed,
                        }}
                    />
                ) : null}
            </div>
            <div>
                {candidate.desiredJobTitle && (
                    <Typography component="i" className="text-[14px]">
                        Desired job title: {candidate.desiredJobTitle}
                    </Typography>
                )}
                {typeof candidate.minSalary === "number" && typeof candidate.maxSalary === "number" && (
                    <Typography component="i" className="text-[14px]">
                        Expected compensation range (USD): {candidate.minSalary} - {candidate.maxSalary}{" "}
                    </Typography>
                )}
            </div>

            <div className="flex flex-col justify-center space-y-3 py-2" id="candidate-resume-tab">
            <DocumentViewer url={candidate.resume as string} />
            </div>
        </>
    );
}
