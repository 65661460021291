import InfoIcon from "@mui/icons-material/Info";
// import { Accordion, AccordionDetails, AccordionSummary } from "@mui/joy";
import { Experimental_CssVarsProvider, Popover } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WorkflowDetails from "./WorkflowDetails";

import CustomButton from "../../../../components/CustomButton";
import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import MembersDropdown from "../../../../components/MembersDropdown";
import {
    useGetWorkflowListQuery,
    useRescheduleWorkflowMutation,
} from "../../../../store/apis/linkedin-workflow/linkedin-workflow.api";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { useLinkedinWorkflowContext } from "../../Context";
import Scrollable from "../Scrollable";

import Loader from "@/components/Loader/Loader";
import ProjectsDropdown from "@/pages/AnalyticsV2/components/ProjectsDropdown";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { isSuperAdminUser, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Checkbox, Modal, Tooltip, Typography } from "@/ui";
import { Accordion } from "@/ui/Accordian/Accordion";

const WorkflowPane = () => {
    const user = useSelector(selectUser);
    const recruiterStatus = user?.features?.recruiterInMail;
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [checkedWorkflows, setCheckedWorkflows] = useState<Record<number, boolean>>({});
    const [dayValue, setDayValue] = useState<Dayjs | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRecruiterMail, setSelectedRecruiterMail] = useState<boolean>(false);
    const [singleCheckedWorkflowId, setSingleCheckedWorkflowId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rescheduleWorkflow, { isLoading }] = useRescheduleWorkflowMutation();
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const dispatch = useDispatch();
    const {
        filters: { projectId, userId, setProjectId, setUserId },
    } = useLinkedinWorkflowContext();

    const {
        isFetching,
        isError: isQueryError,
        data,
        refetch,
    } = useGetWorkflowListQuery({
        userId,
        projectId,
    });

    let hour = 0;
    if (dayValue) {
        hour = dayjs(dayValue).hour();
    }

    const handleCheckboxToggle = (index: any, workflowId: any, event: any, scheduledFor: any) => {
        // event.stopPropagation();
        setCheckedWorkflows((prev) => {
            const newCheckedWorkflows = {
                ...prev,
                [index]: !prev[index],
            };

            // const checkedData = Object.keys(newCheckedWorkflows)
            //     .filter((key) => newCheckedWorkflows[parseInt(key)])
            //     .map((key) => data[parseInt(key)]);

            // const checkedIds = checkedData.map((item) => item?.workflowId);
            // const checkedSelectedRecruiterMail = checkedData.map((item) => item?.recruiterMail);
            // setSelectedRecruiterMail(checkedSelectedRecruiterMail.length === 1 ? checkedSelectedRecruiterMail[0] : null);

            const checkedIds = [];
            for (const key in newCheckedWorkflows) {
                if (!newCheckedWorkflows[parseInt(key)]) continue;
                const workflowId = data[parseInt(key)].workflowId;
                checkedIds.push(workflowId);
            }

            setSingleCheckedWorkflowId(checkedIds.length === 1 ? checkedIds[0] : null);
            if (newCheckedWorkflows[index]) {
                const scheduledDate = dayjs(scheduledFor);
                setDayValue(scheduledDate);
            }

            return newCheckedWorkflows;
        });
    };

    const handleSwitchWorkflow = async () => {
        handleModalClose();
        const checkedWorkflowIds = Object.keys(checkedWorkflows)
            .filter((key) => checkedWorkflows[parseInt(key)])
            .map((key) => data[parseInt(key)].workflowId);
        try {
            if (checkedWorkflowIds.length === 2) {
                await rescheduleWorkflow({
                    workflowId: checkedWorkflowIds[0],
                    scheduledFor: data[parseInt(Object.keys(checkedWorkflows)[1])].scheduledFor,
                }).unwrap();
                await rescheduleWorkflow({
                    workflowId: checkedWorkflowIds[1],
                    scheduledFor: data[parseInt(Object.keys(checkedWorkflows)[0])].scheduledFor,
                }).unwrap();
                dispatch(setSuccessNotification("Workflows switched successfully"));
            }
        } catch (error) {
            dispatch(setErrorNotification("Error switching workflows"));
        }
    };

    const handleModalOpen = () => {
        const checkedWorkflowIds = Object.keys(checkedWorkflows)
            .filter((key) => checkedWorkflows[parseInt(key)])
            .map((key) => data[parseInt(key)].workflowId);
        if (checkedWorkflowIds.length === 2) {
            setIsModalOpen(true);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const formatScheduledDate = (dateString: any) => {
        return dayjs(dateString).format("MMM D, h:mm A");
    };

    if (isQueryError) {
        return <Typography>Error loading data</Typography>;
    }

    const currentCheckedCount = Object.values(checkedWorkflows).filter(Boolean).length;
    const isSingleChecked = currentCheckedCount === 1;

    const handleRescheduleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (isSingleChecked) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleRescheduleWorkflow = async () => {
        setAnchorEl(null);
        setConfirmationModal(false);
        try {
            if (singleCheckedWorkflowId && dayValue) {
                await rescheduleWorkflow({
                    workflowId: singleCheckedWorkflowId,
                    scheduledFor: dayValue.toISOString(),
                }).unwrap();
                dispatch(setSuccessNotification("Rescheduled successfully"));
                refetch();
                setCheckedWorkflows({});
            }
        } catch (error) {
            dispatch(setErrorNotification("Error while rescheduling this LinkedIn outreach!"));
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleDateChange = (newDate: Dayjs | null) => {
        setDayValue(newDate);
    };

    const redirectToProjectPage = (projectId: any) => {
        window.open(`/projects/${projectId}`);
    };

    return (
        <>
            <Scrollable.Container
                sx={{ width: "100%", height: "100%", padding: 2, overflowY: "auto", backgroundColor: "white" }}
            >
                <div className="flex flex-row justify-between ml-[30px]">
                    <Typography className="pb-2 flex items-center text-2xl font-semibold gap-1">
                        LinkedIn scheduled outreach
                        <Tooltip title="View all your LinkedIn scheduled outreach in one place and you can reschedule them here">
                            <InfoIcon color="disabled" sx={{ fontSize: "20px" }} />
                        </Tooltip>
                    </Typography>
                </div>
                <div className="mb-1 flex flex-row items-center justify-between ml-[30px]">
                    <div className="flex flex-row items-center">
                        <MembersDropdown value={userId} onChange={setUserId} />
                        <Experimental_CssVarsProvider>
                            <ProjectsDropdown userId={userId} value={projectId} onChange={setProjectId} />
                        </Experimental_CssVarsProvider>
                    </div>
                    <div className="ml-auto">
                        <Experimental_CssVarsProvider>
                            <Tooltip title="This feature is in progress.">
                                <CustomButton
                                    disabled={true}
                                    variant="outline"
                                    className="text-[15.5px] py-[0.4rem] px-[1rem] rounded-[0.3rem] bg-transparent shadow-none text-[#0891B2] active:opacity-100 active:bg-[#0891B2] active:text-white self-end text-none mr-[5px]"
                                >
                                    Switch
                                </CustomButton>
                            </Tooltip>

                            <Tooltip title="You can only reschedule one outreach at a time.">
                                <CustomButton
                                    onClick={handleRescheduleClick}
                                    disabled={currentCheckedCount !== 1}
                                    variant="outline"
                                    className="text-[15.5px] py-[0.4rem] px-[1rem] rounded-[0.3rem] bg-transparent shadow-none text-[#0891B2] active:opacity-100 active:bg-[#0891B2] active:text-white self-end capitalize"
                                >
                                    Reschedule
                                </CustomButton>
                            </Tooltip>
                        </Experimental_CssVarsProvider>
                    </div>
                </div>
                <hr className="border-t border-gray-300 mb-3 mt-2" />
                {isFetching || isLoading ? (
                    <div className="h-[60vh]">
                        <Loader />
                    </div>
                ) : (
                    <Scrollable.Container
                        sx={{
                            width: "100%",
                            height: "90vh",
                            padding: 2,
                            overflowY: "auto",
                            backgroundColor: "white",
                            gap: 1,
                        }}
                    >
                        {data?.map((workflow: any, index: number) => (
                            <Accordion
                                className="border-none p-0"
                                triggerClassName="p-0 focus:ring-0 outline-none"
                                contentClassName="p-0"
                                key={workflow.workflowId}
                                items={[
                                    {
                                        value: workflow.workflowId,
                                        title: (
                                            <div
                                                className="p-1"
                                                style={{
                                                    paddingLeft: "3px",
                                                    height: "-5px",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tooltip
                                                    title={
                                                        currentCheckedCount >= 2 && !checkedWorkflows[index]
                                                            ? "You cannot select more than 2 workflows to switch"
                                                            : ""
                                                    }
                                                >
                                                    <span>
                                                        <Checkbox
                                                            className="ml-[10px]"
                                                            checked={checkedWorkflows[index] || false}
                                                            onChange={(event) =>
                                                                handleCheckboxToggle(
                                                                    index,
                                                                    workflow.workflowId,
                                                                    event,
                                                                    workflow.scheduledFor
                                                                )
                                                            }
                                                            onClick={(event) => event.stopPropagation()}
                                                            disabled={
                                                                currentCheckedCount >= 2 && !checkedWorkflows[index]
                                                            }
                                                        />
                                                    </span>
                                                </Tooltip>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <div className="flex flex-row">
                                                        <div
                                                            className="ml-5 font-semibold text-lg mr-3"
                                                            onClick={() => redirectToProjectPage(workflow.projectId)}
                                                        >
                                                            {workflow.projectName.length > 30
                                                                ? `${workflow.projectName.substring(0, 30)}...`
                                                                : workflow.projectName}
                                                        </div>
                                                        {isSuperAdmin && (
                                                            <Typography className="font-medium text-[13px] italic -mb-[5px] mr-4">
                                                                {" "}
                                                                ({workflow.projectId}){" "}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    <div className="flex flex-row">
                                                        {isSuperAdmin && workflow?.recruiterInmail === true && (
                                                            <Typography className="font-medium text-[13px] italic mr-3">
                                                                Recruiter
                                                            </Typography>
                                                        )}
                                                        <Typography className="font-medium text-[13px] italic">
                                                            {workflow.candidateCount}{" "}
                                                            {workflow.candidateCount === 1 ? "Candidate" : "Candidates"}
                                                        </Typography>
                                                        <Typography className=" font-medium text-[13px] italic w-[250px]">
                                                            Scheduled for {formatScheduledDate(workflow.scheduledFor)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                        content: (
                                            <>
                                                <WorkflowDetails workflow={workflow} />
                                            </>
                                        ),
                                    },
                                ]}
                                // expanded={expandedWorkflow === workflow.workflowId}
                                // onChange={() => handleWorkflowToggle(workflow.workflowId)}
                            />
                            // <Accordion
                            //     key={workflow.workflowId}
                            //     expanded={expandedWorkflow === workflow.workflowId}
                            //     onChange={() => handleWorkflowToggle(workflow.workflowId)}
                            //     sx={{ marginBottom: 2, boxShadow: 3 }}
                            // >
                            //     <AccordionSummary
                            //         sx={{
                            //             paddingLeft: "3px",
                            //             height: "40px",
                            //             cursor: "pointer",
                            //             display: "flex",
                            //             alignItems: "center",
                            //         }}
                            //     >
                            //         <Tooltip
                            //             title={
                            //                 currentCheckedCount >= 2 && !checkedWorkflows[index]
                            //                     ? "You cannot select more than 2 workflows to switch"
                            //                     : ""
                            //             }
                            //         >
                            //             <span>
                            //                 <Checkbox
                            //                     className="ml-[10px]"
                            //                     checked={checkedWorkflows[index] || false}
                            //                     onChange={(event) =>
                            //                         handleCheckboxToggle(
                            //                             index,
                            //                             workflow.workflowId,
                            //                             event,
                            //                             workflow.scheduledFor
                            //                         )
                            //                     }
                            //                     onClick={(event) => event.stopPropagation()}
                            //                     disabled={currentCheckedCount >= 2 && !checkedWorkflows[index]}
                            //                 />
                            //             </span>
                            //         </Tooltip>
                            //         <div
                            //             className="ml-[5px] font-semibold text-lg"
                            //             onClick={() => redirectToProjectPage(workflow.projectId)}
                            //         >
                            //             {workflow.projectName.length > 30
                            //                 ? `${workflow.projectName.substring(0, 30)}...`
                            //                 : workflow.projectName}
                            //         </div>
                            //         {isSuperAdmin && (
                            //             <Typography className="font-medium text-[13px] italic -mb-[5px]">
                            //                 {" "}
                            //                 ({workflow.projectId}){" "}
                            //             </Typography>
                            //         )}

                            //         <div className="flex-grow"></div>
                            //         {isSuperAdmin && workflow?.recruiterInmail === true && (
                            //             <Typography className="font-medium text-[13px] italic">Recruiter</Typography>
                            //         )}
                            //         <Typography className="font-medium text-[13px] italic">
                            //             {workflow.candidateCount}{" "}
                            //             {workflow.candidateCount === 1 ? "Candidate" : "Candidates"}
                            //         </Typography>
                            //         <Typography className="ml-[5px] font-medium text-[13px] italic w-[250px]">
                            //             Scheduled for {formatScheduledDate(workflow.scheduledFor)}
                            //         </Typography>
                            //     </AccordionSummary>
                            //     <AccordionDetails>
                            //         {workflow.workflowId === expandedWorkflow && (
                            //             <WorkflowDetails workflow={workflow} />
                            //         )}
                            //     </AccordionDetails>
                            // </Accordion>
                        ))}
                    </Scrollable.Container>
                )}

                <Experimental_CssVarsProvider>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <div className="p-2 relative">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    value={dayValue}
                                    onChange={handleDateChange}
                                    orientation="landscape"
                                    disablePast
                                />
                            </LocalizationProvider>
                            <JoyProvider>
                                <div className="flex justify-end mt-2">
                                    <Button
                                        variant="default"
                                        onClick={() => {
                                            setConfirmationModal(true);
                                            handlePopoverClose();
                                        }}
                                        label="Submit"
                                    />
                                </div>
                            </JoyProvider>
                        </div>
                    </Popover>
                </Experimental_CssVarsProvider>

                <Modal open={isModalOpen} onClose={handleModalClose}>
                    <div style={{ width: 500 }}>
                        <p>Please confirm if you want to switch the scheduled dates of the selected workflows.</p>
                        <div className="mt-2 flex justify-center">
                            <Button variant="default" onClick={handleSwitchWorkflow} label="Yes" />

                            <Button variant="outline" className="ml-2" onClick={handleModalClose} label="No" />
                        </div>
                    </div>
                </Modal>
            </Scrollable.Container>

            <MaterialProvider>
                {confirmationModal && (
                    <BaseModal
                        onClose={() => {
                            setConfirmationModal(false);
                        }}
                        overlayStyles={{
                            padding: "1.7rem 1.5rem",
                            width: 600,
                        }}
                        hideCloseButton={false}
                    >
                        <Typography className="text-xl font-semibold mb-2">Sure you want to reschedule?</Typography>

                        {selectedRecruiterMail && hour > 17 ? (
                            <Typography className="text-sm">
                                This workflow will be scheduled for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {formatScheduledDate(dayValue.add(1, "day").toISOString())}
                                </span>{" "}
                                (i.e., 24 hrs after your selected scheduled date) on your LinkedIn Recruiter account.
                            </Typography>
                        ) : (
                            <Typography className="text-sm">
                                This workflow will be scheduled for{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {formatScheduledDate(dayValue.toISOString())}
                                </span>{" "}
                                on your LinkedIn {recruiterStatus ? "Recruiter" : ""} account.
                            </Typography>
                        )}

                        <div className="flex flex-row items-center justify-end space-x-1 mt-4">
                            <Button
                                variant="outline"
                                className="rounded-[4px] text-xs"
                                onClick={() => {
                                    setConfirmationModal(false);
                                }}
                                label="Cancel"
                            />

                            <Button
                                variant="default"
                                className="rounded-[4px] text-xs"
                                onClick={handleRescheduleWorkflow}
                                label="Proceed"
                            />
                        </div>
                    </BaseModal>
                )}
            </MaterialProvider>
        </>
    );
};

export default WorkflowPane;
