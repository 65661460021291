import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

const SuspendedPage: React.FC = () => {
    return (
        <Stack
            spacing={2}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Stack spacing={2}>
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: "center",
                        fontWeight: "700",
                    }}
                >
                    Your account has been suspended due to breach of contract.
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                    }}
                >
                    Please reach out to us at{" "}
                    <a href="mailto:customer-support@hirequotient.com" className="text-primary">
                        customer-support@hirequotient.com.
                    </a>
                </Typography>
            </Stack>
        </Stack>
    );
};

export default SuspendedPage;
