import Item from "./Item";
import ListItem from "./ListItem";

import { parentPropsWithChildren } from "../../../../utils/react-utils";

export interface DataCardProps extends React.HTMLAttributes<HTMLUListElement> {
    disableHoverEffect?: boolean;
    loading?: string;
    divider?: boolean;
}
export default function DataCard(props: DataCardProps) {
    return <ul>{parentPropsWithChildren(props)}</ul>;
}

DataCard.Item = Item;
DataCard.ListItem = ListItem;
