import { FormControl } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonTextWithLoading } from "./ButtonTextWithLoading";

import { useAsyncFn } from "../hooks/useAsyncFn";
import BaseModal from "../pages/triggerWorkflow/components/BaseModal";
import { selectShowCreateDuplicateProject, setModal } from "../store/reducers/modals.slice";
import API from "../utils/API";
import handleException from "../utils/sentry";

import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { IProject } from "@/store/reducers/project/project.types";
import { Button } from "@/ui";

type DuplicateProjectResponse = {
    message: string;
    data: IProject;
};

export default function CreateDuplicateProject() {
    const { t } = useTranslation();
    const projectName = useSelector(selectProjectName);
    const [name, setName] = useState(() => `Copy of ${projectName}` || "");
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectShowCreateDuplicateProject);
    const params = useParams();
    const navigate = useNavigate();
    const handleClose = () => dispatch(setModal({ key: "showCreateDuplicateProject", value: false }));

    const { status, submit } = useAsyncFn(async () => {
        try {
            const payload = {
                projectId: params?.id,
                name,
            };

            const response = (await new API().post("/v2/project/copy-project", payload)) as DuplicateProjectResponse;

            if (response?.data && "_id" in response.data) {
                const copiedProjectId = response.data._id;
                navigate(`/projects/${copiedProjectId}`);
                handleClose();
                dispatch(setSuccessNotification("Successfully created copy of the project"));
                dispatch(
                    getProject({
                        projectId: String(copiedProjectId),
                        doNotFetchCandidates: true,
                        action: getProject.type,
                    })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            handleException(error);
            dispatch(setErrorNotification("something went wrong while creating duplicate project"));
            throw new Error("something went wrong!");
        }
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submit();
    };

    if (!isModalOpen) {
        return null;
    }

    const isLoading = status === "LOADING";
    const isDisabled = isLoading;

    return (
        <BaseModal onClose={handleClose}>
            <Stack gap={1.3}>
                <Typography variant="h6">Add {t("project")} name</Typography>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    }}
                >
                    <FormControl
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Stack>
                            <TextField
                                type="text"
                                name="name"
                                variant="outlined"
                                sx={{ width: "100%", fontSize: "14px" }}
                                inputProps={{
                                    style: {
                                        padding: "0.7rem",
                                        fontSize: "14px",
                                    },
                                    maxLength: "50",
                                    minLength: "1",
                                }}
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <FormHelperText
                                sx={{
                                    mx: 0,
                                    fontStyle: "italic",
                                    fontWeight: "500",
                                }}
                            >
                                This will duplicate the vetting criteria and the outreach templates of this{" "}
                                {t("project")} in your new blank project.
                            </FormHelperText>
                        </Stack>
                        <Button
                            type="submit"
                            variant="default"
                            style={{ alignSelf: "flex-end" }}
                            disabled={isDisabled}
                        >
                            <ButtonTextWithLoading isLoading={false} text={`Create blank duplicate ${t("project")}`} variant="light" />
                        </Button>
                    </FormControl>
                </form>
            </Stack>
        </BaseModal>
    );
}
