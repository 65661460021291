import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../store";
import { useTriggerTestEmailMutation } from "../../../store/apis/outreach/outreach.api";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    selectCurrentOutreachElement,
    selectCurrentOutreachElementType,
} from "@/store/reducers/outreach/outreach.slice";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

export function TestEmailModal({ onClose }: { onClose: () => void }) {
    const user = useSelector(selectUser);
    const dispatch = useAppDispatch();
    const [triggerTestEmailMutation, { isLoading, ...saveMutationState }] = useTriggerTestEmailMutation();
    const { currentOutreachElementId, candidateId, projectId } = useOutreachParams();
    const currentOutreachElement = useSelector((state: RootState) =>
        selectCurrentOutreachElement(state, { templateId: currentOutreachElementId })
    );

    const currentElementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );

    const testEmail = () => {
        if (currentElementType !== "email" && currentElementType !== "follow-up") return;
        const body = currentOutreachElement.eventBody.body;
        const subject = (currentOutreachElement.eventBody as any).subject;
        const attachments = currentOutreachElement.attachments;

        triggerTestEmailMutation({
            email: user.email,
            projectId: projectId,
            candidateId: candidateId,
            subject: subject,
            body: body,
            attachments: attachments,
        })
            .unwrap()
            .then((res: any) => {
                dispatch(setSuccessNotification(res.message || "Test email sent successfully"));
                onClose();
            })
            .catch(() => {
                dispatch(setErrorNotification("Error in sending test email, please try again after some time"));
            });
    };

    return (
        <BaseModal onClose={onClose}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography className="text-[22px]" variant="h6">
                        Trigger a test email
                    </Typography>

                    <Typography variant="body2">
                        A test email for the selected candidate will be sent to your email address{" "}
                        <span style={{ fontWeight: 500, color: "#0891B2", fontStyle: "italic" }}>{user.email}</span> .
                        Do you want to send this?
                    </Typography>
                </Stack>

                <Stack gap={1.3} direction="row" style={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onClose} variant="outline">
                        No, do not send
                    </Button>
                    <Button onClick={() => testEmail()} disabled={isLoading} variant="default">
                        {isLoading ? "Loading..." : "Yes, send email"}
                    </Button>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
