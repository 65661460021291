import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

export interface AccordionProps {
    items: Array<{
        title: React.ReactNode;
        content: React.ReactNode;
        value: string;
        actions?: React.ReactNode;
    }>;
    className?: string;
    triggerClassName?: string;
    contentClassName?: string;
}

// Accordion Root component
/**
 * Accordion component that renders a list of items in an accordion format.
 *
 * @param {AccordionProps} props - The props for the Accordion component.
 * @param {Array} items - The items to be displayed in the accordion. Each item should have a `value`, `title`, `content`, and optionally `actions`.
 * @param {string} [className] - Additional class names to apply to the accordion root element.
 * @param {string} [contentClassName] - Additional class names to apply to the accordion content elements.
 *
 * @returns {JSX.Element} The rendered Accordion component.
 */
const Accordion = ({ items, className, triggerClassName, contentClassName, ...props }: AccordionProps) => (
    <AccordionPrimitive.Root
        type="multiple"
        className={cn("rounded-md border border-gray-200 shadow-sm", className)}
        {...props}
    >
        {items.map((item, index) => (
            <AccordionItem key={index} value={item.value}>
                <AccordionTrigger className={triggerClassName}>{item.title}</AccordionTrigger>
                <AccordionContent className={contentClassName}>
                    <div>{item.content}</div>
                    {item.actions && <div className="flex justify-end space-x-4 mt-4">{item.actions}</div>}
                </AccordionContent>
            </AccordionItem>
        ))}
    </AccordionPrimitive.Root>
);

// Accordion Item component
const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn("border-b border-gray-200 last:border-b-0", className)}
        {...props}
    />
));
AccordionItem.displayName = "AccordionItem";

// Accordion Trigger component
const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                "flex flex-1 items-center justify-between py-4 px-5 font-medium text-lg transition-all",
                "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50",
                "data-[state=open]:bg-gray-50",
                className
            )}
            {...props}
        >
            {children}
            <ChevronDown className="h-5 w-5 shrink-0 text-gray-500 transition-transform duration-300 ease-in-out data-[state=open]:rotate-180" />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

// Accordion Content component
const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={cn(
            "overflow-hidden text-sm transition-all duration-300 ease-in-out",
            "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
            className
        )}
        {...props}
    >
        <div className={cn("p-5", className)}>{children}</div>
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion };
