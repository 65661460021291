import { cn } from "@/lib/utils";

/**
 * A functional React component that renders a skeleton loading placeholder.
 * 
 * @param {React.HTMLAttributes<HTMLDivElement>} props - The props for the component.
 * @param {string} className - Additional class names to apply to the skeleton.
 * @returns {JSX.Element} A div element with skeleton loading styles.
 */
function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return <div className={cn("animate-pulse rounded-md bg-muted bg-slate-200", className)} {...props} />;
}

// eslint-disable-next-line import/no-unused-modules
export { Skeleton };
