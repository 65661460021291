import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { capitalize } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import CalendlyIcon from "../../../assets/img/calendly-logo.png";
import { RootState } from "../../../store";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { useTemplateEditorValues } from "../TemplateEditorProvider";

import { currentEditor, selectCurrentOutreachElementType } from "@/store/reducers/outreach/outreach.slice";
import { Tooltip } from "@/ui";
import { Popover } from "@/ui/Popover/Popover";
import { Stack } from "@/ui/Stack/Stack";

export function CalendlyMenu() {
    const { currentOutreachElementId } = useOutreachParams();
    const elementType = useSelector((state: RootState) =>
        selectCurrentOutreachElementType(state, {
            currentOutreachElementId,
        })
    );
    const {
        calendly: { showCalendlyMenu, calendlyActions, handleAddCalendlyLink },
    } = useTemplateEditorValues();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedValue, setSelectedValue] = useState("");
    const shorthandRef = useRef<HTMLInputElement>(null);
    const [shorthandValue, setShortHandValue] = useState("");
    const open = Boolean(anchorEl);
    const addTo = useSelector(currentEditor);
    const shortHandValueRequired = addTo === "editor1" && elementType !== "linkedin" && elementType !== "SMS";

    useEffect(() => {
        const open = Boolean(anchorEl);
        if (!open) {
            setSelectedValue("");
            setShortHandValue("");
        }
    }, [anchorEl]);

    if (!showCalendlyMenu) {
        return null;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue((event.target as HTMLInputElement).value);
        shorthandRef?.current?.focus();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getFormControlProps = (value: string, url: string = ""): FormControlLabelProps => {
        return {
            value: url,
            label: capitalize(value),
            sx: {
                "& .MuiTypography-root": {
                    fontSize: 14,
                },
            },
            control: (
                <Radio
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: 15,
                        },
                    }}
                />
            ),
        };
    };

    const handleSubmitShortHand = () => {
        if (shortHandValueRequired) {
            if (shorthandValue && selectedValue) {
                handleAddCalendlyLink(shorthandValue, selectedValue);
                handleClose();
            }
        } else {
            if (selectedValue) {
                handleAddCalendlyLink(shorthandValue, selectedValue);
                handleClose();
            }
        }
    };

    return (
        <>
            <div>
                <Tooltip title="Add calendly link">
                    <button onClick={handleClick} style={{ alignSelf: "flex-end", padding: 0 }}>
                        <img src={CalendlyIcon} />
                    </button>
                </Tooltip>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack p={1.5} gap={1}>
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                        }}
                    >
                        <RadioGroup value={selectedValue} onChange={handleSelectedValue}>
                            {calendlyActions.map(({ key, label, url }) => {
                                return <FormControlLabel {...getFormControlProps(label, url)} key={key} />;
                            })}
                        </RadioGroup>
                        {!shortHandValueRequired && (
                            <button onClick={handleSubmitShortHand}>
                                <NavigateNextRoundedIcon sx={{ fontSize: 14 }} />
                            </button>
                        )}
                    </Stack>
                    {shortHandValueRequired && (
                        <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            sx={{
                                height: "fit-content",
                                width: "fit-content",
                            }}
                        >
                            <input
                                value={shorthandValue}
                                onChange={(e) => setShortHandValue(e.target.value)}
                                placeholder="Enter display text"
                                className={`min-w-[300px] text-sm px-3 py-1 border rounded focus:outline-none focus:ring-2 border-gray-300 focus:ring-blue-500`}
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6, width: "max-content" }}
                            />
                            <button onClick={handleSubmitShortHand}>
                                <NavigateNextRoundedIcon sx={{ fontSize: 14 }} />
                            </button>
                        </Stack>
                    )}
                </Stack>
            </Popover>
        </>
    );
}
