import { Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./projectStats.module.scss";

import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    // getProjectCandidates,
    getProjectStats,
    selectProjectStats,
    selectProjectTotalCandidates,
} from "@/store/reducers/project/project.reducer";

export default function ProjectStats() {
    const { t } = useTranslation();
    const projectData = useSelector(selectCurrProject);
    const projectStats = useSelector(selectProjectStats);
    const isStatsLoading = useSelector(checkIfLoading(getProjectStats.type));
    // get calendarly details

    const filterCount = {
        PIPELINE: projectStats?.totalCandidate,
        CONTACTED: projectStats?.contacted,
        RESPONDED: projectStats?.responded,
    };

    const totalCandidates = useSelector(selectProjectTotalCandidates);
    // const isCandidatesLoading = useSelector(checkIfLoading(getProjectCandidates.type));

    return (
        <Stack
            direction="row"
            sx={{
                height: "fit-content",
                width: "fit-content",
            }}
        >
            <div className={style["sp__actionItem"]} style={{ color: "#90a0b7" }}>
                <p className={style["sp__createDate"]}>
                    Created on &nbsp;
                    {dayjs(projectData?.createdAt).format("MMM D, h:mm A")}
                </p>
            </div>
            <span style={{ margin: "0 0.7rem" }}>&#8226;</span>
            <div className={style["sp__actionItem"]}>
                {t("projectsPage.stats.total")} -&nbsp;
                <span> {filterCount["PIPELINE"] > totalCandidates ? filterCount["PIPELINE"] : totalCandidates}</span>
                {/* {isCandidatesLoading ? <Skeleton width={16} /> : <span>{totalCandidates}</span>} */}
                {/* {isStatsLoading ? <Skeleton width={16} /> : <span>{filterCount["PIPELINE"]}</span>} */}
            </div>
            <span style={{ margin: "0 0.7rem" }}>&#8226;</span>
            <div className={style["sp__actionItem"]}>
                {t("projectsPage.stats.contacted")} -&nbsp;
                {isStatsLoading ? <Skeleton width={16} /> : <span>{filterCount["CONTACTED"]}</span>}
            </div>
        </Stack>
    );
}
