import CareerBuilderLogo from "@/assets/img/Logo/careerBuilder.png";
import EasySourceAILogo from "@/assets/img/Logo/Easysoure_AI.png";
import GlassdoorLogo from "@/assets/img/Logo/glassdoor.png";
import GreenHouseLogo from "@/assets/img/Logo/Greenhouse_logo.png";
import HirequotientLogo from "@/assets/img/Logo/hirequotient_logo.png";
import IndeedLogo from "@/assets/img/Logo/indeedLogo.png";
import JazzHRLogo from "@/assets/img/Logo/jazzHr.png";
import LeverLogo from "@/assets/img/Logo/lever_logo.png";
import LinkedinLogo from "@/assets/img/Logo/Linkedin_logo.png";
import MonsterLogo from "@/assets/img/Logo/Monster_logo.png";
import NexxtLogo from "@/assets/img/Logo/nexxt_logo.png";
import RecruiterLogo from "@/assets/img/Logo/recruiter_logo.png";
import RecruiterFlowLogo from "@/assets/img/Logo/recruiterflow-logo.png";
import ResumeLibraryLogo from "@/assets/img/Logo/resumelibrary-logo.png";
import RocketReachLogo from "@/assets/img/Logo/rocketreach-logo.png";
import ZipRecruiterLogo from "@/assets/img/Logo/ziprecruiter_logo.svg";
import ZohoLogo from "@/assets/img/Logo/zoho.png";
import OracleLogo from "@/assets/img/OracleLogo.png";


export const sourceMapping = {
    EasyGrowth: "EasyGrowth",
    JAZZHR: "JazzHR",
    RECRUITER: "Recruiter",
    RocketReach: "RocketReach",
    Indeed: "Indeed",
    AI_CANDIDATE: "AI Candidate",
    GREENHOUSE: "Greenhouse",
    Linkedin: "LinkedIn",
    EasySource: "EasySource",
    "Career Builder": "Career Builder",
    LEVER: "Lever",
    RESUME_LIBRARY: "Resume Library",
    ZOHO: "Zoho",
    LINKEDIN: "LinkedIn",
    ATS: "ATS",
    Monster: "Monster",
    Nexxt: "Nexxt",
    Glassdoor: "Glassdoor",
    ZipRecruiter: "ZipRecruiter",
    Oracle : "Oracle"
};

export const sourceMappingImg = {
    RecruiterFlow: RecruiterFlowLogo,
    RESUME_LIBRARY: ResumeLibraryLogo,
    "Resume Library": ResumeLibraryLogo,
    "Resume-library": ResumeLibraryLogo,
    RocketReach: RocketReachLogo,
    JAZZHR: JazzHRLogo,
    GREENHOUSE: GreenHouseLogo,
    Indeed: IndeedLogo,
    LEVER: LeverLogo,
    Linkedin: LinkedinLogo,
    ZOHO: ZohoLogo,
    Monster: MonsterLogo,
    Nexxt: NexxtLogo,
    Glassdoor: GlassdoorLogo,
    ZipRecruiter: ZipRecruiterLogo,
    Oracle : OracleLogo ,
    LINKEDIN: LinkedinLogo,
    RECRUITER: LinkedinLogo,
    "Career Builder": CareerBuilderLogo,
    EasyGrowth: HirequotientLogo,
    AI_CANDIDATE: EasySourceAILogo,
    EasySource: EasySourceAILogo,
    ATS: HirequotientLogo,
};
