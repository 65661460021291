import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Chip, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import React from "react";
import { useDispatch } from "react-redux";

type PhonePopoverProps = {
    open: boolean;
    candidateNumber: string[];
    anchorEl: HTMLElement | null;
    ref?: any;
    handlePhonePopoverClose: (event: React.MouseEvent<HTMLElement>) => void;
    handlePhonePopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handlePhonePopoverToggle: (event: React.MouseEvent<HTMLElement>) => void;
};

const truncateText = (text: string, length: number) => {
    if (text) {
        if (text.length > length) {
            return text?.slice(0, length) + "...";
        }
        return text;
    }

    return text;
};

function PhonePopover({
    open,
    candidateNumber,
    anchorEl,
    handlePhonePopoverClose,
    handlePhonePopoverToggle,
    ref,
}: PhonePopoverProps) {
    const dispatch = useDispatch();
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        dispatch(setSuccessNotification("Phone number copied to clipboard!"));
    };
    return (
        <Popover
            sx={{
                pointerEvents: "none",
                "& .MuiPopover-paper": {
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    maxWidth: "300px",
                    width: "300px",
                },
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePhonePopoverToggle}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            disableRestoreFocus
            disableEnforceFocus
            ref={ref}
        >
            <Stack
                onMouseLeave={handlePhonePopoverClose}
                onMouseEnter={handlePhonePopoverToggle}
                sx={{
                    p: 3,
                    bgcolor: "background.paper",
                    pointerEvents: "all",
                }}
                gap={1}
                onClick={(e) => e.stopPropagation()}
            >
                {candidateNumber?.length === 0 && (
                    <Typography variant="body2" sx={{ color: "#6C6C6C" }}>
                        No phone numbers available
                    </Typography>
                )}

                {candidateNumber?.length > 0 && (
                    <>
                        <Typography variant="subtitle2" gutterBottom>
                            Phone Numbers
                        </Typography>
                        <Stack sx={{ maxHeight: "110px", overflowY: "auto" }}>
                            {candidateNumber?.map((item, index) => (
                                <Chip
                                    key={index}
                                    label={truncateText(item, 40)}
                                    onDelete={() => handleCopyToClipboard(item)}
                                    deleteIcon={<ContentCopyIcon sx={{ fontSize: "15px", color: "#1976d2" }} />}
                                    sx={{
                                        marginBottom: "2px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "5px",
                                        borderRadius: "4px",
                                    }}
                                />
                            ))}
                        </Stack>
                    </>
                )}
            </Stack>
        </Popover>
    );
}

type PhoneProps = {
    PhoneNumber: string;
    state?: string;
};

// function Phone({ PhoneNumber, state }: PhoneProps) {
//     state = "allowed";

//     const renderIcon = () => {
//         switch (state) {
//             case "not-allowed":
//                 return (
//                     <Tooltip title="SMS Not Allowed" placement="left" arrow>
//                         <SmsFailedOutlinedIcon sx={{ color: "error.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             case "allowed":
//                 return (
//                     <Tooltip title="SMS Allowed" placement="left" arrow>
//                         <TextsmsOutlinedIcon sx={{ color: "success.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//             default:
//                 return (
//                     <Tooltip title="SMS Unknown" placement="left" arrow>
//                         <TextsmsOutlinedIcon sx={{ color: "primary.main", fontSize: "medium" }} />
//                     </Tooltip>
//                 );
//         }
//     };
//     return (
//         <Stack direction="row" alignItems="center" gap={1}>
//             {renderIcon()}
//             <CopyableText
//                 textToCopy={PhoneNumber}
//                 message="Phone number copied to clipboard!"
//                 onClick={(e: any) => {
//                     e.stopPropagation();
//                 }}
//             >
//                 <Typography
//                     variant="body2"
//                     sx={{
//                         color: "#6C6C6C",
//                         fontSize: "12px",
//                     }}
//                 >
//                     {PhoneNumber}
//                 </Typography>
//             </CopyableText>
//         </Stack>
//     );
// }

export default PhonePopover;
