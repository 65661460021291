import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { useState } from "react";

import UploadSection from "./UploadSection";

import BaseModal from "../components/BaseModal";

import { Modal, Tooltip } from "@/ui";

export default function FileUpload() {
    const [showModal, setShowModal] = useState(false);
    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    return (
        <>
            <Tooltip title="Attach Email files" placement="top">
                <button style={{ margin: 0, padding: "5px" }} onClick={handleOpen}>
                    <AttachFileRoundedIcon fontSize="medium" />
                </button>
            </Tooltip>
            <Modal open={showModal} onClose={handleClose}>
                <BaseModal onClose={handleClose}>
                    <UploadSection close={handleClose} />
                </BaseModal>
            </Modal>
        </>
    );
}
