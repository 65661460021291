import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import CustomButton from "../../../components/CustomButton";
import { useAsyncFn } from "../../../hooks/useAsyncFn";
import API from "../../../utils/API";
import handleException from "../../../utils/sentry";
import { availableFields } from "../AllContactFilters";

import {
    selectAllContacts,
    selectAllContactsSearchResults,
    selectAllContactsSelected,
    selectSearchQueryInAllContacts,
    toggleSelectAllSearchResults,
} from "@/store/apis/all-contacts/all-contacts.slice";
import { ExportWithSearchResultsRequestPayload, FetchContactsResponse } from "@/store/apis/all-contacts/all-contacts.types";


const headers = availableFields.map((field) => ({
    label: field,
    key: field,
}));

export default function ExportContacts() {
    const dispatch = useDispatch();
    const csvLink = useRef();
    const selectedContacts = useSelector(selectAllContactsSelected);
    const [exportRows, setSelectedRows] = useState<any>([]);
    const rows = useSelector(selectAllContacts);
    const isAllSelected = useSelector(selectAllContactsSearchResults);
    const searchQuery = useSelector(selectSearchQueryInAllContacts);
    const handleLinkClick = () => {
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
        });
    };
    const { status, submit } = useAsyncFn(async () => {
        try {
            const payload: ExportWithSearchResultsRequestPayload = {
                selectAll: isAllSelected,
                search: searchQuery,
            };

            const response = (await new API().post("/contact-list/contacts/export", payload)) as FetchContactsResponse;

            if (response?.data?.length) {
                setSelectedRows(response.data);
                handleLinkClick();
                dispatch(toggleSelectAllSearchResults());
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            handleException(error);
            throw new Error("something went wrong!");
        }
    });

    const handleClick = () => {
        if (isAllSelected) {
            return submit();
        } else {
            const exportData = rows.filter((contact) => selectedContacts.includes(contact._id));
            setSelectedRows(exportData);
            handleLinkClick();
        }
    };

    const isLoading = status === "LOADING";
    const isContactsSelected = !!selectedContacts.length || isAllSelected;

    const isDisabled = isLoading || !isContactsSelected || (isAllSelected && !searchQuery);

    return (
        <>
            <CustomButton
            
                startDecorator={<FileUploadRoundedIcon />}
                onClick={handleClick}
                disabled={isDisabled}
            >
                <ButtonTextWithLoading isLoading={isLoading} text="Export" />
            </CustomButton>
            <CSVLink
                headers={headers}
                data={exportRows}
                filename={`exported_contacts.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
}
