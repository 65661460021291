import React, { forwardRef, useState, ChangeEvent, FocusEvent } from 'react';
import { AlertCircle, CheckCircle2 } from 'lucide-react';

type TextFieldSize = 'small' | 'medium' | 'large';
type TextFieldVariant = 'outlined' | 'filled';
type TextFieldColor = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

interface TextFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, 'size'> {
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  label?: React.ReactNode;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  type?: string;
  value?: string;
  size?: TextFieldSize;
  variant?: TextFieldVariant;
  color?: TextFieldColor;
  className?: string;
}

/**
 * `TextField` is a React functional component that renders an input or textarea element with various customizable properties.
 * It supports both controlled and uncontrolled input values and provides styles for different states such as focus, error, and disabled.
 * 
 * @param {Object} props - The properties object.
 * @param {string} [autoComplete=''] - The autocomplete attribute for the input element.
 * @param {boolean} [autoFocus=false] - If true, the input element will be focused automatically.
 * @param {string} [defaultValue=''] - The default value of the input element.
 * @param {boolean} [disabled=false] - If true, the input element will be disabled.
 * @param {boolean} [error=false] - If true, the input element will be styled to indicate an error.
 * @param {boolean} [fullWidth=false] - If true, the input element will take up the full width of its container.
 * @param {string} [helperText=''] - The helper text to display below the input element.
 * @param {string} id - The id attribute for the input element.
 * @param {string} label - The label text for the input element.
 * @param {number} [maxRows] - The maximum number of rows for a multiline input element.
 * @param {number} [minRows] - The minimum number of rows for a multiline input element.
 * @param {boolean} [multiline=false] - If true, a textarea element will be rendered instead of an input element.
 * @param {string} name - The name attribute for the input element.
 * @param {function} onChange - The change event handler for the input element.
 * @param {string} placeholder - The placeholder text for the input element.
 * @param {boolean} [required=false] - If true, the input element will be marked as required.
 * @param {number} [rows] - The number of rows for a multiline input element.
 * @param {string} [type='text'] - The type attribute for the input element.
 * @param {string} value - The value of the input element.
 * @param {string} [size='medium'] - The size of the input element. Can be 'small', 'medium', or 'large'.
 * @param {string} [variant='outlined'] - The variant of the input element. Can be 'outlined' or 'filled'.
 * @param {string} [color='primary'] - The color of the input element.
 * @param {string} [className=''] - Additional class names to apply to the input element.
 * @param {Object} ref - The ref object to attach to the input element. 
 * @returns {JSX.Element} The rendered input or textarea element.
 */
const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>(({
  // Input props
  autoComplete = '',
  autoFocus = false,
  defaultValue = '',
  disabled = false,
  error = false,
  fullWidth = false,
  helperText = '',
  id,
  label,
  maxRows,
  minRows,
  multiline = false,
  name,
  onChange,
  placeholder,
  required = false,
  rows,
  type = 'text',
  value,
  size = 'medium',
  variant = 'outlined',
  color = 'primary',
  
  // Additional props
  className = '',
  ...props
}, ref) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(Boolean(defaultValue || value));

  // Handle controlled/uncontrolled input value
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHasValue(Boolean(e.target.value));
    onChange?.(e);
  };

  // Generate base classes
  const getBaseClasses = (): string => {
    const baseClasses = [
      'relative w-full rounded border transition-all duration-200',
      'focus:outline-none focus:ring-2',
      disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white',
      fullWidth ? 'w-full' : 'max-w-sm',
    ];

    // Variant styles
    if (variant === 'outlined') {
      baseClasses.push(
        error 
          ? 'border-red-500 focus:ring-red-200' 
          : 'border-gray-300 focus:ring-blue-200 focus:border-blue-500'
      );
    } else if (variant === 'filled') {
      baseClasses.push('bg-gray-100 border-transparent');
    }

    // Size styles
    if (size === 'small') {
      baseClasses.push('p-1 text-sm');
    } else if (size === 'medium') {
      baseClasses.push('p-2');
    } else if (size === 'large') {
      baseClasses.push('p-3 text-lg');
    }

    return baseClasses.join(' ');
  };

  // Label animation classes
  const getLabelClasses = (): string => {
    const labelClasses = [
      'absolute left-3 transition-all duration-200 pointer-events-none',
      error ? 'text-red-500' : 'text-gray-500',
      disabled ? 'text-gray-400' : '',
    ];

    if (isFocused || hasValue) {
      labelClasses.push('-top-2 text-xs bg-white px-1');
    } else {
      labelClasses.push('top-2');
    }

    return labelClasses.join(' ');
  };

  return (
    <div className={`relative ${fullWidth ? 'w-full' : 'max-w-sm'} ${className}`}>
      <div className={getBaseClasses()}>
        {label && (
          <label htmlFor={id} className={getLabelClasses()}>
            {label}{required && ' *'}
          </label>
        )}
        
        {multiline ? (
          <textarea
            ref={ref as React.Ref<HTMLTextAreaElement>}
            id={id}
            name={name}
            rows={rows || minRows}
            maxLength={maxRows}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className="w-full bg-transparent resize-none focus:outline-none"
            {...props}
          />
        ) : (
          <input
            ref={ref as React.Ref<HTMLInputElement>}
            type={type}
            id={id}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className="w-full bg-transparent focus:outline-none"
            {...props}
          />
        )}
      </div>

      {/* Helper text and error/success icons */}
      {(helperText || error) && (
        <div className="flex items-center mt-1 space-x-1">
          {error && <AlertCircle className="w-4 h-4 text-red-500" />}
          {helperText && (
            <span className={`text-xs ${error ? 'text-red-500' : 'text-gray-500'}`}>
              {helperText}
            </span>
          )}
        </div>
      )}
    </div>
  );
});

TextField.displayName = 'TextField';

export  {TextField};