import { useState } from "react";

import Section from "../../molecules/Section/Section";

import { TLeaderBoardFilter } from "@/store/apis/analytics/analytics.types";
import { Select } from "@/ui";

export default function LeaderBoardFilters({
    children,
    ...props
}: {
    children?: React.ReactNode | ((filters: TLeaderBoardFilter) => React.ReactNode);
}) {
    const [channel, setChannel] = useState<TLeaderBoardFilter["channel"]>("all");

    const filters = {
        channel,
    };

    return (
        <Section className="bg-white p-1">
            <div className="flex flex-row items-center gap-2 py-2" {...props}>
                {/* <Input
                    label="Targets"
                    className="h-10"
                    type="number"
                    value={targetsValue}
                    onChange={(value) => {
                        setTargetsValue(parseInt(value));
                    }}
                /> */}
                <Select
                    size="sm"
                    className="text-sm min-w-[120px]"
                    value={channel}
                    options={[
                        {
                            label: "All",
                            value: "all",
                        },
                        {
                            label: "Email",
                            value: "email",
                        },
                        {
                            label: "SMS",
                            value: "sms",
                        },
                        {
                            label: "LinkedIn",
                            value: "linkedin",
                        },
                        {
                            label: "Calling",
                            value: "calling",
                        },
                        {
                            label: "RB2B",
                            value: "website_traffic",
                        },
                    ]}
                    onChange={(value) => setChannel(value as TLeaderBoardFilter["channel"])}
                />
            </div>
            {typeof children === "function" ? children(filters) : children}
        </Section>
    );
}
