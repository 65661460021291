import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../components/ButtonTextWithLoading";
import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";

import {
    selectCurrProject,
    updateProjectName,
} from "@/store/reducers/allProjects/allProjects.reducer";
import { updateJd } from "@/store/reducers/create-project/CreateProject.reducer";
import { campaignObjective, outreachChannel } from "@/store/reducers/project/project.types";

import { Button } from "@/ui";

type Props = {
    close: () => void;
};

function EditCampaignName({ project }: { project: string }) {
    const dispatch = useDispatch();
    const [text, setText] = useState(project);
    return (
        <Stack spacing={1}>
            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                Edit campaign name
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                <TextField
                    type="text"
                    placeholder="Project Name"
                    value={text}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                    onBlur={() => dispatch(updateProjectName(text))}
                    inputProps={{
                        style: {
                            padding: "0.7rem",
                            fontSize: "14px",
                        },
                    }}
                    sx={{ marginBottom: "10px" }}
                />
            </FormControl>
        </Stack>
    );
}

export default function EditCampaign({ close }: Props) {
    const dispatch = useDispatch();
    const project = useSelector(selectCurrProject);
    const isUpdatingProject = useSelector(checkIfLoading(updateJd.type));
    const [objective, setObjective] = useState("");
    const [platform, setPlatform] = useState("");

    useEffect(() => {
        const { objective, outreachChannel } = project || {};
        setPlatform(outreachChannel || "");
        setObjective(objective || "");
    }, [project]);

    if (!project?.name) {
        return null;
    }

    const handleUpdateProject = () => {
        dispatch(updateJd({ objective, platform }));
    };

    const handleEditProject = () => {
        window.open(`/generate-prospects?project=${project._id}&name=${project.name}&isEdit=true`);
    };

    return (
        <>
            <BaseModal onClose={close} overlayStyles={{ width: "30vw" }}>
                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <EditCampaignName project={project?.name} />
                        <FormControl size="small">
                            <InputLabel id="demo-select-small-label">Campaign Objective</InputLabel>
                            <Select
                                labelId="campaignObjective-select"
                                id="campaignObjective-select"
                                value={objective}
                                label="Campaign Objective"
                                sx={{ marginBottom: "10px" }}
                            >
                                {Object.keys(campaignObjective).map((item: string, i: number) => (
                                    <MenuItem
                                        value={campaignObjective[item]}
                                        onClick={() => setObjective(campaignObjective[item])}
                                        key={i}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel id="demo-select-small-label">Outreach Channel</InputLabel>
                            <Select
                                value={platform}
                                onChange={(e) => setPlatform(e.target.value)}
                                label="Outreach Channel"
                            >
                                {Object.keys(outreachChannel).map((item: string, i: number) => (
                                    <MenuItem key={i} value={outreachChannel[item]}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* {isEasySource && (
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography>Send emails to professional email ids only</Typography>
                                <Switch
                                    checked={Boolean(project.onlyPersonalEmail)}
                                    onChange={(e) => dispatch(updateProjectOnlyPersonalEmail(e.target.checked))}
                                />
                            </Stack>
                        )} */}

                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                        <Button variant="outline" onClick={handleEditProject}>
                            <ButtonTextWithLoading isLoading={false} text="Edit campaign search" />
                        </Button>
                        <Button variant="default" onClick={handleUpdateProject}>
                            <ButtonTextWithLoading isLoading={isUpdatingProject} text="Update" variant="light" />
                        </Button>
                    </Stack>
                </Stack>
            </BaseModal>
        </>
    );
}
