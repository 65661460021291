import { Stack } from "@mui/joy";
import { Experimental_CssVarsProvider } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AnalyticsReportTemplate from "./AnalyticsReportTemplate";

import MembersDropdown from "../../../../components/MembersDropdown";
import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import MultiSelectDropdown from "../MultiSelect";

import BaseModal from "@/components/BaseModal";
import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import ProjectsDropdown from "@/pages/AnalyticsV2/components/ProjectsDropdown";
import AnalyticsDateRangePicker from "@/pages/AnalyticsV3/components/AnalyticsDateRangePicker";
import {
    useGetCreditUsageQuery,
    useGetFunnelOverviewQuery,
    useGetGraphQuery,
    useGetMultipleUserFunnelQuery,
    useGetOutreachStatsQuery,
    useGetProjectsStatusQuery,
} from "@/store/apis/analytics/analytics.api";
import { checkEasySource, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Typography } from "@/ui";
import { TabsComponent } from "@/ui/Tabs/Tabs";

function transformObjectToArray(obj: any) {
    const label: string[] = [];
    const data: number[] = [];

    Object.keys(obj).forEach(function (key, _index) {
        label.push(key);
        // @ts-ignore
        data.push(obj[key]);
    });
    return {
        label,
        data,
    };
}

function transformGraphData(obj: any) {
    const label: string[] = [];
    const relevantData: number[] = [];
    const extractedData: number[] = [];

    for (const key in obj) {
        label.push(dayjs(key).format("MMM D, YYYY"));
        const element = obj?.[key];
        relevantData.push(element.qualifiedCandidates || 0);
        extractedData.push(element.sourcedCandidates || 0);
    }

    return { label, extractedData, relevantData };
}

function transformFunnelData(obj: any) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];

    const label: string[] = [];
    const data: number[] = [];

    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        label.push(key);
        data.push(obj[key]);
    }

    return {
        label,
        data,
    };
}

function transformMultipleUserFunnelData(arr: any[]) {
    const labelMapping = {
        relevantCandidates: "Relevant Candidates",
        totalContacted: "Total Contacted",
        responsesReceived: "Responses Received",
        positiveResponses: "Positive Responses",
    };

    const defaultChartColors = ["#00B7C7", "#EBDC78", "#0D88E6", "#7C1158", "#4421AF", "#5AD45A", "#8BE04E", "#EBDC78"];

    const transformedData: { label: string; data: any[]; backgroundColor: string }[] = [];

    // Extract display labels from the mapping object
    const displayLabels = Object.values(labelMapping);

    if (!arr || arr.length === 0 || !arr[0]?.data?.counts) {
        return {
            labels: displayLabels,
            datasets: [],
        };
    } else {
        arr.forEach((item) => {
            const data = item?.data?.counts;
            const user = item?.userName;
            const userLabel = user ? user : "User";
            const userLabelData = [];

            // Use the keys of the labelMapping object to get the data
            for (const key in labelMapping) {
                userLabelData.push(data[key]);
            }

            transformedData.push({
                label: userLabel,
                data: userLabelData,
                backgroundColor: defaultChartColors[transformedData.length % defaultChartColors.length],
            });
        });
        return {
            labels: displayLabels,
            datasets: transformedData,
        };
    }
}

function transformChannelSplitData(obj: any) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];

    let linkedin: number[] = [];
    let email: number[] = [];
    let sms: number[] = [];
    for (let i = 0; i < order.length; ++i) {
        const data = obj[order[i]];
        const _linkedin = data?.find((elem: any) => elem?._id === "linkedin")?.count || 0;
        const _email = data?.find((elem: any) => elem?._id === "email")?.count || 0;
        const _sms = data?.find((elem: any) => elem?._id === "sms")?.count || 0;
        linkedin.push(_linkedin);
        email.push(_email);
        sms.push(_sms);
    }

    const sumArr = [0, 0, 0, 0, 0];
    linkedin.forEach((item, index) => {
        sumArr[index] += item;
    });
    email.forEach((item, index) => {
        sumArr[index] += item;
    });
    sms.forEach((item, index) => {
        sumArr[index] += item;
    });
    linkedin = linkedin.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });

    email = email.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });
    sms = sms.map((item, index) => {
        const sum = sumArr[index];
        if (sum === 0) return item;
        return (item / sum) * 100;
    });

    return {
        label: order,
        data: {
            linkedin,
            email,
            sms,
        },
    };
}

function transformSourceSplitData(obj: any, platformName: string) {
    const order = ["relevantCandidates", "totalContacted", "responsesReceived", "positiveResponses", "meetingsBooked"];
    const transformedData: any = {};
    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        obj?.[key]?.forEach((item: any) => {
            let sourceKey = item?._id;
            if (!sourceKey) {
                sourceKey = platformName;
            }
            transformedData[sourceKey] = [0, 0, 0, 0, 0];
        });
    }

    const processCategory = (category: any, index: number) => {
        obj?.[category]?.forEach((item: any) => {
            let sourceKey = item?._id;
            if (!sourceKey) {
                sourceKey = platformName;
            }
            transformedData[sourceKey][index] = transformedData[sourceKey][index] + item?.count;
        });
    };
    for (let i = 0; i < order.length; ++i) {
        const key = order[i];
        processCategory(key, i);
    }
    const sumArr = [0, 0, 0, 0, 0];
    Object.keys(transformedData).forEach((key) => {
        transformedData?.[key].forEach((value: number, index: number) => {
            sumArr[index] += value;
        });
    });
    Object.keys(transformedData).forEach((key) => {
        transformedData?.[key].forEach((value: number, index: number) => {
            if (sumArr[index] !== 0) {
                const newValue = (value / sumArr[index]) * 100;
                transformedData[key][index] = newValue;
            }
        });
    });

    return {
        label: order,
        data: transformedData,
    };
}

const tabTypes = {
    "Member Wise Funnel Filter": 0,
    "Funnel Filter": 1,
    "Statistics Filter": 2,
};

const GenerateReportModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    const [userIdFunnel, setUserIdFunnel] = useState<number>(0);
    const [userNameFunnel, setUserNameFunnel] = useState<string>(t("analytics.memberListDropdownLabel"));
    const [userIdStats, setUserIdStats] = useState<number>(0);
    const [userNameStats, setUserNameStats] = useState<string>(t("analytics.memberListDropdownLabel"));
    const [projectIdFunnel, setProjectIdFunnel] = useState<number>(0);
    const [projectNameFunnel, setProjectNameFunnel] = useState<string>(t("analytics.projectListDropdownLabel"));
    const [projectIdStats, setProjectIdStats] = useState<number>(0);
    const [projectNameStats, setProjectNameStats] = useState<string>(t("analytics.projectListDropdownLabel"));
    const [dateFunnel, setDateFunnel] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [dateStats, setDateStats] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [dateMultiUserFunnel, setDateMultiUserFunnel] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const [downloading, setDownloading] = useState(false);
    const [userProjects, setUserProjects] = useState([]);

    const user = useSelector(selectUser);
    const isEasySource = useSelector(checkEasySource);
    const platformName = isEasySource ? "EasySource" : "EasyGrowth";

    const dateFunnelText = `${dateFunnel[0]?.format("MMM D, YYYY") ?? "--"} - ${
        dateFunnel[1]?.format("MMM D, YYYY") ?? "--"
    }`;
    const dateStatsText = `${dateStats[0]?.format("MMM D, YYYY") ?? "--"} - ${
        dateStats[1]?.format("MMM D, YYYY") ?? "--"
    }`;

    const pdfLink = useRef<HTMLAnchorElement | null>(null);
    const [activeTab, setActiveTab] = useState(0);

    const filtersFunnels = {
        projectId: projectIdFunnel,
        userId: userIdFunnel,
        startDate: dateFunnel[0]?.format(),
        endDate: dateFunnel[1]?.format(),
    };

    const filterStats = {
        userId: userIdStats,
        projectId: projectIdStats,
        startDate: dateStats[0]?.format(),
        endDate: dateStats[1]?.format(),
    };

    const multiUserFilters = {
        userProjects,
        startDate: dateMultiUserFunnel[0]?.format(),
        endDate: dateMultiUserFunnel[1]?.format(),
    };

    const { data: funnelData, isFetching: fetchingFunnelData } = useGetFunnelOverviewQuery(filtersFunnels, {
        skip: !dateFunnel[0] || !dateFunnel[1],
    });

    const { data: multiUsersFunnelData, isFetching: fetchingMultipleUsersFunnelData } = useGetMultipleUserFunnelQuery(
        multiUserFilters,
        {
            skip: !dateMultiUserFunnel[0] || !dateMultiUserFunnel[1],
        }
    );

    const { data: outreachData, isFetching: fetchingOutreach } = useGetOutreachStatsQuery(filtersFunnels, {
        skip: !dateFunnel[0] || !dateFunnel[1],
    });

    const { data: projectsData, isFetching: fetchingProjects } = useGetProjectsStatusQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const { data: creditUsageData, isFetching: fetchingCreditUsage } = useGetCreditUsageQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const { data: graphData, isFetching: fetchingGraph } = useGetGraphQuery(filterStats, {
        skip: !dateStats[0] || !dateStats[1],
    });

    const onPdfDownload = () => {
        setDownloading(true);
        setTimeout(() => {
            // @ts-ignore
            if (pdfLink.current) {
                pdfLink.current.click();
            }
            setDownloading(false);
        }, 10000);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const loading =
        fetchingFunnelData ||
        fetchingProjects ||
        fetchingCreditUsage ||
        fetchingGraph ||
        fetchingOutreach ||
        fetchingMultipleUsersFunnelData;
    const disabled = loading || downloading;

    const tabs = [
        {
            label: "Member Wise Funnel Filter",
            content: (
                <Experimental_CssVarsProvider>
                    <Stack flexDirection={"column"} gap={0.5}>
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Members
                            </Typography>
                            <MultiSelectDropdown userProjects={userProjects} setUserProjects={setUserProjects} />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Date
                            </Typography>
                            <AnalyticsDateRangePicker
                                disabled={downloading}
                                hideLabel
                                value={dateMultiUserFunnel}
                                onChange={setDateMultiUserFunnel}
                                defaultOption="Last 3 months"
                            />
                        </Stack>
                    </Stack>
                </Experimental_CssVarsProvider>
            ),
        },
        {
            label: "Funnel Filter",
            content: (
                <Experimental_CssVarsProvider>
                    <Stack flexDirection={"column"} gap={0.5}>
                        {user?.roleType === "ADMIN" && (
                            <Stack direction={"row"} alignItems={"center"} gap={3}>
                                <Typography variant="body2" className="w-[150px]">
                                    Select Member
                                </Typography>
                                {/* <MembersDropdown
                                    disabled={downloading}
                                    value={userIdFunnel}
                                    onChange={(userId: number) => {
                                        setUserIdFunnel(userId);
                                        setProjectIdFunnel(0);
                                    }}
                                    callback={(userName) => {
                                        setUserNameFunnel(userName);
                                    }}
                                /> */}
                                <MembersDropdown value={userIdFunnel} onChange={setUserIdFunnel} />
                            </Stack>
                        )}
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Project
                            </Typography>
                            <ProjectsDropdown
                                disabled={downloading}
                                userId={userIdFunnel}
                                value={projectIdFunnel}
                                onChange={setProjectIdFunnel}
                                callback={(value) => setProjectNameFunnel(value)}
                            />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={3}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Date
                            </Typography>
                            <AnalyticsDateRangePicker
                                disabled={downloading}
                                hideLabel
                                value={dateFunnel}
                                onChange={setDateFunnel}
                                defaultOption="Last 3 months"
                            />
                        </Stack>
                    </Stack>
                </Experimental_CssVarsProvider>
            ),
        },
        {
            label: "Statistics Filter",
            content: (
                <Experimental_CssVarsProvider>
                    <Stack flexDirection={"column"} gap={0.5}>
                        {user?.roleType === "ADMIN" && (
                            <Stack direction={"row"} alignItems={"center"} gap={3}>
                                <Typography variant="body2" className="w-[150px]">
                                    Select Member
                                </Typography>
                                {/* <MembersDropdown
                                    disabled={downloading}
                                    value={userIdStats}
                                    onChange={(userId: number) => {
                                        setUserIdStats(userId);
                                        setProjectIdStats(0);
                                    }}
                                    callback={(userName) => {
                                        setUserNameStats(userName);
                                    }}
                                /> */}
                                <MembersDropdown value={userIdStats} onChange={setUserIdStats} />
                            </Stack>
                        )}
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Project
                            </Typography>
                            <ProjectsDropdown
                                disabled={downloading}
                                userId={userIdStats}
                                value={projectIdStats}
                                onChange={setProjectIdStats}
                                callback={(value) => setProjectNameStats(value)}
                            />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={3}>
                            <Typography variant="body2" className="w-[150px]">
                                Select Date
                            </Typography>
                            <AnalyticsDateRangePicker
                                disabled={downloading}
                                hideLabel
                                value={dateStats}
                                onChange={setDateStats}
                                defaultOption="Last 3 months"
                            />
                        </Stack>
                    </Stack>
                </Experimental_CssVarsProvider>
            ),
        },
    ];

    return (
        <>
            {isOpen || downloading ? (
                <BaseModal
                    onClose={onClose}
                    overlayStyles={{
                        padding: "0",
                        maxWidth: "500px",
                    }}
                >
                    <div className="flex p-4 items-center bg-white border-b border-divider rounded-t-lg">
                        <Typography variant="subtitle1" className="font-medium">
                            Generate Analytics Report
                        </Typography>
                    </div>
                    <div className="bg-[#f6f8fa]">
                        <>
                            <TabsComponent
                                value={tabs[activeTab].label}
                                onValueChange={(value) => {
                                    handleTabChange(null, tabTypes[value as keyof typeof tabTypes]);
                                }}
                                tabs={tabs}
                                contentStyle="p-4"
                            />
                        </>
                    </div>
                    <div className="flex items-center justify-end py-4 px-4 bg-white border-t border-divider rounded-b-lg">
                        <div className="flex gap-2 items-center justify-end">
                            <Button variant="outline" size="sm" disabled={disabled} onClick={onClose} label="Cancel" />

                            <Button size="sm" variant="default" disabled={disabled} onClick={onPdfDownload}>
                                <ButtonTextWithLoading
                                    isLoading={loading}
                                    text={downloading ? "Downloading" : "Download report"}
                                    variant="light"
                                />
                                {loading && <span className="ml-2">Loading</span>}
                            </Button>
                        </div>
                    </div>
                </BaseModal>
            ) : null}

            <BlobProvider
                document={
                    <AnalyticsReportTemplate
                        analyticsMemberFilter={userNameFunnel}
                        analyticsProjectFilter={projectNameFunnel}
                        analyticsDateFilter={dateFunnelText}
                        usageStatsMemberFilter={userNameStats}
                        usageStatsProjectFilter={projectNameStats}
                        usageStagsDateFilter={dateStatsText}
                        multiUserFunnelData={transformMultipleUserFunnelData(
                            multiUsersFunnelData || { labels: [], datasets: [] }
                        )}
                        funnelData={transformFunnelData(funnelData?.counts || {})}
                        funnelSplitDataChannel={transformChannelSplitData(funnelData?.graphs.channelGraph || {})}
                        funnelSplitDataSource={transformSourceSplitData(
                            funnelData?.graphs.sourceGraph || {},
                            platformName
                        )}
                        outreachData={{
                            linkedin: transformObjectToArray(outreachData?.linkedin || {}),
                            email: transformObjectToArray(outreachData?.email || {}),
                            sms: transformObjectToArray(outreachData?.sms || {}),
                        }}
                        usageStats={{
                            projectStatus: transformObjectToArray(projectsData || {}),
                            creditUsage: transformObjectToArray(creditUsageData || {}),
                        }}
                        graphData={transformGraphData(graphData?.chartLabel || {})}
                        isEasySource={isEasySource}
                    />
                }
            >
                {({ url }) => {
                    if (!url) return null;
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            ref={pdfLink}
                            href={url}
                            style={{ display: "none" }}
                            download={`AnalyticsReport_${user.name}_${new Date().toLocaleDateString("en-US")}`}
                            target="_blank"
                            rel="noreferrer"
                        />
                    );
                }}
            </BlobProvider>
        </>
    );
};

export default GenerateReportModal;
