import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { submitResumeSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { Keywords } from "../../Keywords";
import { BSAutoComplete } from "../Filters/components/BSAutoComplete";
import { BSSelect } from "../Filters/components/BSSelect";
import { Location } from "../Filters/Location";
import { SalaryRange } from "../Filters/SalaryRange";

import Loader from "@/components/Loader/Loader";
import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { Button, Typography } from "@/ui";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitResumeSearch.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";

    const handleSubmit = () => dispatch(submitResumeSearch({ navigate }));

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`flex flex-col p-3 pb-0 space-y-2 bg-white border border-[rgba(0,0,0,0.1)] rounded-md ${
                        disabled ? "pointer-events-none" : ""
                    }`}
                    ref={ref}
                >
                    {projectId ? (
                        <Typography className="text-[14px]">Edit {currProjectName} project</Typography>
                    ) : (
                        <>
                            <input
                                placeholder="Project Name"
                                value={projectName}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSearchParams({
                                            name: e.target.value,
                                        });
                                    } else {
                                        searchParams.delete("name");
                                        setSearchParams(searchParams);
                                    }
                                }}
                                className="min-w-[300px] text-sm px-3 py-1 border-b border-gray-300 focus:outline-none focus:border-b focus:border-[#0891B2]"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {errorInSearch && (
                                <span className="text-xs text-red-500 mt-1">Please enter project name</span>
                            )}
                        </>
                    )}

                    <Keywords id="keywords" multiple={false} />
                    <div className="flex flex-row items-center gap-1.5">
                        <Location id="location" />
                        <BSSelect id="distance" label="Distance" />
                    </div>
                    <Typography className="text-[12px]" component={"strong"}>
                        Format for location input
                    </Typography>
                    <div className="px-2">
                        <ul>
                            <Typography className="text-[12px]" component={"i"}>
                                <li>
                                    Write <b>State of Arizona</b> to find candidates in the state Arizona{" "}
                                </li>
                            </Typography>
                            <Typography className="text-[12px]" component={"i"}>
                                <li>
                                    Write <b>Denver, Colorado</b> or <b>Denver, CO</b> to find candidates in the city
                                    Denver
                                </li>
                            </Typography>
                        </ul>
                    </div>
                    <SalaryRange id="salaryRange" />
                    <BSAutoComplete id="jobType" placeholder="Job Type" />
                    <BSSelect id="activeWithin" label="Active within" />
                    <BSAutoComplete id="education" placeholder="Education" />
                    <BSAutoComplete id="yearsOfExperience" placeholder="Years Of Experience"/>

                    {!disabled && (
                        <div className="flex justify-center bg-white sticky gap-2 bottom-0 border-t border-[rgba(0,0,0,0.1)] py-1">
                            <Button variant="default" onClick={handleSubmit} disabled={isLoading}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" variant="light"/>
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
