import { Document, Font, Image, Link, Page, Text, View } from "@react-pdf/renderer";
import { ReactNode } from "react";

import Cross from "./template-assets/cross.png";
import FullStar from "./template-assets/full-star.png";
import GreyStar from "./template-assets/grey-star.png";
import Linkedin from "./template-assets/linkedin.png";
import LocationPin from "./template-assets/location.png";
import No from "./template-assets/no.png";
import NotoSansMedium from "./template-assets/NotoSansGeorgian-Medium.ttf";
import NotoSansRegular from "./template-assets/NotoSansGeorgian-Regular.ttf";
import ProfilePlaceholder from "./template-assets/profile-placeholder.jpg";
import OrganizationPlaceholder from "./template-assets/residential.png";
import Tick from "./template-assets/tick.png";
import Yes from "./template-assets/yes.png";

import { ICandidate } from "@/store/reducers/project/project.types";
import { ICriteria } from "@/store/reducers/vetting-criteria/vettingCriteria.slice";

Font.register({ family: "BOLD", src: NotoSansMedium });
Font.register({ family: "MEDIUM", src: NotoSansRegular });

export function trimText(text: string, maxLength: number) {
    if (!text) return "";
    if (text.length <= maxLength) {
        return text;
    } else {
        return `${text.substring(0, maxLength)}...`;
    }
}

const FONT_SIZE = {
    xl: 30,
    lg: 25,
    md: 20,
    sm: 15,
    xs: 10,
    "2xs": 8,
};

const BLUE_COLOR = "#175874";
const GREY_COLOR = "#5A5A5A";

const RenderVettingResults = ({ vettingCriteria, results }: { vettingCriteria: any[]; results: any[] }) => {
    if (!results || results.length === 0) {
        return (
            <View
                style={{
                    color: GREY_COLOR,
                    fontSize: FONT_SIZE.xs,
                    marginVertical: 20,
                    padding: 15,
                    borderRadius: 10,
                    border: "1px solid lightgrey",
                }}
            >
                <Text style={{ textAlign: "center" }}>Candidate was not vetted. Please try vetting the candidate</Text>
            </View>
        );
    }

    const totalWordCount = results?.reduce((currentValue, item) => {
        return item.text?.length + currentValue;
    }, 0);

    const SMALL_FONT_SIZE = totalWordCount >= 800;
    const UPPER_CAP = totalWordCount >= 1000;

    // sorting the results [...MUST HAVE , ...GOOD TO HAVE]
    let sortedResults = [...results].sort((a, b) => {
        const aTag = vettingCriteria.find((item) => {
            return item.text === a.criterion;
        })?.tag;
        const bTag = vettingCriteria.find((item) => item.text === b.criterion)?.tag;
        if (aTag === "MUST" && bTag !== "MUST") return -1;
        if (aTag !== "MUST" && bTag === "MUST") return 1;
        if (aTag === "GOOD" && bTag === "MUST") return 1;
        if (aTag === "MUST" && bTag === "GOOD") return -1;
        return 0;
    });

    if (UPPER_CAP) {
        sortedResults = sortedResults.splice(0, 8);
    }

    if (sortedResults.length <= 5) {
        return (
            <GRID shouldBreak={false}>
                {sortedResults.map((item, index) => {
                    return (
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 5,
                                width: "100%",
                                alignItems: "flex-start",
                            }}
                        >
                            <Text>{index + 1} .</Text>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    gap: 15,
                                    flexGrow: 1,
                                }}
                            >
                                <Text style={{ maxWidth: 500 }}>{item?.text}</Text>
                                {item?.intent === 1 ? (
                                    <Image style={{ width: 15, height: 15 }} src={Tick} />
                                ) : (
                                    <Image style={{ width: 15, height: 15 }} src={Cross} />
                                )}
                            </View>
                        </View>
                    );
                })}
            </GRID>
        );
    }
    const middleIndex = Math.floor(sortedResults.length / 2);
    const firstHalf = sortedResults?.slice(0, middleIndex + (sortedResults.length % 2));
    const secondHalf = sortedResults?.slice(middleIndex + (sortedResults.length % 2));

    return (
        <GRID shouldBreak={true}>
            <View style={{ fontSize: SMALL_FONT_SIZE ? FONT_SIZE["2xs"] : FONT_SIZE["xs"], width: "100%" }}>
                {firstHalf.map((item, index) => {
                    return (
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                gap: 5,
                                maxWidth: 275,
                            }}
                        >
                            <Text>{index + 1} .</Text>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    gap: 15,
                                    flexGrow: 1,
                                }}
                            >
                                <Text style={{ maxWidth: 225 }}>{trimText(item?.text, 300)}</Text>
                                {item?.intent === 1 ? (
                                    <Image style={{ width: 15, height: 15 }} src={Tick} />
                                ) : (
                                    <Image style={{ width: 15, height: 15 }} src={Cross} />
                                )}
                            </View>
                        </View>
                    );
                })}
            </View>
            <View style={{ fontSize: SMALL_FONT_SIZE ? FONT_SIZE["2xs"] : FONT_SIZE["xs"], width: "100%" }}>
                {secondHalf.map((item, index) => {
                    return (
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                gap: 5,
                                maxWidth: 275,
                            }}
                        >
                            <Text>{firstHalf.length + index + 1} .</Text>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignContent: "flex-start",
                                    justifyContent: "space-between",
                                    gap: 15,
                                    flexGrow: 1,
                                }}
                            >
                                <Text style={{ maxWidth: 225 }}>{trimText(item?.text, 300)}</Text>
                                {item?.intent === 1 ? (
                                    <Image style={{ width: 15, height: 15 }} src={Tick} />
                                ) : (
                                    <Image style={{ width: 15, height: 15 }} src={Cross} />
                                )}
                            </View>
                        </View>
                    );
                })}
            </View>
        </GRID>
    );
};

const RenderExperiences = ({ results }: { results: any[] }) => {
    if (!results || results.length === 0) {
        return (
            <View
                style={{
                    color: GREY_COLOR,
                    fontSize: FONT_SIZE.xs,
                    marginVertical: 20,
                    padding: 15,
                    borderRadius: 10,
                    border: "1px solid lightgrey",
                }}
            >
                <Text style={{ textAlign: "center" }}>Candidate doesn't have any prior work experience</Text>
            </View>
        );
    }

    if (results.length <= 5) {
        return (
            <GRID shouldBreak={false}>
                {results.map((experience) => {
                    return (
                        <View
                            style={{
                                gap: 15,
                                alignItems: "flex-start",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                src={
                                    experience?.orgLogo &&
                                    experience?.orgLogo !== "-" &&
                                    experience?.orgLogo.startsWith("data:") === false
                                        ? experience.orgLogo
                                        : OrganizationPlaceholder
                                }
                            />
                            <View>
                                <Text>
                                    {experience?.position}
                                    <Link
                                        style={{
                                            color: "#1264a3",
                                        }}
                                        src={experience?.orgUrl}
                                    >
                                        {trimText(`@${experience?.org}`, 25)}
                                    </Link>
                                </Text>
                                <Text style={{ color: GREY_COLOR }}>{experience?.duration}</Text>
                            </View>
                        </View>
                    );
                })}
            </GRID>
        );
    }

    const slicedExperiences = results?.slice(0, 8);

    const middleIndex = Math.floor(slicedExperiences.length / 2);
    const firstHalf = slicedExperiences?.slice(0, middleIndex + (slicedExperiences.length % 2));
    const secondHalf = slicedExperiences?.slice(middleIndex + (slicedExperiences.length % 2));

    return (
        <GRID shouldBreak={true}>
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 15,
                    width: "100%",
                }}
            >
                {firstHalf.map((experience) => {
                    return (
                        <View
                            style={{
                                gap: 15,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                src={
                                    experience?.orgLogo &&
                                    experience?.orgLogo !== "-" &&
                                    experience?.orgLogo.startsWith("data:") === false
                                        ? experience.orgLogo
                                        : OrganizationPlaceholder
                                }
                            />
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                }}
                            >
                                <Text style={{ maxWidth: "200px" }}>
                                    {experience?.position}
                                    <Link
                                        style={{
                                            color: "#1264a3",
                                        }}
                                        src={experience?.orgUrl}
                                    >
                                        {trimText(`@${experience?.org}`, 25)}
                                    </Link>
                                </Text>
                                <Text style={{ color: GREY_COLOR }}>{experience?.duration}</Text>
                            </View>
                        </View>
                    );
                })}
            </View>
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                    width: "100%",
                }}
            >
                {secondHalf.map((experience) => {
                    return (
                        <View
                            style={{
                                gap: 15,
                                alignItems: "flex-start",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                src={
                                    experience?.orgLogo &&
                                    experience?.orgLogo !== "-" &&
                                    experience?.orgLogo.startsWith("data:") === false
                                        ? experience.orgLogo
                                        : OrganizationPlaceholder
                                }
                            />
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                }}
                            >
                                <Text style={{ maxWidth: "200px" }}>
                                    {experience?.position}
                                    <Link
                                        style={{
                                            color: "#1264a3",
                                        }}
                                        src={experience?.orgUrl}
                                    >
                                        {trimText(`@${experience?.org}`, 25)}
                                    </Link>
                                </Text>
                                <Text style={{ color: GREY_COLOR }}>{experience?.duration}</Text>
                            </View>
                        </View>
                    );
                })}
            </View>
        </GRID>
    );
};

const GRID = ({ children, shouldBreak }: { children: ReactNode; shouldBreak: boolean }) => {
    if (shouldBreak == false) {
        return (
            <View
                style={{
                    color: GREY_COLOR,
                    fontSize: FONT_SIZE.xs,
                    marginVertical: 15,
                    padding: 15,
                    borderRadius: 10,
                    border: "1px solid lightgrey",
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                }}
            >
                {children}
            </View>
        );
    }
    return (
        <View
            style={{
                color: GREY_COLOR,
                fontSize: FONT_SIZE.xs,
                marginVertical: 15,
                padding: 15,
                borderRadius: 10,
                border: "1px solid lightgrey",
                display: "flex",
                flexDirection: "row",
                gap: 20,
            }}
        >
            {children}
        </View>
    );
};

type PDFTemplateProps = {
    candidatesData: ICandidate[];
    vettingCriteria: ICriteria[];
};

const PDFTemplate = ({ candidatesData, vettingCriteria }: PDFTemplateProps) => {
    if (!vettingCriteria || !candidatesData) return null;

    return (
        <Document style={{ fontFamily: "MEDIUM" }}>
            <Page
                orientation="landscape"
                size={"A4"}
                style={{
                    paddingTop: 30,
                    paddingBottom: 30,
                    paddingLeft: 40,
                    paddingRight: 40,
                }}
            >
                <View>
                    <Text
                        style={{
                            fontSize: FONT_SIZE.md,
                            color: BLUE_COLOR,
                        }}
                    >
                        The criteria used in screening potential candidates
                    </Text>

                    <View
                        style={{
                            marginTop: 15,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: FONT_SIZE.sm,
                            borderBottom: "0.5px solid #494949",
                            gap: 5,
                        }}
                    >
                        <View style={{ flexGrow: 1 }}>
                            <Text>Vetting Criteria</Text>
                        </View>
                        <View
                            style={{
                                paddingVertical: 10,
                                paddingHorizontal: 5,
                                flexShrink: 0,
                                width: 250,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Text>Must have</Text>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Text>Good to have</Text>
                            </View>
                        </View>
                    </View>

                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            marginTop: 25,
                        }}
                    >
                        {vettingCriteria?.length ? (
                            vettingCriteria?.map((criteria) => {
                                return (
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            fontSize: 12,
                                            color: GREY_COLOR,
                                            gap: 5,
                                        }}
                                    >
                                        <View
                                            style={{
                                                border: "0.5px solid #494949",
                                                paddingVertical: 10,
                                                paddingHorizontal: 5,
                                                flexGrow: 1,
                                                flex: 1,
                                                borderRadius: 5,
                                            }}
                                        >
                                            <Text>{criteria?.text}</Text>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical: 10,
                                                paddingHorizontal: 5,
                                                flexShrink: 0,
                                                width: 250,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-evenly",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Image
                                                    style={{
                                                        width: 18,
                                                        height: 18,
                                                    }}
                                                    src={criteria?.tag === "MUST" ? Yes : No}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Image
                                                    style={{
                                                        width: 18,
                                                        height: 18,
                                                    }}
                                                    src={criteria?.tag === "GOOD" ? Yes : No}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                );
                            })
                        ) : (
                            <Text>No vetting criteria selected </Text>
                        )}
                    </View>
                </View>
            </Page>
            {candidatesData.map((candidateData) => {
                return (
                    <Page orientation="landscape" size={"A4"}>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <View
                                // @ts-ignore
                                style={{
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    height: "100vh",
                                    backgroundColor: BLUE_COLOR,
                                    width: "200px",
                                    flexShrink: "0 !important",
                                    display: "flex",
                                    gap: 20,
                                    color: "white",
                                    alignItems: "flex-start",
                                }}
                            >
                                <View
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        gap: 20,
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: 10,
                                            width: "100%",
                                        }}
                                    >
                                        <View
                                            style={{
                                                width: 50,
                                                height: 50,
                                                borderRadius: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            <Image
                                                src={ProfilePlaceholder}
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: 50,
                                                    height: 50,
                                                    borderRadius: "100%",
                                                }}
                                            />
                                            <Image
                                                src={
                                                    candidateData?.profileImage &&
                                                    candidateData?.profileImage !== "-" &&
                                                    !candidateData?.profileImage.toLowerCase().endsWith(".svg") &&
                                                    candidateData?.profileImage.startsWith("data:") === false
                                                        ? candidateData?.profileImage
                                                        : ProfilePlaceholder
                                                }
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: 50,
                                                    height: 50,
                                                    borderRadius: "100%",
                                                }}
                                            />
                                        </View>

                                        <Text
                                            style={{
                                                flex: 1,
                                                color: "white",
                                                fontFamily: "BOLD",
                                            }}
                                        >
                                            {candidateData?.name}
                                        </Text>
                                    </View>
                                    <Text style={{ fontSize: FONT_SIZE.xs }}>
                                        {trimText(candidateData?.title, 150)}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: 10,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: "BOLD",
                                            fontSize: FONT_SIZE.sm,
                                        }}
                                    >
                                        Info
                                    </Text>
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 8,
                                            fontSize: FONT_SIZE.xs,
                                        }}
                                    >
                                        {candidateData?.location && (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Image
                                                    src={LocationPin}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                                <Text
                                                    style={{
                                                        maxWidth: "100px",
                                                    }}
                                                >
                                                    {candidateData.location}
                                                </Text>
                                            </View>
                                        )}
                                        {/* {candidateData?.primaryEmail && (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                }}
                                            >
                                                <Image
                                                    src={Message}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                                <Text>{trimText(candidateData.primaryEmail, 30)}</Text>
                                            </View>
                                        )} */}
                                        {candidateData?.profileUrl && (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                }}
                                            >
                                                <Image
                                                    src={Linkedin}
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                                <Link src={candidateData?.profileUrl}>
                                                    <Text
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        {trimText(candidateData?.profileUrl, 30)}
                                                    </Text>
                                                </Link>
                                            </View>
                                        )}
                                    </View>
                                </View>

                                {candidateData?.skills?.length ? (
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                fontSize: FONT_SIZE.sm,
                                            }}
                                        >
                                            Key Skills
                                        </Text>
                                        <View
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 5,
                                                fontSize: FONT_SIZE.xs,
                                            }}
                                        >
                                            {candidateData.skills.map((skillItem, index) => {
                                                if (index >= 10) return null;
                                                return (
                                                    <Text
                                                        style={{
                                                            fontSize: FONT_SIZE.xs,
                                                        }}
                                                    >
                                                        {trimText(skillItem?.name, 25)}
                                                    </Text>
                                                );
                                            })}
                                        </View>
                                    </View>
                                ) : null}

                                {candidateData?.languages?.length ? (
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                fontSize: FONT_SIZE.sm,
                                            }}
                                        >
                                            Languages
                                        </Text>
                                        <View
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 5,
                                                fontSize: FONT_SIZE.xs,
                                            }}
                                        >
                                            {candidateData.languages.map((language) => {
                                                return (
                                                    <Text
                                                        style={{
                                                            fontSize: FONT_SIZE.xs,
                                                        }}
                                                    >
                                                        {language?.name}
                                                    </Text>
                                                );
                                            })}
                                        </View>
                                    </View>
                                ) : null}

                                {/* {candidateData?.certificates?.length &&
                                candidateData?.certificates?.some(
                                    (item) => item?.course
                                ) ? (
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: "BOLD",
                                                fontSize: FONT_SIZE.sm,
                                            }}
                                        >
                                            Certificates
                                        </Text>
                                        <View
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 5,
                                                fontSize: FONT_SIZE.xs,
                                            }}
                                        >
                                            {candidateData?.certificates?.map(
                                                (certificate) => {
                                                    return (
                                                        <Text
                                                            style={{
                                                                fontSize:
                                                                    FONT_SIZE.xs,
                                                            }}
                                                        >
                                                            {trimText(
                                                                certificate.course,
                                                                25
                                                            )}
                                                        </Text>
                                                    );
                                                }
                                            )}
                                        </View>
                                    </View>
                                ) : null} */}

                                <View style={{ marginTop: "auto" }}>
                                    <Text style={{ fontSize: FONT_SIZE["2xs"] }}>
                                        Note: This candidate review and summary is AI-generated on EasySource. User is
                                        advised to review the summary before further actions
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    height: "100vh",
                                    flexGrow: 1,
                                }}
                            >
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text>AI Executive Summary</Text>
                                    {candidateData?.starRating?.value === -1 ? (
                                        <Text
                                            style={{
                                                padding: 5,
                                                backgroundColor: BLUE_COLOR,
                                                borderRadius: 999,
                                                color: "white",
                                                fontSize: FONT_SIZE["2xs"],
                                            }}
                                        >
                                            Unqualified
                                        </Text>
                                    ) : (
                                        <View
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                fontSize: FONT_SIZE.xs,
                                                gap: 10,
                                            }}
                                        >
                                            <Text>Star Rating</Text>
                                            <View
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: FONT_SIZE.xs,
                                                    flexDirection: "row",
                                                    gap: 2,
                                                }}
                                            >
                                                {Array.from(
                                                    {
                                                        length: Math.min(candidateData?.starRating?.value, 5),
                                                    },
                                                    (_) => (
                                                        <Image
                                                            style={{
                                                                width: 15,
                                                                height: 15,
                                                            }}
                                                            src={FullStar}
                                                        />
                                                    )
                                                )}
                                                {Array.from(
                                                    {
                                                        length: 5 - Math.min(candidateData?.starRating?.value, 5),
                                                    },
                                                    (_) => (
                                                        <Image
                                                            style={{
                                                                width: 15,
                                                                height: 15,
                                                            }}
                                                            src={GreyStar}
                                                        />
                                                    )
                                                )}
                                            </View>
                                        </View>
                                    )}
                                </View>

                                <RenderVettingResults
                                    vettingCriteria={vettingCriteria}
                                    results={candidateData?.starRating?.description}
                                />

                                <View>
                                    <Text>Key Experiences </Text>
                                    <RenderExperiences results={candidateData?.experience} />
                                </View>
                            </View>
                        </View>
                    </Page>
                );
            })}
        </Document>
    );
};

export default PDFTemplate;
