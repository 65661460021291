import { Typography } from "@/ui";

type CandidatesReachoutEmptyPlaceholderProps = {
    icon: React.ReactNode;
    trigger?: React.ReactNode;
    message: string;
    title: string;
};

export function CandidatesReachoutEmptyPlaceholder({
    icon,
    message,
    title,
    trigger,
}: CandidatesReachoutEmptyPlaceholderProps) {
    return (
        <div className="flex flex-col items-center gap-1 py-10">
            {icon}
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body2" align="center">
                {message}
            </Typography>
            {trigger}
        </div>
    );
}
