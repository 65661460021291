import * as React from "react";

import { cn } from "@/lib/utils";

export interface ProgressProps extends React.HTMLAttributes<HTMLDivElement> {
    value?: number;
    variant?: "determinate" | "indeterminate";
    color?: "primary" | "secondary";
    thickness?: number;
}

/**
 * A Progress component that displays a progress bar.
 * 
 * @component
 * @param {string} className - Additional class names to apply to the root element.
 * @param {number} value - The current value of the progress bar (used when variant is "determinate").
 * @param {"determinate" | "indeterminate"} [variant="determinate"] - The variant of the progress bar.
 * @param {"primary" | "secondary"} [color="primary"] - The color of the progress bar.
 * @param {number} [thickness=4] - The thickness of the progress bar.
 * @param {React.Ref<HTMLDivElement>} ref - The ref to be forwarded to the root element.
 * @param {React.HTMLAttributes<HTMLDivElement>} props - Additional props to apply to the root element.
 * 
 * @returns {JSX.Element} The rendered progress bar component.
 */
const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(
    ({ className, value, variant = "determinate", color = "primary", thickness = 4, ...props }, ref) => {
        const rootClassName = cn(
            "relative overflow-hidden",
            variant === "determinate" ? "transition-all" : "",
            className
        );

        const barClassName = cn(
            "absolute top-0 left-0 w-full h-full transition-transform",
            color === "primary" ? "bg-cyan-600" : "bg-blue-500",
            variant === "indeterminate" ? "animate-indeterminate" : ""
        );

        return (
            <div ref={ref} className={rootClassName} style={{ height: thickness }} {...props}>
                <div
                    className={barClassName}
                    style={{
                        transform: variant === "determinate" ? `translateX(${value ? value - 100 : -100}%)` : undefined,
                    }}
                />
            </div>
        );
    }
);

Progress.displayName = "Progress";

export { Progress };
