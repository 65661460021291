import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useUpdateContactOverviewActivityMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import { useFetchActivityTags } from "../AllCandidatesContent";

import { cn } from "@/lib/utils";
import {
    ActivityColumn,
    CommonActivityCellProps,
} from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Chip } from "@/ui";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/ui/Dropdown/Dropdown";

export function ActivityType({
    value,
    activityId,
}: { value: ActivityColumn["activityType"] } & CommonActivityCellProps) {
    const disaptch = useDispatch();
    const [tag, setTag] = useState<string>(() => value);
    const { data = [] } = useFetchActivityTags();
    useEffect(() => {
        setTag(value);
    }, [value]);

    const [updateContactOverviewActivityMutation] = useUpdateContactOverviewActivityMutation();

    const handleActivityTypeUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: activityId,
            tag: value,
        })
            .unwrap()
            .then(() => {
                disaptch(setSuccessNotification("Activity tag updated successfully"));
            })
            .catch(() => {
                disaptch(setSuccessNotification("Error while updating activity tag"));
            });
    };

    const handleMenuItemClick = (value: string) => {
        const tagValue = value;
        setTag(tagValue);
        handleActivityTypeUpdate(tagValue);
    };

    return (
        <>
            {/* @ts-ignore */}
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <div>
                        <Chip
                            variant="secondary"
                            children={
                                <>
                                    <FiberManualRecordIcon sx={{ fontSize: "13px", marginRight: "4px" }} />
                                    {tag}
                                </>
                            }
                            className="flex flex-row text-xs w-fit hover:cursor-pointer"
                        />
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="right" className={cn("overflow-y-auto max-h-[80dvh] ")}>
                    {data.map((i) => {
                        if (!i) {
                            return null;
                        }

                        return (
                            <DropdownMenuItem className="text-xs my-1" key={i} onClick={() => handleMenuItemClick(i)}>
                                {i}
                            </DropdownMenuItem>
                        );
                    })}
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
}
