import { Button, Typography } from "@/ui";

const GiftCard = ({ product, index, handleDetailedView, cardRefs, imageUrl }) => {
    return (
        <div
            ref={(el) => {
                if (el) {
                    cardRefs.current[product.id] = el;
                }
            }}
            style={{
                width: 300,
                height: 380,
                marginBottom: "20px",
                marginRight: "10px",
                borderRadius: "10px",
                overflow: "hidden",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                border: "1px solid lightgrey",
            }}
        >
            {/* Image Container */}
            <div style={{ height: 200, width: "100%", marginBottom: "12px" }}>
                <img
                    src={imageUrl}
                    loading="lazy"
                    alt=""
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "4px",
                    }}
                />
            </div>

            {/* Content Container */}
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Typography className="text-[0.8rem] mb-2">{product?.name}</Typography>

                {/* Price and Button Container */}
                <div className="flex flex-row mt-auto items-center">
                    <Typography className="text-[1rem] font-semibold">
                        $ {product?.variants[0].estimated_total_price?.price_per_unit}
                    </Typography>
                    <Button className="ml-auto font-semibold" size="sm" onClick={() => handleDetailedView(product)}>
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default GiftCard;
