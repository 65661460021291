import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState , useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { addToOracle, getFromOracle, getOracleJobs, selectOracleJobs } from "@/store/reducers/integrations/integrations.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button , Typography  , Tooltip} from "@/ui";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";// Create a custom Paper component for dropdown styling
import Loader from "@/components/Loader/Loader";
const CustomPaper = styled(Paper)(({ theme }) => ({
    width: "450px", // Set your desired dropdown width
    maxHeight: "400px", // Optional: Adjust dropdown height
    overflow: "auto", // Handle long content
}));
type OracleFlowModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
};

export const CustomErrorTypography = ({children} : {children: React.ReactNode}) => {
    return <Typography className="text-red-500 text-center mt-4" variant="body2">{children}</Typography>

}


type OracleJob = {
    RequisitionNumber: string;
    RequisitionId: number;
    Title: string;
    PhaseName: string;
    StateName: string;
};

export default function OracleFlowModal({ open, onClose, id, selectedCandidateIds, variant }: OracleFlowModalProps) {
    const dispatch = useDispatch();
    const oracleFlowJobs: OracleJob[] = useSelector(selectOracleJobs) || [];

    const isOracleFlowSubmitting = useSelector(checkIfLoading(addToOracle.type));
    const isOracleFlowFetching = useSelector(checkIfLoading(getOracleJobs.type));
    const isVariantFetch = variant === "FETCH";
    const [jobId, setJobId] = useState<string>("");
    const [searchValue, setSearchValue] = useState("");
    const [formState, setFormState] = useState({
        startDate: null,
        endDate: null,
    });

    const filteredOptions = useMemo(() => {
        if (!searchValue) return oracleFlowJobs;
        const searchLower = searchValue.toLowerCase();
        return oracleFlowJobs.filter(
            (job) =>
                job.Title.toLowerCase().includes(searchLower) ||
                job.RequisitionNumber.toLowerCase().includes(searchLower)
        );
    }, [oracleFlowJobs, searchValue]);

    useEffect(() => {
        if (open) {
            dispatch(getOracleJobs(getOracleJobs.type));
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        setFormState({
            startDate: null,
            endDate: null,
        });
        setSearchValue("");
        onClose();
    };

    const handleJobChange = (event: React.SyntheticEvent, newValue: OracleJob | null) => {
        setJobId(newValue ? newValue.RequisitionNumber : "");
    };

    const handleInputChange = (event: React.SyntheticEvent, newValue: string) => {
        setSearchValue(newValue || "");
    };

    const handleAddOracleFlow = (isAIVetting?: boolean) => {

        if (jobId === "") {
            dispatch(setErrorNotification("Please select job"));
            return;
        }


        dispatch(
            isVariantFetch
                ? getFromOracle({
                    projectId: Number(id),
                    ...(formState.startDate && { startDate: formState.startDate }),
                    ...(formState.endDate && { endDate: formState.endDate }),
                    jobId: Number(jobId),
                })
                : addToOracle({
                    projectId: Number(id),
                    candidateIds: selectedCandidateIds,
                    selectAll: false,
                    jobId: Number(jobId),
                    ...(isAIVetting && { AddAIVettingOnly: isAIVetting })
                })
        );




        handleCloseModal();
    };

    const handleFormChange = (key: string) => (date: Date | null) => {
        setFormState((prev) => ({ ...prev, [key]: date }));
    };

    const isDisabled = jobId === "";
    const isLoading = isOracleFlowSubmitting || isOracleFlowFetching;
    const showDateAndTime = isVariantFetch && oracleFlowJobs.length > 0 && !isOracleFlowFetching;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"sm"}>
                <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} OracleFlow</DialogTitle>
                <DialogContent>
                    {showDateAndTime && <div className="flex space-x-2 mt-2 mb-2">
                        <DateTimePicker
                            label="Select created after"
                            value={formState.startDate}
                            onChange={handleFormChange("startDate")} 
                            format="YYYY-MM-DD HH:mm:ss"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: "small",
                                },
                            }}
                        />

                        <DateTimePicker
                            label="Select created before"
                            value={formState.endDate}
                            onChange={handleFormChange("endDate")}
                            format="YYYY-MM-DD HH:mm:ss"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: "small",
                                },
                            }}
                        />
                    </div>}
                    <div className="space-y-2">

                        {isOracleFlowFetching ? (
                            <div className="mt-7">
                            <Loader />
                            </div>
                        ) : oracleFlowJobs.length ? (
                            <div className="flex items-center justify-between space-x-2 m-3">
                                <Typography>Select job:</Typography>

                                <Autocomplete
                                    value={oracleFlowJobs.find((job) => job.RequisitionNumber === jobId) || null}
                                    onChange={handleJobChange}
                                    inputValue={searchValue}
                                    onInputChange={handleInputChange}
                                    options={filteredOptions}
                                    getOptionLabel={(option) => option.Title}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            key={option.RequisitionNumber}
                                            className="flex justify-between p-2 w-full"
                                        >
                                            <Tooltip title={option.Title} arrow>
                                                <span  className="w-[50%]">
                                                    <Typography noWrap>{`${option.Title}`}</Typography>
                                                </span>
                                            </Tooltip>
                                            <Typography variant="caption">
                                                ({option.RequisitionNumber})
                                            </Typography>
                                            <Typography variant="caption" className="w-[30%] text-right">
                                                {option.PhaseName} - {option.StateName}
                                            </Typography>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            placeholder="Search jobs..."
                                            sx={{
                                                width: 300, // Keeps the input width fixed
                                                "& .MuiInputBase-input": {
                                                    fontSize: 14,
                                                },
                                            }}
                                        />
                                    )}
                                    noOptionsText="No jobs found"
                                    isOptionEqualToValue={(option, value) =>
                                        option.RequisitionNumber === value.RequisitionNumber
                                    }
                                    openOnFocus
                                    PaperComponent={CustomPaper} // Use the custom Paper here
                                />
                            </div>
                        ) : (
                            <CustomErrorTypography>
                                No jobs found. Create one on your OracleFlow dashboard
                            </CustomErrorTypography>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outline" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button disabled={isDisabled} onClick={() => handleAddOracleFlow(false)}>
                        <ButtonTextWithLoading isLoading={isLoading} text="Confirm" />
                    </Button>
                    {/* {!isVariantFetch && <Button disabled={isDisabled} onClick={() => handleAddOracleFlow(true)}>
                        <ButtonTextWithLoading isLoading={isLoading} text="Vetting Only" />
                    </Button>} */}
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
}
